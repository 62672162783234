import * as React from "react";

import styles from "./BacklogAppPage.module.scss";
import {PopupDialogConnected} from "../../components/PopupDialog/PopupDialogConnected";
import {EntryListExternalConnected} from "../../components/EntryList/EntryListExternal/EntryListExternalConnected";

export class BacklogAppPage extends React.PureComponent {
    public render() {
        return (
            <div className={styles.container}>
                <PopupDialogConnected />
                <EntryListExternalConnected />
            </div>
        );
    }
}
