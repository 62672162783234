import React from "react";
import styles from "./Action.module.scss";
import {ActionVariant} from "../../ducks/action/action";
import {UserRole} from "../../api/userApi";

export interface ActionStateProps {
    actionVariant: ActionVariant;
    userRole: UserRole | undefined;
}

export interface ActionDispatchProps {
    onSupport: (visible: boolean) => void;
    onActionChange: (value: string) => void;
    onActionValidate: (action: ActionVariant) => void;
}

interface ILinkButton {
    className: string;
    title: string;
    actionVariant: ActionVariant;
    visible: boolean;
}

type ActionProps = ActionStateProps & ActionDispatchProps;

export class Action extends React.PureComponent<ActionProps> {
    render() {
        const { actionVariant, userRole } = this.props;

        const backlog = userRole === UserRole.ROLE_ADMIN || userRole === UserRole.ROLE_MAIN_ANNOTATOR;
        const BUTTONS: ILinkButton[] = [
            {
                title: "Search",
                actionVariant: ActionVariant.VARIANT_SEARCH,
                className: styles.searchIcon,
                visible: true,
            },
            {
                title: "Manual addition",
                actionVariant: ActionVariant.VARIANT_MANUAL,
                className: styles.editIcon,
                visible: true,
            },
            {
                title: "Upload CSV",
                actionVariant: ActionVariant.VARIANT_UPLOAD,
                className: styles.uploadIcon,
                visible: true,
            },
            {
                title: "Backlog",
                actionVariant: ActionVariant.VARIANT_BACKLOG,
                className: styles.backlogIcon,
                visible: backlog,
            },
            {
                title: "External additions",
                actionVariant: ActionVariant.VARIANT_EXTERNAL_APP,
                className: styles.externalIcon,
                visible: true,
            },
        ];

        return (
            <div className={styles.container}>
                {BUTTONS.map(elem => {
                    return (
                        elem.visible && (
                            <div
                                onClick={() => {
                                    this.props.onActionValidate(elem.actionVariant);
                                }}
                                className={styles.action}
                            >
                                <div className={styles.leftPart}>
                                    <i className={elem.className} />
                                </div>
                                <div className={styles.rightPart}>{elem.title}</div>
                            </div>
                        )
                    );
                })}
            </div>
        );
    }
}
