import {combineReducers} from "redux";
import {authReducer, AuthState} from "./auth/auth";

import {createBrowserHistory} from "history";
import {connectRouter, RouterState} from "connected-react-router";
import {usersReducer, UsersState} from "./users/users";
import {supportReducer, SupportState} from "./support/support";
import {actionReducer, ActionState} from "./action/action";
import {uploadReducer, UploadState} from "./upload/upload";
import {entriesReducer, EntriesState} from "./entries/entries";
import {popupReducer, PopupState} from "./popup/popup";

export const history = createBrowserHistory();

export interface State {
    auth: AuthState;
    users: UsersState;
    support: SupportState;
    action: ActionState;
    upload: UploadState;
    entries: EntriesState;
    popup: PopupState;

    router: RouterState;
}

export const rootReducer = combineReducers<State>({
    auth: authReducer,
    users: usersReducer,
    support: supportReducer,
    action: actionReducer,
    upload: uploadReducer,
    entries: entriesReducer,
    popup: popupReducer,

    router: connectRouter(history),
});
