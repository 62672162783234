import * as React from "react";

import styles from "./UploadPage.module.scss";
import {UploadConnected} from "../../components/Upload/UploadConnected";
import {SupportConnected} from "../../components/Support/SupportConnected";


export class UploadPage extends React.PureComponent {
    public render() {
        return (
            <div className={styles.container}>
                <SupportConnected />
                <UploadConnected />
            </div>
        );
    }
}
