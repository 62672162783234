import {Endpoints} from "./endpoints";
import {BaseApi} from "./baseApi";

class ObjectIdApi extends BaseApi {
    Endpoint = Endpoints.AppObjectId;
}

export function getObjectIdApiObj() {
    return new ObjectIdApi();
}
