import React from "react";

import styles from "./Upload.module.scss";
import HelpIcon from "@material-ui/icons/ContactSupportOutlined";
import FormControl from "@material-ui/core/FormControl";
import {InputFileConnected} from "../InputFile/InputFileConnected";
import {Button} from "@material-ui/core";
import {CSVSeparators, UploadResult, UploadStatus} from "../../ducks/upload/upload";

import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {IUploadFile} from "../../api/entryApi";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

export interface UploadStateProps {
    resultMessage: string | undefined;
    file: File | null;
    uploadStatus: UploadStatus;
    uploadResult: UploadResult | undefined;
}

export interface UploadDispatchProps {
    onSupport: (visible: boolean) => void;
    onFileUpload: (file: IUploadFile) => void;
}

type UploadProps = UploadStateProps & UploadDispatchProps;

interface UploadState {
    header: boolean;
    separator: CSVSeparators;
}

export class Upload extends React.Component<UploadProps, UploadState> {
    state = {
        header: true,
        separator: CSVSeparators.SEMICOLON,
    };

    private handlerUpload = () => {
        const { file } = this.props;
        const { header, separator } = this.state;

        if (file !== null) {
            this.props.onFileUpload({ header, separator, file });
        }
    };

    private handleChange = (name: keyof UploadState) => (event: any) => {
        let value;
        switch (name) {
            case "header":
                value = event.target.checked;
                break;
            default:
                value = event.target.value;
        }

        this.setState({
            ...this.state,
            [name]: value,
        });
    };

    render() {
        const { resultMessage, file, uploadStatus, uploadResult } = this.props;

        const visibleLoader = uploadStatus === UploadStatus.SENDING;
        const visibleError = [UploadStatus.AFTER_UPLOAD_ERROR, UploadStatus.AFTER_OTHER_ERROR].includes(uploadStatus);
        const visibleErrorInfo = UploadStatus.AFTER_UPLOAD_ERROR === uploadStatus;

        const displayErrorResult = [];
        if (uploadResult && uploadResult.error.length) {
            for (let i = 0; i < uploadResult.error.length; i++) {
                for (let j = 0; j < uploadResult.error[i].messages.length; j++) {
                    displayErrorResult.push(`Row ${uploadResult.error[i].row}: ${uploadResult.error[i].messages[j]}`);
                }
            }
        }

        return (
            <div className={styles.container}>
                <div style={{ display: visibleLoader ? "flex" : "none" }} className={styles.loaderContainer}>
                    <Loader type="Oval" color="#ff4646" height={100} width={100} visible={visibleLoader} />
                </div>
                <FormControl component="fieldset" style={{ display: "flex", alignItems: "center" }}>
                    {!visibleError && <div className={styles.successResult}>{resultMessage}</div>}
                    <div className={styles.uploadContainer}>
                        Upload you CSV&nbsp;&nbsp;
                        <HelpIcon className={styles.uploadHelpIcon} onClick={() => this.props.onSupport(true)} />
                        &nbsp;&nbsp;
                        <InputFileConnected />
                    </div>
                </FormControl>
                <div className={styles.chooseSeparators}>
                    <FormControl>
                        <FormLabel><span className={styles.text}>Separator</span></FormLabel>
                        <div>
                            <FormControlLabel
                                value={<span className={styles.text}>{CSVSeparators.SEMICOLON}</span>}
                                control={
                                    <Radio
                                        className={styles.inputRadioCheckbox}
                                        value={CSVSeparators.SEMICOLON}
                                        checked={this.state.separator === CSVSeparators.SEMICOLON}
                                        onChange={this.handleChange("separator")}
                                    />
                                }
                                label={CSVSeparators.SEMICOLON}
                            />
                            <FormControlLabel
                                value={CSVSeparators.COMMA}
                                control={
                                    <Radio
                                        className={styles.inputRadioCheckbox}
                                        value={CSVSeparators.COMMA}
                                        checked={this.state.separator === CSVSeparators.COMMA}
                                        onChange={this.handleChange("separator")}
                                    />
                                }
                                label={<span className={styles.text}>{CSVSeparators.COMMA}</span>}
                            />
                        </div>
                    </FormControl>
                    <FormControl>
                        <FormLabel><span className={styles.text}>Header</span></FormLabel>
                        <Checkbox  className={styles.inputRadioCheckbox} checked={this.state.header} onChange={this.handleChange("header")} />
                    </FormControl>
                </div>
                {visibleError && <div className={styles.errorResult}>{resultMessage}</div>}
                <div style={{ display: visibleErrorInfo ? "block" : "none" }} className={styles.uploadErrorsContainer}>
                    <div className={`${styles.uploadTitle} ${styles.uploadErrorsTitle}`}>
                        The following errors were found in the file:
                    </div>
                    <ul className={styles.uploadErrors}>
                        <div className={styles.errorsListContainer}>
                            {displayErrorResult.map(error => (
                                <li key={error + Math.random()}>{error}</li>
                            ))}
                        </div>
                    </ul>
                </div>
                <Button
                    disabled={file === null}
                    onClick={this.handlerUpload}
                    className={`${styles.uploadCheckButton} ${file === null ? styles.disabled : ""}`}
                    variant="contained"
                >
                    <span>Run checks</span>
                </Button>
            </div>
        );
    }
}
