import React, {useState} from "react";
import styles from "./SearchList.module.scss";
import {UserRole} from "../../../api/userApi";
import {AdditionalInformationStage, ILemmaSearchField, ILemmaSearchItem,} from "../../../ducks/entries/entries";
import {ITerm, ITermQuery} from "../../../api/termApi";
import {IDeleteCheckQuery} from "../../../api/spellingApi";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import {ESortBy, ESortDirection} from "../SearchFilter/SearchFilter";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import {AdditionalInformationConnected} from "../AdditionalInformation/AdditionalInformationConnected";
import {IconButton} from "@material-ui/core";
import {
    AdditionalInformationEditorConnected
} from "../AdditionalInformationEditor/AdditionalInformationEditorConnected";
import {truncate} from "../../../helper/others";

export enum EDetailedFor {
    LEMMA = "lemma",
    SYNONYM = "synonym",
}

export interface IRequestFor {
    lemma: string;
    synonym: string;
    posTag: string;
    rowIndex: number;
    lemmaIndex: number;
    reqFor: EDetailedFor;
    lan: string;
    inserted_at?: string;
    inserted_by?: string;
    updated_at?: string;
    updated_by?: string;
}

export interface ISearchListStateProps {
    role: UserRole | undefined;
    entrySearch: ILemmaSearchItem[];
    entrySearchCount: number;
    detailedTerm: ITerm | undefined;
    detailedRow: ILemmaSearchField | undefined;
    username: string | undefined;
    stage: AdditionalInformationStage;
}

export interface ISearchListDispatchProps {
    getDetailedTerm: (props: ITermQuery, reqFor: IRequestFor, isSpelling?: boolean) => void;
    onSetLemmaSearch: (params: ILemmaSearchItem[]) => void;
    setDetailedTerm: (term: ITerm | undefined) => void;
    setDetailedRow: (params: ILemmaSearchField | undefined) => void;
    checkDelete: (params: IDeleteCheckQuery) => void;
    getSearchBySort: (sortBy: ESortBy, direction: ESortDirection) => void;
    setAdditionalInformationStage: (stage: AdditionalInformationStage) => void;
    setEditableTerm: (item: ITerm | undefined) => void;
}

export type ISearchListProps = ISearchListStateProps & ISearchListDispatchProps;

export interface ISearchListState {
    langSort: ESortDirection;
    lemmaSort: ESortDirection;
    synonymSort: ESortDirection;
}

export const initialSearchListState: ISearchListState = {
    langSort: ESortDirection.DESC,
    lemmaSort: ESortDirection.DESC,
    synonymSort: ESortDirection.DESC,
};
export default function SearchList(props: ISearchListProps) {
    const [state, setState] = useState({...initialSearchListState});
    const {entrySearch, detailedTerm, detailedRow, role} = props;
    const {synonymSort, lemmaSort, langSort} = state;

    return (
        <div className={styles.listContainer}>
            <div className={styles.leftPart}>
                <div
                    className={styles.topRow}
                >
                    <div className={styles.row}>
                        <div
                            onClick={() => {
                                setState(prevState => {
                                    return {
                                        ...prevState,
                                        langSort:
                                            langSort === ESortDirection.DESC ? ESortDirection.ASC : ESortDirection.DESC,
                                    };
                                });
                            }}
                            className={styles.languageColumn}
                        >
                            Language
                        </div>
                        <div
                            onClick={() => {
                                const sortDirection =
                                    lemmaSort === ESortDirection.DESC ? ESortDirection.ASC : ESortDirection.DESC;
                                setState(prevState => {
                                    return {
                                        ...prevState,
                                        lemmaSort: sortDirection,
                                    };
                                });
                                props.getSearchBySort(ESortBy.LEMMA, sortDirection);
                            }}
                            className={`${styles.column} ${styles.lemmaColumn}`}
                        >
                            Lemma
                            <div className={styles.columnSort}>
                                {lemmaSort !== ESortDirection.DESC ? <ArrowUpwardIcon/> : <ArrowDownwardIcon/>}
                            </div>
                        </div>
                        <div
                            className={styles.column}
                            onClick={() => {
                                const sortDirection =
                                    synonymSort === ESortDirection.DESC ? ESortDirection.ASC : ESortDirection.DESC;
                                setState(prevState => {
                                    return {
                                        ...prevState,
                                        synonymSort: sortDirection,
                                    };
                                });
                                props.getSearchBySort(ESortBy.SYNONYM, sortDirection);
                            }}
                        >
                            Synonym
                            <div className={styles.columnSort}>
                                {synonymSort !== ESortDirection.DESC ? <ArrowUpwardIcon/> : <ArrowDownwardIcon/>}
                            </div>
                        </div>
                    </div>
                </div
                >
                <div className={styles.bottomRow}>
                    {entrySearch.length > 0 ?
                        entrySearch.map((elem, index) => {
                        const isRowEven = index % 2 > 0 ? `${styles.firstRow}` : `${styles.secondRow}`;
                        return (
                            <div key={`${index}${new Date().getTime()}`} className={`${styles.row}  ${isRowEven}`}>
                                <div className={styles.languageColumnRow}>{elem.lg}</div>
                                <div className={`${styles.columnRow}`}>
                                    {elem.lemmas.map((elemLemma, indexOfElemLemma) => {
                                        return (
                                            <React.Fragment
                                                key={`${indexOfElemLemma}${new Date().getTime()}`}
                                            >
                                                <div
                                                    title={elemLemma ? elemLemma.label : ""}
                                                    id={`row/Lemma/${index}`}
                                                    className={`${styles.columnRowElem} 
                                            ${
                                                        elem.lemmas[indexOfElemLemma].isClicked &&
                                                        detailedTerm && (detailedTerm.label === elemLemma.label && detailedTerm.posTag === elemLemma.posTag) &&
                                                        elem.reqFor === EDetailedFor.LEMMA
                                                            ? styles.highLight
                                                            : ""
                                                    } 
                                            `}
                                                    onClick={() => {
                                                        const prevExpanded =
                                                            props.entrySearch[index].lemmas[indexOfElemLemma].expanded;
                                                        let searchList = [...props.entrySearch];
                                                        searchList[index].lemmas[
                                                            indexOfElemLemma
                                                            ].expanded = searchList[index].lemmas[indexOfElemLemma].expanded
                                                            ? false
                                                            : true;
                                                        searchList[index].lemmas[
                                                            indexOfElemLemma
                                                            ].isClicked = searchList[index].lemmas[indexOfElemLemma]
                                                            .isClicked
                                                            ? false
                                                            : true;
                                                        searchList = searchList.map(
                                                            (searchListLemma, searchListIndex) => {
                                                                return {
                                                                    ...searchListLemma,
                                                                    lemmas: searchListLemma.lemmas.map(
                                                                        (lem, indexLem) => {
                                                                            if (
                                                                                !(
                                                                                    searchListIndex === index &&
                                                                                    indexLem === indexOfElemLemma
                                                                                )
                                                                            ) {
                                                                                lem = {...lem, isClicked: false};
                                                                            }
                                                                            return lem;
                                                                        },
                                                                    ),
                                                                };
                                                            },
                                                        );
                                                        if (
                                                            prevExpanded &&
                                                            ((detailedTerm && (detailedTerm.label !== elemLemma.label || detailedTerm.posTag !== elemLemma.posTag)) ||
                                                                !detailedTerm)
                                                        ) {
                                                            searchList[index].lemmas[
                                                                indexOfElemLemma
                                                                ].expanded = searchList[index].lemmas[
                                                                indexOfElemLemma
                                                                ].expanded = true;
                                                            searchList[index].lemmas[
                                                                indexOfElemLemma
                                                                ].isClicked = searchList[index].lemmas[
                                                                indexOfElemLemma
                                                                ].isClicked = true;
                                                        } else if (
                                                            !prevExpanded &&
                                                            elemLemma.requestData &&
                                                            detailedTerm &&
                                                            detailedTerm.label === elemLemma.label &&
                                                            detailedTerm.posTag === elemLemma.posTag
                                                        ) {
                                                            searchList[index].lemmas[
                                                                indexOfElemLemma
                                                                ].expanded = searchList[index].lemmas[
                                                                indexOfElemLemma
                                                                ].expanded = true;
                                                            searchList[index].lemmas[
                                                                indexOfElemLemma
                                                                ].isClicked = searchList[index].lemmas[
                                                                indexOfElemLemma
                                                                ].isClicked = true;
                                                        } else if (
                                                            prevExpanded &&
                                                            searchList[index].lemmas[indexOfElemLemma].expanded &&
                                                            elemLemma &&
                                                            detailedTerm &&
                                                            detailedTerm.label === elemLemma.label &&
                                                            detailedTerm.posTag === elemLemma.posTag
                                                        ) {
                                                            searchList[index].lemmas[
                                                                indexOfElemLemma
                                                                ].expanded  = true;
                                                            searchList[index].lemmas[
                                                                indexOfElemLemma
                                                                ].isClicked  = true;
                                                        }else if (
                                                            prevExpanded &&
                                                            !searchList[index].lemmas[indexOfElemLemma].expanded &&
                                                            elemLemma &&
                                                            detailedTerm &&
                                                            detailedTerm.label === elemLemma.label &&
                                                            detailedTerm.posTag === elemLemma.posTag && detailedTerm.isSynonym
                                                        ) {
                                                            searchList[index].lemmas[
                                                                indexOfElemLemma
                                                                ].expanded  = true;
                                                            searchList[index].lemmas[
                                                                indexOfElemLemma
                                                                ].isClicked  = true;
                                                        }
                                                        props.onSetLemmaSearch([...searchList]);
                                                        const conditionForRequest = !(
                                                            detailedTerm &&
                                                            detailedTerm.label === elemLemma.label && detailedTerm.posTag === elemLemma.posTag &&
                                                            detailedRow && !detailedRow.isSpelling &&
                                                            !detailedTerm.isSynonym &&
                                                            elem.reqFor &&
                                                            elem.reqFor === EDetailedFor.LEMMA &&
                                                            !elemLemma.expanded
                                                        );
                                                        !conditionForRequest && props.setDetailedTerm(undefined)
                                                        props.setDetailedRow(
                                                            !conditionForRequest
                                                                ? undefined
                                                                : {
                                                                    ...elemLemma,
                                                                    lang: elem.lg,
                                                                    synonym: elem.synonym.label,
                                                                    index: indexOfElemLemma,
                                                                    rowIndex: index,
                                                                    parentLemma: elemLemma ? elemLemma.label : "",
                                                                    reqFor: {
                                                                        lemmaIndex: -1,
                                                                        lemma: elem.lemmas[0] ? elem.lemmas[0].label : "",
                                                                        synonym: elem.synonym ? elem.synonym.label : "",
                                                                        posTag: elem.lemmas[0] ? elem.lemmas[0].posTag : "",
                                                                        lan: elem.lg,
                                                                        rowIndex: index,
                                                                        reqFor: EDetailedFor.SYNONYM,
                                                                    }
                                                                },
                                                        );
                                                        conditionForRequest &&
                                                        props.getDetailedTerm(
                                                            {
                                                                label: elemLemma ? elemLemma.label : "",
                                                                lang: elem.lg,
                                                                posTag: elemLemma ? elemLemma.posTag : "",
                                                                lemmaPosTag: elemLemma ? elemLemma.posTag : "",
                                                                lemma: elemLemma ? elemLemma.label : "",
                                                                synonym: elem.synonym ? elem.synonym.label : "",
                                                            },
                                                            {
                                                                ...elemLemma,
                                                                lemmaIndex: indexOfElemLemma,
                                                                lemma: elemLemma ? elemLemma.label : "",
                                                                synonym: elem.synonym ? elem.synonym.label : "",
                                                                posTag: elemLemma ? elemLemma.posTag : "",
                                                                lan: elem.lg,
                                                                rowIndex: index,
                                                                reqFor: EDetailedFor.LEMMA,
                                                            },
                                                        );
                                                    }}
                                                >
                                                    {truncate(elemLemma ? elemLemma.label : "", 30)}{" "}
                                                    <span className={styles.subElemSpan}>
                                                        {elemLemma ? elemLemma.posTag : ""}
                                                    </span>
                                                </div>
                                                {//@ts-ignore
                                                    elem.lemmas[indexOfElemLemma].expanded &&
                                                    elemLemma.requestData &&
                                                    elemLemma.requestData.map((lemma, requestDataIndex) => {
                                                        return (
                                                            <div
                                                                title={lemma ? lemma.label : ""}
                                                                className={`
                                                    ${styles.columnRowElem} 
                                                    ${styles.subElem}
                                                    ${
                                                                    elem.lemmas[indexOfElemLemma].expanded &&
                                                                    detailedRow &&
                                                                    (detailedRow!.index === indexOfElemLemma && detailedRow.rowIndex === index && detailedTerm &&
                                                                        lemma.label === detailedTerm!.label && lemma.posTag === detailedTerm.posTag && detailedRow.isSpelling) &&
                                                                    elem.reqFor === EDetailedFor.LEMMA
                                                                        ? styles.highLight
                                                                        : ""
                                                                }
                                                    `}
                                                                onClick={e => {
                                                                    e.stopPropagation();
                                                                    let searchList = [...props.entrySearch];
                                                                    searchList = searchList.map(
                                                                        (searchListLemma, searchListIndex) => {
                                                                            return {
                                                                                ...searchListLemma,
                                                                                lemmas: searchListLemma.lemmas.map(
                                                                                    (lem, indexLem) => {
                                                                                        return {
                                                                                            ...lem,
                                                                                            isClicked: false,
                                                                                        };
                                                                                    },
                                                                                ),
                                                                            };
                                                                        },
                                                                    );
                                                                    props.onSetLemmaSearch([...searchList]);
                                                                    detailedRow && detailedRow.label === lemma.label && detailedRow.posTag === lemma.posTag && detailedRow.isSpelling && (detailedRow.index === indexOfElemLemma && detailedRow.requestDataIndex === requestDataIndex) && props.setDetailedTerm(undefined)
                                                                    props.setDetailedRow(
                                                                        detailedRow && detailedRow.label === lemma.label && detailedRow.posTag === lemma.posTag && detailedRow.isSpelling && (detailedRow.index === indexOfElemLemma && detailedRow.requestDataIndex === requestDataIndex)
                                                                            ? undefined
                                                                            : {
                                                                                ...elemLemma,
                                                                                label: lemma.label,
                                                                                lang: elem.lg,
                                                                                synonym: elem.synonym.label,
                                                                                index: indexOfElemLemma,
                                                                                rowIndex: index,
                                                                                requestDataIndex,
                                                                                parentLemma: elemLemma
                                                                                    ? elemLemma.label
                                                                                    : "",
                                                                                reqFor: {
                                                                                    lemmaIndex: -1,
                                                                                    lemma: lemma ? lemma.label : "",
                                                                                    synonym: elem.synonym
                                                                                        ? elem.synonym.label
                                                                                        : "",
                                                                                    posTag: lemma ? lemma.posTag : "",
                                                                                    lan: elem.lg,
                                                                                    rowIndex: index,
                                                                                    reqFor: EDetailedFor.LEMMA,
                                                                                },
                                                                                isSpelling: true,
                                                                            },
                                                                    );
                                                                    !(detailedRow && detailedRow.label === lemma.label && detailedRow.posTag === lemma.posTag && detailedRow.isSpelling && (detailedRow.index === indexOfElemLemma && detailedRow.requestDataIndex === requestDataIndex)) && props.getDetailedTerm(
                                                                        {
                                                                            label: lemma ? lemma.label : "",
                                                                            lang: elem.lg,
                                                                            posTag: lemma ? lemma.posTag : "",
                                                                            lemmaPosTag: elemLemma ? elemLemma.posTag : "",
                                                                            lemma: elemLemma ? elemLemma.label : "",
                                                                            synonym: elem.synonym ? elem.synonym.label : "",
                                                                        },
                                                                        {
                                                                            lemmaIndex: -1,
                                                                            lemma: lemma ? lemma.label : "",
                                                                            synonym: elem.synonym
                                                                                ? elem.synonym.label
                                                                                : "",
                                                                            posTag: lemma ? lemma.posTag : "",
                                                                            lan: elem.lg,
                                                                            rowIndex: index,
                                                                            reqFor: EDetailedFor.LEMMA,
                                                                        },
                                                                        true,
                                                                    );
                                                                }}
                                                            >
                                                                {truncate(lemma ? lemma.label : "", 30)}
                                                                <span className={styles.subElemSpan}>
                                                                    {lemma ? lemma.posTag : ""}
                                                                </span>
                                                            </div>
                                                        );
                                                    })}
                                            </React.Fragment>
                                        );
                                    })}
                                </div>
                                <div className={styles.columnRow}>
                                    <div
                                        id={`row/Synonym/${index}`}
                                        onClick={() => {
                                            let searchList = [...props.entrySearch];
                                            searchList = searchList.map(
                                                (searchListLemma, searchListIndex) => {
                                                    return {
                                                        ...searchListLemma,
                                                        lemmas: searchListLemma.lemmas.filter(
                                                            (lem, indexLem) => {
                                                                if (
                                                                    !(
                                                                        searchListIndex === index &&
                                                                        elem.expanded &&
                                                                        (detailedRow && detailedRow.synonym === elem.synonym.label) &&
                                                                        elem.reqFor === EDetailedFor.SYNONYM
                                                                        && lem.fromSyn
                                                                    )
                                                                ) {
                                                                    return lem;
                                                                }
                                                            },
                                                        ),
                                                    };
                                                },
                                            );
                                            if (elem.expanded && elem.reqFor === EDetailedFor.SYNONYM && (detailedRow && detailedRow.synonym === elem.synonym.label && detailedRow.posTag === elem.synonym.posTag)) {
                                                searchList[index].expanded = !elem.expanded;
                                            } else {
                                                searchList[index].expanded = true;
                                            }
                                            props.onSetLemmaSearch([...searchList]);
                                            props.setDetailedRow({
                                                ...elem.lemmas[0],
                                                lang: elem.lg,
                                                synonym: elem.synonym.label,
                                                reqFor: {
                                                    lemmaIndex: -1,
                                                    lemma: elem.lemmas[0] ? elem.lemmas[0].label : "",
                                                    synonym: elem.synonym ? elem.synonym.label : "",
                                                    posTag: elem.lemmas[0] ? elem.lemmas[0].posTag : "",
                                                    lan: elem.lg,
                                                    rowIndex: index,
                                                    reqFor: EDetailedFor.SYNONYM,
                                                },
                                            });
                                            const conditionForRequest = !(
                                                detailedTerm &&
                                                detailedTerm.synonym === elem.synonym.label &&
                                                detailedTerm.posTag === elem.synonym.posTag &&
                                                elem.reqFor
                                                && detailedRow && !detailedRow.isSpelling &&
                                                elem.reqFor === EDetailedFor.SYNONYM
                                            );
                                            !conditionForRequest && props.setDetailedRow(undefined);
                                            !conditionForRequest && props.setDetailedTerm(undefined)
                                            conditionForRequest && props.getDetailedTerm(
                                                {
                                                    label: elem.synonym ? elem.synonym.label : "",
                                                    lang: elem.lg,
                                                    posTag: elem.synonym ?elem.synonym.posTag : "",
                                                    synonym: elem.synonym ? elem.synonym.label : "",
                                                    isSynonym: true,
                                                },
                                                {
                                                    lemmaIndex: -1,
                                                    lemma: elem.lemmas[0] ? elem.lemmas[0].label : "",
                                                    synonym: elem.synonym ? elem.synonym.label : "",
                                                    posTag: elem.synonym ?elem.synonym.posTag : "",
                                                    lan: elem.lg,
                                                    rowIndex: index,
                                                    reqFor: EDetailedFor.SYNONYM,
                                                },
                                            );
                                        }}
                                        className={`${styles.columnRowElem} ${
                                            (detailedTerm && detailedTerm.synonym === elem.synonym.label && detailedTerm.posTag === elem.synonym.posTag) &&
                                            elem.reqFor === EDetailedFor.SYNONYM
                                                ? styles.highLight
                                                : ""
                                        }`}
                                    >
                                        {truncate(elem ? elem.synonym.label : "", 30)}
                                        <span
                                            className={styles.subElemSpan}>{truncate(elem ? elem.synonym.posTag : "", 30)}</span>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                    : <div className={styles.noResultsFound}>No results were found</div>}
                </div>
            </div>
            <div className={styles.rightPart}>
                <div className={styles.topPart}>
                    <div className={styles.header}>Additional information</div>
                    {(detailedTerm && role && [UserRole.ROLE_ADMIN, UserRole.ROLE_MAIN_ANNOTATOR].includes(role)) && <IconButton style={{paddingTop: '25px'}}>
                        <span
                            onClick={() => {
                                props.setAdditionalInformationStage(props.stage === AdditionalInformationStage.read ? AdditionalInformationStage.edit : AdditionalInformationStage.read);
                                props.setEditableTerm(props.detailedTerm);
                            }}
                            className={styles.editPencilIcon}
                        />
                    </IconButton>}
                </div>
                {props.stage === AdditionalInformationStage.read ? <AdditionalInformationConnected/> :
                    <AdditionalInformationEditorConnected/>}
            </div>
        </div>
    );
}
