import {Endpoints} from "./endpoints";
import {BaseApi} from "./baseApi";

class EntryLanguageApi extends BaseApi {
    Endpoint = Endpoints.EntryLanguage;
}

export function getEntryLanguageApiObj() {
    return new EntryLanguageApi();
}
