import React, {PropsWithChildren} from "react";

import styles from "./EntryRow.module.scss";
import CreatableSelect from "react-select/creatable";
import {ValueType} from "react-select/src/types";
import {Spelling, SpellingBackColors} from "./Spelling/Spelling";
import {Language, LANGUAGE_UNDEFINED} from "../../../api/languageApi";
import {Entry} from "../../../api/entryApi";
import {POS_TAG_PROPER_NOUN, POS_TAG_UNDEFINED, PosTag} from "../../../api/posTagApi";
import {Net, NET_UNDEFINED} from "../../../api/netApi";
import {
    autocompleteLemmaQuery,
    autocompleteSynonymQuery,
    EntryNotify,
    EntryNotifyStatus,
    EntryNotifyType,
    filteredNet,
    getEntriesSpinner,
    IEntryDetailedNotify,
    ITermFromPos,
    lemmaBySpellingQuery,
    lemmaBySynonymPosQuery,
    netFromSynQuery,
    spellingByLemmaPosQuery,
    synonymQuery,
} from "../../../ducks/entries/entries";
import {Lemma} from "../../../api/lemmaApi";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {QueryResult} from "../../../api/spellingApi";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {IBacklogElementsWidth, IExternalElementsWidth, IManualElementsWidth} from "../../../helper/screenConsts";
import {createFilter} from "react-select";
import {Checkbox, MuiThemeProvider} from "@material-ui/core";
import {customStylesForAdditionScreens, themeForAdditionalScreen} from "../../../helper";
import {ThemeProvider} from "@material-ui/styles";

export interface EntryRowStateProps {
    entryRowWidth: IManualElementsWidth | IBacklogElementsWidth | IExternalElementsWidth;
    entry: Entry;
    languages: Language[];
    nets: Net[];
    filteredNets: filteredNet[];
    posTags: PosTag[];
    entryUserName: string | undefined;
    entryDate: string | undefined;
    didMountFreeze: boolean | undefined;
    index: number;
    notify?: IEntryDetailedNotify;
    autocompleteLemma: boolean;
    autocompleteSynonym: boolean;
    previousNetEmpty?: boolean;
}

export interface EntryRowDispatchProps {
    onEntryDataChange: (
        id: number,
        field: keyof Entry,
        value: string | Net[] | boolean | Language | PosTag | Net,
    ) => void;
    onEntryRowSpellingChange: (id: number, spellingIndex: number, value: string) => void;
    onEntryRowSpellingAdd: (id: number) => void;
    onEntryRowSpellingDelete: (id: number, index: number) => void;
    onEntryCheck: (id: number) => void;
    onEntryAddNotify: (id: number, notify: EntryNotify) => void;
    onEntryNotifyExist: (id: number, notifyType: EntryNotifyType, object: string | null) => boolean;
    onEntryNotifyShow: (
        notify: IEntryDetailedNotify | null,
        id: number | null,
        type: EntryNotifyType | null,
        object: string | null,
    ) => void;
    onEntryNotifyClear: () => void;
    onEntryGetHighNotify: (id: number, notifyTypes: EntryNotifyType[], object: string) => EntryNotify | null;
    onEntryOverlapCheck: (id: number) => void;
    onEntryClearAutocompleteNotify: (id: number, notifyType: EntryNotifyType) => void;
    onSetNets: (nets: filteredNet[]) => void;
}

export type EntryRowProps = PropsWithChildren<EntryRowStateProps & EntryRowDispatchProps>;

export interface AutocompleteOption {
    label: string;
    value: string;
    posTag: PosTag;
}

export interface EntryRowState {
    autocompleteOptions: AutocompleteOption[];
    lemmaLoader: boolean;
    synonymLoader: boolean;
    queryTimerId: any;
    lemmaAutocompleteHappen: boolean;
    synonymAutocompleteHappen: boolean;
    netAutocompleteHappen: boolean;
    spellingLoader: number;
}

export class EntryRow extends React.Component<EntryRowProps, EntryRowState> {
    lemmaRef: any;
    synonymRef: any;

    constructor(props: EntryRowProps) {
        super(props);

        this.lemmaRef = React.createRef();
        this.synonymRef = React.createRef();

        const { entry, autocompleteLemma } = this.props;

        this.state = {
            autocompleteOptions: [],
            lemmaLoader: false,
            synonymLoader: false,
            queryTimerId: undefined,
            lemmaAutocompleteHappen: props.autocompleteLemma,
            synonymAutocompleteHappen: false,
            netAutocompleteHappen: false,
            spellingLoader: 0,
        };
    }

    componentDidMount(): void {
        if (this.props.entry && this.props.entry.synonym && this.props.entry.posTag.code === POS_TAG_PROPER_NOUN.code) {
            this.synonymNetExistCheckRequest(this.props.entry.synonym);
        }
        const elem1 = document.getElementById("react-select-2-input");
        if (elem1) elem1.setAttribute("maxlength", "50");
        const elem2 = document.getElementById("react-select-3-input");
        if (elem2) elem2.setAttribute("maxlength", "50");
    }

    componentDidUpdate(prevProps: Readonly<EntryRowProps>, prevState: Readonly<EntryRowState>, snapshot?: any) {
        if (
            prevProps.autocompleteLemma !== this.props.autocompleteLemma &&
            prevProps.entry.lemma === this.props.entry.lemma &&
            prevProps.entry.synonym === this.props.entry.synonym
        ) {
            this.setState(prevState => {
                return { ...prevState, lemmaAutocompleteHappen: this.props.autocompleteLemma };
            });
        }
        if (
            prevProps.autocompleteSynonym !== this.props.autocompleteSynonym &&
            prevProps.entry.lemma === this.props.entry.lemma &&
            prevProps.entry.synonym === this.props.entry.synonym &&
            !this.state.lemmaAutocompleteHappen
        ) {
            this.setState(prevState => {
                return { ...prevState, synonymAutocompleteHappen: this.props.autocompleteSynonym };
            });
        }
    }

    handleIsValidNewOption = (inputValue: any, selectValue: any, selectOptions: any) => {
        //@ts-ignore
        const exactValueExists = selectOptions.find(
            el => el.value === inputValue || el.value.toLowerCase() === inputValue.toLowerCase(),
        );
        const valueIsNotEmpty = inputValue.trim().length;
        return !exactValueExists && valueIsNotEmpty;
    };

    render() {
        const { entry, languages, posTags, nets, entryRowWidth, notify, previousNetEmpty } = this.props;
        const {
            lemmaLoader,
            synonymLoader,
            queryTimerId,
            lemmaAutocompleteHappen,
            synonymAutocompleteHappen,
            netAutocompleteHappen,
        } = this.state;
        const languageOptions = languages.map(language => (
            <MenuItem key={language.code} value={language.code}>
                {language.name}
            </MenuItem>
        ));
        const posOptions = posTags.map(pos => (
            <MenuItem key={pos.code} value={pos.code}>
                {pos.name}
            </MenuItem>
        ));
        const netOptions = nets.map(net => {
            let disabled = false;

            if (this.props.filteredNets.length > 0) {
                const filteredNet = this.props.filteredNets.find(elem => elem.entryId === entry.id);

                if (filteredNet && !filteredNet.nets.includes(net)) {
                    disabled = true;
                }
            }

            return (
                <MenuItem disabled={disabled} key={net.code} value={net.code}>
                    {net.name}
                </MenuItem>
            );
        });

        const lemmaDisabled: boolean = entry.language.code === LANGUAGE_UNDEFINED.code;
        const posDisabled: boolean = lemmaDisabled || entry.lemma === "" || lemmaAutocompleteHappen;
        const spellingDisabled: boolean = lemmaDisabled;
        const netDisabled: boolean =
            (!synonymAutocompleteHappen && entry.posTag.code !== POS_TAG_PROPER_NOUN.code) ||
            entry.posTag.code !== POS_TAG_PROPER_NOUN.code ||
            (!lemmaAutocompleteHappen && entry.posTag.code !== POS_TAG_PROPER_NOUN.code) ||
            (lemmaAutocompleteHappen &&
                entry.nets.code !== NET_UNDEFINED.code &&
                !previousNetEmpty &&
                netAutocompleteHappen) ||
            (synonymAutocompleteHappen &&
                entry.nets.code !== NET_UNDEFINED.code &&
                !previousNetEmpty &&
                netAutocompleteHappen);
        const synonymDisabled: boolean = lemmaDisabled || entry.lemma === "" || lemmaAutocompleteHappen;
        const isSelfDisabled: boolean = synonymDisabled;
        const filteredLemmaNotify = entry.notifications.filter(
            notify => notify.type === EntryNotifyType.ENTRY_NOTIFY_LEMMA_AUTOCOMPLETE,
        );
        const lemmaNotify = filteredLemmaNotify[0];
        const filteredSynonymNotify = entry.notifications.filter(
            notify => notify.type === EntryNotifyType.ENTRY_NOTIFY_SYNONYM_AUTOCOMPLETE,
        );
        const synonymNotify = filteredSynonymNotify[0];
        let lemmaClassName = "";
        let synonymClassName = "";
        let status = null;
        if (lemmaNotify) {
            status = this.defineBorderColorByStatus(lemmaNotify.status);
            if (
                (notify && notify.type === EntryNotifyType.ENTRY_NOTIFY_EMPTY) ||
                (notify &&
                    (notify.object !== this.props.entry.lemma ||
                        entry.id !== this.props.notify!.entryId ||
                        (notify.object === this.props.entry.lemma &&
                            [
                                EntryNotifyType.ENTRY_NOTIFY_SYNONYM_AUTOCOMPLETE,
                                EntryNotifyType.ENTRY_NOTIFY_SPELLING_EXISTS,
                                EntryNotifyType.ENTRY_NOTIFY_SPELLING_OVERLAP,
                            ].includes(notify.type))))
            ) {
                lemmaClassName = styles.notifyStatusWarning;
            }
            if (lemmaClassName === "" && lemmaDisabled) {
                lemmaClassName = styles.notifyStatusSelected;
            }
        }

        if (synonymNotify) {
            status = this.defineBorderColorByStatus(synonymNotify.status);
            if (
                (notify && notify.type === EntryNotifyType.ENTRY_NOTIFY_EMPTY) ||
                (notify &&
                    (notify.object !== this.props.entry.synonym ||
                        entry.id !== this.props.notify!.entryId ||
                        (notify.object === this.props.entry.lemma &&
                            [
                                EntryNotifyType.ENTRY_NOTIFY_LEMMA_AUTOCOMPLETE,
                                EntryNotifyType.ENTRY_NOTIFY_SPELLING_EXISTS,
                                EntryNotifyType.ENTRY_NOTIFY_SPELLING_OVERLAP,
                            ].includes(notify.type))))
            ) {
                synonymClassName = status.valueClass;
            }
            if (synonymClassName === "" && synonymDisabled && this.props.notify!.entryId === entry.id) {
                synonymClassName = styles.notifyStatusSelected;
            }
        }
        //@ts-ignore
        const editDeleteIcon = (isLemma: boolean = true) => ({ children, ...props }) => {
            return (
                <div className={styles.iconsContainer}>
                    <div
                        onMouseDown={event => {
                            if (event && event.type === "mousedown" && event.button !== 0) {
                                return;
                            }
                            isLemma ? this.lemmaRef.current.focus() : this.synonymRef.current.focus();
                            if (isLemma) {
                                this.lemmaRef.current.focus();
                                this.lemmaRef.current.setState({ inputValue: entry.lemma });
                                this.lemmaRef.current.selectionStart = entry.lemma.length;
                            } else {
                                this.synonymRef.current.focus();
                                this.synonymRef.current.setState({ inputValue: entry.synonym });
                                this.synonymRef.current.selectionStart = entry.synonym.length;
                            }
                            if (event.type === "touchend") {
                                isLemma ? this.lemmaRef.current.focus() : this.synonymRef.current.focus();
                            } else {
                                setTimeout(() => {
                                    return isLemma ? this.lemmaRef.current.focus() : this.synonymRef.current.focus();
                                });
                            }
                        }}
                        className={styles.iconContainer}
                    >
                        <div className={styles.editIcon} />
                    </div>
                    <div
                        onMouseDown={event => {
                            if (event && event.type === "mousedown" && event.button !== 0) {
                                return;
                            }
                            isLemma ? this.lemmaRef.current.focus() : this.synonymRef.current.focus();
                            if (isLemma) {
                                this.autocompleteLemmaChange({ value: "", label: "", posTag: POS_TAG_UNDEFINED });
                                this.autocompleteSynonymChange({ value: "", label: "" });
                            } else {
                                let id: number = this.props.entry.id;
                                this.props.onEntryDataChange(id, "synonym", "");
                                this.props.onEntryDataChange(id, "nets", NET_UNDEFINED);
                                this.autocompleteSynonymChange({ value: "", label: "" });
                            }
                            if (event.type === "touchend") {
                                isLemma ? this.lemmaRef.current.focus() : this.synonymRef.current.focus();
                            } else {
                                setTimeout(() => {
                                    return isLemma ? this.lemmaRef.current.focus() : this.synonymRef.current.focus();
                                });
                            }
                        }}
                        className={styles.iconContainer}
                    >
                        <div className={styles.deleteIcon} />
                    </div>
                </div>
            );
        };

        //@ts-ignore
        const crossIcon = ({ children, ...props }) => {
            return <></>;
        };

        //@ts-ignore
        const separatorIcon = ({ children, ...props }) => {
            return <></>;
        };

        return (
            <div className={styles.entriesRow}>
                <div style={entryRowWidth.language}>
                    <div className={styles.languageListCont}>
                        <Checkbox
                            className={`${styles.languageListCheckBox} ${this.props.entry.check && styles.checked}`}
                            checked={this.props.entry.check}
                            onChange={() => {
                                this.props.onEntryCheck(this.props.entry.id);
                            }}
                        />
                        <MuiThemeProvider theme={themeForAdditionalScreen}>
                            <ThemeProvider theme={themeForAdditionalScreen}>
                                <Select
                                    className={styles.languageList}
                                    displayEmpty
                                    onChange={this.languageChangeEntry}
                                    onClick={() => this.props.onSetNets([])}
                                    onBlur={() => this.props.onSetNets([])}
                                    value={entry.language.code}
                                >
                                    {languageOptions}
                                </Select>
                            </ThemeProvider>
                        </MuiThemeProvider>
                    </div>
                </div>
                <div style={entryRowWidth.inputs} className={styles.spellingContainer}>
                    <Spelling
                        disabled={spellingDisabled}
                        entryId={entry.id}
                        language={entry.language}
                        spelling={entry.spelling}
                        onSpellingChange={this.props.onEntryRowSpellingChange}
                        onSpellingAdd={this.props.onEntryRowSpellingAdd}
                        onSpellingDelete={this.props.onEntryRowSpellingDelete}
                        onEntryAddNotify={this.props.onEntryAddNotify}
                        onEntryNotifyExist={this.props.onEntryNotifyExist}
                        onEntryGetHighNotify={this.props.onEntryGetHighNotify}
                        onEntryNotifyShow={this.props.onEntryNotifyShow}
                        onEntryNotifyClear={this.props.onEntryNotifyClear}
                        onEntryOverlapCheck={this.props.onEntryOverlapCheck}
                        onHandlerSpellingBlur={this.handlerSpellingBlur}
                        spellingLoader={this.state.spellingLoader}
                    />
                </div>
                <div
                    onClick={event => {
                        event.stopPropagation();
                        if (lemmaNotify) {
                            this.props.onEntryNotifyShow({ entryId: entry.id, ...lemmaNotify }, null, null, null);
                        }
                    }}
                    style={entryRowWidth.inputs}
                    className={styles.lemmaContainer}
                    title={entry.lemma}
                >
                    <CreatableSelect
                        components={{
                            ClearIndicator: crossIcon,
                            DropdownIndicator: editDeleteIcon(),
                            IndicatorSeparator: separatorIcon,
                        }}
                        styles={customStylesForAdditionScreens}
                        isValidNewOption={this.handleIsValidNewOption}
                        name={"lemma"}
                        className={`${
                            lemmaClassName ? styles.lemmaList : styles.lemmaListWithoutBorder
                        } ${lemmaDisabled && styles.lemmaListDisabled} ${lemmaClassName}`}
                        isDisabled={lemmaDisabled}
                        options={this.state.autocompleteOptions}
                        isLoading={
                            queryTimerId !== undefined || lemmaLoader || getEntriesSpinner(this.props.entry.language)
                        }
                        isClearable={true}
                        filterOption={createFilter({ ignoreCase: true })}
                        onChange={this.autocompleteLemmaChange}
                        //entry symbols in input
                        onInputChange={newValue => {
                            this.autocompleteFieldInput(newValue, "lemma");
                        }}
                        onBlur={() => {
                            this.lemmaOnBlur();
                            if (lemmaNotify) {
                                this.defineBorderColorByStatus(lemmaNotify.status);
                            }
                        }}
                        //created non-existent in auto-completion
                        onCreateOption={this.lemmaCreate}
                        value={EntryRow.stringToSelectValue(entry.lemma)}
                        ref={this.lemmaRef}
                        onClick={() => {
                            this.props.onSetNets([]);
                        }}
                    />
                </div>
                <div style={entryRowWidth.selectors}>
                    <MuiThemeProvider theme={themeForAdditionalScreen}>
                        <ThemeProvider theme={themeForAdditionalScreen}>
                            <Select
                                style={entryRowWidth.selectors}
                                displayEmpty
                                disabled={posDisabled}
                                value={entry.posTag.code}
                                className={`${styles.posList} ${posDisabled ? styles.posListDisabled : ""}`}
                                onChange={this.posChangeEntry}
                                onClick={() => this.props.onSetNets([])}
                                onBlur={() => this.props.onSetNets([])}
                            >
                                {posOptions}
                            </Select>
                        </ThemeProvider>
                    </MuiThemeProvider>
                </div>
                <div
                    onClick={event => {
                        if (synonymNotify) {
                            event.stopPropagation();
                            this.props.onEntryNotifyShow({ entryId: entry.id, ...synonymNotify }, null, null, null);
                        }
                    }}
                    style={entryRowWidth.inputs}
                    className={styles.synonymContainer}
                    title={entry.synonym}
                >
                    <CreatableSelect
                        components={{
                            ClearIndicator: crossIcon,
                            DropdownIndicator: editDeleteIcon(false),
                            IndicatorSeparator: separatorIcon,
                        }}
                        ref={this.synonymRef}
                        styles={customStylesForAdditionScreens}
                        spellCheck={true}
                        isClearable={true}
                        onChange={this.autocompleteSynonymChange}
                        onInputChange={newValue => {
                            this.autocompleteFieldInput(newValue, "synonym");
                        }}
                        allowCreateWhileLoading={false}
                        isLoading={queryTimerId !== undefined || synonymLoader}
                        value={EntryRow.stringToSelectValue(entry.synonym)}
                        isDisabled={synonymDisabled}
                        className={`${synonymClassName ? styles.synonymList : styles.synonymListWithoutBorder} ${
                            synonymDisabled ? styles.posListDisabled : ""
                        } ${synonymClassName}`}
                        options={this.state.autocompleteOptions}
                        name={"synonym"}
                        formatCreateLabel={() => undefined}
                        isValidNewOption={() => false}
                    />
                    <div className={styles.synonymItSelfLabel}>
                        <Checkbox
                            className={`${styles.synonymItSelf} ${entry.itSelf && styles.checked} ${isSelfDisabled &&
                                styles.disabled}`}
                            disabled={isSelfDisabled}
                            checked={this.props.entry.itSelf}
                            onChange={this.itSelfClick}
                        />
                        <div className={styles.label}>Itself</div>
                    </div>
                </div>
                <div style={entryRowWidth.selectors}>
                    <MuiThemeProvider theme={themeForAdditionalScreen}>
                        <ThemeProvider theme={themeForAdditionalScreen}>
                            <Select
                                style={entryRowWidth.selectors}
                                displayEmpty
                                disabled={netDisabled}
                                value={entry.nets.code}
                                className={`${styles.netList} ${netDisabled ? styles.netListDisabled : ""}`}
                                onChange={this.netChangeEntry}
                            >
                                {netOptions}
                            </Select>
                        </ThemeProvider>
                    </MuiThemeProvider>
                </div>
                {this.props.children}
            </div>
        );
    }

    public defineBorderColorByStatus = (status: EntryNotifyStatus) => {
        let valueBack = SpellingBackColors.NORMAL;
        let valueClass = "";

        switch (status) {
            case EntryNotifyStatus.NOTIFY_STATUS_WARNING:
                valueBack = SpellingBackColors.WARNING;
                valueClass = styles.notifyStatusWarning;
                break;
            case EntryNotifyStatus.NOTIFY_STATUS_ERROR:
                valueBack = SpellingBackColors.ERROR;
                valueClass = styles.notifyStatusError;
                break;
        }

        return {
            valueBack,
            valueClass,
        };
    };

    public static stringToSelectValue(value: string): AutocompleteOption {
        return {
            value: value,
            label: value,
            posTag: POS_TAG_UNDEFINED,
        };
    }

    public itSelfClick = (event: any) => {
        event.stopPropagation();
        event.nativeEvent.stopPropagation();

        let id: number = this.props.entry.id;
        if (this.props.entry.itSelf) {
            this.props.onEntryDataChange(id, "synonym", "");
            if (this.props.entry.posTag.code === POS_TAG_PROPER_NOUN.code) {
                this.props.onEntryDataChange(id, "nets", NET_UNDEFINED);
            }
            this.props.onEntryDataChange(id, "synonym", "");
            this.props.onEntryDataChange(id, "itSelf", false);
        } else {
            this.props.onEntryClearAutocompleteNotify(id, EntryNotifyType.ENTRY_NOTIFY_SYNONYM_AUTOCOMPLETE);
            this.props.onEntryDataChange(id, "itSelf", true);
            this.props.onEntryDataChange(id, "synonym", this.props.entry.lemma);
        }
    };

    protected autocompleteClear() {
        this.setState(prevState => {
            return { ...prevState, autocompleteOptions: [] };
        });
    }

    protected autocompleteFieldInput = (newValue: string, type: "lemma" | "synonym") => {
        let queryTimerId = this.state.queryTimerId;

        if (queryTimerId !== undefined) {
            clearTimeout(queryTimerId);
        }

        if (newValue.length >= 3) {
            queryTimerId = setTimeout(() => {
                this.setState(prevState => {
                    return { ...prevState, queryTimerId: undefined };
                });

                this.setState(prevState => {
                    return {
                        ...prevState,
                        lemmaLoader: type === "lemma",
                        synonymLoader: type === "synonym",
                    };
                });
                //TODO:есть возможность более красивой группировки

                const autocompleteProcessed = (res: Lemma[]) => {
                    //@ts-ignore
                    const newOptions: AutocompleteOption[] = res.map(lexiconElem => {
                        return {
                            value: `${lexiconElem.label}_postfix_`,
                            label: (
                                <div className={styles.autocompleteOption}>
                                    <span>
                                        {lexiconElem.label}
                                        <span className={styles.autocompleteOptionSubElem}>{lexiconElem.posTag}</span>
                                    </span>
                                </div>
                            ),
                            posTag: this.props.posTags.filter(
                                posTag => posTag.name.toLowerCase() === lexiconElem.posTag,
                            )[0],
                        };
                    });

                    this.setState({
                        autocompleteOptions: newOptions,
                    });
                };

                if (type === "lemma") {
                    autocompleteLemmaQuery(newValue, this.props.entry.language.code, 300)
                        .then(autocompleteProcessed)
                        .finally(() => {
                            this.setState({
                                lemmaLoader: false,
                                synonymLoader: false,
                            });
                        });
                }

                if (type === "synonym") {
                    autocompleteSynonymQuery({
                        synonym: newValue,
                        lang: this.props.entry.language.code,
                        posTag: this.props.entry.posTag.name,
                        limit: 300,
                    })
                        .then(autocompleteProcessed)
                        .finally(() => {
                            this.setState({
                                lemmaLoader: false,
                                synonymLoader: false,
                            });
                        });
                }
            }, 1000);

            this.setState({
                queryTimerId,
            });
        } else {
            this.autocompleteClear();
        }
    };

    protected lemmaCreate = (inputValue: string) => {
        let id: number = this.props.entry.id;
        this.props.onEntryClearAutocompleteNotify(id, EntryNotifyType.ENTRY_NOTIFY_LEMMA_AUTOCOMPLETE);
        this.props.onEntryDataChange(id, "lemma", inputValue);
        this.props.onEntryDataChange(id, "synonym", inputValue);
        this.props.onEntryDataChange(id, "itSelf", true);
        this.props.onEntryDataChange(id, "posTag", POS_TAG_UNDEFINED);
        this.props.onEntryDataChange(id, "nets", NET_UNDEFINED);
        this.props.onEntryOverlapCheck(id);
        this.setState(prevState => {
            return {
                ...prevState,
                lemmaAutocompleteHappen: false,
                synonymAutocompleteHappen: false,
            };
        });
        this.props.onEntryNotifyClear();
        this.lemmaRef.current.blur();
    };

    protected lemmaOnBlur = () => {
        const { queryTimerId } = this.state;
        if (queryTimerId) clearTimeout(queryTimerId);

        setTimeout(() => {
            this.setState(prevState => {
                return {
                    ...prevState,
                    lemmaLoader: false,
                    queryTimerId: undefined,
                };
            });
        }, 1000);
    };

    protected autocompleteLemmaChange = (value: ValueType<AutocompleteOption, false>) => {
        this.lemmaRef.current.blur();
        this.props.onEntryNotifyClear();
        this.autocompleteClear();

        let newValueRaw = value && (value.valueOf() as AutocompleteOption).value;
        const newLemmaValue = newValueRaw ? newValueRaw.replace("_postfix_", "") : "";

        const newPosTagRaw = value && (value.valueOf() as AutocompleteOption).posTag;
        const newPosTag = newPosTagRaw ? newPosTagRaw : POS_TAG_UNDEFINED;

        let id: number = this.props.entry.id;

        this.props.onEntryDataChange(id, "lemma", newLemmaValue);
        this.props.onEntryDataChange(id, "synonym", "");
        this.props.onEntryDataChange(id, "nets", NET_UNDEFINED);

        if (newLemmaValue) {
            this.setState({
                ...this.state,
                synonymLoader: true,
                lemmaAutocompleteHappen: true,
                synonymAutocompleteHappen: false,
                netAutocompleteHappen: false,
            });

            const languageCode = this.props.entry.language.code;
            synonymQuery(newLemmaValue, languageCode, newPosTag.code).then((resSynText: string) => {
                const resSyn = JSON.parse(resSynText);
                this.props.onEntryDataChange(id, "synonym", resSyn.label);
                this.props.onEntryDataChange(id, "itSelf", resSyn.label === this.props.entry.lemma);

                if (resSyn) {
                    this.lemmaBySynonymNotify(resSyn.label);
                }
                // @ts-ignore
                if (value!.posTag.code === POS_TAG_PROPER_NOUN.code) {
                    netFromSynQuery([resSyn.label], languageCode)
                        .then(resNet => {
                            let filtered: Net[] = [];

                            if (resNet[0] && resNet[0].targets && resNet[0].targets[0]) {
                                const netValue = resNet.find(elem => elem.source === resSyn.label)!.targets;
                                if (netValue) {
                                    filtered = this.props.nets.filter(value =>
                                        netValue.includes(value.code.toLowerCase()),
                                    );
                                }
                            }

                            const net = filtered.length ? filtered : NET_UNDEFINED;

                            //@ts-ignore
                            if (net.code !== NET_UNDEFINED.code) {
                                this.setState(prevState => ({ ...prevState, netAutocompleteHappen: true }));
                            }

                            this.props.onEntryDataChange(id, "nets", net);
                        })
                        .finally(() => {
                            this.setState({ synonymLoader: false });
                        });
                } else {
                    this.setState({ synonymLoader: false });
                }
            });
        }

        this.props.onEntryDataChange(id, "posTag", newPosTag);
        this.props.onEntryDataChange(id, "itSelf", false);
        this.props.onEntryOverlapCheck(id);

        if (!newLemmaValue) {
            this.props.onEntryClearAutocompleteNotify(id, EntryNotifyType.ENTRY_NOTIFY_LEMMA_AUTOCOMPLETE);
        }
    };

    protected spellingByLemmaPos(newLemmaValue?: string) {
        this.setState({ lemmaLoader: true });
        const value = newLemmaValue ? newLemmaValue : this.props.entry.lemma;
        spellingByLemmaPosQuery(value, this.props.entry.posTag.name, this.props.entry.language.code)
            .then((res: ITermFromPos[]) => {
                const spellingsByLemmaPos = res.map(elem => elem.label);

                if (spellingsByLemmaPos.includes(value)) {
                    this.props.onEntryAddNotify(this.props.entry.id, {
                        type: EntryNotifyType.ENTRY_NOTIFY_LEMMA_AUTOCOMPLETE,
                        status: EntryNotifyStatus.NOTIFY_STATUS_WARNING,
                        object: value,
                        list: spellingsByLemmaPos,
                        insertItem: "",
                    });
                }
            })
            .finally(() => {
                this.setState({ lemmaLoader: false });
            });
    }

    protected lemmaBySynonymNotify(newSynonymValue?: string) {
        const value = newSynonymValue ? newSynonymValue : this.props.entry.synonym;

        if (this.props.entry.posTag.code !== POS_TAG_UNDEFINED.code) {
            this.setState({ synonymLoader: true });
            lemmaBySynonymPosQuery({
                synonym: value,
                lang: this.props.entry.language.code,
                posTag: this.props.entry.posTag.name,
            })
                .then((res: ITermFromPos[]) => {
                    if (res.length) {
                        this.props.onEntryAddNotify(this.props.entry.id, {
                            type: EntryNotifyType.ENTRY_NOTIFY_SYNONYM_AUTOCOMPLETE,
                            status: EntryNotifyStatus.NOTIFY_STATUS_WARNING,
                            object: value,
                            list: res.map(elem => elem.label),
                            insertItem: this.props.entry.lemma,
                        });
                    }
                })
                .finally(() => {
                    this.setState({ synonymLoader: false });
                });
        }
    }

    protected autocompleteSynonymChange = (value: ValueType<{ label: string; value: string }, false>) => {
        this.autocompleteClear();
        this.synonymRef.current.blur();
        this.props.onEntryNotifyClear();

        const newValueRaw = value && (value.valueOf() as AutocompleteOption).value;
        const newSynonymValue = newValueRaw ? newValueRaw.replace("_postfix_", "") : "";

        let id: number = this.props.entry.id;

        this.props.onEntryDataChange(id, "synonym", newSynonymValue);
        this.props.onEntryDataChange(id, "itSelf", false);
        this.props.onEntryClearAutocompleteNotify(id, EntryNotifyType.ENTRY_NOTIFY_SYNONYM_AUTOCOMPLETE);
        if (newSynonymValue) {
            const newValuePosTag = value && (value.valueOf() as AutocompleteOption).posTag;
            if (newValuePosTag) {
                this.setState({ synonymAutocompleteHappen: true });
                this.props.onEntryDataChange(id, "posTag", newValuePosTag);
                this.lemmaBySynonymNotify(newSynonymValue);
                this.synonymNetRequest(id, newSynonymValue, newValuePosTag);
            }
        }
    };

    protected synonymNetRequest = (id: number, synonym: string, postTag: PosTag) => {
        if (postTag.code === POS_TAG_PROPER_NOUN.code) {
            const languageCode = this.props.entry.language.code;

            this.setState({ synonymLoader: true });
            netFromSynQuery([synonym], languageCode)
                .then(resNet => {
                    let filtered: Net[] = [];

                    if (resNet[0] && resNet[0].targets && resNet[0].targets[0]) {
                        const netValue = resNet.find(elem => elem.source === synonym)!.targets;
                        if (netValue) {
                            filtered = this.props.nets.filter(value => netValue.includes(value.code.toLowerCase()));
                        }
                    }

                    const net = filtered.length ? filtered : NET_UNDEFINED;
                    //@ts-ignore
                    if (net.code !== NET_UNDEFINED.code) {
                        this.setState(prevState => ({ ...prevState, netAutocompleteHappen: true }));
                    }
                    this.props.onEntryDataChange(id, "nets", net);
                })
                .finally(() => {
                    this.setState({ ...this.state, synonymLoader: false });
                });
        } else {
            this.props.onEntryDataChange(id, "nets", NET_UNDEFINED);
        }
    };

    protected synonymNetExistCheckRequest = (synonym: string) => {
        const languageCode = this.props.entry.language.code;

        this.setState(prevState => ({ ...prevState, synonymLoader: true }));
        netFromSynQuery([synonym], languageCode)
            .then(resNet => {
                let filtered: Net[] = [];

                if (resNet[0] && resNet[0].targets && resNet[0].targets[0]) {
                    const netValue = resNet.find(elem => elem.source === synonym)!.targets;
                    if (netValue) {
                        filtered = this.props.nets.filter(value => netValue.includes(value.code.toLowerCase()));
                    }
                }
                //@ts-ignore
                if (filtered.length) {
                    this.setState(prevState => ({ ...prevState, netAutocompleteHappen: true }));
                }
            })
            .finally(() => {
                this.setState({ ...this.state, synonymLoader: false });
            });
    };

    protected netChangeEntry = (event: any) => {
        event.preventDefault();

        const netRaw = event.target.value;
        const filtered = this.props.nets.filter(value => value.code === netRaw);
        let net = filtered.length ? filtered[0] : NET_UNDEFINED;
        let id: number = this.props.entry.id;
        this.props.onEntryDataChange(id, "nets", net);
        this.props.onSetNets([]);
    };

    protected lemmaByPos = (posRaw: any) => {
        const { lemma, posTag, id, language } = this.props.entry;
        posRaw !== POS_TAG_UNDEFINED.code &&
            synonymQuery(lemma, language.code, posRaw).then(resSynText => {
                if(!resSynText) return;
                const resSyn: ITermFromPos = JSON.parse(resSynText);
                if (resSyn) {
                    this.props.onEntryDataChange(id, "synonym", resSyn.label);
                    this.props.onEntryDataChange(id, "itSelf", resSyn.label === this.props.entry.lemma);
                }
                // @ts-ignore
                if (posTag.code === POS_TAG_PROPER_NOUN.code) {
                    netFromSynQuery([resSyn.label], language.code)
                        .then(resNet => {
                            let filtered: Net[] = [];

                            if (resNet[0] && resNet[0].targets && resNet[0].targets[0]) {
                                const netValue = resNet.find(elem => elem.source === resSyn.label)!.targets;
                                if (netValue) {
                                    filtered = this.props.nets.filter(value =>
                                        netValue.includes(value.code.toLowerCase()),
                                    );
                                }
                            }

                            const net = filtered.length ? filtered : NET_UNDEFINED;
                            this.props.onEntryDataChange(id, "nets", net);
                        })
                        .finally(() => {
                            this.setState({
                                ...this.state,
                                synonymLoader: false,
                                synonymAutocompleteHappen: !!(resSyn && resSyn.label.length),
                                lemmaAutocompleteHappen: !!(resSyn && resSyn.label.length),
                            });
                        });
                } else {
                    this.setState({
                        ...this.state,
                        synonymLoader: false,
                        synonymAutocompleteHappen: !!(resSyn && resSyn.label.length),
                        lemmaAutocompleteHappen: !!(resSyn && resSyn.label.length),
                    });
                }
            }).catch((err) => console.log("err", err));
    };

    protected posChangeEntry = (event: any) => {
        event.preventDefault();
        const posRaw = event.target.value;
        const filtered = this.props.posTags.filter(value => value.code === posRaw);
        const pos = filtered.length ? filtered[0] : POS_TAG_UNDEFINED;
        const { id, spelling, itSelf } = this.props.entry;
        this.props.onEntryDataChange(id, "posTag", pos);

        if (pos.code !== POS_TAG_PROPER_NOUN.code && this.props.entry.nets.code !== NET_UNDEFINED.code)
            this.props.onEntryDataChange(id, "nets", NET_UNDEFINED);

        this.spellingByLemmaPos();
        this.props.onEntryOverlapCheck(id);
        this.lemmaByPos(posRaw);
        if (itSelf) {
            const newSynonymValue = this.props.entry.synonym;
            this.lemmaBySynonymNotify(newSynonymValue);
            this.synonymNetRequest(id, newSynonymValue, pos);
        } else {
            this.props.onEntryDataChange(id, "synonym", "");
            this.props.onEntryClearAutocompleteNotify(id, EntryNotifyType.ENTRY_NOTIFY_SYNONYM_AUTOCOMPLETE);
        }
    };

    protected languageChangeEntry = (event: any) => {
        event.preventDefault();
        let filtered = this.props.languages.filter(language => language.code === event.target.value);
        let lang = filtered.length ? filtered[0] : LANGUAGE_UNDEFINED;
        let id: number = this.props.entry.id;
        this.props.onEntryDataChange(id, "language", lang);
    };

    protected handlerSpellingBlur = (entryId: number, newSpellings: string[]) => {
        newSpellings.filter(elem => elem.length > 3);

        if (newSpellings.length > 0) {
            this.setState({ spellingLoader: this.state.spellingLoader + 1 });
            lemmaBySpellingQuery(newSpellings, this.props.entry.language.code).then((res: QueryResult[]) => {
                this.setState({ spellingLoader: this.state.spellingLoader - 1 });
                const lemmas = res.map(res => res.targets).flat();
                const sources = res.map(elem => elem.source.toLowerCase());
                if (lemmas.length > 0) {
                    newSpellings.forEach(s => {
                        if (sources.includes(s.toLowerCase())) {
                            let list: string[] = [];
                            try {
                                list = res
                                    .filter(elem => elem.source.toLowerCase() === s.toLowerCase())
                                    .map(elem => elem.targets)
                                    .flat();
                            } catch {}
                            this.props.onEntryAddNotify(entryId, {
                                type: EntryNotifyType.ENTRY_NOTIFY_SPELLING_EXISTS,
                                status: EntryNotifyStatus.NOTIFY_STATUS_WARNING,
                                object: s,
                                list: list,
                                insertItem: s,
                            });
                        }
                    });
                }
            });
        }
    };
}
