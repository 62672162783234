import React from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import GroupIcon from '@material-ui/icons/Group';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HomeIcon from '@material-ui/icons/Home';
import PersonSharpIcon from '@material-ui/icons/PersonSharp';
import {UserRole} from "../../api/userApi";
import VerifiedUserSharpIcon from '@material-ui/icons/VerifiedUserSharp';

import styles from "./Menu.module.scss";
import {push} from "connected-react-router";
import {getApiUtils} from "../../api/utils";

export interface IHamburgerMenuProps {
    username: string;
    onLogout: () => void;
    role: UserRole | undefined;
}

export interface IListItem {
    notShow?: boolean;
    subElem?: boolean;
    separatedElem?: boolean;
    action?: () => void;
    leftItem: string;
    rightItem: JSX.Element;
}

export default function HamburgerMenu(props: IHamburgerMenuProps) {
    const [state, setState] = React.useState({
        isOpen: false,
    })

    const toggle = (isOpen?: boolean) => {
        setState({...state, isOpen: isOpen !== undefined ? isOpen : !state.isOpen})
    }

    const listItems: IListItem[] = [
        {
            separatedElem: true,
            leftItem: props.username,
            rightItem: <PersonSharpIcon/>
        },
        {
            leftItem: "Home",
            rightItem: <HomeIcon/>,
            action: () => {
                toggle(false)
                getApiUtils().dispatch(push("/"));
            }
        },
        {
            leftItem: "Admin",
            rightItem: <VerifiedUserSharpIcon/>,
            notShow: props.role !== UserRole.ROLE_ADMIN,
        },
        {
            action: () => {
                toggle(false)
                getApiUtils().dispatch(push("/users"));
            },
            notShow: props.role !== UserRole.ROLE_ADMIN,
            leftItem: "Users",
            rightItem: <GroupIcon/>,
            subElem: true,
        },
        {
            action: () => {
                props.onLogout()
            },
            leftItem: "Logout",
            rightItem: <ExitToAppIcon/>
        },
    ];

    return <div className={styles.menu} onClick={() => {
        setState({...state, isOpen: !state.isOpen})
    }}>
        <MenuIcon className={styles.menuIcon} cursor={"pointer"} onClick={() => {
            setState({...state, isOpen: !state.isOpen})
        }}/>
        <SwipeableDrawer anchor={"right"} onOpen={() => toggle(true)} onClose={() => toggle(false)} open={state.isOpen}>
            <div className={styles.menuContainer}>
                <div onClick={() => toggle(false)} className={styles.menuContainerRow}>
                    <div className={styles.menuContainerRowCloseItem}>
                        <CloseIcon cursor={"pointer"}/>
                    </div>
                </div>
                {listItems.map((elem, index) => {
                    return !elem.notShow ? <div
                        key={`${index}${new Date().getTime()}`}
                        style={{cursor: `${elem.action && 'pointer'}`}}
                        onClick={() => {
                            elem.action && elem.action()
                        }}
                        className={`${styles.menuContainerRow} ${elem.subElem && styles.subElem} ${elem.separatedElem && styles.separatedElem}`}
                    >
                        <div className={`${styles.menuContainerRowLeftItem} ${elem.subElem && styles.subElemRightPart}`}>
                            {elem.leftItem}
                        </div>
                        <div className={styles.menuContainerRowRightItem}>
                            {elem.rightItem}
                        </div>

                    </div> : <></>
                })}
            </div>
        </SwipeableDrawer>
    </div>

}
