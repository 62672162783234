import {Thunk, TypedAction} from "../types";
import {getLanguageApiObj, Language} from "../../api/languageApi";
import {BacklogProps, Entry, EntryPush, getEntryApiObj, OrderBy} from "../../api/entryApi";
import {getPosTagApiObj, IEditPostag, POS_TAG_PROPER_NOUN, PosTag} from "../../api/posTagApi";
import {getNetApiObj, IAddLocation, IAddNet, Net} from "../../api/netApi";
import {
    getLemmaApiObj,
    ILemmaLastInsertionsQueryProps,
    ILemmaSearchQueryProps,
    PropsSynonymLang,
    PropsSynonymLanPos,
} from "../../api/lemmaApi";
import {getSpellingApiObj, IBulkSourcesToTargetsNoPOS, IDeleteCheckQuery} from "../../api/spellingApi";
import {formatDate} from "../../helper";
import {getSynonymApiObj} from "../../api/synonymApi";
import {getSourceApiObj} from "../../api/sourceApi";
import {getObjectIdApiObj} from "../../api/objectIdApi";
import {popupPush, PopupType, spinnerDown, spinnerUp} from "../popup/popup";
import {
    selectAuth,
    selectEntries,
    selectEntryFilter,
    selectEntrySorting,
    selectEntrySpinners,
} from "../../selectors/selectors";
import {getEntryLanguageApiObj} from "../../api/entryLanguageApi";
import {DEFAULT_FILTER_PARAMS, IFilterParams} from "../../api/baseApi";
import {IEntrySorting} from "../../components/EntryList/EntryListBacklog/EntryListBacklog";
import {getApiUtils} from "../../api/utils";
import {getTermApiObj, ITerm, ITermQuery} from "../../api/termApi";
import {convertSearchStateToUrl, getSearchStateProcessed} from "../../helper/others";
import {EDetailedFor, IRequestFor} from "../../components/Search/SearchList/SearchList";
import {
    ESearchRadio,
    ESortBy,
    ESortDirection,
    EWordPartOrSearchParam,
    ISearchFilterState,
    searchFilterState
} from "../../components/Search/SearchFilter/SearchFilter";
import moment from "moment";
import _ from "lodash";

export const ENTRIES_SET = "ENTRIES/ENTRIES_SET";
export const ENTRIES_GET_SOURCES = "ENTRIES/ENTRIES_GET_SOURCES";
export const ENTRIES_NOTIFY_SET = "ENTRIES/ENTRIES_NOTIFY_SET";
export const UPDATE_LANGUAGES = "ENTRIES/UPDATE_LANGUAGES";
export const UPDATE_LEVELS = "ENTRIES/UPDATE_LEVELS";
export const UPDATE_ENTRY_LANGUAGES = "ENTRIES/UPDATE_ENTRY_LANGUAGES";
export const UPDATE_POS_TAGS = "ENTRIES/UPDATE_POS_TAGS";
export const UPDATE_POS_TAGS_SLA = "ENTRIES/UPDATE_POS_TAGS_SLA";
export const UPDATE_NETS = "ENTRIES/UPDATE_NETS";
export const UPDATE_NETS_SLA = "ENTRIES/UPDATE_NETS_SLA";
export const UPDATE_FILTERED_NETS = "ENTRIES/UPDATE_FILTERED_NETS ";
export const UPDATE_SOURCES = "ENTRIES/UPDATE_SOURCES";
export const UPDATE_OBJECT_IDS = "ENTRIES/UPDATE_OBJECT_IDS";
export const SET_PUSH_RESULT = "UPLOAD/SET_PUSH_RESULT";
export const SET_ENTRY_FILTER = "ENTRIES/SET_ENTRY_FILTER";
export const SET_ENTRY_SORTING = "ENTRIES/SET_ENTRY_SORTING";
export const SET_BACKLOG_COUNT = "ENTRIES/SET_BACKLOG_COUNT";
export const SET_LEMMA_SEARCH_COUNT = "ENTRIES/SET_LEMMA_SEARCH_COUNT";
export const SET_LEMMA_SEARCH_PAGE = "ENTRIES/SET_LEMMA_SEARCH_PAGE";
export const SET_EXTERNAL_COUNT = "ENTRIES/SET_EXTERNAL_COUNT";
export const SET_EXTERNAL_PAGE = "ENTRIES/SET_EXTERNAL_PAGE";
export const SET_BACKLOG_PAGE = "ENTRIES/SET_BACKLOG_PAGE";
export const SET_SPELLING_LEMMA_SPINNING = "ENTRIES/SET_SPELLING_LEMMA_SPINNING";
export const UPDATE_LEMMA_SEARCH_LIST = "ENTRIES/UPDATE_LEMMA_SEARCH_LIST";
export const SET_DETAILED_TERM = "ENTRIES/SET_DETAILED_TERM";
export const SET_DETAILED_ROW = "ENTRIES/SET_DETAILED_ROW";
export const SET_IS_LEMMA_SEARCH = "ENTRIES/SET_IS_LEMMA_SEARCH";
export const SET_PREVIOUS_ENTRIES = "ENTRIES/SET_PREVIOUS_ENTRIES";
export const SET_ADDITIONAL_INFO_STAGE = "ENTRIES/SET_ADDITIONAL_INFO_STAGE";
export const SET_EDITABLE_TERM = "ENTRIES/SET_EDITABLE_TERM";
export const SET_IS_LOCATION_POPUP_OPEN = "ENTRIES/SET_IS_LOCATION_POPUP_OPEN";
export const SET_IS_LOCATION_POPUP_HANDLER = "ENTRIES/SET_IS_LOCATION_POPUP_HANDLER";
export const SET_EDIT_TERM_ERROR = "ENTRIES/SET_EDIT_TERM_ERROR";

export enum AdditionalInformationStage {
    read,
    edit
}

export interface IEditLabelProps {
    lang: string;
    posTag: string;
    target: string;
    source: string;
    user: string;
}

export interface IFetchClusterElement {
    label: string,
    posTag: string
}

export interface IEditLabelProps {
    lang: string;
    posTag: string;
    target: string;
    source: string;
    user: string;
}

export interface IEditTermProps extends IEditLabelProps {
    editPosTags: IEditPostag[];
    addNets: IAddNet[];
    deleteNets: IAddNet[];
    addLocations: IAddLocation[];
    deleteLocations: IAddLocation[];
}

export interface PushResult extends EntryPush {
    id: number;
}

export interface ISpellingLemmaSpinner {
    language: Language;
    isSpinning: boolean;
}

export interface ITermFromPos {
    label: string;
    posTag: string;
}

export interface ILemmaSearchField {
    label: string;
    posTag: string;
    inserted_at: string | undefined;
    inserted_by: string | undefined;
    updated_at: string | undefined;
    updated_by: string | undefined;
    index: number;
    requestDataIndex?: number;
    rowIndex: number;
    parentLemma: string;
    lang?: string;
    synonym?: string;
    fromSyn?: boolean;
    requestData: ITermFromPos[];
    expanded?: boolean;
    isClicked?: boolean;
    reqFor?: IRequestFor;
    isSpelling?: boolean;
}

export interface ILemmaSearchItem {
    lg: string;
    synonym: ILemmaSearchField;
    lemmas: ILemmaSearchField[];
    latestDate: string[];
    expanded?: boolean;
    reqFor?: EDetailedFor;
}

export type EntriesActions =
    | TypedAction<typeof ENTRIES_SET, Entry[]>
    | TypedAction<typeof SET_PREVIOUS_ENTRIES, Entry[]>
    | TypedAction<typeof ENTRIES_NOTIFY_SET, IEntryDetailedNotify[]>
    | TypedAction<typeof SET_IS_LOCATION_POPUP_HANDLER, () => void>
    | TypedAction<typeof ENTRIES_GET_SOURCES, IBulkSourcesToTargetsNoPOS[]>
    | TypedAction<typeof UPDATE_LANGUAGES, Language[]>
    | TypedAction<typeof UPDATE_ENTRY_LANGUAGES, Language[]>
    | TypedAction<typeof UPDATE_POS_TAGS, PosTag[]>
    | TypedAction<typeof UPDATE_NETS, Net[]>
    | TypedAction<typeof UPDATE_NETS_SLA, string[]>
    | TypedAction<typeof UPDATE_FILTERED_NETS, filteredNet[] | []>
    | TypedAction<typeof SET_PUSH_RESULT, PushResult>
    | TypedAction<typeof UPDATE_SOURCES, Source[]>
    | TypedAction<typeof SET_ENTRY_FILTER, BacklogProps>
    | TypedAction<typeof SET_ENTRY_SORTING, IEntrySorting>
    | TypedAction<typeof UPDATE_OBJECT_IDS, ObjectId[]>
    | TypedAction<typeof SET_BACKLOG_COUNT, number>
    | TypedAction<typeof UPDATE_LEVELS, string[]>
    | TypedAction<typeof SET_SPELLING_LEMMA_SPINNING, ISpellingLemmaSpinner[]>
    | TypedAction<typeof SET_EXTERNAL_PAGE, number>
    | TypedAction<typeof SET_BACKLOG_PAGE, number>
    | TypedAction<typeof UPDATE_LEMMA_SEARCH_LIST, ILemmaSearchItem[]>
    | TypedAction<typeof SET_LEMMA_SEARCH_COUNT, number>
    | TypedAction<typeof SET_LEMMA_SEARCH_PAGE, number>
    | TypedAction<typeof UPDATE_POS_TAGS_SLA, string[]>
    | TypedAction<typeof SET_EXTERNAL_COUNT, number>
    | TypedAction<typeof SET_IS_LEMMA_SEARCH, boolean>
    | TypedAction<typeof SET_IS_LOCATION_POPUP_OPEN, boolean>
    | TypedAction<typeof SET_EDIT_TERM_ERROR, boolean>
    | TypedAction<typeof SET_DETAILED_ROW, ILemmaSearchField | undefined>
    | TypedAction<typeof SET_ADDITIONAL_INFO_STAGE, AdditionalInformationStage>
    | TypedAction<typeof SET_DETAILED_TERM, ITerm | undefined>
    | TypedAction<typeof SET_EDITABLE_TERM, ITerm | undefined>;

export enum EntryNotifyType {
    ENTRY_NOTIFY_EMPTY = "ENTRY_NOTIFY_EMPTY",
    ENTRY_NOTIFY_SPELLING_EXISTS = "ENTRY_WARNING_NOTIFY_SPELLING_EXISTS",
    ENTRY_NOTIFY_LEMMA_AUTOCOMPLETE = "ENTRY_WARNING_NOTIFY_LEMMA_AUTOCOMPLETE",
    ENTRY_NOTIFY_SYNONYM_AUTOCOMPLETE = "ENTRY_WARNING_NOTIFY_SYNONYM_AUTOCOMPLETE",
    ENTRY_NOTIFY_SPELLING_OVERLAP = "ENTRY_ERROR_NOTIFY_SPELLING_OVERLAP",
}

export enum BacklogStatuses {
    BACKLOG_DEFAULT = 1,
    BACKLOG_PROCESSED = 2,
    BACKLOG_EXTERNAL_APP = 3,
}

export enum EntryNotifyStatus {
    NOTIFY_STATUS_EMPTY,
    NOTIFY_STATUS_WARNING,
    NOTIFY_STATUS_ERROR,
}

export interface IEntryCheckSourceItem {
    source: string;
    targets: string[];
}

export interface IEntryCheckSources {
    lemmas_spellings: IEntryCheckSourceItem[];
    spellings_lemmas: IEntryCheckSourceItem[];
    synonyms_lemmas: IEntryCheckSourceItem[];
}

export interface EntryNotify {
    type: EntryNotifyType;
    status: EntryNotifyStatus;
    object: string;
    list: string[];
    insertItem: string;
}

export interface IEntryDetailedNotify extends EntryNotify {
    entryId: number;
}

export interface Source {
    name: string;
}

export interface ObjectId {
    name: string;
}

export interface NetRes {
    source: string;
    targets: string[];
}

export interface EntriesState {
    entries: Entry[];
    levels: string[];
    locationPopupHandler: () => void;
    previousEntries: Entry[];
    isInLemmaSearch: boolean;
    isLocationPopupOpen: boolean;
    spinners: ISpellingLemmaSpinner[];
    entriesForCheck: IEntryCheckSources[];
    languages: Language[];
    entryLanguages: Language[];
    nets: Net[];
    netsSla: string[];
    filteredNets: filteredNet[] | [];
    posTags: PosTag[];
    notify: IEntryDetailedNotify;
    pushResult: PushResult[];
    sources: Source[];
    objectIds: ObjectId[];
    entryFilter: BacklogProps;
    backlogCount: number;
    externalCount: number;
    backlogPage: number;
    externalPage: number;
    sorting: IEntrySorting;
    entrySearch: ILemmaSearchItem[];
    entrySearchCount: number,
    searchPage: number;
    posTagSla: string[];
    detailedTerm: ITerm | undefined;
    editableTerm: ITerm | undefined;
    detailedRow: ILemmaSearchField | undefined;
    additionalInfoStage: AdditionalInformationStage;
    editTermErrorStatus: boolean;
}

export interface filteredNet {
    entryId: number;
    nets: Net[];
}

export const ENTRY_NOTIFY_INITIAL = {
    entryId: 0,
    type: EntryNotifyType.ENTRY_NOTIFY_EMPTY,
    status: EntryNotifyStatus.NOTIFY_STATUS_EMPTY,
    object: "",
    list: [],
    insertItem: "",
};

export const initialState: EntriesState = {
    entries: [],
    levels: [],
    previousEntries: [],
    isInLemmaSearch: false,
    entriesForCheck: [],
    languages: [],
    entryLanguages: [],
    nets: [],
    filteredNets: [],
    posTags: [],
    notify: ENTRY_NOTIFY_INITIAL,
    pushResult: [],
    sources: [],
    spinners: [],
    objectIds: [],
    entryFilter: {},
    backlogCount: 0,
    externalCount: 0,
    externalPage: 0,
    backlogPage: 0,
    searchPage: 1,
    entrySearch: [],
    entrySearchCount: 0,
    sorting: {
        language: undefined,
        source: undefined,
        lemma: undefined,
        partOfSpeech: undefined,
        synonym: undefined,
        net: undefined,
        date: undefined,
        user: undefined,
        objectId: undefined,
        orderBy: OrderBy.Undefined,
        orderByReversed: false,
    },
    detailedTerm: undefined,
    editableTerm: undefined,
    detailedRow: undefined,
    additionalInfoStage: AdditionalInformationStage.read,
    posTagSla: [],
    netsSla: [],
    isLocationPopupOpen: false,
    editTermErrorStatus: false,
    locationPopupHandler: () => {
    },
};

export function entriesSet(entries: Entry[], previousEntries: Entry[] = []): Thunk {
    return async dispatch => {
        dispatch({
            type: ENTRIES_SET,
            payload: entries,
        });
        previousEntries.length > 0 && dispatch({
            type: SET_PREVIOUS_ENTRIES,
            payload: entries,
        });
    };
}

export function setEntryFilter(filter: BacklogProps): Thunk {
    return async dispatch => {
        dispatch({
            type: SET_ENTRY_FILTER,
            payload: filter,
        });
    };
}

export function setAdditionalInformationStage(stage: AdditionalInformationStage): Thunk {
    return async dispatch => {
        dispatch({
            type: SET_ADDITIONAL_INFO_STAGE,
            payload: stage,
        });
    };
}

export function setLocationPopupHandler(handler: () => void): Thunk {
    return async dispatch => {
        dispatch({
            type: SET_IS_LOCATION_POPUP_HANDLER,
            payload: handler,
        });
    };
}

export function setEntrySorting(sorting: IEntrySorting): Thunk {
    return async dispatch => {
        dispatch({
            type: SET_ENTRY_SORTING,
            payload: sorting,
        });
    };
}

export function setEditableTerm(item: ITerm | undefined): Thunk {
    return async dispatch => {
        dispatch({
            type: SET_EDITABLE_TERM,
            payload: item ? _.cloneDeep(item) : undefined,
        });
    };
}

export function setIsInLemmaSearch(flag: boolean): Thunk {
    return async dispatch => {
        dispatch({
            type: SET_IS_LEMMA_SEARCH,
            payload: flag,
        });
    };
}

export function setBacklogCount(count: number): Thunk {
    return async dispatch => {
        dispatch({
            type: SET_BACKLOG_COUNT,
            payload: count,
        });
    };
}

export function setExternalCount(count: number): Thunk {
    return async dispatch => {
        dispatch({
            type: SET_EXTERNAL_COUNT,
            payload: count,
        });
    };
}

export function setExternalListPage(page: number): Thunk {
    return async dispatch => {
        dispatch({
            type: SET_EXTERNAL_PAGE,
            payload: page,
        });
    };
}

export function setLemmaSearchPage(page: number): Thunk {
    return async dispatch => {
        dispatch({
            type: SET_LEMMA_SEARCH_PAGE,
            payload: page,
        });
    };
}

export function setBacklogListPage(page: number): Thunk {
    return async dispatch => {
        dispatch({
            type: SET_BACKLOG_PAGE,
            payload: page,
        });
    };
}

export function setEditTermErrorStatus(flag: boolean): Thunk {
    return async dispatch => {
        dispatch({
            type: SET_EDIT_TERM_ERROR,
            payload: flag,
        });
    };
}

export function backlogDelete(id: number): Thunk {
    return async dispatch => {
        dispatch(spinnerUp());
        await getEntryApiObj().delete(id);
        dispatch(spinnerDown());
    };
}

export function deleteSomeEntries(ids: number[]): Thunk {
    return async (dispatch, getState) => {
        if (ids && ids.length > 0) {
            dispatch(spinnerUp());
            for (let i = 0; i < ids.length; i++) {
                const id = ids[i];
                await getEntryApiObj()
                    .delete(id)
                    .then()
                    .catch();
            }
            const filter = selectEntryFilter(getState());
            dispatch(backlogGetQuery(filter));
            dispatch(spinnerDown());
        }
    };
}

export function getEntriesByPageBacklog(offset: number): Thunk {
    return (dispatch, getState) => {
        const filter = selectEntryFilter(getState());
        const sort = selectEntrySorting(getState());
        if (sort.orderBy !== OrderBy.Undefined) {
            filter.orderBy = sort.orderBy;
            filter.orderByReversed = sort.orderByReversed;
        }
        filter.offset = offset;
        filter.limit = 20;
        dispatch(backlogGetQuery(filter));
    };
}

export function getEntriesSourcesForCheck(props: IBulkSourcesToTargetsNoPOS): Thunk {
    return async (dispatch, getState) => {
        await getSpellingApiObj()
            .newSpellingEndpoint(props)
            .then(async (res) => {
                const entries = await res.json();

                dispatch({
                    type: ENTRIES_GET_SOURCES,
                    payload: entries,
                });
            })
            .catch()
            .finally();
    };
}

export function getEntriesByPageExternal(offset: number): Thunk {
    return (dispatch, getState) => {
        const filter = selectEntryFilter(getState());
        const sort = selectEntrySorting(getState());
        if (sort.orderBy !== OrderBy.Undefined) {
            filter.orderBy = sort.orderBy;
            filter.orderByReversed = sort.orderByReversed;
        }
        filter.offset = offset;
        filter.limit = 20;
        dispatch(backlogAppGetQuery(filter));
    };
}

export function setEntriesSpinner(language: Language, value: boolean): Thunk {
    return (dispatch, getState) => {
        const spinners = selectEntrySpinners(getState());
        const isInclude = spinners.map(elem => elem.language.code).includes(language.code);

        if (value) {
            !isInclude && spinners.push({language, isSpinning: value});
        } else if (isInclude && !value) {
            const findIndex = spinners.findIndex(elem => elem.language.code === language.code);
            findIndex >= 0 && spinners.splice(findIndex, 1);
        }

        dispatch({type: SET_SPELLING_LEMMA_SPINNING, payload: [...spinners]});
    };
}

export function getEntriesSpinner(language: Language) {
    const spinners = selectEntrySpinners(getApiUtils().getState());
    return spinners.map(elem => elem.language.code).includes(language.code);
}

export function backlogGetQuery(props: BacklogProps): Thunk {
    return async dispatch => {
        dispatch({
            type: ENTRIES_SET,
            payload: [],
        });

        dispatch(spinnerUp());

        const response = await getEntryApiObj().getBacklog(props);
        let entries = response;
        const newEntries = response.data.map((entry: Entry) => {
            const res = {
                ...entry,
                check: false,
                created: false,
                notifications: [],
            };

            if (props.status === BacklogStatuses.BACKLOG_EXTERNAL_APP) {
                res.date_app = entry.date_app ? entry.date_app : "";
            } else {
                res.date = entry.date ? entry.date : "";
            }

            return res;
        });

        if (entries) {
            dispatch({
                type: ENTRIES_SET,
                payload: newEntries,
            });
            dispatch({
                type: SET_PREVIOUS_ENTRIES,
                payload: newEntries,
            });
            dispatch(props.status !== BacklogStatuses.BACKLOG_EXTERNAL_APP
                ? setBacklogCount(parseInt(entries.count as string))
                : setExternalCount(parseInt(entries.count as string)),
            );
        } else console.error("Не получили res backlogGetQuery");

        dispatch(spinnerDown());
    };
}

export function getLemmaSearch(props: ILemmaSearchQueryProps, clearTerm: boolean = true, successCallback?: () => void): Thunk {
    return async (dispatch, getState) => {
        dispatch({
            type: UPDATE_LEMMA_SEARCH_LIST,
            payload: [],
        });
        dispatch({
            type: SET_IS_LEMMA_SEARCH,
            payload: true,
        });
        const {additionalInfoStage} = selectEntries(getState());
        dispatch(spinnerUp());

        await getLemmaApiObj()
            .getSearch({
                ...props,
                before: new Date(moment(props.before).format("YYYY-MM-DD 23:59:59")).toISOString(),
                limit: 21,
            })
            .then((res) => {
                if (!res) return;
                const result = [...res.data];
                if (res.data.length === 21) {
                    result.pop();
                }
                dispatch({
                    type: UPDATE_LEMMA_SEARCH_LIST,
                    payload: result,
                });
                dispatch({
                    type: SET_DETAILED_ROW,
                    payload: undefined,
                });
                {
                    clearTerm && dispatch({
                        type: SET_DETAILED_TERM,
                        payload: undefined,
                    });
                }
                dispatch({
                    type: SET_LEMMA_SEARCH_COUNT,
                    payload: res.data.length,
                });
                successCallback && successCallback();
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                dispatch({
                    type: SET_IS_LEMMA_SEARCH,
                    payload: false,
                });
                dispatch(spinnerDown());
            });
    };
}

export function getLemmaLastInsertions(props: ILemmaLastInsertionsQueryProps, clearSearch: boolean = true): Thunk {
    return async (dispatch, getState) => {
        dispatch({
            type: UPDATE_LEMMA_SEARCH_LIST,
            payload: [],
        });

        dispatch(spinnerUp());
        const {additionalInfoStage} = selectEntries(getState());
        await getLemmaApiObj()
            .getLastInsertions({...props, limit: 21})
            .then(res => {
                if (!res) return;
                const result = [...res.data];
                if (res.data.length === 21) {
                    result.pop();
                }
                dispatch({
                    type: UPDATE_LEMMA_SEARCH_LIST,
                    payload: result,
                });
                dispatch({
                    type: SET_DETAILED_ROW,
                    payload: undefined,
                });
                {
                    clearSearch && dispatch({
                        type: SET_DETAILED_TERM,
                        payload: undefined,
                    });
                }
                dispatch({
                    type: SET_LEMMA_SEARCH_COUNT,
                    payload: res.data.length,
                });
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                dispatch(spinnerDown());
            });
    };
}

export function backlogAppGetQuery(props: BacklogProps): Thunk {
    return async dispatch => {
        dispatch({
            type: ENTRIES_SET,
            payload: [],
        });

        dispatch(spinnerUp());

        const response = await getEntryApiObj().getBacklog(props);
        let entries = response;
        const newEntries = entries.data.map((entry: Entry) => {
            return {
                ...entry,
                check: false,
                created: false,
                notifications: [],
                date: formatDate(Date.parse(entry.date ? entry.date : "")),
            };
        });

        if (entries) {
            dispatch({
                type: ENTRIES_SET,
                payload: newEntries,
            });
            dispatch({
                type: SET_PREVIOUS_ENTRIES,
                payload: newEntries,
            });
            dispatch(setExternalCount(parseInt(entries.count as string)));
        } else console.error("Не получили res backlogGetQuery");

        dispatch(spinnerDown());
    };
}

export function editLabel(props: IEditLabelProps): Thunk {
    return async (dispatch, getState) => {
        const {username,} = selectAuth(getState());
        await getSpellingApiObj()
            .editLabel({
                ...props, user: username
                    ? username
                    : "",
            })
            .then(res => res.json())
            .then(() => {
                dispatch(setLemmaSearchPage(1));
            })
            .catch((error) => {
                dispatch(setEditTermErrorStatus(true))
                try {
                    const errorText = JSON.parse(error.message);
                    const subError = JSON.parse(errorText.message);

                    dispatch(popupPush({
                        id: new Date().getTime().toString(),
                        type: PopupType.POPUP_ERROR_LABEL,
                        dataPosTag: {
                            errorText: subError.generalMessage,
                            label: props.target as string,
                            data: [{
                                label: props.target as string,
                                posTag: props.posTag,
                            }],
                            replace: true,
                        },
                        data: [],
                        actionVisible: false,
                        cancelVisible: true,
                        cancelTitle: "Close",
                    }));
                } catch (e) {
                    console.log("catch", e);
                    const errorText = JSON.parse(error.message);
                    dispatch(popupPush({
                        id: new Date().getTime().toString(),
                        type: PopupType.POPUP_ERROR_LABEL,
                        dataPosTag: {
                            errorText: errorText.message,
                            label: props.target as string,
                            data: [{
                                label: props.target as string,
                                posTag: props.posTag,
                            }],
                        },
                        data: [],
                        actionVisible: false,
                        cancelVisible: true,
                        cancelTitle: "Close",
                    }));
                }
                console.log("edit label error", error);
            })
            .finally(() => {
            });
    };
}

export function fetchCluster(newValue: string): Thunk {
    return async (dispatch, getState) => {
        const {detailedTerm, editableTerm} = selectEntries(getState());
        await getSpellingApiObj()
            .fetchCluster({
                posTag: detailedTerm!.posTag,
                label: detailedTerm!.label,
                lang: detailedTerm!.lang,
            })
            .then((response) => response.json())
            .then(async (res) => {
                const response: IFetchClusterElement[] = res;
                const filteredRes =  response.filter(elem => elem.posTag !== newValue)
                if (filteredRes && ((filteredRes.length === 1 && filteredRes[0] && filteredRes[0].label === detailedTerm!.label && filteredRes[0].posTag === detailedTerm!.posTag) || filteredRes.length === 0)) {
                    dispatch(setEditableTerm({...editableTerm as ITerm, posTag: newValue}));
                } else {
                    dispatch(popupPush({
                        id: new Date().getTime().toString(),
                        type: PopupType.POPUP_POSTAG,
                        dataPosTag: {
                            label: detailedTerm!.label,
                            data: filteredRes.filter(elem => elem.posTag !== newValue),
                        },
                        data: [],
                        actionTitle: "Ok",
                        actionHandler: () => {
                            dispatch(setEditableTerm({...editableTerm as ITerm, posTag: newValue}));
                        },
                        actionVisible: true,
                        cancelVisible: true,
                        cancelTitle: "Cancel",
                    }));
                }
            })
            .catch((err) => {

            })
            .finally(() => {

            });

    };
}

export function editPostag(props: IEditPostag[]): Thunk {
    return async (dispatch, getState) => {
        const {username} = selectAuth(getState());
        const {detailedTerm, editableTerm, entryLanguages} = selectEntries(getState());

        await getSpellingApiObj()
            .fetchCluster({
                posTag: detailedTerm!.posTag,
                label: editableTerm!.label,
                lang: detailedTerm!.lang,
            })
            .then((response) => response.json())
            .then(async (res) => {
                const response: IFetchClusterElement[] = res;
                const firstElem = props[0];
                const posTags = [...props.map(elem => ({
                    ...elem,
                    source: editableTerm!.label,
                    user: username
                            ? username
                            : "",
                })), ...response.filter(filterElem => filterElem.posTag !== editableTerm!.posTag).map(elem => ({
                    ...firstElem,
                    source: elem!.label,
                    posTag: elem.posTag,
                    user: username
                        ? username
                        : "",
                }))];
                await getPosTagApiObj()
                    .editPosTag(posTags)
                    .then(res => {
                        dispatch(setLemmaSearchPage(1));
                        if (editableTerm!.posTag === POS_TAG_PROPER_NOUN.name) {
                            dispatch(popupPush({
                                id: new Date().getTime().toString(),
                                type: PopupType.POPUP_POSTAG_PROPER_NOUN,
                                dataPosTag: {
                                    label: detailedTerm!.label,
                                    data: [{
                                        label: detailedTerm!.synonym,
                                        posTag: detailedTerm!.posTag,
                                    }],
                                },
                                data: [],
                                cancelHandler: () => {
                                    const requestProps: ILemmaSearchQueryProps = {
                                        lang: detailedTerm!.lang.toLowerCase(),
                                        lower: `${editableTerm!.synonym}`,
                                        field: EWordPartOrSearchParam.SYNONYM,
                                        caseSensitive: true,
                                        type: ESearchRadio.EXACT_MATCH,
                                        posTags: `${editableTerm!.posTag}`,
                                        limit: 21,
                                        direction: ESortDirection.DESC,
                                        sort: ESortBy.DATE,
                                        page: 1,
                                        after:  new Date("1970-01-01").toISOString(),
                                    };
                                    const languages = entryLanguages.filter(elem => elem.name);
                                    const findLanguage = languages.find(elem => elem.code === detailedTerm!.lang)
                                    if(findLanguage){
                                        let stateProcessed: ISearchFilterState = {
                                            ...searchFilterState,
                                            language: findLanguage,
                                            caseSensitive: true,
                                            posTags: `${editableTerm!.posTag}`,
                                            searchParam: ESearchRadio.EXACT_MATCH,
                                            wordPart: EWordPartOrSearchParam.SYNONYM,
                                            search: `${editableTerm!.synonym ? editableTerm!.synonym : detailedTerm!.synonym ? detailedTerm!.synonym : editableTerm!.label}`,
                                            insDate: new Date("1970-01-01").toISOString(),
                                            endDate: moment().format("YYYY-MM-DD"),
                                        }
                                        dispatch(setLemmaSearchPage(1));
                                        window.history.pushState(null, "/search/", `/search/?${convertSearchStateToUrl({
                                            ...stateProcessed,
                                            language: `${findLanguage.code}/${findLanguage.name}`
                                        })}`)
                                    }
                                    dispatch(getLemmaSearch(requestProps, false, () => {
                                        setTimeout(() => {
                                            const element = document.getElementById(`row/Synonym/0`);
                                            if (element) {
                                                element.click();
                                            }
                                        }, 25);
                                    }));
                                },
                                cancelTitle: "Ok",
                                cancelVisible: true,
                            }));

                        }
                    })
                    .catch((error) => {
                        dispatch(setEditTermErrorStatus(true))
                        try {
                            const errorText = JSON.parse(error.message);
                            const subError = JSON.parse(errorText.message);
                            dispatch(popupPush({
                                id: new Date().getTime().toString(),
                                type: PopupType.POPUP_POSTAG_ERROR,
                                dataPosTag: {
                                    errorText: subError.generalMessage,
                                    label: "",
                                    data: [{
                                        posTag: detailedTerm!.posTag,
                                        label: detailedTerm!.label,
                                    }],
                                },
                                data: [],
                                actionVisible: false,
                                cancelVisible: true,
                                cancelTitle: "Close",
                            }));
                        } catch (e) {
                            console.log("catch", e);
                            const errorText = JSON.parse(error.message);
                            dispatch(popupPush({
                                id: new Date().getTime().toString(),
                                type: PopupType.POPUP_POSTAG_ERROR,
                                dataPosTag: {
                                    errorText: errorText.message,
                                    label: "",
                                    data: [{
                                        posTag: detailedTerm!.posTag,
                                        label: detailedTerm!.label,
                                    }],
                                },
                                data: [],
                                actionVisible: false,
                                cancelVisible: true,
                                cancelTitle: "Close",
                            }));
                        }

                    })
                    .finally(() => {

                    });


            })
            .catch((err) => {
                dispatch(popupPush({
                    id: new Date().getTime().toString(),
                    type: PopupType.POPUP_POSTAG_ERROR,
                    dataPosTag: {
                        errorText: err.message,
                        label: "",
                        data: [{
                            posTag: editableTerm!.posTag,
                            label: editableTerm!.label,
                        }],
                    },
                    data: [],
                    actionVisible: false,
                    cancelVisible: true,
                    cancelTitle: "\"Ok\"",
                }));
            })
            .finally(() => {

            });
    };
}

export function addNets(props: IAddNet[]): Thunk {
    return async (dispatch, getState) => {
        const {username} = selectAuth(getState());
        const {detailedTerm, editableTerm} = selectEntries(getState());
        for (let i = 0; i < props.length; i++) {
            const net = props[i];
            await getNetApiObj()
                .addNet({
                    ...net,
                    source: editableTerm!.synonym ? editableTerm!.synonym : detailedTerm!.synonym ? detailedTerm!.synonym : detailedTerm!.label,
                    posTag: POS_TAG_PROPER_NOUN.name,
                    user: username
                        ? username
                        : "",
                })
                .then(res => res.json())
                .then(() => {

                })
                .catch((error) => {
                    dispatch(setEditTermErrorStatus(true))
                    try {
                        const errorText = JSON.parse(error.message);
                        const subError = JSON.parse(errorText.message);
                        dispatch(popupPush({
                            id: new Date().getTime().toString(),
                            type: PopupType.POPUP_MESSAGE,
                            data: [subError.generalMessage],
                            actionVisible: false,
                            cancelVisible: true,
                            cancelTitle: "Close",
                        }));
                    } catch (e) {
                        console.log("catch", e);
                        const errorText = JSON.parse(error.message);
                        dispatch(popupPush({
                            id: new Date().getTime().toString(),
                            type: PopupType.POPUP_MESSAGE,
                            data: [errorText.message],
                            actionVisible: false,
                            cancelVisible: true,
                            cancelTitle: "Close",
                        }));
                    }
                })
                .finally(() => {
                });
        }
    };
}

export function addLocations(props: IAddLocation[]): Thunk {
    return async (dispatch, getState) => {
        const {username} = selectAuth(getState());
        const {editableTerm, detailedTerm} = selectEntries(getState());
        for (let i = 0; i < props.length; i++) {
            const net = props[i];
            //@ts-ignore
            delete net.isNew;
            await getNetApiObj()
                .addLocation({
                    ...net,
                    source: editableTerm!.synonym ? editableTerm!.synonym : detailedTerm!.synonym ? detailedTerm!.synonym : detailedTerm!.label,
                    longitude: parseFloat(net.longitude as string),
                    latitude: parseFloat(net.latitude as string),
                    user: username
                        ? username
                        : "",
                })
                .then(res => res.json())
                .then(() => {

                })
                .catch((error) => {
                    dispatch(setEditTermErrorStatus(true))
                    try {
                        const errorText = JSON.parse(error.message);
                        const subError = JSON.parse(errorText.message);
                        dispatch(popupPush({
                            id: new Date().getTime().toString(),
                            type: PopupType.POPUP_MESSAGE,
                            data: [subError.generalMessage],
                            actionVisible: false,
                            cancelVisible: true,
                            cancelTitle: "Close",
                        }));
                    } catch (e) {
                        console.log("catch", e);
                        const errorText = JSON.parse(error.message);
                        dispatch(popupPush({
                            id: new Date().getTime().toString(),
                            type: PopupType.POPUP_MESSAGE,
                            data: [errorText.message],
                            actionVisible: false,
                            cancelVisible: true,
                            cancelTitle: "Close",
                        }));
                    }
                })
                .finally(() => {
                });
        }
    };
}

export function deleteLocations(props: IAddLocation[]): Thunk {
    return async (dispatch, getState) => {
        const {username} = selectAuth(getState());
        const {editableTerm, detailedTerm} = selectEntries(getState());
        for (let i = 0; i < props.length; i++) {
            const net = props[i];
            await getNetApiObj()
                .deleteLocation({
                    ...net,
                    source: editableTerm!.synonym ? editableTerm!.synonym : detailedTerm!.synonym ? detailedTerm!.synonym : detailedTerm!.label,
                    user: username
                        ? username
                        : "",
                })
                .then(res => res.json())
                .then(() => {

                })
                .catch((error) => {
                    dispatch(setEditTermErrorStatus(true))
                    console.log("edit label error", error);
                })
                .finally(() => {
                });
        }
    };
}

export function deleteNets(props: IAddNet[]): Thunk {
    return async (dispatch, getState) => {
        const {editableTerm, detailedTerm} = selectEntries(getState());
        const {username,} = selectAuth(getState());
        for (let i = 0; i < props.length; i++) {
            const net = props[i];
            await getSpellingApiObj()
                .deleteNet({
                    ...net,
                    source: editableTerm!.synonym ? editableTerm!.synonym : detailedTerm!.synonym ? detailedTerm!.synonym : detailedTerm!.label,
                    posTag: POS_TAG_PROPER_NOUN.name,
                    relationshipType: "net",
                    user: username
                        ? username
                        : "",
                })
                .then(() => {
                })
                .catch((error) => {
                    dispatch(setEditTermErrorStatus(true))
                    try {
                        const errorText = JSON.parse(error.message);
                        const subError = JSON.parse(errorText.message);
                        dispatch(popupPush({
                            id: new Date().getTime().toString(),
                            type: PopupType.POPUP_MESSAGE,
                            data: [subError.generalMessage],
                            actionVisible: false,
                            cancelVisible: true,
                            cancelTitle: "Close",
                        }));
                    } catch (e) {
                        console.log("catch", e);
                        const errorText = JSON.parse(error.message);
                        dispatch(popupPush({
                            id: new Date().getTime().toString(),
                            type: PopupType.POPUP_MESSAGE,
                            data: [errorText.message],
                            actionVisible: false,
                            cancelVisible: true,
                            cancelTitle: "Close",
                        }));
                    }
                })
                .finally(() => {
                });
        }
    };
}

export function editTerm(props: IEditTermProps): Thunk {
    return async (dispatch, getState) => {
        const {detailedTerm, editableTerm, detailedRow} = selectEntries(getState());
        const row = {...detailedRow};
        dispatch(spinnerUp());

        if (editableTerm!.posTag === POS_TAG_PROPER_NOUN.name || detailedTerm!.posTag === POS_TAG_PROPER_NOUN.name) {
            if (props.deleteLocations.length > 0) {
                await dispatch(await deleteLocations(props.deleteLocations));
            }
            if (props.addNets.length > 0) {
                await dispatch(await addNets(props.addNets));
            }
            if (props.addLocations.length > 0) {
                await dispatch(await addLocations(props.addLocations));
            }
            if (props.deleteNets.length > 0) {
                await dispatch(await deleteNets(props.deleteNets));
            }
        }
        if (detailedTerm!.label !== editableTerm!.label) {
            await dispatch(await editLabel({...props, posTag: detailedTerm!.posTag}));
        }
        if (detailedTerm!.posTag !== editableTerm!.posTag) {
            await dispatch(await editPostag(props.editPosTags));
        }

        const {editTermErrorStatus, searchPage} = selectEntries(getState());
        if (!editTermErrorStatus) {
            dispatch(await getSearchByUrl(false, searchPage));
        }
        if (!editTermErrorStatus) {
            await getTermApiObj()
                .getTerm({
                    label: editableTerm!.label,
                    lang: editableTerm!.lang,
                    posTag: editableTerm!.posTag,
                })
                .then(response => response.json())
                .then(async (res: ITerm) => {
                    if (!res) return;
                    dispatch({
                        type: SET_DETAILED_TERM,
                        payload: {
                            ...res,
                            nets: res.nets.map((elem: string[][]) => Object.keys(elem)).flat(),
                            lang: props.lang,
                        },
                    });
                })
                .catch((err) => {
                    try {
                        const errorText = JSON.parse(err.message);
                        const subError = JSON.parse(errorText.message);

                        dispatch(popupPush({
                            id: new Date().getTime().toString(),
                            type: PopupType.POPUP_MESSAGE,
                            data: [subError.generalMessage],
                            actionVisible: false,
                            cancelVisible: true,
                            cancelTitle: "Close",
                        }));
                    } catch (e) {
                        try {
                            console.log("catch", e);
                            const errorText = JSON.parse(err.message);
                            dispatch(popupPush({
                                id: new Date().getTime().toString(),
                                type: PopupType.POPUP_MESSAGE,
                                data: [errorText.message],
                                actionVisible: false,
                                cancelVisible: true,
                                cancelTitle: "Close",
                            }));
                        } catch {

                        }

                    }
                })
                .finally(() => {
                });
        }
        dispatch(setAdditionalInformationStage(AdditionalInformationStage.read));
        dispatch(spinnerDown());
        if (editTermErrorStatus) {
            dispatch(setEditTermErrorStatus(false))
        }
    };
}

export function languagesQuery(unique?: boolean): Thunk {
    return async (dispatch, getState) => {
        dispatch(spinnerUp());
        const entryFilter = selectEntryFilter(getState());
        const params: IFilterParams = {...DEFAULT_FILTER_PARAMS};

        params.userApp = entryFilter.userApp;
        //@ts-ignore
        params.userId = entryFilter.user;
        params.sourceApp = entryFilter.sourceApp;
        params.objectIdApp = entryFilter.objectIdApp;
        params.status = entryFilter.status;

        const response = unique
            ? await getEntryLanguageApiObj().getAllUniq(params)
            : await getLanguageApiObj().getAll();
        const languages = await response.json();

        if (languages) {
            dispatch({
                type: UPDATE_LANGUAGES,
                payload: languages,
            });
        } else {
            console.error("Не получили res languagesQuery");
        }

        dispatch(spinnerDown());
    };
}

export function getLanguagesForEntryEditor(): Thunk {
    return async (dispatch) => {
        dispatch(spinnerUp());
        const response = await getLanguageApiObj().getAll();
        const languages = await response.json();

        if (languages) {
            dispatch({
                type: UPDATE_ENTRY_LANGUAGES,
                payload: languages,
            });
        } else {
            console.error("Не получили res languagesQuery");
        }

        dispatch(spinnerDown());
    };
}


export function posTagsQuery(): Thunk {
    return async dispatch => {
        dispatch(spinnerUp());

        const response = await getPosTagApiObj().getAll();
        const posTags: PosTag[] = await response.json();

        if (posTags) {
            dispatch({
                type: UPDATE_POS_TAGS,
                payload: posTags,
            });
        } else {
            console.error("Не получили res posTagsQuery");
        }

        dispatch(spinnerDown());
    };
}

export function posTagsSlaQuery(): Thunk {
    return async dispatch => {
        dispatch(spinnerUp());

        const response = await getPosTagApiObj().getPosTagSla();
        const posTags: PosTag[] = await response.json();

        if (posTags) {
            dispatch({
                type: UPDATE_POS_TAGS_SLA,
                payload: posTags,
            });
        } else {
            console.error("Не получили res posTagsQuery");
        }

        dispatch(spinnerDown());
    };
}

export function checkDelete(props: IDeleteCheckQuery): Thunk {
    return async dispatch => {
        dispatch(spinnerUp());
        await getSpellingApiObj()
            .checkDelete(props)
            .then(response => response.json())
            .then((res) => {
                if (!res) return;

                if (res.length > 0) {
                    dispatch(popupPush({
                        id: new Date().getTime().toString(),
                        type: PopupType.POPUP_MESSAGE,
                        data: ["This term cannot be\n" +
                        "deleted, it has dependencies"],
                        actionTitle: "Ok",
                        actionVisible: true,
                        cancelVisible: false,
                    }));
                } else {
                    dispatch(popupPush({
                        id: new Date().getTime().toString(),
                        type: PopupType.POPUP_MESSAGE,
                        data: ["Confirm that you want to delete term"],
                        actionHandler: () => {
                            dispatch(deleteNode(props));
                        },
                        actionTitle: "Confirm",
                        cancelTitle: "Cancel",
                        actionVisible: true,
                        cancelVisible: true,
                    }));
                }
            })
            .catch((error) => console.log(error))
            .finally(() => {
                dispatch(spinnerDown());
            });
    };
}

export function getSearchByUrl(clearSearch: boolean = true, page?: number): Thunk {
    return async (dispatch, getState) => {
        const stateProcessed = getSearchStateProcessed();
        const {searchPage, additionalInfoStage} = selectEntries(getState());
        if (stateProcessed !== undefined) {
            const {
                search,
                searchParam,
                wordPart,
                posTags,
                language,
                caseSensitive,
                insDate,
                endDate,
                direction,
                sortBy,
            } = stateProcessed;

            if (stateProcessed.search.length > 2 && searchPage > 0) {
                const requestProps: ILemmaSearchQueryProps = {
                    lang: language.code.toLowerCase(),
                    lower: search,
                    field: wordPart,
                    caseSensitive,
                    type: searchParam,
                    posTags: posTags.length > 0 ? posTags : "",
                    limit: 21,
                    direction: direction,
                    sort: sortBy,
                    page: searchPage,
                    after: new Date(insDate).toISOString(),
                    before: new Date(endDate).toISOString(),
                };
                dispatch(getLemmaSearch(requestProps, clearSearch));
            } else {
                dispatch(getLemmaLastInsertions({
                    language: language.code.toLowerCase(),
                    page: page ? page : 1,
                    limit: 21
                }, clearSearch));
            }
        } else {
            dispatch(getLemmaLastInsertions({language: "", page: page ? page : 1, limit: 21}, clearSearch));
        }

    };
}

export function getSearchBySort(sortBy: ESortBy, direction: ESortDirection): Thunk {
    return async (dispatch, getState) => {
        const stateProcessed = getSearchStateProcessed();
        const {searchPage} = selectEntries(getState());
        if (stateProcessed !== undefined) {
            const {} = stateProcessed;
            if (stateProcessed.search.length > 2 && searchPage > 0) {
                window.history.pushState(null, "/search/", `/search/?${convertSearchStateToUrl({
                    ...stateProcessed,
                    sortBy: sortBy,
                    direction: direction,
                    language: `${stateProcessed.language.code}/${stateProcessed.language.name}`,
                })}`);
                dispatch(getSearchByUrl());
            }
        }

    };
}

export function netQuery(): Thunk {
    return async dispatch => {
        dispatch(spinnerUp());

        const response = await getNetApiObj().getAll();
        const nets = await response.json();

        if (nets) {
            dispatch({
                type: UPDATE_NETS,
                payload: nets,
            });
        } else {
            console.error("Не получили res netQuery");
        }

        dispatch(spinnerDown());
    };
}

export function netSlaQuery(): Thunk {
    return async dispatch => {
        dispatch(spinnerUp());

        const response = await getNetApiObj().getNetsSla();
        const nets = await response.json();

        if (nets) {
            dispatch({
                type: UPDATE_NETS_SLA,
                payload: nets,
            });
        } else {
            console.error("Не получили res netQuery");
        }

        dispatch(spinnerDown());
    };
}

export function deleteNode(props: IDeleteCheckQuery): Thunk {
    return async (dispatch, getState) => {
        dispatch(spinnerUp());
        await getSpellingApiObj()
            .deleteNode(props)
            .then(() => {
                dispatch(getSearchByUrl());
            })
            .catch((er) => {
                console.log(er);
            })
            .finally(() => {
                dispatch(spinnerDown());
            });
    };
}

export function onSetNets(nets: filteredNet[] | []): Thunk {
    return async dispatch => {
        dispatch({
            type: UPDATE_FILTERED_NETS,
            payload: nets,
        });
    };
}

export function onSetLemmaSearch(items: ILemmaSearchItem[] | []): Thunk {
    return async dispatch => {
        dispatch({
            type: UPDATE_LEMMA_SEARCH_LIST,
            payload: items,
        });
    };
}

export function getLevels(): Thunk {
    return async dispatch => {
        dispatch(spinnerUp())
        await getNetApiObj()
            .getLevels()
            .then(response => response.json())
            .then(response => {
                dispatch({
                    type: UPDATE_LEVELS,
                    payload: response,
                });
            })
            .catch((error) => {
                console.log("getLevels eroor", error)
            })
        dispatch(spinnerDown())
    };
}

export function querySourcesApp(unique?: boolean, params: IFilterParams = DEFAULT_FILTER_PARAMS): Thunk {
    return async (dispatch, getState) => {
        dispatch(spinnerUp());

        const params: IFilterParams = {...DEFAULT_FILTER_PARAMS};
        const entryFilter = selectEntryFilter(getState());

        params.userApp = entryFilter.userApp;
        params.objectIdApp = entryFilter.objectIdApp;
        params.lang = entryFilter.lang;

        const response = unique ? await getSourceApiObj().getAllUniq(params) : await getSourceApiObj().getAll();
        const sources = await response.json();

        if (sources) {
            dispatch({
                type: UPDATE_SOURCES,
                payload: sources,
            });
        } else {
            console.error("Не получили res netQuery");
        }

        dispatch(spinnerDown());
    };
}

export function queryObjectIdsApp(unique?: boolean): Thunk {
    return async (dispatch, getState) => {
        dispatch(spinnerUp());
        const params: IFilterParams = {...DEFAULT_FILTER_PARAMS};
        const entryFilter = selectEntryFilter(getState());

        params.userApp = entryFilter.userApp;
        params.sourceApp = entryFilter.sourceApp;
        params.lang = entryFilter.lang;

        const response = unique ? await getObjectIdApiObj().getAllUniq(params) : await getObjectIdApiObj().getAll();
        const objectIds = await response.json();

        if (objectIds) {
            dispatch({
                type: UPDATE_OBJECT_IDS,
                payload: objectIds,
            });
        } else {
            console.error("Не получили res netQuery");
        }

        dispatch(spinnerDown());
    };
}

export async function netFromSynQuery(synonym: string[], lang: string) {
    const response = await getNetApiObj().fromSyn({synonym, lang});
    const nets: NetRes[] = await response.json();

    return nets;
}

export async function autocompleteLemmaQuery(lemma: string, lang: string, limit: number) {
    const response = await getLemmaApiObj().getAutocomplete({lemma, lang, limit});

    return response.json();
}

export async function autocompleteSynonymQuery(props: PropsSynonymLang) {
    const response = await getSynonymApiObj().getAutocomplete(props);

    return response.json();
}

export async function synonymQuery(lemma: string, lang: string, posTag: string) {
    const response = await getSynonymApiObj().getByLemmaPos({lang, lemma, posTag});

    return response.json();
}

export async function spellingByLemmaPosQuery(lemmaLabel: string, posTag: string, lang: string) {
    //getApiUtils().dispatch(spinnerUp())
    const response = await getSpellingApiObj().spellingByLemmaPos({lemmaLabel, posTag, lang});
    //getApiUtils().dispatch(spinnerDown())

    return response.json();
}

export async function bulkSourcesToTargetsNoPOS(props: IBulkSourcesToTargetsNoPOS) {
    //getApiUtils().dispatch(spinnerUp())
    const response = await getSpellingApiObj().newSpellingEndpoint(props);
    //getApiUtils().dispatch(spinnerDown())

    return response.json();
}

export async function lemmaBySynonymPosQuery(props: PropsSynonymLanPos) {
    //getApiUtils().dispatch(spinnerUp())
    const response = await getLemmaApiObj().lemmaBySynonymPos(props);
    //getApiUtils().dispatch(spinnerDown())

    return response.json();
}

export async function lemmaBySpellingQuery(spelling: string[], lang: string) {
    //getApiUtils().dispatch(spinnerUp())
    const response = await getLemmaApiObj().lemmaBySpelling({spelling, lang});
    //getApiUtils().dispatch(spinnerDown())

    return response.json();
}

export function entryValidate(entries: EntryPush[], success: () => void, fail: (errors: string[]) => void, isManual: boolean = false): Thunk {
    return async (dispatch, getState) => {
        dispatch(spinnerUp());

        let successStatus = true;
        let errors: string[] = [];

        for (let i = 0; i < entries.length; i++) {
            let response;
            const entryElem = entries[i];

            try {
                response = await getEntryApiObj().validate(entryElem);
                let responseStatus = response && response.status === 200;
                successStatus = successStatus && responseStatus;
            } catch (e) {
                successStatus = false;
                if (e.status === 400) {
                    const errorData = JSON.parse(e.text);
                    const errorMessage = (errorData.line.details[0] as String).replace("On line 1: ", "");
                    errors.push(errorMessage);
                }
            }
        }

        if (successStatus) {
            success();
        } else {
            fail(errors);
        }

        dispatch(spinnerDown());
    };
}

export function entryPushToBacklog(entries: EntryPush[], success: () => void): Thunk {
    return async (dispatch, getState) => {
        dispatch(spinnerUp());

        const copyEntriesFromState = [...getState().entries.entries];
        let entriesToBacklog = [];

        for (let i = 0; i < entries.length; i++) {
            const entryElem = entries[i];
            let response;
            if (entryElem.created) {
                response = await getEntryApiObj().entry(entryElem);
                const entry: EntryPush = await response.json();

                copyEntriesFromState[entryElem.index!].id = entry.id;
                copyEntriesFromState[entryElem.index!].created = false;
                !entryElem.data.sendData && entriesToBacklog.push(entry);
            } else {
                response = await getEntryApiObj().updateToBack(entryElem);
            }
        }

        success();

        entriesToBacklog.length > 0 &&
        dispatch({
            type: ENTRIES_SET,
            payload: copyEntriesFromState,
        });
        dispatch({
            type: SET_PREVIOUS_ENTRIES,
            payload: copyEntriesFromState,
        });

        dispatch(spinnerDown());
    };
}

export function entrySaveChanges(entries: EntryPush[], success: () => void): Thunk {
    return async (dispatch, getState) => {
        dispatch(spinnerUp());
        const {username} = selectAuth(getState());
        const copyEntriesFromState = [...getState().entries.entries];
        let entriesToBacklog = [];

        for (let i = 0; i < entries.length; i++) {
            const entryElem = entries[i];
            delete entryElem.data.sendData;
            let response;

            if (entryElem.created) {
                response = await getEntryApiObj().entrySaveToExternal({
                    source: "source",
                    objectId: "objectId",
                    terms: {
                        spellings: entryElem.data.spelling,
                        lemma: entryElem.data.lemma,
                    },
                    date: moment().format("YYYY-MM-DD"),
                    user: username ? username : "username",
                    language: entryElem.data.language.code,
                    posTag: entryElem.data.posTag,
                    synonym: entryElem.data.synonym,
                    itSelf: entryElem.data.itSelf,
                    netTag: entryElem.data.netTag,
                });
                const entry: EntryPush[] = await response.json();
                copyEntriesFromState[entryElem.index!].id = entry[0].id;
                copyEntriesFromState[entryElem.index!].created = false;
                !entryElem.data.sendData && entriesToBacklog.push(entry[0]);
            } else {
                await getEntryApiObj().entrySaveChanges(entryElem);
            }
        }
        success();
        entriesToBacklog.length > 0 &&
        dispatch({
            type: ENTRIES_SET,
            payload: copyEntriesFromState,
        });
        dispatch({
            type: SET_PREVIOUS_ENTRIES,
            payload: copyEntriesFromState,
        });
        dispatch(spinnerDown());
    };
}

export function getDetailedTerm(props: ITermQuery, reqFor: IRequestFor, isSpelling?: boolean): Thunk {
    return async (dispatch, getState) => {
        dispatch(spinnerUp());
        const {posTags, entrySearch, additionalInfoStage} = selectEntries(getState());
        const findPosTag = posTags.find(elem => elem.name === reqFor.posTag);
        let newSearch = [...entrySearch];
        let reqDate: ITermFromPos[] = [];
        if (props.isSynonym) {
            delete props.lemma;
        }
        await getTermApiObj()
            .getTerm(props)
            .then(response => response.json())
            .then(async (res: ITerm) => {
                if (!res) return;
                if (!isSpelling) {
                    if (reqFor.reqFor === EDetailedFor.LEMMA) {
                        await getSpellingApiObj()
                            .spellingByLemmaPos({
                                lang: reqFor.lan,
                                posTag: findPosTag ? findPosTag.name : "",
                                lemmaLabel: reqFor.lemma,
                            })
                            .then(response => response.json())
                            .then((resSpellings: ITermFromPos[]) => {
                                if (!resSpellings) return;
                                reqDate = resSpellings
                            })
                            .catch((err) => {
                                dispatch(popupPush({
                                    id: new Date().getTime().toString(),
                                    type: PopupType.POPUP_MESSAGE,
                                    data: [err.message],
                                    actionTitle: "Close",
                                    actionVisible: true,
                                    cancelVisible: false,
                                }));
                            })
                            .finally();
                    } else {
                        await getLemmaApiObj()
                            .lemmaBySynonymPos({
                                lang: reqFor.lan,
                                posTag: findPosTag ? findPosTag.name : "",
                                synonym: reqFor.synonym,
                            })
                            .then(response => response.json())
                            .then((resSynonym: ITermFromPos[]) => {
                                if (!resSynonym) return;
                                reqDate = resSynonym
                            })
                            .catch((err) => {
                                dispatch(popupPush({
                                    id: new Date().getTime().toString(),
                                    type: PopupType.POPUP_MESSAGE,
                                    data: [err.message],
                                    actionTitle: "Close",
                                    actionVisible: true,
                                    cancelVisible: false,
                                }));
                            })
                            .finally();
                    }
                }
                const allLemmas = newSearch[reqFor.rowIndex].lemmas.map(elem => elem.label);
                if (reqFor.reqFor === EDetailedFor.SYNONYM && newSearch[reqFor.rowIndex].expanded) {
                    reqDate.forEach(el => {
                        if (!allLemmas.includes(el.label)) {
                            //@ts-ignore
                            newSearch[reqFor.rowIndex].lemmas.push({
                                label: el.label,
                                updated_at: reqFor.updated_by,
                                updated_by: reqFor.updated_by,
                                inserted_by: reqFor.inserted_by,
                                inserted_at: reqFor.inserted_at,
                                posTag: el.posTag,
                                fromSyn: true,
                            });
                        }
                    });
                } else {
                    try {
                        const currentLemmaIndex = newSearch[reqFor.rowIndex].lemmas[reqFor.lemmaIndex].index;
                        if (currentLemmaIndex === reqFor.lemmaIndex && !newSearch[reqFor.rowIndex].lemmas[reqFor.lemmaIndex].expanded) {
                            newSearch[reqFor.rowIndex].lemmas[currentLemmaIndex].requestData = [];
                            // @ts-ignore
                            newSearch[reqFor.rowIndex].lemmas[reqFor!.lemmaIndex].index = undefined;
                        } else {
                            newSearch[reqFor.rowIndex].lemmas[reqFor!.lemmaIndex].requestData = [];
                            newSearch[reqFor.rowIndex].lemmas[reqFor!.lemmaIndex].index = reqFor.lemmaIndex;
                            reqDate.forEach(requestData => {
                                if (!allLemmas.includes(requestData.label)) {
                                    newSearch[reqFor.rowIndex].lemmas[reqFor!.lemmaIndex].requestData.push(requestData);
                                }
                            });
                        }
                    } catch {

                    } finally {

                    }
                }
                newSearch[reqFor.rowIndex].reqFor = reqFor.reqFor;
                dispatch({
                    type: UPDATE_LEMMA_SEARCH_LIST,
                    payload: newSearch,
                });
                dispatch({
                    type: SET_DETAILED_TERM,
                    payload: {
                        ...res,
                        reqFor: reqFor,
                        nets: res.nets.map((elem: string[][]) => Object.keys(elem)).flat(),
                        lang: props.lang,
                        lemma: props.lemma,
                        synonym: props.synonym,
                        isSynonym: props.isSynonym,
                    },
                });
                if (additionalInfoStage) {
                    dispatch({
                        type: SET_EDITABLE_TERM,
                        payload: {
                            ...res,
                            reqFor,
                            synonym: props.synonym,
                            isSynonym: props.isSynonym,
                            nets: res.nets.map((elem: string[][]) => Object.keys(elem)).flat(),
                            lang: props.lang,
                        },
                    });
                }
            })
            .catch((err) => {
                try {
                    const errorText = JSON.parse(err.message);
                    const subError = JSON.parse(errorText.message);
                    dispatch(popupPush({
                        id: new Date().getTime().toString(),
                        type: PopupType.POPUP_MESSAGE,
                        data: [subError.generalMessage],
                        actionVisible: false,
                        cancelVisible: true,
                        cancelTitle: "Close",
                    }));
                } catch (e) {
                    try {
                        console.log("catch", e);
                        const errorText = JSON.parse(err.message);
                        dispatch(popupPush({
                            id: new Date().getTime().toString(),
                            type: PopupType.POPUP_MESSAGE,
                            data: [errorText.message],
                            actionVisible: false,
                            cancelVisible: true,
                            cancelTitle: "Close",
                        }));
                    } catch {

                    }

                }
            })
            .finally(() => {
                dispatch(spinnerDown());
            });
    };
}

export function setDetailedTerm(term: ITerm | undefined): Thunk {
    return (dispatch, getState) => {
        dispatch({
            type: SET_DETAILED_TERM,
            payload: term,
        });
    };
}

export function setLocationPopupOpen(flag: boolean): Thunk {
    return (dispatch, getState) => {
        dispatch({
            type: SET_IS_LOCATION_POPUP_OPEN,
            payload: flag,
        });
    };
}

export function setDetailedRow(row: ILemmaSearchField | undefined): Thunk {
    return (dispatch, getState) => {
        if (!row) {
            dispatch({
                type: SET_DETAILED_TERM,
                payload: undefined,
            });
        }
        dispatch({
            type: SET_DETAILED_ROW,
            payload: row,
        });
        dispatch({
            type: SET_EDITABLE_TERM,
            payload: undefined,
        });
        dispatch({
            type: SET_ADDITIONAL_INFO_STAGE,
            payload: AdditionalInformationStage.read,
        });
    };
}

export function entriesReducer(state: EntriesState = initialState, action: EntriesActions): EntriesState {
    switch (action.type) {
        case ENTRIES_SET:
            return {
                ...state,
                entries: [...action.payload].map(elem => ({...elem})),
            };
        case ENTRIES_NOTIFY_SET:
            return {
                ...state,
                notify: action.payload,
            };
        case UPDATE_LANGUAGES:
            return {
                ...state,
                languages: action.payload,
            };
        case UPDATE_ENTRY_LANGUAGES:
            return {
                ...state,
                entryLanguages: action.payload,
            };
        case SET_BACKLOG_COUNT:
            return {
                ...state,
                backlogCount: action.payload,
            };
        case SET_EXTERNAL_COUNT:
            return {
                ...state,
                externalCount: action.payload,
            };
        case SET_EXTERNAL_PAGE:
            return {
                ...state,
                externalPage: action.payload,
            };
        case SET_BACKLOG_PAGE:
            return {
                ...state,
                backlogPage: action.payload,
            };
        case UPDATE_POS_TAGS:
            return {
                ...state,
                posTags: action.payload,
            };
        case UPDATE_NETS:
            return {
                ...state,
                nets: action.payload,
            };
        case UPDATE_FILTERED_NETS:
            return {
                ...state,
                filteredNets: action.payload,
            };
        case UPDATE_SOURCES:
            return {
                ...state,
                sources: action.payload,
            };
        case UPDATE_OBJECT_IDS:
            return {
                ...state,
                objectIds: action.payload,
            };
        case SET_ENTRY_FILTER:
            return {
                ...state,
                entryFilter: action.payload,
            };
        case SET_ENTRY_SORTING:
            return {
                ...state,
                sorting: action.payload,
            };
        case ENTRIES_GET_SOURCES:
            return {
                ...state,
                entriesForCheck: action.payload,
            };
        case SET_SPELLING_LEMMA_SPINNING:
            return {
                ...state,
                spinners: action.payload,
            };
        case UPDATE_LEMMA_SEARCH_LIST:
            return {
                ...state,
                entrySearch: action.payload,
            };
        case SET_DETAILED_TERM:
            return {
                ...state,
                detailedTerm: action.payload,
            };
        case SET_LEMMA_SEARCH_COUNT:
            return {
                ...state,
                entrySearchCount: action.payload,
            };
        case SET_LEMMA_SEARCH_PAGE:
            return {
                ...state,
                searchPage: action.payload,
            };
        case SET_DETAILED_ROW:
            return {
                ...state,
                detailedRow: action.payload,
            };
        case SET_IS_LOCATION_POPUP_OPEN:
            return {
                ...state,
                isLocationPopupOpen: action.payload,
            };
        case SET_IS_LEMMA_SEARCH:
            return {
                ...state,
                isInLemmaSearch: action.payload,
            };
        case SET_EDIT_TERM_ERROR:
            return {
                ...state,
                editTermErrorStatus: action.payload,
            };
        case SET_PREVIOUS_ENTRIES:
            return {
                ...state,
                previousEntries: [...action.payload].map(elem => ({...elem})),
            };
        case SET_ADDITIONAL_INFO_STAGE:
            return {
                ...state,
                additionalInfoStage: action.payload,
            };
        case SET_EDITABLE_TERM:
            return {
                ...state,
                editableTerm: action.payload,
            };
        case UPDATE_POS_TAGS_SLA:
            return {
                ...state,
                posTagSla: action.payload,
            };
        case UPDATE_NETS_SLA:
            return {
                ...state,
                netsSla: action.payload,
            };
        case UPDATE_LEVELS:
            return {
                ...state,
                levels: action.payload,
            };
        case SET_IS_LOCATION_POPUP_HANDLER:
            return {
                ...state,
                locationPopupHandler: action.payload,
            };
        case SET_PUSH_RESULT:
            state.pushResult.push(action.payload);
            return {
                ...state,
                pushResult: [...state.pushResult],
            };
        default:
            return {
                ...state,
            };
    }
}
