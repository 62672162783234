import React from "react";
import {LogoutConnected} from "../Logout/LogoutConnected";
import styles from "./TopNavBar.module.scss";
import {Link} from "react-router-dom";
import {UserRole} from "../../api/userApi";

export interface TopNavBarStateProps {
    isAuthenticated: boolean;
    role: UserRole | undefined;
}

export interface TopNavBarDispatchProps {}

export type TopNavBarProps = TopNavBarStateProps & TopNavBarDispatchProps;

export class TopNavBar extends React.PureComponent<TopNavBarProps> {
    public render() {
        const { isAuthenticated, role } = this.props;

        return (
            <div className={styles.topNavBar}>
                <Link to={"/"} className={`${styles.elem} ${styles.appName}`}>
                    SLE
                </Link>
                <div className={styles.elem}>
                    {isAuthenticated && <LogoutConnected />}
                </div>
            </div>
        );
    }
}
