import * as React from "react";
import {Button, MuiThemeProvider, TextField} from "@material-ui/core";

import styles from "./LoginForm.module.scss";
import Loader from "react-loader-spinner";
import {theme} from "../../helper";
import ThemeProvider from "@material-ui/styles/ThemeProvider";

export interface LoginFormStateProps {
    authLoader: boolean;
    loginError: string | undefined;
}

export interface LoginFormDispatchProps {
    authentication: (login: string, password: string) => void;
}

export type LoginFormProps = LoginFormStateProps & LoginFormDispatchProps;

export interface LoginFormState {
    login: string;
    password: string;
}

export class LoginForm extends React.PureComponent<LoginFormProps, LoginFormState> {
    state = {
        login: "",
        password: "",
    };

    private handleChange = (name: keyof LoginFormState) => (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({...this.state, [name]: event.target.value || event.target.name});
    };

    private authentication = () => {
        const {authentication} = this.props;
        const {login, password} = this.state;

        if (!login || !password) {
            return;
        }

        authentication(login, password);
    };

    public render() {
        const {loginError, authLoader} = this.props;

        return (
            <div className={styles.loginContainer}>
                <div style={{display: authLoader ? "flex" : "none"}} className={styles.loaderContainer}>
                    <Loader type="Oval" color="#ff4646" height={100} width={100} visible={authLoader}/>
                </div>
                <MuiThemeProvider theme={theme}>
                    <ThemeProvider theme={theme}>
                        <TextField
                            className={styles.input}
                            label="Login"
                            variant="outlined"
                            fullWidth
                            onChange={this.handleChange("login")}
                            onKeyDown={event => {
                                if (event.keyCode === 13) this.authentication();
                            }}
                        />
                        <TextField
                            className={styles.input}
                            label="Password"
                            type="password"
                            variant="outlined"
                            onChange={this.handleChange("password")}
                            fullWidth
                            onKeyDown={event => {
                                if (event.keyCode === 13) this.authentication();
                            }}
                        />
                    </ThemeProvider>
                </MuiThemeProvider>
                <Button
                    className={`${styles.loginContainer} ${styles.saveButtonColor}`}
                    variant="contained"
                    color="primary"
                    fullWidth={false}
                    size="large"
                    onClick={this.authentication}
                >
                    Sign In
                </Button>
                <div className={styles.errorMessage}>{loginError}</div>
            </div>
        );
    }
}
