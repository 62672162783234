import * as React from "react";

import styles from "./ActionPage.module.scss";
import {ActionConnected} from "../../components/Action/ActionConnected";
import {SupportConnected} from "../../components/Support/SupportConnected";
import {refresh} from "../../ducks/auth/auth";
import {getApiUtils} from "../../api/utils";

export interface IActionPageStateProps {
    isTokenOutOfTime: boolean;
}

export class ActionPage extends React.PureComponent<IActionPageStateProps> {

    componentDidMount() {
        if(this.props.isTokenOutOfTime){
            getApiUtils().dispatch(refresh());
        }
    }

    componentDidUpdate(prevProps: Readonly<IActionPageStateProps>, prevState: Readonly<{}>, snapshot?: any) {
        if(!prevProps.isTokenOutOfTime && this.props.isTokenOutOfTime){
            getApiUtils().dispatch(refresh());
        }
    }

    public render() {
        return (
            <div className={styles.container}>
                <SupportConnected />
                <ActionConnected />
            </div>
        );
    }
}
