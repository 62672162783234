import React from "react";
import styles from "./../EntryFilter.module.scss";
import {Button, Icon, Select} from "@material-ui/core";
import {Language, LANGUAGE_UNDEFINED} from "../../../../api/languageApi";
import {User} from "../../../../api/userApi";
import DateFnsUtils from "@date-io/date-fns";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {customStyles, datePickerTheme, formatDate, theme} from "../../../../helper";
import {BacklogProps, Entry, OrderBy} from "../../../../api/entryApi";
import {BacklogStatuses} from "../../../../ducks/entries/entries";
import MenuItem from "@material-ui/core/MenuItem";
import {UserApp} from "../../../../api/userAppApi";
import {tableCustomAlphabetSort, truncate} from "../../../../helper/others";
import {IEntrySorting} from "../../EntryListBacklog/EntryListBacklog";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import {Net, NET_UNDEFINED} from "../../../../api/netApi";
import {POS_TAG_UNDEFINED, PosTag} from "../../../../api/posTagApi";

export interface EntryFilterStateProps {
    entries: Entry[];
    users: User[] | UserApp[];
    languages: Language[];
    sources: { name: string }[];
    objectIds: { name: string }[];
    entryFilter: BacklogProps;
    entryLanguages: Language[];
    nets: Net[];
    posTags: PosTag[];
    userId: number;
}

export interface EntryFilterDispatchProps {
    onQueryUsers: (unique?: boolean) => void;
    onQueryUsersApp: () => void;
    onQuerySourcesApp: () => void;
    onQueryObjectIdsApp: () => void;
    onBacklogGetQuery: (props: BacklogProps) => void;
    setEntryFilter: (props: BacklogProps) => void;
    onLanguagesQuery: (unique?: boolean) => void;
    setBacklogListPage: (page: number) => void;
    setEntrySorting: (sorting: IEntrySorting) => void;
    onSetEntries: (entries: Entry[]) => void;
}

type EntryFilterProps = EntryFilterStateProps & EntryFilterDispatchProps;

export interface EntryFilterState {
    userSelected: string;
    languageCode: string;
    from: string;
    to: string;
    source: string;
    objectId: string;
}

export class EntryFilterBacklog extends React.PureComponent<EntryFilterProps, EntryFilterState> {
    constructor(props: EntryFilterProps) {
        super(props);

        this.state = {
            userSelected: undefined,
            languageCode: "",
            from: "1970-01-01",
            to: formatDate(Date.now()),
            source: "",
            objectId: "",
        };
    }

    componentDidMount(): void {
        this.loadBacklog(20);
    }

    render() {
        const {users, languages, entryFilter, entries, entryLanguages, nets, posTags, userId} = this.props;
        const {from, to, languageCode, userSelected} = this.state;
        let usersJSX = !(entryFilter.user && entries.length === 0)
            ? (users as User[])
                .filter(elem => {
                    if (elem.username) {
                        return elem
                    }
                })
                .sort((a, b) => {
                    return tableCustomAlphabetSort(a.username, b.username)
                })
                .map((user: User) => ({
                    value: `${user.id}`,
                    label: <div title={(user.username && user.username.length >= 10) ? user.username : ""}>{truncate(user.username, 10)}</div>
                }))
            : [];

        const filterLanguages = !(entryFilter.lang && entries.length === 0)
            ? (languages as Language[])
                .filter(elem => {
                    if (elem.name) {
                        return elem
                    }
                })
                .sort((a, b) => {
                    return tableCustomAlphabetSort(a.name, b.name)
                })
                .map((language: Language) => ({
                    value: `${language.code}`,
                    label: language.name,
                }))
            : []
        ;
        const visibleAddButton: boolean = entryLanguages.length > 0 && nets.length > 0 && posTags.length > 0;

        return (
            <div className={styles.filterContainer}>
                <div className={styles.containerStart}>
                    <div className={styles.subContainerOther}>
                        <Button
                            disabled={!visibleAddButton}
                            variant="outlined"
                            className={styles.addButton}
                            color="primary"
                            onClick={() => this.entryAdd()}
                        >
                            ADD ENTRY <Icon className={styles.plusIcon}/>
                        </Button>
                    </div>
                </div>
                <div className={styles.container}>
                    <div className={styles.subContainerOther}>
                        <ThemeProvider theme={theme}>
                            <Select
                                displayEmpty
                                className={styles.filterSelect}
                                value={userSelected}
                                onChange={this.handleSelectChangeData("userSelected")}
                                renderValue={!userSelected
                                    ? () => {
                                        return <span>
                                        User
                                    </span>
                                    }
                                    : undefined}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null,
                                    marginThreshold: 110
                                }}
                            >
                                <MenuItem key={"undefined"} value={""}/>
                                {usersJSX.map(user => (
                                    <MenuItem key={user.value} value={user.value}>
                                        {user.label}
                                    </MenuItem>))}
                            </Select>
                        </ThemeProvider>
                    </div>
                    <div className={styles.subContainerOther}>
                        <ThemeProvider theme={theme}>
                            <Select
                                renderValue={!languageCode
                                    ? () => {
                                        return <span>
                                        Language
                                    </span>
                                    }
                                    : undefined}
                                displayEmpty
                                className={styles.filterSelect}
                                value={languageCode}
                                onChange={this.handleSelectChangeData("languageCode")}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null,
                                    marginThreshold: 110
                                }}
                            >
                                <MenuItem key={"undefined"} value={""}/>
                                {filterLanguages.map(language => (
                                    <MenuItem key={language.value} value={language.value}>
                                        {language.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </ThemeProvider>
                    </div>
                    <div className={styles.subContainerOther}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <ThemeProvider theme={datePickerTheme}>
                                <KeyboardDatePicker
                                    onFocus={() => {}}
                                    onBlur={() => {}}
                                    className={styles.dateInput}
                                    disableToolbar
                                    variant="inline"
                                    format="yyyy-MM-dd"
                                    margin="normal"
                                    id="date-picker-inline-1"
                                    value={from}
                                    style={{
                                        color: "#FFFFFF",
                                        outline: "none",
                                        borderRadius: "5px",
                                    }}
                                    inputProps={{
                                        style: {
                                            color: "#FFFFFF",
                                            borderRadius: "5px",
                                        },
                                    }}
                                    onChange={(date: any) => {
                                        this.handleChangeData(date, "from");
                                    }}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                        style: {
                                            color: "#FFFFFF",
                                        },
                                    }}
                                    keyboardIcon={<Icon className={styles.dateIcon}/>}
                                />

                            </ThemeProvider>
                        </MuiPickersUtilsProvider>
                    </div>
                    <div className={styles.subContainerOther}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <ThemeProvider theme={datePickerTheme}>
                                <KeyboardDatePicker
                                    onFocus={() => {}}
                                    onBlur={() => {}}
                                    className={styles.dateInput}
                                    disableToolbar
                                    variant="inline"
                                    format="yyyy-MM-dd"
                                    margin="normal"
                                    id="date-picker-inline-2"
                                    value={to}
                                    style={{
                                        color: "#FFFFFF",
                                        outline: "none",
                                        borderRadius: "5px",
                                    }}
                                    inputProps={{
                                        style: {
                                            color: "#FFFFFF",
                                            borderRadius: "5px",
                                        },
                                    }}
                                    onChange={(date: any) => {
                                        this.handleChangeData(date, "to");
                                    }}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                        style: {
                                            color: "#FFFFFF",
                                        },
                                    }}
                                    keyboardIcon={<Icon className={styles.dateIcon}/>}
                                />
                            </ThemeProvider>
                        </MuiPickersUtilsProvider>
                    </div>
                    <div className={styles.subContainerButton}>
                        <Button
                            variant="outlined"
                            className={styles.searchButton}
                            color="primary"
                            onClick={() => this.loadBacklog(20)}
                        >
                            LOAD
                        </Button>
                    </div>
                </div>

            </div>
        );
    }

    entryAdd = () => {
        const newEntries = [...this.props.entries];

        let newEntry: Entry = {
            id: 0,
            check: false,
            created: true,
            language: LANGUAGE_UNDEFINED,
            spelling: [""],
            lemma: "",
            posTag: POS_TAG_UNDEFINED,
            synonym: "",
            itSelf: true,
            nets: NET_UNDEFINED,
            notifications: [],
            date: formatDate(Date.now()),
            userId: this.props.userId,
            source_app: undefined,
            date_app: undefined,
            user_app: undefined,
            account_app: undefined,
            objectid_app: undefined,
        };

        newEntry.id = Date.now();
        newEntries.unshift(newEntry);

        this.props.onSetEntries(newEntries);
    };

    private loadBacklog = (limit: number = 20) => {
        const {
            onBacklogGetQuery,
            setEntryFilter,
            onQueryUsers,
            onLanguagesQuery,
            entries,
            setBacklogListPage
        } = this.props;
        const {from, to, userSelected, languageCode, source, objectId} = this.state;
        const props: BacklogProps = {from, to, limit};

        if (userSelected) {
            props.user = userSelected;
        }
        if (languageCode !== "" && languageCode !== LANGUAGE_UNDEFINED.code) props.lang = languageCode;
        if (source !== "") props.sourceApp = source;
        if (objectId !== "") props.objectIdApp = objectId;
        props.status = BacklogStatuses.BACKLOG_DEFAULT;

        if (userSelected && entries.length === 0) {
            props.user = undefined
        }
        if (languageCode && entries.length === 0) {
            props.lang = undefined
        }

        this.props.setEntrySorting({
            language: undefined,
            lemma: undefined,
            date: undefined,
            net: undefined,
            user: undefined,
            objectId: undefined,
            partOfSpeech: undefined,
            synonym: undefined,
            source: undefined,
            orderBy: OrderBy.Undefined,
            orderByReversed: false,
        });

        setEntryFilter(props);
        onQueryUsers(true)
        onLanguagesQuery(true);
        setBacklogListPage(0);
        onBacklogGetQuery(props);
    };

    private handleSelectChangeData = (field: string) => {
        return (event: any) => {
            const value = event.target.value;
            this.handleChangeData(value, field);
        };
    };

    private handleSearchSelectChangeData = (field: string) => {
        return (event: any) => {
            const value = event;
            this.handleChangeData(value, field);
        };
    };

    private defineDataForFilter = () => {
        const {entries} = this.props;
        const {languageCode, userSelected} = this.state;
        let newData: Entry[] = [];

        if (entries.length === 0) return [];
        if (userSelected && userSelected !== "") {
            newData = entries.filter(elem => userSelected === `${elem.userId}`);
        }

        if (languageCode !== "") {
            const arrayToFilter = newData.length > 0 ? newData : entries;
            newData = arrayToFilter.filter(elem => elem.language.code === languageCode);
        }

        return newData.length === 0 ? entries : newData;
    }

    private handleChangeData = (data: any, field: string) => {
        const dataRes = field === "from" || field === "to" ? formatDate(new Date(data)) : data;

        const state = {...this.state};
        // @ts-ignore
        state[`${field}`] = dataRes;

        this.setState(state);
    };
}
