import {createTheme} from "@material-ui/core";

export function formatDate(date: any) {
    var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
}

export const COMMON_BLUE = "#0E4DB7";
export const COMMON_LIGHT_BLUE = "#03A9F4";
export const COMMON_BLACK = "#091124";
export const COMMON_DARK = "#1E293B";

export const customStyles = {
    //@ts-ignore
    menuList: provided => {
        return {
            ...provided,
            height: '100%',
            maxHeight: '100%',
        };
    },
    menu: (provided, state) => ({
        ...provided,
        backgroundColor: COMMON_BLACK,
        cursor: "pointer",
        color: "#FFFFFF",
        ":active": {
            ...provided[":active"],
            backgroundColor: COMMON_BLUE,
        },
    }),
    //@ts-ignore
    control: provided => ({
        ...provided,
        backgroundColor: COMMON_BLACK,
        color: "#FFFFFF",
        border: "none",
        outline: "none",
        cursor: "pointer",
    }),
    //@ts-ignore
    container: provided => ({
        ...provided,
        backgroundColor: COMMON_BLACK,
        color: "#FFFFFF",
        borderColor: COMMON_BLACK,
    }),
    //@ts-ignore
    indicatorsContainer: provided => ({
        ...provided,
        borderRadius: "2px",
    }),
    //@ts-ignore
    singleValue: (provided, state) => {
        return { ...provided, color: "#FFFFFF" };
    },
    //@ts-ignore
    multiValueLabel: (provided, state) => {
        return { ...provided, color: "#FFFFFF", backgroundColor: COMMON_BLACK };
    },
    //@ts-ignore
    input: (provided, state) => {
        return { ...provided, color: "#FFFFFF", backgroundColor: COMMON_BLACK };
    },
    //@ts-ignore
    placeholder: (provided, state) => {
        return { ...provided, color: "#FFFFFF", backgroundColor: COMMON_BLACK };
    },
    //@ts-ignore
    multiValue: (provided, state) => {
        return { ...provided, color: "#FFFFFF", backgroundColor: COMMON_BLACK };
    },
    //@ts-ignore
    valueContainer: (provided, state) => {
        return { ...provided, color: "#FFFFFF", backgroundColor: COMMON_BLACK };
    },
    //@ts-ignore
    option: styles => {
        return {
            ...styles,
            color: "#FFFFFF",
            backgroundColor: COMMON_BLACK,
            ":active": {
                ...styles[":active"],
                backgroundColor: COMMON_BLUE,
                color: "#FFFFFF",
            },
            ":hover": {
                ...styles[":hover"],
                backgroundColor: COMMON_BLUE,
                color: "#FFFFFF",
            },
        };
    },
};

export const customStylesForAdditionScreens = {
    //@ts-ignore
    menu: (provided, state) => ({
        ...provided,
        backgroundColor: COMMON_DARK,
        cursor: "pointer",
        color: "#FFFFFF",
        ":active": {
            ...provided[":active"],
            backgroundColor: COMMON_LIGHT_BLUE,
        },
    }),
    //@ts-ignore
    control: (provided, state) => ({
        ...provided,
        backgroundColor: COMMON_DARK,
        color: "#FFFFFF",
        border: state.isFocused ? `1px solid ${COMMON_LIGHT_BLUE}` : "none",
        outline: "none",
        cursor: "pointer",
    }),
    //@ts-ignore
    container: provided => ({
        ...provided,
        backgroundColor: COMMON_DARK,
        color: "#FFFFFF",
        border: "none",
        borderColor: 'transparent',
        borderRadius: "8px",
    }),
    //@ts-ignore
    singleValue: (provided, state) => {
        return { ...provided, color: "#FFFFFF" };
    },
    //@ts-ignore
    multiValueLabel: (provided, state) => {
        return { ...provided, color: "#FFFFFF", backgroundColor: COMMON_DARK };
    },
    //@ts-ignore
    input: (provided, state) => {
        return { ...provided, color: "#FFFFFF", backgroundColor: COMMON_DARK };
    },
    //@ts-ignore
    placeholder: (provided, state) => {
        return { ...provided, color: "#FFFFFF", backgroundColor: COMMON_DARK };
    },
    //@ts-ignore
    multiValue: (provided, state) => {
        return { ...provided, color: "#FFFFFF", backgroundColor: COMMON_DARK };
    },
    //@ts-ignore
    valueContainer: (provided, state) => {
        return { ...provided, color: "#FFFFFF", backgroundColor: COMMON_DARK };
    },
    //@ts-ignore
    option: styles => {
        return {
            ...styles,
            color: "#FFFFFF",
            backgroundColor: COMMON_DARK,
            ":active": {
                ...styles[":active"],
                backgroundColor: COMMON_LIGHT_BLUE,
                color: "#FFFFFF",
            },
            ":hover": {
                ...styles[":hover"],
                backgroundColor: COMMON_LIGHT_BLUE,
                color: "#FFFFFF",
            },
        };
    },
};

export const datePickerTheme = createTheme({
    palette: {
        primary: {
            main: COMMON_BLUE,
        },
        secondary: {
            main: COMMON_BLUE,
        },
    },
    overrides: {
        MuiInputBase: {
            root: {
                height: "41px",
            }
        },
        MuiInput: {
          root: {
              borderRadius: "5px",
          },
        },
    }
});

export const theme = createTheme({
    palette: {
        primary: {
            main: COMMON_BLUE,
        },
        secondary: {
            main: COMMON_BLUE,
        },
    },
    overrides: {
        MuiTableSortLabel: {
          icon: {
              fill: '#FFFFFF'
          }
        },
        MuiTablePagination: {
            root: {
                color: "#FFFFFF",
                backgroundColor: COMMON_BLACK,
                borderColor: COMMON_BLACK,
            },
        },
        MuiTable: {
            root: {
                color: "#FFFFFF",
                backgroundColor: COMMON_BLACK,
                borderColor: COMMON_BLACK,
            },
        },
        MuiTableRow: {
            root: {
                color: "#FFFFFF",
                backgroundColor: COMMON_BLACK,
                borderColor: COMMON_BLACK,
            },
        },
        MuiFormControlLabel: {
            root: {
                color: "#FFFFFF",
                backgroundColor: "transparent",
            },
        },
        MuiMenuItem: {
            root: {
                '&:hover': {
                    background: COMMON_BLUE,
                },
                "&$selected, &$selected:hover": {
                    backgroundColor: `${COMMON_BLUE} `,
                },
            }
        },
        MuiInputLabel: {
            root: {
                color: "#FFFFFF",
                backgroundColor: "transparent",
            },
        },
        MuiInput: {
            root: {
                color: "#FFFFFF",
            },
        },
        MuiToolbar: {

            root: {
                color: "#FFFFFF",
                backgroundColor: COMMON_BLACK,
                borderColor: COMMON_BLACK,
            },
        },
        MuiTableCell: {
            body: {
                color: "#FFFFFF",
            },
            root: {
                color: "#FFFFFF",
                backgroundColor: COMMON_BLACK,
                borderColor: COMMON_BLACK,
            },

        },
        MuiSelect: {
            root: {
                color: "#FFFFFF",
                paddingLeft: "6px",
            },
            icon: {
                fill: "#FFFFFF",
            },
        },
        MuiListItem: {
            root: {
                "&$selected, &$selected:hover": {
                    backgroundColor: COMMON_BLUE,
                },
            },
        },
        MuiInputBase: {
            root: {
                color: "#FFFFFF",
                backgroundColor: COMMON_BLACK,
                borderColor: COMMON_BLACK,
            },
        },
        MuiMenu: {
            // @ts-ignore
            list: {
                color: "#FFFFFF",
                backgroundColor: COMMON_BLACK,
            },
        },
    },
});

export const netSelector = createTheme({
    palette: {
        primary: {
            main: COMMON_BLUE,
        },
        secondary: {
            main: COMMON_BLUE,
        },
    },
    overrides: {
        MuiInputBase: {
            root: {
                width: "1px",
                height: "17px",
            }
        },
        MuiSelect: {
            root: {
                width: "1px",
                color: COMMON_LIGHT_BLUE,
                paddingLeft: "0",
                paddingTop: "0",
                paddingBottom: "0",
                marginTop: 0,
                marginBottom: 0,
            },
            icon: {
                fill: "transparent",
            },
        },
        MuiMenuItem: {
            root: {
                color: COMMON_LIGHT_BLUE,
                '&:hover': {
                    color: COMMON_LIGHT_BLUE,
                    background: COMMON_BLUE,
                },
                "&$selected, &$selected:hover": {
                    color: COMMON_LIGHT_BLUE,
                    backgroundColor: `${COMMON_BLUE} `,
                },
            }
        },
    },
});
export const locationPopupStyles = createTheme({
    palette: {
        primary: {
            main: "#0E4DB7",
        },
        secondary: {
            main: "#0E4DB7",
        },
    },
    overrides: {
        MuiInputBase: {
            root: {
                width: "220px",
                height: "43px",
                backgroundColor: `#FFFFFF`,
                color: '#707070 !important',
                paddingLeft: "8px !important",
                paddingRight: "8px !important",
                paddingTop: "8px !important",
                paddingBottom: "8px !important",

            }
        },
        MuiSelect: {
            root: {
                width: "220px",
                height: "17px",
                backgroundColor: `#FFF !important`,
                color: '#707070 !important',
                paddingLeft: "4px !important",
                paddingRight: "4px !important",
                paddingTop: "4px !important",
                paddingBottom: "4px !important",
            },
            icon: {
                fill: "#707070",
            },
        },
        MuiInputLabel: {
            root: {
                color: "#707070",
                backgroundColor: "transparent",
            },
        },
        MuiInput: {
            root: {
                color: "#707070",
            },
        },
        MuiList: {
            root: {
                color: "#0E4DB7 !important",
                background: '#FFF !important',
            }
        },
        MuiMenuItem: {
            root: {
                color: "#707070 !important",
                background: '#FFF !important',
                '&:hover': {
                    color: "#0E4DB7 !important",
                    background: '#FFF !important',
                },
                "&$selected, &$selected:hover": {
                    color: "#0E4DB7 !important",
                    background: '#FFF !important',
                },
            }
        },
    },
});

export const themeForAdditionalScreen = createTheme({
    palette: {
        primary: {
            main: COMMON_LIGHT_BLUE,
        },
        secondary: {
            main: COMMON_LIGHT_BLUE,
        },
    },
    overrides: {
        MuiTablePagination: {
            root: {
                color: "#FFFFFF",
                backgroundColor: COMMON_DARK,
                borderColor: COMMON_DARK,
            },
        },
        MuiTable: {
            root: {
                color: "#FFFFFF",
                backgroundColor: COMMON_DARK,
                borderColor: COMMON_DARK,
            },
        },
        MuiFormControlLabel: {
            root: {
                color: "#FFFFFF",
                backgroundColor: "transparent",
            },
        },
        MuiMenuItem: {
            root: {
                '&:hover': {
                    background: COMMON_LIGHT_BLUE,
                },
                "&$selected, &$selected:hover": {
                    backgroundColor: `${COMMON_LIGHT_BLUE} `,
                },
            }
        },
        MuiInputLabel: {
            root: {
                color: "#FFFFFF",
                backgroundColor: "transparent",
            },
        },
        MuiInput: {
            root: {
                color: "#FFFFFF",
            },
        },
        MuiToolbar: {

            root: {
                color: "#FFFFFF",
                backgroundColor: COMMON_DARK,
                borderColor: COMMON_DARK,
            },
        },
        MuiTableCell: {
            root: {
                color: "#FFFFFF",
                backgroundColor: COMMON_DARK,
                borderColor: COMMON_DARK,
            },

        },
        MuiSelect: {
            root: {
                color: "#FFFFFF",
                paddingLeft: "6px",
            },
            icon: {
                fill: "#FFFFFF",
            },
        },
        MuiListItem: {
            root: {
                "&$selected, &$selected:hover": {
                    backgroundColor: COMMON_LIGHT_BLUE,
                },
            },
        },
        MuiInputBase: {
            root: {
                color: "#FFFFFF",
                backgroundColor: COMMON_DARK,
                borderColor: COMMON_DARK,
            },
        },
        MuiMenu: {
            // @ts-ignore
            list: {
                color: "#FFFFFF",
                backgroundColor: COMMON_DARK,
            },
        },
    },
});
