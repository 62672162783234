import React from "react";
import styles from "./InputFile.module.scss";

export interface InputFileStateProps {
    file: File | null;
}

export interface InputFileDispatchProps {
    onFileSet: (file: File | null) => void;
}

type InputFileProps = InputFileStateProps & InputFileDispatchProps;

export class InputFile extends React.Component<InputFileProps> {
    private buttonClick() {
        const inputFileElem = document.getElementById("id_input-file");
        if (inputFileElem) inputFileElem.click();
    }

    private handleChange(files: FileList | null) {
        const file = files ? files[0] : null;
        this.props.onFileSet(file);
        const elem = document.getElementById("id_input-file");
        if (elem) (elem as HTMLInputElement).value = "";
    }

    render() {
        const { file } = this.props;

        return (
            <div>
                <input value={file ? file.name : ""} readOnly={true} className={styles.inputText} type={"text"} />
                <button onClick={this.buttonClick} className={styles.inputButton}>
                    Browse...
                </button>
                <input
                    type={"file"}
                    id={"id_input-file"}
                    accept={".csv"}
                    multiple={false}
                    onChange={e => this.handleChange(e.target.files)}
                    style={{ display: "none" }}
                />
            </div>
        );
    }
}
