import {Endpoints, getEndpoint} from "./endpoints";
import {BaseApi} from "./baseApi";
import {PropsArrSynonymLang} from "./lemmaApi";
import {IEditLabel} from "./spellingApi";

export interface Net {
    code: string;
    name: string;
}

export interface IAddLocation {
    source: string,
    lang: string,
    latitude: number | string,
    longitude: number | string,
    level: string,
    user: string
}

export interface IAddNet extends IEditLabel{

}
export interface IDeleteNet extends IAddNet{
    relationshipType: string;
}

export const NET_UNDEFINED: Net = { code: "UNDEFINED", name: "" };
export const NET_BRAND: Net = { code: "BRAND", name: "brand" };

class NetApi extends BaseApi {
    Endpoint = Endpoints.Nets;

    fromSyn(props: PropsArrSynonymLang) {
        const propsProcessed = `${props.synonym
            .map(synonym => `synonym[]=${encodeURIComponent(synonym)}`)
            .join("&")}&lang=${encodeURIComponent(props.lang)}`;

        const res = getEndpoint(this.Endpoint, "fromSyn")
            .query(propsProcessed)
            .get()
            .res();

        return res;
    }

    async getNetsSla() {
        return getEndpoint(this.Endpoint,'getNetsSla')
            .get()
    }

    async addNet(props: IAddNet) {
        return await getEndpoint(this.Endpoint,'addNET')
            .post(props)
            .res()
    }
    async addLocation(props: IAddLocation) {
        return await getEndpoint(this.Endpoint,'addLocationDetails')
            .post(props)
            .res()
    }
    async deleteLocation(props: IAddLocation) {
        return await getEndpoint(this.Endpoint,'deleteLocation')
            .post(props)
            .res()
    }
    async getLevels() {
        return await getEndpoint(this.Endpoint,'getLevels')
            .get()
            .res()
    }
}

export function getNetApiObj() {
    return new NetApi();
}
