import React, {useState} from "react";
import moment from "moment";
import Select from "@material-ui/core/Select";
import {ClickAwayListener, Grow, IconButton, MenuItem, MenuList, Paper, Popper} from "@material-ui/core";
import {ThemeProvider} from "@material-ui/styles";

import {UserRole} from "../../../api/userApi";
import {ILocation, ITerm} from "../../../api/termApi";
import {AdditionalInformationStage, IEditTermProps, ILemmaSearchField,} from "../../../ducks/entries/entries";
import {IDeleteCheckQuery} from "../../../api/spellingApi";
import {theme} from "../../../helper";

import styles from "./AdditionalInformationEditor.module.scss";
import {POS_TAG_PROPER_NOUN} from "../../../api/posTagApi";
import {PopupElement, PopupType} from "../../../ducks/popup/popup";

export const DEFAULT_EDITABLE_TERM = {
    label: "",
    location: [],
    inserted_at: "",
    inserted_by: "",
    nets: [],
    posTag: '',
    updated_at: '',
    updated_by: '',
    lang: '',
    lemma: "",
    synonym: "",
};

export interface IAdditionalInformationEditorStateProps {
    editableTerm: ITerm;
    detailedTerm: ITerm;
    detailedRow: ILemmaSearchField | undefined;
    role: UserRole | undefined;
    stage: AdditionalInformationStage;
    posTags: string[];
    nets: string[];
}

export interface IAdditionalInformationEditorDispatchProps {
    checkDelete: (params: IDeleteCheckQuery) => void;
    setAdditionalInformationStage: (stage: AdditionalInformationStage) => void;
    setEditableTerm: (item: ITerm | undefined) => void;
    editTerm: (props: IEditTermProps) => void;
    setLocationPopupOpen: (flag: boolean) => void;
    setLocationPopupHandler: (handler: () => void) => void;
    fetchCluster: (value: string) => void;
    onPopupPush: (popup: PopupElement) => void;
}

export interface IAdditionalInformationEditorState {
    nets: string[];
    deleteNets: string[];
    locations: ILocation[];
    isSelectOpen: boolean;
}

type AdditionalInformationEditorProps =
    IAdditionalInformationEditorStateProps
    & IAdditionalInformationEditorDispatchProps;

const defaultState: IAdditionalInformationEditorState = {
    nets: [],
    deleteNets: [],
    locations: [],
    isSelectOpen: false,
}

export function AdditionalInformationEditor(props: AdditionalInformationEditorProps) {
    const {editableTerm, detailedRow, role} = props;
    const [state, setState] = useState({...defaultState});

    const onChangeTerm = (name: keyof ITerm) => (event: any) => {
        const value = event.target.value;
        const detailedTerm: ITerm = {...props.editableTerm};
        //@ts-ignore
        detailedTerm[name] = value;
        props.setEditableTerm(detailedTerm);
    }
    return <div className={styles.bottomPart}>
        <div className={styles.topBottomPart}>
            {!editableTerm && !detailedRow && (
                <div className={styles.detailedNetHeaderEmpty}>{"No term selected"}</div>
            )}
            {editableTerm && (
                <div className={styles.detailedNet}>
                    <div className={styles.detailedNetHeader}>
                        <input
                            onChange={onChangeTerm("label")}
                            className={styles.detailedNetHeaderItem}
                            type={'text'}
                            value={props.editableTerm!.label}
                        />
                        <ThemeProvider theme={theme}>
                            <Select
                                onChange={(event, child) => {
                                    const value = event.target.value;

                                    props.fetchCluster(value as string);
                                }}
                                renderValue={() => <span
                                    className={styles.subElemSpanGrey}
                                >
                                    {editableTerm.posTag}
                                </span>}
                                placeholder={"Language"}
                                value={editableTerm.posTag}
                                className={styles.subElemSpanGrey}
                            >
                                {props.posTags.map((elem, index) => (
                                    <MenuItem className={styles.subElemSpanGrey} key={`${index}${new Date().getTime()}`}
                                              value={elem}>{elem}</MenuItem>
                                ))}
                            </Select>
                        </ThemeProvider>
                    </div>
                    <div className={styles.info}>
                        {editableTerm.inserted_at && (
                            <div className={styles.infoRow}>
                                <div className={styles.infoRowLeftItem}>Created by</div>
                                <div className={styles.infoRowRightItem}>{editableTerm.inserted_by}</div>
                            </div>
                        )}
                        {editableTerm.updated_by && (
                            <div className={styles.infoRow}>
                                <div className={styles.infoRowLeftItem}>Last modified by</div>
                                <div className={styles.infoRowRightItem}>{editableTerm.updated_by}</div>
                            </div>
                        )}
                        {editableTerm.inserted_at && (
                            <div className={styles.infoRow}>
                                <div className={styles.infoRowLeftItem}>Created</div>
                                <div className={styles.infoRowRightItem}>
                                    {moment(editableTerm.inserted_at).format("YYYY-MM-DD")}
                                </div>
                            </div>
                        )}
                        {editableTerm.updated_at && (
                            <div className={styles.infoRow}>
                                <div className={styles.infoRowLeftItem}>Last modified</div>
                                <div className={styles.infoRowRightItem}>
                                    {moment(editableTerm.updated_at).format("YYYY-MM-DD")}
                                </div>
                            </div>
                        )}
                    </div>
                    {props.detailedTerm.posTag === POS_TAG_PROPER_NOUN.name && <div className={styles.headerRow}>
                        <div className={styles.headerTitle}>
                            NETs
                        </div>
                        <div className={styles.iconContainerPlus}>
                            <IconButton
                                size={"small"}
                                onClick={() => {
                                    setState((prevState) => ({...prevState, isSelectOpen: true}))
                                }}
                            >
                                <div
                                    className={styles.plusIcon}
                                />
                            </IconButton>
                        </div>
                        <Popper
                            placement={'right'}
                            open={state.isSelectOpen}
                            transition
                            disablePortal
                            style={{top: 20, left: "auto", zIndex: 1100, position: 'absolute'}}
                        >
                            {({TransitionProps, placement}) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{
                                        transformOrigin:
                                            placement === "bottom" ? "right" : "right"
                                    }}
                                >
                                    <Paper>
                                        <ClickAwayListener onClickAway={() => {
                                            setState((prevState) => ({
                                                ...prevState,
                                                isSelectOpen: false
                                            }))
                                        }}>
                                            <MenuList
                                                className={styles.menuList}
                                                id="menu-list-grow"
                                                onKeyDown={() => {
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        isSelectOpen: false
                                                    }))
                                                }}
                                            >
                                                {props.nets.filter(elem =>
                                                    (!props.editableTerm.nets.includes(elem) && !state.nets.includes(elem)) || state.deleteNets.includes(elem)
                                                )
                                                    .filter(elem => {
                                                        if (elem !== 'location') {
                                                            return true
                                                        } else if (state.deleteNets.includes('location')) {
                                                            return true;
                                                        } else {
                                                            return (!(
                                                                !state.deleteNets.includes('location') &&
                                                                ((editableTerm.location.length > 0 && editableTerm.location.flat().length !== 0) || (editableTerm.location.length === 1 && editableTerm.location.flat().length === 0))
                                                            ));
                                                        }
                                                    })
                                                    .map((elem, index) => (
                                                        <MenuItem
                                                            onClick={() => {
                                                                const newNets = state.nets;

                                                                if (elem === 'location' && state.deleteNets.includes(elem) && editableTerm.location[0] && editableTerm.location[0].isDelete) {
                                                                    setState((prevState) => ({
                                                                        ...prevState,
                                                                        deleteNets: prevState.deleteNets.filter(deleteNet => deleteNet !== 'location'),
                                                                        isSelectOpen: false,
                                                                    }));
                                                                    const newTerm = {...props.editableTerm};
                                                                    //@ts-ignore
                                                                    newTerm.location = [...newTerm.location, []]
                                                                    props.setLocationPopupOpen(true);
                                                                    props.setLocationPopupHandler(() => {
                                                                        setState((prevState) => {
                                                                            return {
                                                                                ...prevState,
                                                                                nets: prevState.nets.filter(elem => elem !== 'location'),
                                                                                deleteNets: [...prevState.deleteNets, 'location'],
                                                                            }
                                                                        })
                                                                    })
                                                                    props.setEditableTerm({...newTerm});
                                                                    return;
                                                                } else if (elem === 'location') {
                                                                    props.setLocationPopupOpen(true);
                                                                    props.setLocationPopupHandler(() => {
                                                                        setState((prevState) => ({
                                                                            ...prevState,
                                                                            nets: prevState.nets.filter(elem => elem !== 'location'),
                                                                            deleteNets: [...prevState.deleteNets, 'location']
                                                                        }))
                                                                    })
                                                                    setState((prevState) => ({
                                                                        ...prevState,
                                                                        deleteNets: prevState.deleteNets.filter(elem => elem !== 'location'),
                                                                        isSelectOpen: false,
                                                                    }))
                                                                } else if (state.deleteNets.includes(elem)) {
                                                                    setState((prevState) => ({
                                                                        ...prevState,
                                                                        deleteNets: prevState.deleteNets.filter(deleteNet => deleteNet !== elem),
                                                                        nets: prevState.deleteNets.filter(deleteNet => deleteNet !== elem),
                                                                        isSelectOpen: false,
                                                                    }))
                                                                } else {
                                                                    setState((prevState) => ({
                                                                        ...prevState,
                                                                        nets: [...newNets, elem as string],
                                                                        isSelectOpen: false,
                                                                    }))
                                                                }
                                                            }}
                                                            className={`${styles.subElemNet} ${styles.menuItem}`}
                                                            key={`${index}${new Date().getTime()}`}
                                                            value={elem}>
                                                            {elem}
                                                        </MenuItem>
                                                    ))}
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </div>}
                    {props.detailedTerm.posTag === POS_TAG_PROPER_NOUN.name && <React.Fragment>
                        {editableTerm.nets.map(net => {
                            const enableToShow = !state.deleteNets.includes(net);
                            return enableToShow ? <div
                                className={`${styles.netsRow} ${editableTerm.location.length >= 3 && styles.moreThanThreeLocations}`}>
                                <div className={styles.subElemNet}>
                                    {net}
                                </div>
                                <IconButton
                                    onClick={() => {
                                        props.onPopupPush({
                                            id: new Date().getTime().toString(),
                                            type: PopupType.POPUP_DELETE_NET,
                                            data: [],
                                            actionTitle: "Ok",
                                            actionVisible: true,
                                            label: editableTerm.label,
                                            net: net,
                                            actionHandler: () => {
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    deleteNets: [...prevState.deleteNets, net]
                                                }))
                                            },
                                            cancelVisible: true,
                                            cancelTitle: "Cancel"
                                        });
                                    }}
                                >
                                    <i className={styles.deleteIcon}/>
                                </IconButton>
                            </div> : <></>;
                        })}
                        {state.nets.filter(elem => elem !== 'location').map((netEdit, indexNetEdit) => {
                            return <div
                                className={`${styles.netsRow} ${editableTerm.location.length >= 3 && styles.moreThanThreeLocations}`}>
                                <span
                                    className={`${styles.subElemNet}`}
                                >
                                    {netEdit}
                                </span>
                                <IconButton
                                    onClick={() => {
                                        props.onPopupPush({
                                            id: new Date().getTime().toString(),
                                            type: PopupType.POPUP_DELETE_NET,
                                            data: [],
                                            actionTitle: "Ok",
                                            actionVisible: true,
                                            label: editableTerm.label,
                                            net: netEdit,
                                            actionHandler: () => {
                                                const newNets = state.nets;
                                                newNets.splice(indexNetEdit, 1)
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    nets: [...newNets],
                                                }))
                                            },
                                            cancelVisible: true,
                                            cancelTitle: "Cancel"
                                        });
                                    }}
                                >
                                    <i className={styles.deleteIcon}/>
                                </IconButton>
                            </div>;
                        })}
                    </React.Fragment>}

                    {(props.detailedTerm.posTag === POS_TAG_PROPER_NOUN.name && !state.deleteNets.includes('location') && (props.editableTerm.location.filter(elem => !elem.isDelete).length > 0)) &&
                    <div className={styles.locationRow}>
                        <div className={styles.subElemNet}>
                            location
                        </div>
                        <span
                            onClick={() => {
                                props.setLocationPopupOpen(true);
                                setState((prevState) => ({
                                    ...prevState,
                                    isSelectOpen: false,
                                }))
                            }}
                            className={styles.addLocationIcon}/>
                    </div>}
                    <div className={`${styles.info} ${styles.infoLocationMargin}`}>
                        {editableTerm.location.map((location, index) => {
                            return (!location.isDelete && ((location.latitude || location.latitude === 0) && (location.longitude || location.longitude === 0))) ? (
                                <React.Fragment>
                                    <div
                                        style={{justifyContent: "flex-start !important"}}
                                        className={`${styles.infoRow}`}
                                    >
                                        <div
                                            className={`${styles.infoRowLeftItemWithoutWidth}`}>
                                            <i className={styles.locationIcon}/>
                                        </div>
                                        <div className={styles.infoRowRightItemWithoutWidth}>
                                            <span>{location.latitude}, {location.longitude}</span> <IconButton
                                            onClick={() => {
                                                props.onPopupPush({
                                                    id: new Date().getTime().toString(),
                                                    type: PopupType.POPUP_DELETE_LOCATION,
                                                    data: [],
                                                    actionTitle: "Ok",
                                                    actionVisible: true,
                                                    label: editableTerm.label,
                                                    location: location,
                                                    actionHandler: () => {
                                                        const newTerm = {...props.editableTerm};
                                                        if (location.isNew) {
                                                            if (newTerm.location.flat().length === 1 || newTerm.location.filter(elem => !elem.isDelete).length === 1) {
                                                                if (state.nets.includes('location')) {
                                                                    const newNets = state.nets;
                                                                    const index = state.nets.findIndex(elem => elem === 'location')
                                                                    newNets.splice(index, 1)
                                                                    setState((prevState) => ({
                                                                        ...prevState,
                                                                        nets: [...newNets],
                                                                    }))
                                                                } else {
                                                                    setState((prevState) => ({
                                                                        ...prevState,
                                                                        deleteNets: [...prevState.deleteNets, 'location']
                                                                    }))
                                                                }
                                                            } else {
                                                            }
                                                            newTerm.location.splice(index, 1);
                                                        } else {
                                                            newTerm.location[index] = {
                                                                ...newTerm.location[index],
                                                                isDelete: true,
                                                            }
                                                            if (newTerm.location.flat().length === 0 || newTerm.location.filter(elem => !elem.isDelete).length === 0) {

                                                                if (state.nets.includes('location')) {
                                                                    const newNets = state.nets;
                                                                    const index = state.nets.findIndex(elem => elem === 'location')
                                                                    newNets.splice(index, 1)
                                                                    setState((prevState) => ({
                                                                        ...prevState,
                                                                        nets: [...newNets],
                                                                    }))
                                                                } else {
                                                                    setState((prevState) => ({
                                                                        ...prevState,
                                                                        deleteNets: [...prevState.deleteNets, 'location']
                                                                    }))
                                                                }
                                                            }
                                                        }
                                                        props.setEditableTerm({...newTerm});
                                                    },
                                                    cancelVisible: true,
                                                    cancelTitle: "Cancel"
                                                });
                                            }}
                                        >
                                            <i className={styles.deleteIcon}/>
                                        </IconButton>
                                        </div>

                                    </div>
                                    <div
                                        style={{justifyContent: "flex-start !important"}}
                                        className={styles.infoRow}
                                    >
                                        <div className={styles.infoRowLeftItemWithoutWidth}></div>
                                        <div className={styles.infoRowRightItemWithoutWidth}>
                                            {location.level}
                                        </div>
                                    </div>
                                </React.Fragment>
                            ) : <></>;
                        })}
                    </div>

                </div>
            )}
        </div>
        {([UserRole.ROLE_ADMIN, UserRole.ROLE_MAIN_ANNOTATOR].includes(props.role as UserRole) && editableTerm) && (
            <div className={styles.buttonsContainer}>
                <div
                    className={`${styles.button}`}
                >
                    <div
                        className={`${styles.buttonRow} ${!(props.editableTerm.label.trim().length >= 3 && props.editableTerm.label.trim().length <= 50) && styles.disabled}`}

                        onClick={() => {

                            if ((props.editableTerm.label.trim().length >= 3 && props.editableTerm.label.trim().length <= 50)) {
                                props.editTerm({
                                    lang: props.editableTerm.lang,
                                    posTag: props.editableTerm.posTag,
                                    source: props.detailedTerm.label,
                                    target: props.editableTerm.label,
                                    user: props.detailedTerm.updated_by,
                                    editPosTags: [{
                                        lang: props.editableTerm.lang,
                                        posTag: props.detailedTerm.posTag,
                                        source: props.detailedTerm.label,
                                        target: props.editableTerm.posTag,
                                        user: props.detailedTerm.updated_by,
                                    }],
                                    addNets: [...state.nets.filter(elem => elem !== 'location').map(elem => ({
                                        lang: props.editableTerm.lang,
                                        posTag: props.editableTerm.posTag,
                                        source: props.editableTerm.label,
                                        target: elem,
                                        user: props.detailedTerm.updated_by,
                                    }))],
                                    deleteNets: [...state.deleteNets.map(elem => ({
                                        lang: props.editableTerm.lang,
                                        posTag: props.editableTerm.posTag,
                                        source: props.editableTerm.label,
                                        target: elem,
                                        user: props.detailedTerm.updated_by,
                                    }))],
                                    addLocations: props.editableTerm.location.filter(elem => elem.isNew).map(elem => ({
                                        lang: props.editableTerm.lang,
                                        posTag: props.editableTerm.posTag,
                                        source: props.editableTerm.label,
                                        user: props.detailedTerm.updated_by,
                                        ...elem,
                                    })),
                                    deleteLocations: props.editableTerm.location.filter(elem => elem.isDelete).map(elem => ({
                                        lang: props.editableTerm.lang,
                                        posTag: props.editableTerm.posTag,
                                        source: props.editableTerm.label,
                                        user: props.detailedTerm.updated_by,
                                        ...elem,
                                    })),
                                })

                            }
                        }}
                    >
                        <span>Update</span>
                    </div>
                    <div
                        onClick={() => {
                            props.setEditableTerm(undefined);
                            props.setAdditionalInformationStage(AdditionalInformationStage.read);
                        }}
                        className={`${styles.buttonRow} ${styles.cancelButton}`}
                    >
                        <span>Cancel</span>
                    </div>
                </div>
            </div>
        )}
    </div>

}
