import * as React from "react";
import {
    Button,
    Chip,
    FormControl,
    Input,
    InputLabel,
    MenuItem,
    MuiThemeProvider,
    Select,
    TextField
} from "@material-ui/core";

import styles from "./UserEditor.module.scss";
import {NewUser, User, UserRole} from "../../api/userApi";
import {theme} from "../../helper";
import ThemeProvider from "@material-ui/styles/ThemeProvider";

export type UserEditorStateProps = Partial<User>;

export interface UserEditorDispatchProps {
    onSave: (user: User | NewUser) => void;
    onCancel: () => void;
}

export type UserEditorProps = UserEditorStateProps & UserEditorDispatchProps;

export interface UserEditorState extends Partial<User> {
    repeatPassword: string;
}

export class UserEditor extends React.PureComponent<UserEditorProps, UserEditorState> {
    constructor(props: UserEditorProps) {
        super(props);

        const {username = "", email = "", password = "", roles = [], id} = props;

        this.state = {
            id: id,
            username: username,
            email: email,
            roles: roles,
            password: password,
            repeatPassword: "",
        };
    }

    public render() {
        const {email, username, roles, password, repeatPassword} = this.state;
        const {onCancel} = this.props;
        const disableSaveButton = password !== repeatPassword;
        const errorText = disableSaveButton ? "passwords do not match" : "";

        return (
            <div className={styles.container}>
                <div className={styles.editUserContainer}>
                    <div>
                        <MuiThemeProvider theme={theme}>
                            <ThemeProvider theme={theme}>
                                <TextField
                                    value={username}
                                    label="Username"
                                    variant="outlined"
                                    fullWidth
                                    onChange={this.handleChange("username")}
                                />
                            </ThemeProvider>
                        </MuiThemeProvider>
                        <MuiThemeProvider theme={theme}>
                            <ThemeProvider theme={theme}>
                        <FormControl className={styles.roleContainer} fullWidth={true}>

                                    <InputLabel color={"primary"} htmlFor="select-multiple-chip">Role</InputLabel>
                                    <Select
                                        value={roles}
                                        onChange={this.handleRoleChange}
                                        input={<Input id="select-multiple-chip"/>}
                                        renderValue={(selected: any) => (
                                            <div>
                                                <Chip key={selected} label={selected}/>
                                            </div>
                                        )}
                                    >
                                        {Object.values(UserRole).map(role => (
                                            <MenuItem value={role} key={role}>
                                                {role}
                                            </MenuItem>
                                        ))}
                                    </Select>

                        </FormControl>

                        <TextField
                            className={styles.emailInput}
                            value={email}
                            label="Email"
                            variant="outlined"
                            fullWidth
                            onChange={this.handleChange("email")}
                        />
                        <div className={styles.passwordsContainer}>
                            <TextField
                                value={password}
                                onChange={this.handleChange("password")}
                                label="Password"
                                variant="outlined"
                                type="password"
                                fullWidth
                                autoComplete={"new-password"}
                            />
                            <TextField
                                helperText={<span style={{color: "#FF0000"}}>{errorText}</span>}
                                value={repeatPassword}
                                onChange={this.handleChange("repeatPassword")}
                                label="Repeat password"
                                variant="outlined"
                                type="password"
                                fullWidth
                                autoComplete={"new-password"}
                            />
                        </div>
                            </ThemeProvider>
                        </MuiThemeProvider>
                    </div>
                    <div>
                        <div className={styles.buttonsGroup}>
                            <Button
                                variant="contained"
                                color="default"
                                fullWidth={false}
                                size="large"
                                onClick={onCancel}
                            >
                                Cancel
                            </Button>
                            <Button
                                className={`${styles.saveButtonColor} ${disableSaveButton ? styles.disabled : ""}`}
                                variant="contained"
                                color="primary"
                                fullWidth={false}
                                size="large"
                                onClick={this.onSave}
                                disabled={disableSaveButton}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private handleChange = (name: keyof UserEditorState) => (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{ name?: string; value: unknown }>,
    ) => {
        event.preventDefault();
        this.setState({...this.state, [name]: event.target.value || event.target.name});
    };

    private handleRoleChange = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{ name?: string; value: unknown }>,
    ) => {
        event.preventDefault();
        this.setState({...this.state, roles: [event.target.value as UserRole]});
    };

    private getUser = (): User => {
        const {username, roles, password, email} = this.state;
        const {id} = this.props;

        const user = {
            id,
            username,
            email,
            roles,
            password,
        };

        return user as User;
    };

    private onSave = () => {
        this.props.onSave(this.getUser());
    };
}
