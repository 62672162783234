import {connect, MapDispatchToProps, MapStateToProps} from "react-redux";
import {LoginForm, LoginFormDispatchProps, LoginFormStateProps} from "./LoginForm";
import {authentication} from "../../ducks/auth/auth";
import {State} from "../../ducks/rootReducer";
import {selectAuth} from "../../selectors/selectors";

const mapStateToProps: MapStateToProps<LoginFormStateProps, {}, State> = state => {
    const { loginError, authLoader } = selectAuth(state);

    return {
        authLoader: authLoader !== undefined && authLoader,
        loginError,
    };
};

const dispatchProps: MapDispatchToProps<LoginFormDispatchProps, {}> = {
    authentication: authentication,
};

export const LoginFormConnected = connect(
    mapStateToProps,
    dispatchProps,
)(LoginForm);
