import React from "react";

import styles from "../EntryList.module.scss";
import {Button, Checkbox} from "@material-ui/core";
import {EntryRowExternal} from "../EntryRow/EntryRowExternal";
import {Language, LANGUAGE_UNDEFINED} from "../../../api/languageApi";
import {BacklogProps, Entry, EntryPush, OrderBy} from "../../../api/entryApi";
import Loader from "react-loader-spinner";
import {POS_TAG_PROPER_NOUN, POS_TAG_UNDEFINED, PosTag} from "../../../api/posTagApi";
import {Net, NET_UNDEFINED} from "../../../api/netApi";
import {User, UserRole} from "../../../api/userApi";
import {EntryNotifyPanelConnected} from "../EntryNotify/EntryNotifyPanelConnected";
import {
    bulkSourcesToTargetsNoPOS,
    ENTRIES_NOTIFY_SET,
    ENTRY_NOTIFY_INITIAL,
    EntryNotify,
    EntryNotifyStatus,
    EntryNotifyType,
    filteredNet,
    IEntryCheckSources,
    IEntryDetailedNotify,
    ISpellingLemmaSpinner,
    ITermFromPos,
    PushResult,
    spellingByLemmaPosQuery,
} from "../../../ducks/entries/entries";
import {getApiUtils} from "../../../api/utils";
import {UploadStatus} from "../../../ducks/upload/upload";
import {formatDate} from "../../../helper";
import {PopupElement, PopupType, spinnerDown, spinnerUp} from "../../../ducks/popup/popup";
import {UserApp} from "../../../api/userAppApi";
import {EntryFilterExternalConnected} from "../EntryFilter/EntryFilterExternal/EntryFilterExternalConnected";
import {ELEMENT_EXTERNAL_WIDTH} from "../../../helper/screenConsts";
import ReactPaginate from "react-paginate";
import {IEntrySorting, INotifyAddingByArrayElem, PER_PAGE} from "../EntryListBacklog/EntryListBacklog";
import {deleteDuplicatedItems, getIsCheckAllActive} from "../../../helper/others";
import {IBulkSourcesToTargetsNoPOS} from "../../../api/spellingApi";
import entryRowStyles from "../EntryRow/Spelling/Spelling.module.scss";

export interface EntriesListStateProps {
    entries: Entry[];
    previousEntries: Entry[];
    userId: number;
    userRole: UserRole | undefined;
    users: User[] | UserApp[];
    entryLanguages: Language[];
    nets: Net[];
    posTags: PosTag[];
    pushResult: PushResult[];
    spinnerStatus: number;
    filteredNets: filteredNet[];
    filterProps: BacklogProps;
    externalCount: number;
    page: number;
    entrySorting: IEntrySorting | undefined;
    spinners: ISpellingLemmaSpinner[];
    notify: IEntryDetailedNotify;
}

export interface EntriesListDispatchProps {
    onLanguagesQuery: (unique?: boolean) => void;
    onPosTagsQuery: () => void;
    onNetQuery: () => void;
    onPopupPush: (popup: PopupElement) => void;
    onEntryValidate: (entries: EntryPush[], success: () => void, fail: (errors: string[]) => void) => void;
    onEntryPushToBacklog: (entries: EntryPush[], success: () => void) => void;
    onEntrySaveChanges: (entries: EntryPush[], success: () => void) => void;
    onSetEntries: (entries: Entry[], previousEntries?: Entry[]) => void;
    onEntryDelete: (ids: number[]) => void;
    onSetNets: (nets: filteredNet[]) => void;
    onBacklogGetQuery: (props: BacklogProps) => void;
    getEntriesByPageExternal: (offset: number) => void;
    setExternalListPage: (page: number) => void;
    setEntrySorting: (sorting: IEntrySorting) => void;
    setEntriesSpinner: (language: Language, value: boolean) => void;
}

type EntriesListProps = EntriesListStateProps & EntriesListDispatchProps;

export enum RemovalCandidateType {
    REMOVE_SELECTED_ENTRIES,
    REMOVE_ONE_SPELLING,
}

interface RemovalCandidate {
    type: RemovalCandidateType;
    id?: number;
    created?: boolean;
    spellingIndex?: number;
}

export interface EntriesListState {
    deleteDialogShow: boolean;
    removalCandidate: RemovalCandidate | undefined;
    uploadStatus: UploadStatus;
    language: boolean | undefined;
    date: boolean | undefined;
    user: boolean | undefined;
    source: boolean | undefined;
    lemma: boolean | undefined;
    partOfSpeech: boolean | undefined;
    synonym: boolean | undefined;
    net: boolean | undefined;
    objectId: boolean | undefined;
    entryIdsForAutoCompleteLemma: number[];
    entryIdsForAutoCompleteSynonym: number[];
}

export class EntryListExternal extends React.PureComponent<EntriesListProps, EntriesListState> {
    constructor(props: EntriesListProps) {
        super(props);

        this.state = {
            deleteDialogShow: false,
            removalCandidate: undefined,
            uploadStatus: UploadStatus.BEFORE,
            language: undefined,
            source: undefined,
            lemma: undefined,
            partOfSpeech: undefined,
            synonym: undefined,
            net: undefined,
            date: undefined,
            user: undefined,
            objectId: undefined,
            entryIdsForAutoCompleteLemma: [],
            entryIdsForAutoCompleteSynonym: [],
        };
    }

    componentDidMount(): void {
        this.props.onNetQuery();
        this.props.onLanguagesQuery();
        this.props.onPosTagsQuery();
        if (this.props.entries.length !== 0) {
            this.onEntryFilterForRequest(this.props.entries);
        }
    }

    componentDidUpdate(prevProps: Readonly<EntriesListProps>, prevState: Readonly<EntriesListState>, snapshot?: any) {
        const { entries, page } = this.props;
        if (prevProps.entries.length === 0 || prevProps.page !== page) {
            this.onEntryFilterForRequest(entries);
        }
    }

    render() {
        const {
            userRole,
            entryLanguages,
            nets,
            posTags,
            spinnerStatus,
            filteredNets,
            externalCount,
            page,
            previousEntries,
        } = this.props;
        const { entries } = this.props;
        const isChecked = getIsCheckAllActive(entries);
        const pageCount = externalCount ? externalCount / PER_PAGE : 0;
        const permissionToDeleteSelected = entries.filter(entry => entry.check).length > 0;
        const permissionToPush = permissionToDeleteSelected && !this.errorCheck() && !this.errorNotificationsCheck();
        const permissionToPut = permissionToDeleteSelected;
        const visibleLoader = spinnerStatus > 0;

        const pushButtonTitle =
            userRole === UserRole.ROLE_BU_USER || userRole === UserRole.ROLE_ANNOTATOR
                ? "Create"
                : "Validate and create";

        const entryRows = entries.map((entry, entryIndex) => {
            const userName = entry.user_app as string;
            const date = entry.date_app as string;

            const stringDate = date ? date.split("T")[0] : "";

            return (
                <EntryRowExternal
                    previousNetEmpty={
                        previousEntries[entryIndex] && previousEntries[entryIndex].nets.code === NET_UNDEFINED.code
                    }
                    autocompleteLemma={this.state.entryIdsForAutoCompleteLemma.includes(entry.id)}
                    autocompleteSynonym={this.state.entryIdsForAutoCompleteSynonym.includes(entry.id)}
                    index={entryIndex}
                    didMountFreeze={undefined}
                    onSetNets={this.props.onSetNets}
                    filteredNets={filteredNets}
                    entryRowWidth={ELEMENT_EXTERNAL_WIDTH}
                    key={entry.id}
                    entryUserName={userName}
                    entryDate={stringDate}
                    languages={entryLanguages}
                    nets={nets}
                    posTags={posTags}
                    entry={entry}
                    onEntryDataChange={this.dataChangeInEntry}
                    onEntryRowSpellingChange={this.spellingChange}
                    onEntryRowSpellingAdd={this.spellingAdd}
                    onEntryRowSpellingDelete={this.spellingDelete}
                    onEntryCheck={this.entryCheck}
                    onEntryAddNotify={this.notifyAdd}
                    onEntryNotifyExist={this.notifyExist}
                    onEntryGetHighNotify={this.getHighNotify}
                    onEntryNotifyShow={this.notifyShow}
                    onEntryNotifyClear={this.notifyClear}
                    onEntryOverlapCheck={this.entryOverlapCheck}
                    onEntryClearAutocompleteNotify={this.entryClearAutocompleteNotify}
                    notify={this.props.notify}
                />
            );
        });

        return (
            <div className={styles.container}>
                <div style={{ display: visibleLoader ? "flex" : "none" }} className={styles.loaderContainer}>
                    <Loader type="Oval" color="#ff4646" height={100} width={100} visible={visibleLoader} />
                </div>
                <div className={styles.editorContainer}>
                    <div className={styles.entriesContainer} onClick={this.notifyClear}>
                        <EntryFilterExternalConnected />
                        <div className={styles.entryRowsListContainer}>
                            <div className={styles.entriesRowsFullContainer}>
                                <div className={`${styles.entriesHeader} ${styles.entriesHeaderBacklogApp}`}>
                                    <Checkbox
                                        disabled={this.props.entries.length === 0}
                                        className={`${styles.selectAllCheckbox} ${isChecked &&
                                            styles.checked}`}
                                        checked={isChecked}
                                        onChange={this.entryAllCheck}
                                    />
                                    <div
                                        onClick={() => {
                                            this.entryByStringSort(OrderBy.Language, "language");
                                        }}
                                        style={{ ...ELEMENT_EXTERNAL_WIDTH.language, cursor: "pointer" }}
                                    >
                                        Language
                                    </div>
                                    <div style={ELEMENT_EXTERNAL_WIDTH.inputs}>Spellings</div>
                                    <div
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            this.entryByStringSort(OrderBy.Lemma, "lemma");
                                        }}
                                    >
                                        Lemma
                                    </div>
                                    <div
                                        onClick={() => {
                                            this.entryByStringSort(OrderBy.Postag, "partOfSpeech");
                                        }}
                                        style={{ ...ELEMENT_EXTERNAL_WIDTH.selectors, cursor: "pointer" }}
                                    >
                                        Part of Speech
                                    </div>
                                    <div
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            this.entryByStringSort(OrderBy.Synonym, "synonym");
                                        }}
                                    >
                                        Synonym
                                    </div>
                                    <div
                                        style={{ ...ELEMENT_EXTERNAL_WIDTH.selectors, cursor: "pointer" }}
                                        onClick={() => {
                                            this.entryByStringSort(OrderBy.Net, "net");
                                        }}
                                    >
                                        NET
                                    </div>
                                    <div
                                        onClick={() => {
                                            this.entryByDateSort(OrderBy.CreatedAt, "date");
                                        }}
                                        style={{ ...ELEMENT_EXTERNAL_WIDTH.date, cursor: "pointer" }}
                                    >
                                        Date
                                    </div>
                                    <div
                                        onClick={() => {
                                            this.entryByStringSort(OrderBy.UserApp, "user");
                                        }}
                                        style={{ ...ELEMENT_EXTERNAL_WIDTH.user, cursor: "pointer" }}
                                    >
                                        User
                                    </div>
                                    <div
                                        onClick={() => {
                                            this.entryByStringSort(OrderBy.SourceApp, "source");
                                        }}
                                        style={{ ...ELEMENT_EXTERNAL_WIDTH.source, cursor: "pointer" }}
                                    >
                                        Source
                                    </div>
                                    <div
                                        onClick={() => {
                                            this.entryByStringSort(OrderBy.ObjectIdApp, "objectId");
                                        }}
                                        style={{ ...ELEMENT_EXTERNAL_WIDTH.objectID, cursor: "pointer" }}
                                    >
                                        Object ID
                                    </div>
                                </div>
                                <div className={styles.entriesRowsContainer}>{entryRows}</div>
                            </div>
                            <EntryNotifyPanelConnected />
                        </div>

                        <ReactPaginate
                            forcePage={page}
                            previousLabel={"←"}
                            breakLabel="..."
                            nextLabel={"→"}
                            pageCount={pageCount ? Math.ceil(pageCount) : 1}
                            marginPagesDisplayed={3}
                            onPageChange={event => {
                                const page = event.selected;
                                const offset = page === 0 ? 0 : page * PER_PAGE;
                                this.props.getEntriesByPageExternal(offset);
                                this.props.setExternalListPage(page);
                            }}
                            containerClassName={styles.pagination}
                            previousLinkClassName={styles.paginationLink}
                            nextLinkClassName={styles.paginationLink}
                            disabledClassName={styles.paginationLinkDisabled}
                            activeClassName={styles.paginationLinkActive}
                        />
                        <div className={styles.buttonsContainer}>
                            <Button
                                disabled={!permissionToDeleteSelected}
                                onClick={this.entryDelete}
                                className={`${styles.deleteSelectedButton} ${!permissionToDeleteSelected &&
                                    styles.buttonDisabled}`}
                                variant="contained"
                            >
                                {"Delete selected"}
                            </Button>
                            <Button
                                disabled={!permissionToPut}
                                onClick={this.saveEntriesChanges}
                                className={`${styles.backlogSaveButton} ${!permissionToPut && styles.buttonDisabled}`}
                                variant="contained"
                            >
                                {"Save changes"}
                            </Button>
                            <Button
                                disabled={!permissionToPush}
                                onClick={this.pushToBacklog}
                                className={`${styles.uploadCheckButton} ${!permissionToPush && styles.buttonDisabled}`}
                                variant="contained"
                            >
                                {pushButtonTitle}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    onEntryFilterForRequest = (entries: Entry[]) => {
        const languages = deleteDuplicatedItems(entries.map(elem => elem.language.code));
        languages.forEach(ln => {
            getApiUtils().dispatch(spinnerUp());
            const newEntries = entries.filter(el => el.language.code === ln);
            this.props.setEntriesSpinner({ name: "", code: ln }, true);
            const requestProps: IBulkSourcesToTargetsNoPOS = {
                spellings: newEntries
                    .map(enEl => enEl.spelling)
                    .flat()
                    .filter(elem => elem),
                lemmas: newEntries.map(enEl => enEl.lemma).filter(elem => elem),
                synonyms: newEntries.map(enEl => enEl.synonym).filter(elem => elem),
                lang: ln,
            };
            bulkSourcesToTargetsNoPOS(requestProps).then(async (response: IEntryCheckSources) => {
                if (!response) return;
                const newEntryListForRequest: IEntryCheckSources = {
                    spellings_lemmas: response.spellings_lemmas.filter(elem => elem),
                    synonyms_lemmas: response.synonyms_lemmas.filter(elem => elem),
                    lemmas_spellings: response.lemmas_spellings.filter(elem => elem),
                };
                let notifyList: INotifyAddingByArrayElem[] = [];
                for (let i = 0; i < newEntries.length; i++) {
                    const entry = newEntries[i];
                    const synonyms_lemma =
                        entry.synonym !== ""
                            ? newEntryListForRequest.synonyms_lemmas.filter(elem => entry.synonym === elem.source)
                            : [];
                    const lemmas_spellings =
                        entry.lemma !== ""
                            ? newEntryListForRequest.lemmas_spellings.filter(elem => entry.lemma === elem.source)
                            : [];
                    const spelling_lemmas =
                        entryRowStyles.spelling.length > 0 && entryRowStyles.spelling[0] !== ""
                            ? newEntryListForRequest.spellings_lemmas.filter(elem =>
                                  entry.spelling.map(elem => elem.toLowerCase()).includes(elem.source.toLowerCase()),
                              )
                            : [];
                    const newSpellings = entry.spelling.filter(elem => elem.length >= 3);

                    if (synonyms_lemma.length > 0 && entry.synonym !== "" && entry.lemma !== "") {
                        this.setState(prevState => {
                            return {
                                ...prevState,
                                entryIdsForAutoCompleteSynonym: [...prevState.entryIdsForAutoCompleteSynonym, entry.id],
                            };
                        });
                        notifyList.push({
                            entryId: entry.id,
                            notify: {
                                type: EntryNotifyType.ENTRY_NOTIFY_SYNONYM_AUTOCOMPLETE,
                                status: EntryNotifyStatus.NOTIFY_STATUS_WARNING,
                                object: entry.synonym,
                                list: synonyms_lemma.map(elem => elem.targets).flat(),
                                insertItem: entry.lemma,
                            },
                        });
                    }
                    if (entry.spelling.length > 0 && entry.spelling[0].trim() !== "") {
                        const lemmas = spelling_lemmas.map(res => res.targets).flat();
                        const sources = spelling_lemmas.map(elem => elem.source.toLowerCase());
                        if (lemmas.length > 0) {
                            newSpellings.forEach(s => {
                                if (sources.includes(s.toLowerCase())) {
                                    let list: string[] = [];
                                    try {
                                        list = spelling_lemmas
                                            .filter(elem => elem.source.toLowerCase() === s.toLowerCase())
                                            .map(elem => elem.targets)
                                            .flat();
                                    } catch {}
                                    notifyList.push({
                                        entryId: entry.id,
                                        notify: {
                                            type: EntryNotifyType.ENTRY_NOTIFY_SPELLING_EXISTS,
                                            status: EntryNotifyStatus.NOTIFY_STATUS_WARNING,
                                            object: s,
                                            list: list,
                                            insertItem: s,
                                        },
                                    });
                                }
                            });
                        }
                    }
                    const lemma = entry.lemma;
                    if (lemma.trim() !== "" && lemmas_spellings.length > 0) {
                        await spellingByLemmaPosQuery(lemma, entry.posTag.name, entry.language.code).then(
                            (res: ITermFromPos[]) => {
                                const spellingsByLemmaPos = res.map(elem => elem.label);
                                if (spellingsByLemmaPos.includes(entry.lemma)) {
                                    if (synonyms_lemma.length > 0 && entry.synonym !== "") {
                                        this.setState(prevState => {
                                            return {
                                                ...prevState,
                                                entryIdsForAutoCompleteLemma: [
                                                    ...prevState.entryIdsForAutoCompleteLemma,
                                                    entry.id,
                                                ],
                                            };
                                        });
                                    }

                                    notifyList.push({
                                        entryId: entry.id,
                                        notify: {
                                            type: EntryNotifyType.ENTRY_NOTIFY_LEMMA_AUTOCOMPLETE,
                                            status: EntryNotifyStatus.NOTIFY_STATUS_WARNING,
                                            object: entry.lemma,
                                            list: spellingsByLemmaPos,
                                            insertItem: "",
                                        },
                                    });
                                }
                                const spellingByLemmaPosList = spellingsByLemmaPos.map(elem => elem.toLowerCase());
                                entry.spelling.forEach(spelling => {
                                    if (spellingByLemmaPosList.includes(spelling.trim().toLowerCase())) {
                                        notifyList.push({
                                            entryId: entry.id,
                                            notify: {
                                                type: EntryNotifyType.ENTRY_NOTIFY_SPELLING_OVERLAP,
                                                status: EntryNotifyStatus.NOTIFY_STATUS_ERROR,
                                                object: spelling,
                                                list: [lemma],
                                                insertItem: "",
                                            },
                                        });
                                    }
                                });
                            },
                        );
                    }
                }
                notifyList.length && this.notifyArrayAdd(notifyList);
                this.props.setEntriesSpinner({ name: "", code: ln }, false);
            });
            getApiUtils().dispatch(spinnerDown());
        });
    };

    notifyArrayAdd = (notifies: INotifyAddingByArrayElem[]) => {
        notifies.forEach(elem => {
            const { entryId, notify } = elem;
            const entry = this.getEntryById(entryId);
            if (!entry) return;

            this.entryClearAutocompleteNotify(entryId, notify.type);

            if (notify.type === EntryNotifyType.ENTRY_NOTIFY_SPELLING_EXISTS) {
                for (let i = 0; i < entry.notifications.length; i++) {
                    if (
                        entry.notifications[i].type === notify.type &&
                        entry.notifications[i].object === notify.object
                    ) {
                        entry.notifications.splice(i, 1);
                    }
                }
            }

            entry.notifications.push(notify);
        });

        this.props.onSetEntries([...this.props.entries]);
    };

    private static permissionToNewRecord(newEntries: Entry[]) {
        const preFilter = newEntries.filter(entry => {
            return (
                entry.spelling.length === 1 &&
                entry.spelling[0] === "" &&
                entry.lemma === "" &&
                entry.posTag.code === "UNDEFINED"
            );
        });

        return preFilter.length === 0;
    }

    entryAdd = () => {
        const newEntries = [...this.props.entries];

        let newEntry: Entry = {
            id: 0,
            check: false,
            created: true,
            language: this.props.entryLanguages[0],
            spelling: [""],
            lemma: "",
            posTag: POS_TAG_UNDEFINED,
            synonym: "",
            itSelf: true,
            nets: NET_UNDEFINED,
            notifications: [],
            date: formatDate(Date.now()),
            userId: this.props.userId,
            source_app: undefined,
            date_app: undefined,
            user_app: undefined,
            account_app: undefined,
            objectid_app: undefined,
        };

        newEntry.id = Date.now();
        newEntries.unshift(newEntry);

        this.props.onSetEntries(newEntries);
    };

    private handleSortBacklog = (orderBy: OrderBy, orderByReversed: boolean) => {
        const props = {
            ...this.props.filterProps,
            orderBy,
            orderByReversed,
        };
        props.offset = this.props.page * 20;
        this.props.onBacklogGetQuery(props);
    };

    entryByDateSort = (fieldId: OrderBy, name: keyof IEntrySorting) => {
        const isColumnSorted = this.props.entrySorting ? this.props.entrySorting[name] : undefined;
        const reverseSort = !(isColumnSorted || isColumnSorted === undefined);
        this.handleSortBacklog(fieldId, reverseSort);
        this.props.setEntrySorting({
            language: undefined,
            lemma: undefined,
            date: undefined,
            net: undefined,
            user: undefined,
            objectId: undefined,
            partOfSpeech: undefined,
            synonym: undefined,
            source: undefined,
            [name]: reverseSort,
            orderBy: fieldId,
            orderByReversed: reverseSort,
        });
    };

    entryByStringSort = (field: OrderBy, name: keyof IEntrySorting) => {
        const isColumnSorted = this.props.entrySorting ? this.props.entrySorting[name] : undefined;
        const reverseSort = !(isColumnSorted || isColumnSorted === undefined);
        this.handleSortBacklog(field, reverseSort);
        this.props.setEntrySorting({
            language: undefined,
            lemma: undefined,
            date: undefined,
            net: undefined,
            user: undefined,
            objectId: undefined,
            partOfSpeech: undefined,
            synonym: undefined,
            source: undefined,
            [name]: reverseSort,
            orderBy: field,
            orderByReversed: reverseSort,
        });
    };

    pushToBacklog = () => {
        const entriesChecked: EntryPush[] = [];

        this.props.entries.forEach((entry, index) => {
            if (entry.check) {
                entriesChecked.push({
                    id: entry.id,
                    created: entry.created,
                    index,
                    data: {
                        language: entry.language,
                        spelling: entry.spelling,
                        lemma: entry.lemma,
                        synonym: entry.synonym,
                        posTag: entry.posTag,
                        itSelf: entry.itSelf,
                        netTag: entry.nets,
                        sendData: true,
                    },
                });
            }
        });

        this.props.onEntryValidate(
            entriesChecked,
            () => {
                this.props.onEntryPushToBacklog(entriesChecked, () => {
                    this.props.onBacklogGetQuery(this.props.filterProps);
                });
            },
            errors => {
                this.props.onPopupPush({
                    id: new Date().getTime().toString(),
                    type: PopupType.POPUP_VALIDATE_ERRORS,
                    data: ["Errors detected:", ...errors],
                    actionTitle: "Ok",
                    actionVisible: true,
                    cancelVisible: false,
                });
            },
        );
    };

    saveEntriesChanges = (): void => {
        const entriesChecked: EntryPush[] = [];
        this.props.entries.forEach((entry, index) => {
            if (entry.check) {
                entriesChecked.push({
                    id: entry.id,
                    created: entry.created,
                    index,
                    data: {
                        language: entry.language,
                        spelling: entry.spelling,
                        lemma: entry.lemma,
                        synonym: entry.synonym,
                        posTag: entry.posTag,
                        itSelf: entry.itSelf,
                        netTag: entry.nets,
                        sendData: false,
                    },
                });
            }
        });

        this.props.onEntrySaveChanges(entriesChecked, () => {});
    };

    errorCheck = (): boolean => {
        let result = false;
        const entries = this.props.entries;

        for (let i = 0; i < entries.length && !result; i++) {
            if (entries[i].check) {
                if (
                    entries[i].language.code === LANGUAGE_UNDEFINED.code ||
                    entries[i].lemma.trim() === "" ||
                    entries[i].synonym.trim() === "" ||
                    entries[i].posTag.code === POS_TAG_UNDEFINED.code ||
                    (entries[i].posTag.code === POS_TAG_PROPER_NOUN.code && entries[i].nets.code === NET_UNDEFINED.code)
                ) {
                    result = true;
                    break;
                }

                for (let j = 0; j < entries[i].spelling.length && !result; j++) {
                    if (entries[i].spelling[j].trim() === "") {
                        result = true;
                        break;
                    }
                }
            }
        }

        return result;
    };

    errorNotificationsCheck = (): boolean => {
        let result = false;
        const entries = this.props.entries;

        for (let i = 0; i < entries.length && !result; i++) {
            if (entries[i].check) {
                for (let j = 0; j < entries[i].notifications.length && !result; j++) {
                    if (entries[i].notifications[j].status === EntryNotifyStatus.NOTIFY_STATUS_ERROR) {
                        result = true;
                        break;
                    }
                }
            }
        }

        return result;
    };

    entryOverlapCheck = (id: number, spelling: string | null = null) => {
        const entry = this.getEntryById(id);

        if (entry) {
            if (spelling) {
                entry.notifications = entry.notifications.filter(
                    notify =>
                        !(notify.type === EntryNotifyType.ENTRY_NOTIFY_SPELLING_OVERLAP && notify.object === spelling),
                );
            } else {
                entry.notifications = entry.notifications.filter(
                    notify => notify.type !== EntryNotifyType.ENTRY_NOTIFY_SPELLING_OVERLAP,
                );
            }
            this.props.onSetEntries([...this.props.entries]);
            const lemma = entry.lemma;
            if (lemma.trim() !== "") {
                spellingByLemmaPosQuery(lemma, entry.posTag.name, entry.language.code).then((res: ITermFromPos[]) => {
                    const spellingsByLemmaPos = res;
                    const spellingByLemmaPosList = res.map(elem => elem.label.toLowerCase());
                    if (spellingByLemmaPosList.includes(lemma.trim().toLowerCase())) {
                        this.notifyAdd(id, {
                            type: EntryNotifyType.ENTRY_NOTIFY_LEMMA_AUTOCOMPLETE,
                            status: EntryNotifyStatus.NOTIFY_STATUS_WARNING,
                            object: lemma,
                            list: res.map(elem => elem.label),
                            insertItem: "",
                        });
                    }
                    entry.spelling.forEach(spelling => {
                        if (spellingByLemmaPosList.includes(spelling.trim().toLowerCase())) {
                            this.notifyAdd(id, {
                                type: EntryNotifyType.ENTRY_NOTIFY_SPELLING_OVERLAP,
                                status: EntryNotifyStatus.NOTIFY_STATUS_ERROR,
                                object: spelling,
                                list: [lemma],
                                insertItem: "",
                            });
                        }
                    });
                });
            }
        }
    };

    private getEntryById = (id: number) => {
        const entryFiltered = this.props.entries.filter(entry => entry.id === id);
        if (!entryFiltered || entryFiltered.length === 0) return null;
        return entryFiltered[0];
    };

    private getNotifyFromEntry = (entry: Entry | null, notifyType: EntryNotifyType, object: string | null) => {
        let filtered;
        if (entry) {
            filtered = entry.notifications.filter(notify => {
                return (
                    (object === null && notify.type === notifyType) ||
                    (!(object === null) && notify.type === notifyType && notify.object === object)
                );
            });
        }

        if (filtered !== undefined && filtered.length > 0) return filtered[0];
        else return null;
    };

    notifyShow = (
        notify: EntryNotify | null,
        id: number | null,
        type: EntryNotifyType | null,
        object: string | null,
    ) => {
        let findNotify;
        if (id && type) {
            const entry = this.getEntryById(id);
            if (entry) findNotify = this.getNotifyFromEntry(entry, type, object);
        }

        getApiUtils().dispatch({
            type: ENTRIES_NOTIFY_SET,
            payload: notify || findNotify,
        });
    };

    notifyExist = (id: number, notifyType: EntryNotifyType, object: string | null): boolean => {
        const entry = this.getEntryById(id);

        const notify = this.getNotifyFromEntry(entry, notifyType, object);
        return entry !== null && notify !== null;
    };

    getHighNotify = (id: number, notifyTypes: EntryNotifyType[], object: string): EntryNotify | null => {
        const entry = this.getEntryById(id);
        if (!entry) return null;

        let currPriority: number = -1;
        let resultNotify: EntryNotify | null = null;
        for (let i = 0; i < entry.notifications.length; i++) {
            const notify = entry.notifications[i];
            if (notifyTypes.includes(notify.type) && notify.object === object && notify.status > currPriority) {
                resultNotify = notify;
                currPriority = notify.status;
            }
        }

        return resultNotify;
    };

    entryClearAutocompleteNotify = (id: number, notifyType: EntryNotifyType) => {
        const entry = this.getEntryById(id);
        if (!entry) return;

        if (
            [
                EntryNotifyType.ENTRY_NOTIFY_LEMMA_AUTOCOMPLETE,
                EntryNotifyType.ENTRY_NOTIFY_SYNONYM_AUTOCOMPLETE,
            ].includes(notifyType)
        ) {
            for (let i = 0; i < entry.notifications.length; i++) {
                if (entry.notifications[i].type === notifyType) {
                    entry.notifications.splice(i, 1);
                }
            }
        }
    };

    notifyAdd = (id: number, notify: EntryNotify) => {
        const entry = this.getEntryById(id);
        if (!entry) return;

        this.entryClearAutocompleteNotify(id, notify.type);

        if (notify.type === EntryNotifyType.ENTRY_NOTIFY_SPELLING_EXISTS) {
            for (let i = 0; i < entry.notifications.length; i++) {
                if (entry.notifications[i].type === notify.type && entry.notifications[i].object === notify.object) {
                    entry.notifications.splice(i, 1);
                }
            }
        }

        entry.notifications.push(notify);

        this.props.onSetEntries([...this.props.entries]);
    };

    notifyClear = () => {
        const currentNotifyType = getApiUtils().getState().entries.notify.type;

        if (currentNotifyType !== EntryNotifyType.ENTRY_NOTIFY_EMPTY) {
            getApiUtils().dispatch({
                type: ENTRIES_NOTIFY_SET,
                payload: ENTRY_NOTIFY_INITIAL,
            });
        }
    };

    private handleDeleteDialogYes = () => {
        if (this.state.removalCandidate !== undefined) {
            const removalCandidate = this.state.removalCandidate;
            const id = removalCandidate.id;
            const spellingIndex = removalCandidate.spellingIndex;
            const { entries, onEntryDelete } = this.props;

            let newEntries: Entry[];
            let idsToDelete: number[] = [];
            let idsToExclude: number[] = [];

            if (spellingIndex !== undefined) {
                newEntries = entries.map(entry => {
                    if (entry.id === id) {
                        const removeSpelling = entry.spelling[spellingIndex];
                        entry.spelling.splice(spellingIndex, 1);
                        entry.notifications = entry.notifications.filter(
                            i =>
                                !(
                                    [
                                        EntryNotifyType.ENTRY_NOTIFY_SPELLING_EXISTS,
                                        EntryNotifyType.ENTRY_NOTIFY_SPELLING_OVERLAP,
                                    ].includes(i.type) && i.object === removeSpelling
                                ),
                        );
                    }
                    return entry;
                });
                this.props.onSetEntries(newEntries);
            } else {
                entries.forEach(entry => {
                    if (entry.check) {
                        if (!entry.created) {
                            idsToDelete.push(entry.id);
                        } else {
                            idsToExclude.push(entry.id);
                        }
                    }
                });
            }

            this.setState({
                ...this.state,
                removalCandidate: undefined,
                deleteDialogShow: false,
            });
            onEntryDelete(idsToDelete);
            if (idsToExclude.length > 0) {
                this.props.onSetEntries(
                    entries.filter(elem => {
                        if (!idsToExclude.includes(elem.id)) {
                            return entries;
                        }
                    }),
                );
            }
        }
    };

    private handleDeleteDialogCancel = () => {
        this.setState({
            ...this.state,
            removalCandidate: undefined,
            deleteDialogShow: false,
        });
    };

    entryDelete = () => {
        const { entries, onEntryDelete } = this.props;
        let notToShowDialog = true;
        let allSpellingsEmpty = true;

        entries.forEach(entry => {
            entry.spelling.forEach(spelling => {
                if (spelling) {
                    allSpellingsEmpty = false;
                }
            });

            if (entry.check) {
                if (!entry.created || (entry.language.code && (entry.lemma || !allSpellingsEmpty))) {
                    notToShowDialog = false;
                }
            }
        });

        this.setState(
            () => {
                return {
                    ...this.state,
                    removalCandidate: {
                        type: RemovalCandidateType.REMOVE_SELECTED_ENTRIES,
                    },
                };
            },
            () => {
                notToShowDialog && this.handleDeleteDialogYes();
                !notToShowDialog &&
                    this.props.onPopupPush({
                        id: new Date().getTime().toString(),
                        type: PopupType.POPUP_MESSAGE,
                        data: ["Do you confirm removal?"],
                        actionTitle: "Yes, delete",
                        actionVisible: true,
                        cancelVisible: true,
                        cancelTitle: "Cancel",
                        actionHandler: () => {
                            this.handleDeleteDialogYes();
                        },
                    });
            },
        );
    };

    entryAllCheck = () => {
        const {entries, onSetEntries} = this.props;
        const isChecked = getIsCheckAllActive(entries);
        const newCheckStatus = !isChecked;

        const newEntries: Entry[] = this.props.entries.concat();
        for (let i = 0; i < newEntries.length; i++) {
            newEntries[i].check = newCheckStatus;
        }
        onSetEntries(newEntries);

        this.setState({ ...this.state });
    };

    entryCheck = (id: number) => {
        const newEntries: Entry[] = this.props.entries.concat();

        for (let i = 0; i < newEntries.length; i++) {
            const entry: Entry = newEntries[i];

            if (entry.id === id) {
                entry.check = !entry.check;
                break;
            }
        }

        this.props.onSetEntries(newEntries);
    };

    dataChangeInEntry = (id: number, field: keyof Entry, value: string | Net[] | boolean | Language | PosTag | Net) => {
        const newEntries: Entry[] = this.props.entries.concat();
        let previousEntries: Entry[] = [];

        for (let i = 0; i < newEntries.length; i++) {
            const entry: Entry = newEntries[i];

            if (entry.id === id) {
                if (Array.isArray(value)) {
                    //@ts-ignore
                    entry[field] = { code: value[0].code, name: value[0].name };
                    const filteredNets = this.props.nets.filter(elem => value.includes(elem));
                    const filteredNet: filteredNet = {
                        entryId: id,
                        nets: filteredNets,
                    };
                    this.props.onSetNets([filteredNet]);
                    if (filteredNet.nets.length === 1) {
                        this.props.onSetNets([]);
                    }
                } else {
                    //@ts-ignore
                    entry[field] = value;
                    //@ts-ignore
                    if (field === "nets" && value!.code === NET_UNDEFINED.code) {
                        previousEntries = [...newEntries];
                    }
                }
                break;
            }
        }

        this.props.onSetEntries(newEntries, previousEntries);
    };

    spellingChange = (id: number, spellingIndex: number, value: string) => {
        const newEntries = this.props.entries.map(entry => {
            if (entry.id === id) entry.spelling[spellingIndex] = value;
            return entry;
        });

        this.props.onSetEntries(newEntries);
    };

    spellingAdd = (id: number) => {
        const newEntries = this.props.entries.map(entry => {
            if (entry.id === id) entry.spelling.push("");
            return entry;
        });

        this.props.onSetEntries(newEntries);
    };

    spellingDelete = (id: number, spellingIndex: number) => {
        let notShowDeletePopup = true;
        const { entries } = this.props;
        if (spellingIndex !== undefined) {
            entries.forEach(entry => {
                if (entry.id === id) {
                    const removeSpelling = entry.spelling[spellingIndex];
                    if (removeSpelling && removeSpelling.trim() !== "") {
                        notShowDeletePopup = false;
                    }
                }
            });
        }
        this.setState(
            () => {
                return {
                    ...this.state,
                    removalCandidate: {
                        type: RemovalCandidateType.REMOVE_ONE_SPELLING,
                        id,
                        spellingIndex,
                    },
                };
            },
            () => {
                notShowDeletePopup && this.handleDeleteDialogYes();
                !notShowDeletePopup &&
                    this.props.onPopupPush({
                        id: new Date().getTime().toString(),
                        type: PopupType.POPUP_MESSAGE,
                        data: ["Do you confirm removal?"],
                        actionTitle: "Yes, delete",
                        actionVisible: true,
                        cancelVisible: true,
                        cancelTitle: "Cancel",
                        actionHandler: () => {
                            this.handleDeleteDialogYes();
                        },
                    });
            },
        );
    };
}
