import {Endpoints, getEndpoint} from "./endpoints";
import {BaseApi} from "./baseApi";
import {IRequestFor} from "../components/Search/SearchList/SearchList";

export interface ILocation {
    latitude: number;
    longitude: number;
    level: string;
    isNew?: boolean;
    isDelete?: boolean;
}

export interface ITerm {
    label: string;
    posTag: string;
    location: ILocation[];
    nets: any[];
    inserted_by: string;
    updated_by: string;
    inserted_at: string;
    updated_at: string;
    lang: string;
    lemma: string;
    synonym: string;
    reqFor?: IRequestFor;
    isSynonym?: boolean;
}

export interface ITermQuery {
    label: string;
    lang: string;
    posTag: string;
    lemma?: string;
    lemmaPosTag?: string;
    synonym?: string;
    isSynonym?: boolean;
}

class TermApi extends BaseApi {
    Endpoint = Endpoints.Term;

    getTerm(props: ITermQuery) {
        const res = getEndpoint(this.Endpoint, "")
            .query(props)
            .get()
            .res();

        return res;
    }

}

export function getTermApiObj() {
    return new TermApi();
}
