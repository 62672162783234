import * as React from "react";
import {PopupElement, PopupType} from "../../ducks/popup/popup";
import styles from "./PopupDialog.module.scss";

export interface PopupDialogStateProps {
    popups: PopupElement[];
}

export interface PopupDialogDispatchProps {
    onPopupDelete: (id: string) => void;
}

export type PopupDialogProps = PopupDialogStateProps & PopupDialogDispatchProps;

export class PopupDialog extends React.Component<PopupDialogProps> {
    public render() {
        const popups = this.props.popups;

        return popups.length ? (
            <div className={styles.popupBack}>
                {popups.map((popupElem, index) => {
                    const actionHandler = () => {
                        if (popupElem.actionHandler) popupElem.actionHandler();
                        this.props.onPopupDelete(popupElem.id!);
                    };

                    const cancelHandler = () => {
                        if (popupElem.cancelHandler) popupElem.cancelHandler();
                        this.props.onPopupDelete(popupElem.id!);
                    };

                    const actionTitle = popupElem.actionTitle ? popupElem.actionTitle : "Хорошо";
                    const cancelTitle = popupElem.cancelTitle ? popupElem.cancelTitle : "Отмена";

                    let output = <></>;

                    switch (popupElem.type) {
                        case PopupType.POPUP_MESSAGE:
                            output = (
                                <>
                                    <div className={styles.messageContainer}>
                                        {popupElem.data.map(splitElem => {
                                            return (
                                                <div key={Math.random()} className={styles.message}>
                                                    {splitElem}
                                                </div>
                                            );
                                        })}
                                    </div>
                                    {(popupElem.actionVisible || popupElem.cancelVisible) && (
                                        <div className={styles.buttonContainer}>
                                            {popupElem.actionVisible &&
                                                <button className={styles.buttonAction} onClick={actionHandler}>
                                                    {actionTitle}
                                                </button>}
                                            {popupElem.cancelVisible && (
                                                <button className={styles.buttonCancel} onClick={cancelHandler}>
                                                    {cancelTitle}
                                                </button>
                                            )}
                                        </div>
                                    )}
                                </>
                            );
                            break;
                        case PopupType.POPUP_POSTAG:
                            output = (
                                <>
                                    <div className={styles.messageContainer}>
                                        <span className={styles.posTagTitle}>
                                            WARNING
                                        </span>
                                        <span className={styles.posTagText}>
                                            Editing the POS tag of
                                        </span>
                                        <span className={styles.posTagValue}>
                                            {popupElem.dataPosTag!.label}
                                        </span>
                                        <span className={styles.posTagText}>
                                            will update POS tags of all following linked terms:
                                        </span>
                                        {popupElem.dataPosTag!.data.map(({ posTag, label }) => {
                                            return (
                                                <div key={Math.random()} className={styles.message}>
                                                    <span
                                                        className={styles.posTagValue}>{label}&#160;&#160;&#160;&#160;{posTag}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    {(popupElem.actionVisible || popupElem.cancelVisible) && (
                                        <div className={styles.buttonContainer}>
                                            {popupElem.actionVisible &&
                                                <button className={styles.buttonAction} onClick={actionHandler}>
                                                    {actionTitle}
                                                </button>}
                                            {popupElem.cancelVisible && (
                                                <button className={styles.buttonCancel} onClick={cancelHandler}>
                                                    {cancelTitle}
                                                </button>
                                            )}
                                        </div>
                                    )}
                                </>
                            );
                            break;
                        case PopupType.POPUP_ERROR_LABEL:
                            let replaceRow = "";
                            if(popupElem.dataPosTag && popupElem.dataPosTag.errorText && popupElem.dataPosTag!.replace){
                                const {label, posTag } = popupElem.dataPosTag.data[0];
                                //@ts-ignore
                                replaceRow = popupElem.dataPosTag.errorText.split(" ").map(elem => {
                                    if(elem === `'${label}'`) return <span className={styles.posTagValue}>{`${label} `}</span>
                                    if(elem === `'${posTag}'`) return <span className={styles.posTagName}>{`${posTag} `}</span>
                                    return `${elem} `
                                });
                            }
                            output = (
                                <>
                                    <div className={styles.messageContainer}>
                                        <span className={styles.posTagTitle}>
                                            ERROR
                                        </span>
                                        {!popupElem.dataPosTag!.replace && popupElem.dataPosTag!.data.map(({ posTag, label }) => {
                                            return (
                                                <div key={Math.random()} className={styles.message}>
                                                    <span
                                                        className={styles.posTagValue}>{label}&#160;&#160;&#160;&#160;{posTag}</span>
                                                </div>
                                            );
                                        })}
                                        {popupElem.dataPosTag!.replace && <span className={styles.posTagText}>
                                            {replaceRow}
                                        </span>}
                                        {!popupElem.dataPosTag!.replace && <span className={styles.posTagText}>
                                            {popupElem.dataPosTag!.errorText}
                                        </span>}
                                    </div>
                                    {(popupElem.actionVisible || popupElem.cancelVisible) && (
                                        <div className={styles.buttonContainer}>
                                            {popupElem.actionVisible &&
                                                <button className={styles.buttonAction} onClick={actionHandler}>
                                                    {actionTitle}
                                                </button>}
                                            {popupElem.cancelVisible && (
                                                <button className={styles.buttonCancel} onClick={cancelHandler}>
                                                    {cancelTitle}
                                                </button>
                                            )}
                                        </div>
                                    )}
                                </>
                            );
                            break;
                        case PopupType.POPUP_DELETE_NET:
                            output = (
                                <>
                                    <div className={styles.messageContainer}>
                                        <span className={styles.posTagTitle}>
                                            WARNING
                                        </span>
                                        <span className={styles.posTagText}>
                                             Delete
                                        </span>
                                        <span className={styles.posTagValue}>
                                            {popupElem.net}
                                        </span>
                                        <span className={styles.posTagText}>
                                            for
                                        </span>
                                        <span
                                            className={styles.posTagValue}>{popupElem.label}</span>
                                    </div>
                                    {(popupElem.actionVisible || popupElem.cancelVisible) && (
                                        <div className={styles.buttonContainer}>
                                            {popupElem.actionVisible &&
                                                <button className={styles.buttonAction} onClick={actionHandler}>
                                                    {actionTitle}
                                                </button>}
                                            {popupElem.cancelVisible && (
                                                <button className={styles.buttonCancel} onClick={cancelHandler}>
                                                    {cancelTitle}
                                                </button>
                                            )}
                                        </div>
                                    )}
                                </>
                            );
                            break;
                        case PopupType.POPUP_DELETE_LOCATION:
                            output = (
                                <>
                                    <div className={styles.messageContainer}>
                                        <span className={styles.posTagTitle}>
                                            WARNING
                                        </span>
                                        <span className={styles.posTagText}>
                                            Delete
                                        </span>
                                        <span className={styles.posTagValue}>
                                            {popupElem.location!.latitude},&#160;{popupElem.location!.longitude}
                                            <br/>{popupElem.location!.level}
                                        </span>
                                        <span className={styles.posTagText}>
                                            for
                                        </span>
                                        <span
                                            className={styles.posTagValue}>{popupElem.label}</span>
                                    </div>
                                    {(popupElem.actionVisible || popupElem.cancelVisible) && (
                                        <div className={styles.buttonContainer}>
                                            {popupElem.actionVisible &&
                                                <button className={styles.buttonAction} onClick={actionHandler}>
                                                    {actionTitle}
                                                </button>}
                                            {popupElem.cancelVisible && (
                                                <button className={styles.buttonCancel} onClick={cancelHandler}>
                                                    {cancelTitle}
                                                </button>
                                            )}
                                        </div>
                                    )}
                                </>
                            );
                            break;
                        case PopupType.POPUP_POSTAG_PROPER_NOUN:
                            output = (
                                <>
                                    <div className={styles.messageContainer}>
                                        <span className={styles.posTagTitle}>
                                            WARNING
                                        </span>
                                        <span className={styles.posTagText}>
                                            You've created a new <span className={styles.posTagValue}>
                                            proper noun
                                        </span> synonym
                                        </span>
                                        {popupElem.dataPosTag!.data.map(({ posTag, label }) => {
                                            return (
                                                <div key={Math.random()} className={styles.message}>
                                                    <span
                                                        className={styles.posTagValue}>{label}&#160;&#160;&#160;&#160;{posTag}</span>
                                                </div>
                                            );
                                        })}
                                        <span className={styles.posTagText}>
                                           please appoint a NET to it
                                        </span>
                                    </div>
                                    {(popupElem.actionVisible || popupElem.cancelVisible) && (
                                        <div className={styles.buttonContainer}>
                                            {popupElem.actionVisible &&
                                                <button className={styles.buttonAction} onClick={actionHandler}>
                                                    {actionTitle}
                                                </button>}
                                            {popupElem.cancelVisible && (
                                                <button className={styles.buttonCancel} onClick={cancelHandler}>
                                                    {cancelTitle}
                                                </button>
                                            )}
                                        </div>
                                    )}
                                </>
                            );
                            break;
                        case PopupType.POPUP_POSTAG_ERROR:
                            output = (
                                <>
                                    <div className={styles.messageContainer}>
                                        <span className={styles.posTagTitle}>
                                            ERROR
                                        </span>
                                        <span className={styles.posTagText}>
                                            Editing the POS tag of
                                        </span>
                                        {popupElem.dataPosTag!.data.map(({ posTag, label }) => {
                                            return (
                                                <div key={Math.random()} className={styles.message}>
                                                    <span
                                                        className={styles.posTagValue}>{label}&#160;&#160;&#160;&#160;{posTag}</span>
                                                </div>
                                            );
                                        })}
                                        <span className={styles.posTagText}>
                                            {popupElem.dataPosTag!.errorText}
                                        </span>

                                    </div>
                                    {(popupElem.actionVisible || popupElem.cancelVisible) && (
                                        <div className={styles.buttonContainer}>
                                            {popupElem.actionVisible &&
                                                <button className={styles.buttonAction} onClick={actionHandler}>
                                                    {actionTitle}
                                                </button>}
                                            {popupElem.cancelVisible && (
                                                <button className={styles.buttonCancel} onClick={cancelHandler}>
                                                    {cancelTitle}
                                                </button>
                                            )}
                                        </div>
                                    )}
                                </>
                            );
                            break;
                        case PopupType.POPUP_VALIDATE_ERRORS:
                            output = (
                                <>
                                    <div className={styles.errorsContainer}>
                                        {popupElem.data.map(splitElem => {
                                            return (
                                                <div key={Math.random()} className={styles.error}>
                                                    {splitElem}
                                                </div>
                                            );
                                        })}
                                    </div>
                                    {popupElem.actionVisible && (
                                        <div className={styles.buttonContainer}>
                                            <button className={styles.buttonAction} onClick={actionHandler}>
                                                {actionTitle}
                                            </button>
                                        </div>
                                    )}
                                </>
                            );
                            break;
                    }

                    return (
                        <div key={popupElem.id} className={styles.popupContainer}>
                            {output}
                        </div>
                    );
                })}
            </div>
        ) : (
            <></>
        );
    }
}
