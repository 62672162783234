import React from 'react';
import styles from './SearchPage.module.scss';
import {PopupDialogConnected} from "../../components/PopupDialog/PopupDialogConnected";
import {SearchConnected} from "../../components/Search/SearchConnected";
import {ILemmaSearchQueryProps} from "../../api/lemmaApi";
import {ITerm} from "../../api/termApi";
import {ILemmaSearchField} from "../../ducks/entries/entries";
import {PopupLocationConnected} from "../../components/PopupLocation/PopupLocationConnected";
import {ThemeProvider} from "@material-ui/styles";
import {theme} from "../../helper";

export interface ISearchPageStateProps {

}

export interface ISearchPageDispatchProps {
    onLanguagesQuery: (unique?: boolean) => void;
    onPosTagsQuery: () => void;
    onPosTagsSlaQuery: () => void;
    getLevels: () => void;
    getSearchByUrl: () => void;
    onSearchQuery: (props: ILemmaSearchQueryProps) => void;
    setDetailedTerm: (item: ITerm | undefined) => void;
    setDetailedRow: (item: ILemmaSearchField | undefined) => void;
    onNetQuery: () => void;
}

type SearchPageProps = ISearchPageDispatchProps & ISearchPageStateProps;

export default class SearchPage extends React.PureComponent<SearchPageProps, any> {

    componentDidMount() {
        this.props.getSearchByUrl();
        this.props.onLanguagesQuery();
        this.props.onPosTagsQuery();
        this.props.onNetQuery();
        this.props.getLevels();
        this.props.onPosTagsSlaQuery();
        this.props.setDetailedTerm(undefined);
        this.props.setDetailedRow(undefined);
    }

    render() {
        return  <ThemeProvider theme={theme}> <div className={styles.container}>
            <PopupLocationConnected/>
            <PopupDialogConnected />
            <SearchConnected/>
        </div> </ThemeProvider>;
    }

}
