import React from "react";
import {Provider} from "react-redux";
import {Route} from "react-router-dom";
import {ConnectedRouter} from "connected-react-router";
import {history} from "../ducks/rootReducer";

import {LoginPage} from "../pages/login/LoginPage";
import {PrivateRouteConnected} from "./PrivateRoute";

import {getApiUtils, initApiUtils} from "../api/utils";
import {store} from "../store";
import styles from "./app.module.scss";
import {NewEntriesPage} from "../pages/newEntries/NewEntriesPage";
import {UploadPage} from "../pages/upload/UploadPage";
import {BacklogPage} from "../pages/backlog/BacklogPage";
import {UsersPage} from "../pages/users/UsersPage";
import {UserEditorPage} from "../pages/userEditor/UserEditorPage";
import {TopNavBarConnected} from "../components/TopNavBar/TopNavBarConnected";
import {UserRole} from "../api/userApi";
import {BacklogAppPage} from "../pages/backlogApp/BacklogAppPage";
import {AuthState, refresh} from "../ducks/auth/auth";
import {checkForTokenUpdate, defineDifferenceBetweenTokenTimeIsNotPositive} from "../helper/others";
import {ActionPageConnected} from "../pages/action/ActionPageConnected";
import {SearchPageConnected} from "../pages/search/SearchPageConnected";

// @ts-ignore
initApiUtils(store.dispatch, store.getState);

const getAuthFromStorage = (): AuthState => {
    let authState;
    try {
        authState = JSON.parse(window.localStorage.getItem("authState") || '{"id": 0}');
    } catch (e) {
        authState = JSON.parse('{"id": 0}');
    }

    return authState;
};

const auth = getAuthFromStorage();
if (auth.id !== 0 && auth.token) {
    if (defineDifferenceBetweenTokenTimeIsNotPositive(auth.token.exp)) {
        getApiUtils().dispatch(refresh());
    } else {
        setInterval(async () => {
            //@ts-ignore
            await checkForTokenUpdate();
        }, 3000);
    }
}

export function App() {
    return (
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <div className={styles.pageContainer}>
                    <TopNavBarConnected />
                    <div className={styles.main}>
                        <Route path="/login" component={LoginPage} />
                        <PrivateRouteConnected path="/" exact component={ActionPageConnected} />
                        <PrivateRouteConnected path="/upload" exact component={UploadPage} />
                        <PrivateRouteConnected path="/search" component={SearchPageConnected} />
                        <PrivateRouteConnected path="/new-entries" exact component={NewEntriesPage} />
                        <PrivateRouteConnected path="/backlog" exact component={BacklogPage} />
                        <PrivateRouteConnected path="/external-app" exact component={BacklogAppPage} />
                        <PrivateRouteConnected path="/users" exact component={UsersPage} role={UserRole.ROLE_ADMIN} />
                        <PrivateRouteConnected
                            path="/users/edit"
                            exact
                            component={UserEditorPage}
                            role={UserRole.ROLE_ADMIN}
                        />
                    </div>
                </div>
            </ConnectedRouter>
        </Provider>
    );
}
