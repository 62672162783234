import {EntryRow} from "./EntryRow";
import React from "react";

export class EntryRowManual extends EntryRow {
    render() {
        const didMountFreeze = true;

        return <EntryRow autocompleteLemma={false} didMountFreeze={didMountFreeze} {...this.props} entryRowWidth={this.props.entryRowWidth} />;
    }
}
