import {connect, MapDispatchToProps, MapStateToProps} from "react-redux";

import {
    AdditionalInformationEditor,
    DEFAULT_EDITABLE_TERM,
    IAdditionalInformationEditorDispatchProps,
    IAdditionalInformationEditorStateProps,
} from "./AdditionalInformationEditor";
import {State} from "../../../ducks/rootReducer";
import {selectEntries, selectUserRole} from "../../../selectors/selectors";
import {
    AdditionalInformationStage,
    checkDelete,
    editTerm,
    fetchCluster,
    setAdditionalInformationStage,
    setEditableTerm,
    setLocationPopupHandler,
    setLocationPopupOpen,
} from "../../../ducks/entries/entries";
import {popupPush} from "../../../ducks/popup/popup";

const mapStateToProps: MapStateToProps<IAdditionalInformationEditorStateProps, {}, State> = state => {
    const props: IAdditionalInformationEditorStateProps = {
        editableTerm: { ...DEFAULT_EDITABLE_TERM },
        detailedTerm: { ...DEFAULT_EDITABLE_TERM },
        posTags: [],
        role: undefined,
        stage: AdditionalInformationStage.read,
        detailedRow: undefined,
        nets: [],
    };
    const role = selectUserRole(state);
    props.role = role;
    const { detailedRow, additionalInfoStage, netsSla, editableTerm, detailedTerm, posTags } = selectEntries(state);
    if (editableTerm) {
        props.editableTerm = {...editableTerm};
    }
    if (detailedTerm) {
        props.detailedTerm = {...detailedTerm};
    }
    if (detailedRow) {
        props.detailedRow = detailedRow;
    }
    props.stage = additionalInfoStage;

    if (posTags && posTags.length > 0) {
        props.posTags = posTags.filter(elem => elem).map(elem => elem.name);
    }
    if (netsSla && netsSla.length > 0) {
        props.nets = netsSla.filter(elem => elem);

    }

    return {
        ...props,
    };
};

const dispatchProps: MapDispatchToProps<IAdditionalInformationEditorDispatchProps, {}> = {
    checkDelete,
    setAdditionalInformationStage,
    setLocationPopupHandler,
    setEditableTerm,
    editTerm,
    setLocationPopupOpen,
    fetchCluster,
    onPopupPush: popupPush,
};

export const AdditionalInformationEditorConnected = connect(
    mapStateToProps,
    dispatchProps,
)(AdditionalInformationEditor);
