import {Endpoints} from "./endpoints";
import {BaseApi} from "./baseApi";

export interface UserApp {
    name: string;
}

class UserAppApi extends BaseApi {
    Endpoint = Endpoints.AppUser;
}

export function getUserAppApiObj() {
    return new UserAppApi();
}
