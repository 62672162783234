import {Endpoints} from "./endpoints";
import {BaseApi} from "./baseApi";

export interface Language {
    code: string;
    name: string;
}

export const LANGUAGE_UNDEFINED: Language = { code: "UNDEFINED", name: "" };

class LanguageApi extends BaseApi {
    Endpoint = Endpoints.Language;
}

export function getLanguageApiObj() {
    return new LanguageApi();
}
