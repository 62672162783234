import {connect, MapDispatchToProps, MapStateToProps} from "react-redux";
import {State} from "../../ducks/rootReducer";
import SearchPage, {ISearchPageDispatchProps, ISearchPageStateProps} from "./SearchPage";
import {
    getLanguagesForEntryEditor,
    getLemmaSearch,
    getLevels,
    getSearchByUrl,
    netSlaQuery,
    posTagsQuery,
    posTagsSlaQuery,
    setDetailedRow,
    setDetailedTerm,
} from "../../ducks/entries/entries";

const mapStateToProps: MapStateToProps<ISearchPageStateProps, {}, State> = state => {
    return {
    };
};

const dispatchProps: MapDispatchToProps<ISearchPageDispatchProps, {}> = {
    onLanguagesQuery: getLanguagesForEntryEditor,
    onPosTagsQuery: posTagsQuery,
    onSearchQuery: getLemmaSearch,
    setDetailedTerm,
    setDetailedRow,
    getSearchByUrl,
    onNetQuery: netSlaQuery,
    onPosTagsSlaQuery: posTagsSlaQuery,
    getLevels,
};

export const SearchPageConnected = connect(
    mapStateToProps,
    dispatchProps,
)(SearchPage);
