import {Thunk, TypedAction} from "../types";
import {getEntryApiObj, IUploadFile} from "../../api/entryApi";
import {WretcherError} from "wretch";

export const SET_UPLOAD_FILE = "UPLOAD/SET_UPLOAD_FILE";
export const SET_UPLOAD_STATUS = "UPLOAD/SET_UPLOAD_STATUS";
export const SET_UPLOAD_RESULT = "UPLOAD/SET_UPLOAD_RESULT";
export const SET_UPLOAD_RESULT_MESSAGE = "UPLOAD/SET_UPLOAD_MESSAGE";

export type UploadActions =
    | TypedAction<typeof SET_UPLOAD_RESULT_MESSAGE, string>
    | TypedAction<typeof SET_UPLOAD_FILE, boolean>
    | TypedAction<typeof SET_UPLOAD_STATUS, UploadStatus>
    | TypedAction<typeof SET_UPLOAD_RESULT, UploadResult>;

interface UploadResultRow {
    row: number;
    messages: string[];
}

export type UploadResult = {
    data: Array<Object>;
    error: Array<UploadResultRow>;
};

export enum UploadStatus {
    BEFORE = "BEFORE",
    SENDING = "SENDING",
    AFTER_SUCCESS = "AFTER_SUCCESS",
    AFTER_UPLOAD_ERROR = "AFTER_UPLOAD_ERROR",
    AFTER_OTHER_ERROR = "AFTER_OTHER_ERROR",
}

export enum CSVSeparators {
    SEMICOLON = ";",
    COMMA = ",",
}

export interface UploadState {
    resultMessage: string | undefined;
    file: File | null;
    uploadStatus: UploadStatus;
    uploadResult: UploadResult | undefined;
}

export const initialState = {
    file: null,
    uploadStatus: UploadStatus.BEFORE,
    uploadResult: undefined,
    resultMessage: undefined,
};

export function fileSet(file: File | null): Thunk {
    return dispatch => {
        dispatch({ type: SET_UPLOAD_FILE, payload: file });
        dispatch({ type: SET_UPLOAD_STATUS, payload: UploadStatus.BEFORE });
        dispatch({ type: SET_UPLOAD_RESULT, payload: undefined });
        dispatch({ type: SET_UPLOAD_RESULT_MESSAGE, payload: undefined });
    };
}

export function statusSet(status: UploadStatus): Thunk {
    return dispatch => {
        dispatch({ type: SET_UPLOAD_STATUS, payload: status });
    };
}

export function resultSet(res: UploadResult): Thunk {
    return dispatch => {
        dispatch({ type: SET_UPLOAD_RESULT, payload: res });
    };
}

export function fileUpload(file: IUploadFile): Thunk {
    return async dispatch => {
        dispatch({ type: SET_UPLOAD_STATUS, payload: UploadStatus.SENDING });
        dispatch({ type: SET_UPLOAD_RESULT_MESSAGE, payload: "" });

        const response = await getEntryApiObj().file(file);
        if (response && response.status === 200) {
            const result: UploadResult = await response.json();

            if (result && result.data) {
                dispatch({ type: SET_UPLOAD_RESULT, payload: result });
                if (result.error && result.error.length > 0) {
                    dispatch({ type: SET_UPLOAD_RESULT_MESSAGE, payload: "Data sending completely canceled." });
                    dispatch({ type: SET_UPLOAD_STATUS, payload: UploadStatus.AFTER_UPLOAD_ERROR });
                } else {
                    dispatch({ type: SET_UPLOAD_RESULT_MESSAGE, payload: "Data processed successfully" });
                    dispatch({ type: SET_UPLOAD_STATUS, payload: UploadStatus.AFTER_SUCCESS });
                }
            }
        }
    };
}

export function fileUploadOtherError(err: WretcherError): Thunk {
    return async dispatch => {
        let errorMessage: string = err.text ? JSON.parse(err.text).message : "";
        if (errorMessage.indexOf("generalMessage")) {
            errorMessage = JSON.parse(errorMessage).generalMessage;
        }
        dispatch({ type: SET_UPLOAD_RESULT, payload: undefined });
        dispatch({ type: SET_UPLOAD_STATUS, payload: UploadStatus.AFTER_OTHER_ERROR });
        dispatch({
            type: SET_UPLOAD_RESULT_MESSAGE,
            payload: errorMessage,
        });
    };
}

export function uploadReducer(state: UploadState = initialState, action: UploadActions): UploadState {
    switch (action.type) {
        case SET_UPLOAD_FILE:
            return {
                ...state,
                file: action.payload,
            };
        case SET_UPLOAD_RESULT_MESSAGE:
            return {
                ...state,
                resultMessage: action.payload,
            };
        case SET_UPLOAD_STATUS:
            return {
                ...state,
                uploadStatus: action.payload,
            };
        case SET_UPLOAD_RESULT:
            return {
                ...state,
                uploadResult: action.payload,
            };
        default:
            return state;
    }
}
