import React from "react";

import styles from "../EntryList.module.scss";
import stylesFilter from "../EntryFilter/EntryFilter.module.scss";
import {Button, Checkbox, Icon} from "@material-ui/core";
import {EntryRowManual} from "../EntryRow/EntryRowManual";
import {Language, LANGUAGE_UNDEFINED} from "../../../api/languageApi";
import {Entry, EntryPush} from "../../../api/entryApi";
import {POS_TAG_PROPER_NOUN, POS_TAG_UNDEFINED, PosTag} from "../../../api/posTagApi";
import {Net, NET_UNDEFINED} from "../../../api/netApi";
import {User, UserRole} from "../../../api/userApi";
import {EntryNotifyPanelConnected} from "../EntryNotify/EntryNotifyPanelConnected";
import {
    ENTRIES_NOTIFY_SET,
    ENTRY_NOTIFY_INITIAL,
    EntryNotify,
    EntryNotifyStatus,
    EntryNotifyType,
    filteredNet,
    IEntryDetailedNotify,
    ITermFromPos,
    PushResult,
    spellingByLemmaPosQuery,
} from "../../../ducks/entries/entries";
import {getApiUtils} from "../../../api/utils";
import {UploadStatus} from "../../../ducks/upload/upload";
import {formatDate} from "../../../helper";
import {PopupElement, PopupType} from "../../../ducks/popup/popup";
import {UserApp} from "../../../api/userAppApi";
import {ELEMENT_MANUAL_WIDTH} from "../../../helper/screenConsts";
import Loader from "react-loader-spinner";
import {getIsCheckAllActive} from "../../../helper/others";

export interface EntryListManualStateProps {
    entries: Entry[];
    previousEntries: Entry[];
    userId: number;
    userRole: UserRole | undefined;
    users: User[] | UserApp[];
    languages: Language[];
    nets: Net[];
    posTags: PosTag[];
    pushResult: PushResult[];
    spinnerStatus: number;
    filteredNets: filteredNet[];
    notify: IEntryDetailedNotify;
}

export interface EntryListManualDispatchProps {
    onLanguagesQuery: () => void;
    onPosTagsQuery: () => void;
    onNetQuery: () => void;
    onPopupPush: (popup: PopupElement) => void;
    onEntryValidate: (entries: EntryPush[], success: () => void, fail: (errors: string[]) => void, isManual?: boolean) => void;
    onEntryPushToBacklog: (entries: EntryPush[], success: () => void) => void;
    onSetEntries: (entries: Entry[], previousEntries?: Entry[]) => void;
    onEntryDelete: (id: number) => void;
    onSetNets: (nets: filteredNet[] | []) => void;
}

type EntryListManualProps = EntryListManualStateProps & EntryListManualDispatchProps;

export enum RemovalCandidateType {
    REMOVE_SELECTED_ENTRIES,
    REMOVE_ONE_SPELLING,
}

interface RemovalCandidate {
    type: RemovalCandidateType;
    id?: number;
    created?: boolean;
    spellingIndex?: number;
}

export interface EntryListManualState {
    deleteDialogShow: boolean;
    removalCandidate: RemovalCandidate | undefined;
    uploadStatus: UploadStatus;
}

export class EntryListManual extends React.PureComponent<EntryListManualProps, EntryListManualState> {
    constructor(props: EntryListManualProps) {
        super(props);

        this.state = {
            deleteDialogShow: false,
            removalCandidate: undefined,
            uploadStatus: UploadStatus.BEFORE,
        };
    }

    componentDidMount(): void {
        this.props.onLanguagesQuery();
        this.props.onNetQuery();
        this.props.onPosTagsQuery();
    }

    render() {
        const { userRole, languages, nets, posTags, spinnerStatus, filteredNets, previousEntries} = this.props;
        const {entries} = this.props;
        const isChecked = getIsCheckAllActive(entries);

        const visibleAddButton = languages.length && nets.length && posTags.length;
        const permissionToDeleteSelected = entries.filter(entry => entry.check).length > 0;
        const permissionToPush = permissionToDeleteSelected && !this.errorCheck() && !this.errorNotificationsCheck();
        const permissionToPut = permissionToDeleteSelected;
        const visibleLoader = spinnerStatus > 0;

        const pushButtonTitle =
            userRole === UserRole.ROLE_BU_USER || userRole === UserRole.ROLE_ANNOTATOR
                ? "Create"
                : "Validate and create";

        const entryRows = entries.map((entry, entryIndex) => {
            let userName = "";
            let date = "";

            const stringDate = date ? date.split("T")[0] : "";

            return (
                <EntryRowManual
                    previousNetEmpty={previousEntries[entryIndex] && previousEntries[entryIndex].nets.code === NET_UNDEFINED.code}
                    autocompleteSynonym={false}
                    autocompleteLemma={false}
                    index={entryIndex}
                    didMountFreeze={undefined}
                    filteredNets={filteredNets}
                    entryRowWidth={ELEMENT_MANUAL_WIDTH}
                    key={entry.id}
                    entryUserName={userName}
                    entryDate={stringDate}
                    languages={languages}
                    nets={nets}
                    posTags={posTags}
                    entry={entry}
                    onEntryDataChange={this.dataChangeInEntry}
                    onEntryRowSpellingChange={this.spellingChange}
                    onEntryRowSpellingAdd={this.spellingAdd}
                    onEntryRowSpellingDelete={this.spellingDelete}
                    onEntryCheck={this.entryCheck}
                    onEntryAddNotify={this.notifyAdd}
                    onEntryNotifyExist={this.notifyExist}
                    onEntryGetHighNotify={this.getHighNotify}
                    onEntryNotifyShow={this.notifyShow}
                    onEntryNotifyClear={this.notifyClear}
                    onEntryOverlapCheck={this.entryOverlapCheck}
                    onEntryClearAutocompleteNotify={this.entryClearAutocompleteNotify}
                    onSetNets={this.props.onSetNets}
                    notify={this.props.notify}
                />
            );
        });

        return (
            <div className={styles.container}>
                <div style={{display: visibleLoader ? "flex" : "none"}} className={styles.loaderContainer}>
                    <Loader type="Oval" color="#ff4646" height={100} width={100} visible={visibleLoader}/>
                </div>
                <div className={`${styles.editorContainer} ${styles.editorContainerManualHeight}`}>
                    <div className={styles.entriesContainer} onClick={this.notifyClear}>
                        <div className={stylesFilter.filterContainer}>
                            <div className={stylesFilter.containerStart}>
                                <div className={stylesFilter.subContainerOther}>
                                    <Button
                                        disabled={!visibleAddButton}
                                        variant="outlined"
                                        className={stylesFilter.addButton}
                                        color="primary"
                                        onClick={() => this.entryAdd()}
                                    >
                                        ADD ENTRY <Icon className={stylesFilter.plusIcon}/>
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className={styles.entryRowsListContainer}>
                            <div className={styles.entriesRowsFullContainer}>
                                <div className={`${styles.entriesHeader} ${styles.entriesHeaderManual}`}>
                                    <Checkbox
                                        disabled={this.props.entries.length === 0}
                                        className={`${styles.selectAllCheckbox} ${isChecked && styles.checked}`}
                                        checked={isChecked}
                                        onChange={this.entryAllCheck}
                                    />
                                    <div style={ELEMENT_MANUAL_WIDTH.language}>Language</div>
                                    <div>Spellings</div>
                                    <div>Lemma</div>
                                    <div style={ELEMENT_MANUAL_WIDTH.selectors}>Part of Speech</div>
                                    <div>Synonym</div>
                                    <div style={ELEMENT_MANUAL_WIDTH.selectors}>NET</div>
                                </div>
                                <div className={styles.entriesRowsContainer}>{entryRows}</div>
                            </div>
                            <EntryNotifyPanelConnected/>
                        </div>
                        <div className={`${styles.buttonsContainer} ${styles.buttonsManualListPadding}`}>
                            <Button
                                disabled={!permissionToDeleteSelected}
                                onClick={this.entryDelete}
                                className={`${styles.deleteSelectedButton} ${!permissionToPut && styles.buttonDisabled}`}
                                variant="contained"
                            >
                                {"Delete selected"}
                            </Button>
                            <Button
                                disabled={!permissionToPush}
                                onClick={this.pushToBacklog}
                                className={`${styles.uploadCheckButton} ${!permissionToPush && styles.buttonDisabled}`}
                                variant="contained"
                            >
                                {pushButtonTitle}
                            </Button>
                        </div>

                    </div>

                </div>
            </div>
        );
    }

    protected static permissionToNewRecord(newEntries: Entry[]) {
        const preFilter = newEntries.filter(entry => {
            return (
                entry.spelling.length === 1 &&
                entry.spelling[0] === "" &&
                entry.lemma === "" &&
                entry.posTag.code === "UNDEFINED"
            );
        });

        return preFilter.length === 0;
    }

    entryAdd = () => {
        const newEntries = [...this.props.entries];
        this.props.onSetNets([]);

        let newEntry: Entry = {
            id: 0,
            check: false,
            created: true,
            language: this.props.languages[0],
            spelling: [""],
            lemma: "",
            posTag: POS_TAG_UNDEFINED,
            synonym: "",
            itSelf: true,
            nets: NET_UNDEFINED,
            notifications: [],
            date: formatDate(Date.now()),
            userId: this.props.userId,
            source_app: undefined,
            date_app: undefined,
            user_app: undefined,
            account_app: undefined,
            objectid_app: undefined,
        };

        newEntry.id = Date.now();
        newEntries.unshift(newEntry);

        this.props.onSetEntries(newEntries);
    };

    pushToBacklog = () => {
        const entriesChecked: EntryPush[] = [];

        this.props.entries.forEach((entry, index) => {
            if (entry.check) {
                entriesChecked.push({
                    id: entry.id,
                    created: entry.created,
                    index,
                    data: {
                        language: entry.language,
                        spelling: entry.spelling,
                        lemma: entry.lemma,
                        synonym: entry.synonym,
                        posTag: entry.posTag,
                        itSelf: entry.itSelf,
                        netTag: entry.nets,
                        sendData: true,
                    },
                });
            }
        });

        this.props.onEntryValidate(
            entriesChecked,
            () => {
                this.props.onEntryPushToBacklog(entriesChecked, () => {
                    let newEntries: Entry[] = this.props.entries.filter(entry => !entry.check);
                    this.props.onSetEntries(newEntries);
                });
            },
            errors => {
                this.props.onPopupPush({
                    id: new Date().getTime().toString(),
                    type: PopupType.POPUP_VALIDATE_ERRORS,
                    data: ["Errors detected:", ...errors],
                    actionTitle: "Ok",
                    actionVisible: true,
                    cancelVisible: false,
                });
            },
            true
        );
    };

    saveEntriesToBacklog = (): void => {
        const entriesChecked: EntryPush[] = [];
        this.props.entries.forEach((entry, index) => {
            if (entry.check) {
                entriesChecked.push({
                    id: entry.id,
                    created: entry.created,
                    index,
                    data: {
                        language: entry.language,
                        spelling: entry.spelling,
                        lemma: entry.lemma,
                        synonym: entry.synonym,
                        posTag: entry.posTag,
                        itSelf: entry.itSelf,
                        netTag: entry.nets,
                        sendData: false,
                    },
                });
            }
        });

        this.props.onEntryPushToBacklog(entriesChecked, () => {
        });
    };

    errorCheck = (): boolean => {
        let result = false;
        const entries = this.props.entries;

        for (let i = 0; i < entries.length && !result; i++) {
            if (entries[i].check) {
                if (
                    entries[i].language.code === LANGUAGE_UNDEFINED.code ||
                    entries[i].lemma.trim() === "" ||
                    entries[i].synonym.trim() === "" ||
                    entries[i].posTag.code === POS_TAG_UNDEFINED.code ||
                    (entries[i].posTag.code === POS_TAG_PROPER_NOUN.code && entries[i].nets.code === NET_UNDEFINED.code)
                ) {
                    result = true;
                    break;
                }

                for (let j = 0; j < entries[i].spelling.length && !result; j++) {
                    if (entries[i].spelling[j] && entries[i].spelling[j].trim() === "") {
                        result = true;
                        break;
                    }
                }
            }
        }

        return result;
    };

    errorNotificationsCheck = (): boolean => {
        let result = false;
        const entries = this.props.entries;

        for (let i = 0; i < entries.length && !result; i++) {
            if (entries[i].check) {
                for (let j = 0; j < entries[i].notifications.length && !result; j++) {
                    if (entries[i].notifications[j].status === EntryNotifyStatus.NOTIFY_STATUS_ERROR) {
                        result = true;
                        break;
                    }
                }
            }
        }

        return result;
    };

    entryOverlapCheck = (id: number) => {
        const entry = this.getEntryById(id);

        if (entry) {
            entry.notifications = entry.notifications.filter(
                notify => notify.type !== EntryNotifyType.ENTRY_NOTIFY_SPELLING_OVERLAP,
            );
            this.props.onSetEntries([...this.props.entries]);

            const lemma = entry.lemma;
            if (lemma.trim() !== "") {
                spellingByLemmaPosQuery(lemma, entry.posTag.name, entry.language.code).then((res: ITermFromPos[]) => {
                    const spellingByLemmaPosList = res.map(elem => elem.label.toLowerCase());
                    for (let i = 0; i < entry.spelling.length; i++) {
                        const spelling = entry.spelling[i];
                        if (spellingByLemmaPosList.includes(lemma.trim().toLowerCase())) {
                            this.notifyAdd(id, {
                                type: EntryNotifyType.ENTRY_NOTIFY_LEMMA_AUTOCOMPLETE,
                                status: EntryNotifyStatus.NOTIFY_STATUS_WARNING,
                                object: lemma,
                                list: res.map(elem => elem.label),
                                insertItem: "",
                            });
                        }
                        if (spellingByLemmaPosList.includes(spelling.trim().toLowerCase())) {
                            this.notifyAdd(id, {
                                type: EntryNotifyType.ENTRY_NOTIFY_SPELLING_OVERLAP,
                                status: EntryNotifyStatus.NOTIFY_STATUS_ERROR,
                                object: spelling,
                                list: [lemma],
                                insertItem: "",
                            });
                        }
                    }

                });
            }
        }
    };

    protected getEntryById = (id: number) => {
        const entryFiltered = this.props.entries.filter(entry => entry.id === id);
        if (!entryFiltered || entryFiltered.length === 0) return null;
        return entryFiltered[0];
    };

    protected getNotifyFromEntry = (entry: Entry | null, notifyType: EntryNotifyType, object: string | null) => {
        let filtered;
        if (entry) {
            filtered = entry.notifications.filter(notify => {
                return (
                    (object === null && notify.type === notifyType) ||
                    (!(object === null) && notify.type === notifyType && notify.object === object)
                );
            });
        }

        if (filtered !== undefined && filtered.length > 0) return filtered[0];
        else return null;
    };

    notifyShow = (
        notify: EntryNotify | null,
        id: number | null,
        type: EntryNotifyType | null,
        object: string | null,
    ) => {
        let findNotify;
        if (id && type) {
            const entry = this.getEntryById(id);
            if (entry) findNotify = this.getNotifyFromEntry(entry, type, object);
        }

        getApiUtils().dispatch({
            type: ENTRIES_NOTIFY_SET,
            payload: notify || findNotify,
        });
    };

    notifyExist = (id: number, notifyType: EntryNotifyType, object: string | null): boolean => {
        const entry = this.getEntryById(id);

        const notify = this.getNotifyFromEntry(entry, notifyType, object);
        return entry !== null && notify !== null;
    };

    getHighNotify = (id: number, notifyTypes: EntryNotifyType[], object: string): EntryNotify | null => {
        const entry = this.getEntryById(id);
        if (!entry) return null;

        let currPriority: number = -1;
        let resultNotify: EntryNotify | null = null;
        for (let i = 0; i < entry.notifications.length; i++) {
            const notify = entry.notifications[i];
            if (notifyTypes.includes(notify.type) && notify.object === object && notify.status > currPriority) {
                resultNotify = notify;
                currPriority = notify.status;
            }
        }

        return resultNotify;
    };

    entryClearAutocompleteNotify = (id: number, notifyType: EntryNotifyType) => {
        const entry = this.getEntryById(id);
        if (!entry) return;

        if (
            [
                EntryNotifyType.ENTRY_NOTIFY_LEMMA_AUTOCOMPLETE,
                EntryNotifyType.ENTRY_NOTIFY_SYNONYM_AUTOCOMPLETE,
            ].includes(notifyType)
        ) {
            for (let i = 0; i < entry.notifications.length; i++) {
                if (entry.notifications[i].type === notifyType) {
                    entry.notifications.splice(i, 1);
                }
            }
        }
    };

    notifyAdd = (id: number, notify: EntryNotify) => {
        const entry = this.getEntryById(id);
        if (!entry) return;

        this.entryClearAutocompleteNotify(id, notify.type);

        if (notify.type === EntryNotifyType.ENTRY_NOTIFY_SPELLING_EXISTS) {
            for (let i = 0; i < entry.notifications.length; i++) {
                if (entry.notifications[i].type === notify.type && entry.notifications[i].object === notify.object) {
                    entry.notifications.splice(i, 1);
                }
            }
        }

        entry.notifications.push(notify);

        this.props.onSetEntries([...this.props.entries]);
    };

    notifyClear = () => {
        const currentNotifyType = getApiUtils().getState().entries.notify.type;

        if (currentNotifyType !== EntryNotifyType.ENTRY_NOTIFY_EMPTY) {
            getApiUtils().dispatch({
                type: ENTRIES_NOTIFY_SET,
                payload: ENTRY_NOTIFY_INITIAL,
            });
        }
    };

    protected handleDeleteDialogYes = () => {
        if (this.state.removalCandidate !== undefined) {
            const removalCandidate = this.state.removalCandidate;
            const id = removalCandidate.id;
            const spellingIndex = removalCandidate.spellingIndex;
            const {entries, onEntryDelete} = this.props;

            let newEntries: Entry[];

            if (spellingIndex !== undefined) {
                newEntries = entries.map(entry => {
                    if (entry.id === id) {
                        const removeSpelling = entry.spelling[spellingIndex];
                        entry.spelling.splice(spellingIndex, 1);
                        entry.notifications = entry.notifications.filter(
                            i =>
                                !(
                                    [
                                        EntryNotifyType.ENTRY_NOTIFY_SPELLING_EXISTS,
                                        EntryNotifyType.ENTRY_NOTIFY_SPELLING_OVERLAP,
                                    ].includes(i.type) && i.object === removeSpelling
                                ),
                        );
                    }
                    return entry;
                });
            } else {
                newEntries = entries.filter(entry => {
                    return !entry.check;
                });
            }

            this.setState({
                ...this.state,
                removalCandidate: undefined,
                deleteDialogShow: false,
            });

            this.props.onSetEntries(newEntries);
        }
    };

    protected handleDeleteDialogCancel = () => {
        this.setState({
            ...this.state,
            removalCandidate: undefined,
            deleteDialogShow: false,
        });
    };

    entryDelete = () => {
        const {entries, onEntryDelete} = this.props;
        let notToShowDialog = true;
        let allSpellingsEmpty = true;

        entries.forEach(entry => {
            entry.spelling.forEach((spelling => {
                if(spelling){
                    allSpellingsEmpty = false;
                }
            }))

            if (entry.check) {
                if (!entry.created || (entry.language.code && (entry.lemma || !allSpellingsEmpty))) {
                    notToShowDialog = false;
                }
            }
        });

        this.setState(() => {
            return {
                ...this.state,
                removalCandidate: {
                    type: RemovalCandidateType.REMOVE_SELECTED_ENTRIES,
                },
            };
        }, () => {
            notToShowDialog && this.handleDeleteDialogYes();
            !notToShowDialog && this.props.onPopupPush({
                id: new Date().getTime().toString(),
                type: PopupType.POPUP_MESSAGE,
                data: ["Do you confirm removal?"],
                actionTitle: "Yes, delete",
                actionVisible: true,
                cancelVisible: true,
                cancelTitle: "Cancel",
                actionHandler: () => {
                    this.handleDeleteDialogYes();
                }
            });
        });
    };

    entryAllCheck = () => {
        const {entries, onSetEntries} = this.props;
        const isChecked = getIsCheckAllActive(entries);
        const newCheckStatus = !isChecked;

        const newEntries: Entry[] = this.props.entries.concat();
        for (let i = 0; i < newEntries.length; i++) {
            newEntries[i].check = newCheckStatus;
        }
        onSetEntries(newEntries);

        this.setState({ ...this.state });
    };

    entryCheck = (id: number) => {
        const newEntries: Entry[] = this.props.entries.concat();

        for (let i = 0; i < newEntries.length; i++) {
            const entry: Entry = newEntries[i];

            if (entry.id === id) {
                entry.check = !entry.check;
                break;
            }
        }

        this.props.onSetEntries(newEntries);
    };

    dataChangeInEntry = (id: number, field: keyof Entry, value: string | Net[] | boolean | Language | PosTag | Net) => {
        const newEntries: Entry[] = this.props.entries.concat();
        let previousEntries: Entry[] = [];

        for (let i = 0; i < newEntries.length; i++) {
            const entry: Entry = newEntries[i];

            if (entry.id === id) {
                if (Array.isArray(value)) {
                    //@ts-ignore
                    entry[field] = {code: value[0].code, name: value[0].name};
                    const filteredNets = this.props.nets.filter(elem => value.includes(elem));
                    const filteredNet: filteredNet = {
                        entryId: id,
                        nets: filteredNets,
                    };
                    this.props.onSetNets([filteredNet]);
                    if (filteredNet.nets.length === 1) {
                        this.props.onSetNets([]);
                    }
                } else {
                    //@ts-ignore
                    entry[field] = value;
                    //@ts-ignore
                    if(field === "nets" && value!.code === NET_UNDEFINED.code) {
                        previousEntries = [...newEntries]
                    }
                }
                break;
            }
        }

        this.props.onSetEntries(newEntries, previousEntries);
    };

    spellingChange = (id: number, spellingIndex: number, value: string) => {
        const newEntries = this.props.entries.map(entry => {
            if (entry.id === id) entry.spelling[spellingIndex] = value;
            return entry;
        });

        this.props.onSetEntries(newEntries);
    };

    spellingAdd = (id: number) => {
        const newEntries = this.props.entries.map(entry => {
            if (entry.id === id) entry.spelling.push("");
            return entry;
        });

        this.props.onSetEntries(newEntries);
    };

    spellingDelete = (id: number, spellingIndex: number) => {
        let notShowDeletePopup = true;
        const {entries} = this.props;
        if (spellingIndex !== undefined) {
            entries.forEach(entry => {
                if (entry.id === id) {
                    const removeSpelling = entry.spelling[spellingIndex];
                    if(removeSpelling && removeSpelling.trim() !== "") {
                        notShowDeletePopup = false;
                    }
                };
            });
        }
        this.setState(() => {
            return {
                ...this.state,
                removalCandidate: {
                    type: RemovalCandidateType.REMOVE_ONE_SPELLING,
                    id,
                    spellingIndex,
                },
            }
        }, () => {
            notShowDeletePopup && this.handleDeleteDialogYes();
            !notShowDeletePopup && this.props.onPopupPush({
                id: new Date().getTime().toString(),
                type: PopupType.POPUP_MESSAGE,
                data: ["Do you confirm removal?"],
                actionTitle: "Yes, delete",
                actionVisible: true,
                cancelVisible: true,
                cancelTitle: "Cancel",
                actionHandler: () => {
                    this.handleDeleteDialogYes();
                }
            });
        });
    };
}
