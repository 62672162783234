import {Endpoints} from "./endpoints";
import {BaseApi} from "./baseApi";
import {Language} from "./languageApi";
import {PosTag} from "./posTagApi";
import {Net} from "./netApi";
import {EntryNotify} from "../ducks/entries/entries";
import {getApiUtils} from "./utils";
import {CSVSeparators, fileUploadOtherError} from "../ducks/upload/upload";

export enum BACKLOG_TYPE {
    backlog= "BACKLOG",
    external= "BACKLOG_APP"
}

export enum OrderBy {
    Id = "id",
    CreatedAt = "createdAt",
    UserApp = "userApp",
    User = "user",
    SourceApp = "sourceApp",
    ObjectIdApp = "objectIdApp",
    Language = "lang",
    Lemma = "lemma",
    Postag = "postag",
    Net = "net",
    Synonym = "synonym",
    Undefined = "",
}

export interface ICreateExternalItem {
    source: string,
    date: string,
    user: string,
    terms: {
        spellings: string[],
        lemma: string
    },
    objectId: string,
    language: string
    posTag: PosTag;
    synonym: string;
    itSelf: boolean;
    netTag: Net;
}

export interface BacklogProps {
    user?: number | string;
    from?: string;
    to?: string;
    lang?: string;
    status?: number;
    sourceApp?: string;
    userApp?: string;
    accountApp?: string;
    objectIdApp?: string;
    limit?: number;
    offset?: number;
    orderBy?: OrderBy,
    orderByReversed?: boolean,
}

export interface IUploadFile {
    header: boolean;
    separator: CSVSeparators;
    file: File;
}

export interface EntryPush {
    id: number;
    index?: number;
    created?: boolean;
    data: {
        language: Language;
        spelling: string[];
        lemma: string;
        posTag: PosTag;
        synonym: string;
        itSelf: boolean;
        netTag: Net;
        sendData?: boolean;
        source?: string,
        objectId?: string,
        user?: string,
        account?: string,
        date?: string,
        status?: BACKLOG_TYPE;
    };
}

export interface Entry {
    id: number;
    check: boolean;
    created: boolean;
    language: Language;
    spelling: string[];
    lemma: string;
    posTag: PosTag;
    synonym: string;
    itSelf: boolean;
    nets: Net;
    notifications: EntryNotify[];
    date: string | undefined;
    userId: number | undefined;
    source_app: string | undefined;
    date_app: string | undefined;
    user_app: string | undefined;
    account_app: string | undefined;
    objectid_app: string | undefined;
}

class EntryApi extends BaseApi {
    Endpoint = Endpoints.Entry;

    async getBacklog(props: BacklogProps) {
        return await this.endpoint("")
            .query(props)
            .get()
            .res()
            .then(async r => {
                return {
                    data: await r.json(),
                    count: r.headers.get("x-total-count"),
                };
            });
    }

    async file(props: IUploadFile) {
        return await this.endpoint(`file?header=${props.header}&separator=${props.separator}`)
            .catcher(500, err => {
                getApiUtils().dispatch(fileUploadOtherError(err));
            })
            .formData({ file: props.file })
            .post()
            .res();
    }

    async validate(entry: EntryPush) {
        return await this.endpoint("validate")
            .post(entry.data)
            .res();
    }

    async entry(entry: EntryPush) {
        return await this.endpoint("")
            .post(entry.data)
            .res();
    }

    async entrySaveChanges(entry: EntryPush) {
        return await this.endpoint(`app/${entry.id}`)
            .put(entry.data)
            .res();
    }

    async updateToBack(entry: EntryPush) {
        return await this.endpoint(entry.id)
            .put(entry.data)
            .res();
    }

    async entrySaveToExternal(entry: ICreateExternalItem) {
        return await this.endpoint('appMerge')
            .post(entry)
            .res();
    }
}

export function getEntryApiObj() {
    return new EntryApi();
}
