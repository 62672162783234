import {Endpoints, getEndpoint} from "./endpoints";

interface AuthProps {
    username: string;
    password: string;
}

class AuthApi {
    Endpoint = Endpoints.Auth;

    async auth(props: AuthProps) {
        return getEndpoint(this.Endpoint)
            .post(props)
            .json();
    }

    async refresh(refreshToken: string) {
        return getEndpoint(this.Endpoint, "refresh")
            .headers({ "X-AUTH-REFRESH-TOKEN": `${refreshToken}` })
            .post()
            .json();
    }
}

export function getAuthApiObj() {
    return new AuthApi();
}
