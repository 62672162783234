import {connect, MapDispatchToProps, MapStateToProps} from "react-redux";
import {State} from "../../ducks/rootReducer";
import {Support, SupportDispatchProps, SupportStateProps} from "./Support";
import {selectSupportVisible} from "../../selectors/selectors";
import {support} from "../../ducks/support/support";

const mapStateToProps: MapStateToProps<SupportStateProps, {}, State> = state => {
    const isShow = selectSupportVisible(state);

    return {
        isShow
    };
};

const dispatchProps: MapDispatchToProps<SupportDispatchProps, {}> = {
    onSupport: support
};

export const SupportConnected = connect(
    mapStateToProps,
    dispatchProps,
)(Support);
