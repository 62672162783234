import React from "react";

import styles from "./Spelling.module.scss";
import {
    EntryNotify,
    EntryNotifyStatus,
    EntryNotifyType,
    getEntriesSpinner,
    IEntryDetailedNotify
} from "../../../../ducks/entries/entries";
import {Language} from "../../../../api/languageApi";
import Loader from "react-loader-spinner";
import {Input, MuiThemeProvider} from "@material-ui/core";
import {themeForAdditionalScreen} from "../../../../helper";
import ThemeProvider from "@material-ui/styles/ThemeProvider";

export interface SpellingStateProps {
    entryId: number;
    spelling: string[];
    disabled: boolean;
    language: Language;
}

export interface SpellingDispatchProps {
    onSpellingChange: (id: number, index: number, value: string) => void;
    onSpellingAdd: (id: number) => void;
    onSpellingDelete: (id: number, index: number) => void;
    onEntryAddNotify: (id: number, notify: EntryNotify) => void;
    onEntryNotifyExist: (id: number, notifyType: EntryNotifyType, object: string | null) => boolean;
    onEntryNotifyShow: (
        notify: IEntryDetailedNotify | null,
        id: number | null,
        type: EntryNotifyType | null,
        object: string | null,
    ) => void;
    onEntryNotifyClear: () => void;
    onEntryGetHighNotify: (id: number, notifyTypes: EntryNotifyType[], object: string) => EntryNotify | null;
    onEntryOverlapCheck: (id: number, spelling?: string | null) => void;
    onHandlerSpellingBlur: (entryId: number, newSpellings: string[]) => void;
    spellingLoader: number;
}

type SpellingProps = SpellingStateProps & SpellingDispatchProps;

export interface SpellingState {
    spelling: string[];
    spellingFocusId: string | undefined;
}

export enum SpellingBackColors {
    DISABLED = "",
    NORMAL = "#1E293B",
    WARNING = "#FFE097",
    ERROR = "#E26655",
}

export class Spelling extends React.Component<SpellingProps, SpellingState> {
    constructor(props: SpellingProps) {
        super(props);

        const {spelling} = props;

        this.state = {
            spelling,
            spellingFocusId: undefined,
        };
    }

    componentDidMount() {
        const {spelling} = this.state;
    }

    componentDidUpdate() {
        this.lastSpellingFocus();
    }

    render() {
        const {entryId, spellingLoader, disabled = false} = this.props;
        const {spelling} = this.state;
        const visibleLoader = spellingLoader > 0 || getEntriesSpinner(this.props.language);
        const disabledAdd =
            spelling.filter(spelling => spelling.trim() === "" || spelling.trim().length < 3).length !== 0;

        const spellingRows = spelling.map((spellingElem, index) => {
            let highNotify: EntryNotify | null = null;
            if (spellingElem.length > 2) {
                highNotify = this.props.onEntryGetHighNotify(
                    this.props.entryId,
                    [EntryNotifyType.ENTRY_NOTIFY_SPELLING_EXISTS, EntryNotifyType.ENTRY_NOTIFY_SPELLING_OVERLAP],
                    spellingElem,
                );
            }

            const end = <div className={styles.iconsContainer}>
                {index === spelling.length - 1 && <div
                    onClick={() => {
                        if (!disabled && !disabledAdd) this.handlerAddSpellingClick(entryId, 0);
                    }}
                    className={`${styles.iconContainerPlus} ${(disabledAdd || disabled) ? styles.disabled : ""}`}
                >
                    <div
                        className={styles.plusIcon}
                    />
                </div>}
                {spelling && spelling.length > 1 && <div
                    onClick={() => {
                        this.props.onSpellingDelete(entryId, index);
                    }}
                    className={styles.iconContainerCross}
                >
                    <div
                        className={styles.crossIcon}
                    />
                </div>}
            </div>

            let backColor = SpellingBackColors.NORMAL;
            let color = '';
            if (disabled) backColor = SpellingBackColors.DISABLED;
            else {
                if (highNotify !== null)
                    switch (highNotify.status) {
                        case EntryNotifyStatus.NOTIFY_STATUS_WARNING:
                            backColor = SpellingBackColors.WARNING;
                            color = styles.spellingInputBlack;
                            break;
                        case EntryNotifyStatus.NOTIFY_STATUS_ERROR:
                            backColor = SpellingBackColors.ERROR;
                            color = styles.spellingInputBlack;
                            break;
                    }
            }
            return (
                <div key={`${entryId}_${index}`} className={styles.spellingRow}>
                    <MuiThemeProvider theme={themeForAdditionalScreen}>
                        <ThemeProvider theme={themeForAdditionalScreen}>
                            <Input
                                endAdornment={end}
                                title={spellingElem}
                                style={highNotify !== null ? {
                                    color: '#FFFFFF',
                                    borderColor: backColor,
                                    borderWidth: "1.5px",
                                    borderStyle: "solid"
                                } : {color: '#FFFFFF',}}
                                id={`id_spelling_${entryId}_${index}`}
                                disabled={disabled}
                                value={spellingElem}
                                className={`${styles.spellingInput} ${disabled && styles.spellingDisabled}`}
                                type="text"
                                inputProps={{
                                    maxLength: 50,
                                }}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    const newSpellingValue = event.target.value;
                                    event.preventDefault();
                                    this.props.onSpellingChange(entryId, index, newSpellingValue);
                                    this.props.onEntryNotifyClear();
                                }}
                                onKeyPress={event => {
                                    if (event.key === "Enter" && !disabled && !disabledAdd) {
                                        this.handlerAddSpellingClick(entryId, index);
                                    }
                                }}
                                onClick={event => {
                                    if (highNotify) {
                                        event.stopPropagation();
                                        this.props.onEntryNotifyShow({entryId: entryId, ...highNotify}, null, null, null);
                                    }
                                }}
                                onBlur={event => {
                                    const entryId = this.props.entryId;
                                    //@ts-ignore
                                    const newSpellingValue = event.target.value;

                                    this.props.onHandlerSpellingBlur(entryId, [newSpellingValue]);
                                    if (newSpellingValue.trim() !== "")
                                        this.props.onEntryOverlapCheck(entryId, newSpellingValue);
                                }}
                            />
                        </ThemeProvider>
                    </MuiThemeProvider>
                </div>
            );
        });

        return (
            <>
                <div hidden={!visibleLoader} className={styles.loader}>
                    <Loader width={16} height={16} visible={true} color={"red"} type={"Oval"}/>
                </div>
                <div className={styles.spelling}>
                    {spellingRows}
                </div>
            </>
        );
    }

    private handlerAddSpellingClick = (entryId: number, index: number) => {
        this.props.onSpellingAdd(entryId);
        this.setState({spellingFocusId: `id_spelling_${entryId}_${this.props.spelling.length - 1}`});
    };

    private lastSpellingFocus = () => {
        const {spellingFocusId} = this.state;

        if (spellingFocusId !== undefined) {
            const elem = document.getElementById(spellingFocusId);
            if (elem) elem.focus();
            Promise.resolve().then(() => {
                this.setState({spellingFocusId: undefined});
            });
        }
    };
}
