import * as React from "react";

import styles from "./BacklogPage.module.scss";
import {PopupDialogConnected} from "../../components/PopupDialog/PopupDialogConnected";
import {EntryListBacklogConnected} from "../../components/EntryList/EntryListBacklog/EntryListBacklogConnected";

export class BacklogPage extends React.PureComponent {
    public render() {
        return (
            <div className={styles.container}>
                <PopupDialogConnected />
                <EntryListBacklogConnected />
            </div>
        );
    }
}
