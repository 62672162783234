import React from 'react';
import styles from './Search.module.scss';
import {ISearchFilterState} from "./SearchFilter/SearchFilter";
import {SearchFilterConnected} from "./SearchFilter/SearchFilterConnected";
import Loader from "react-loader-spinner";
import {SearchListConnected} from "./SearchList/SearchListConnected";
import {ILemmaLastInsertionsQueryProps, ILemmaSearchQueryProps} from "../../api/lemmaApi";
import {getSearchStateProcessed} from "../../helper/others";

export interface ISearchStateProps {
    spinnerStatus: number;
    searchPage: number;
    searchCount: number;
}

export interface ISearchDispatchProps {
    onSetSearchPage: (page: number) => void;
    onSearch: (props: ILemmaSearchQueryProps) => void;
    getLemmaLastInsertions: (props: ILemmaLastInsertionsQueryProps) => void;
}

export type ISearchProps = ISearchStateProps & ISearchDispatchProps;

export default function Search(props: ISearchProps) {
    const visibleLoader = props.spinnerStatus > 0;
    let stateProcessed: ISearchFilterState | undefined = getSearchStateProcessed()

    const onQuery = (pageNumber: number) => {
        const newPage = pageNumber;
        if (stateProcessed !== undefined) {
            const {
                search,
                searchParam,
                wordPart,
                posTags,
                language,
                caseSensitive,
                insDate,
                endDate,
                direction,
                sortBy
            } = stateProcessed;
            const requestProps: ILemmaSearchQueryProps = {
                lang: language.code.toLowerCase(),
                lower: search,
                field: wordPart,
                caseSensitive,
                type: searchParam,
                posTags: posTags.length > 0 ? posTags : "",
                limit: 20,
                direction: direction,
                sort: sortBy,
                page: newPage,
                after: new Date(insDate).toISOString(),
                before: new Date(endDate).toISOString(),
            }

            if (stateProcessed.search.length > 2 && pageNumber > 0) {
                props.onSearch({
                    ...requestProps,
                })
                props.onSetSearchPage(newPage)
            } else if (pageNumber > 0) {
                props.getLemmaLastInsertions({
                    language: language.code.toLowerCase(),
                    limit: 21,
                    page: newPage,
                })
                props.onSetSearchPage(newPage)
            }
        }
        else {
            props.getLemmaLastInsertions({
                language: "",
                limit: 21,
                page: newPage,
            })
            props.onSetSearchPage(newPage)
        }
    }

    return <div className={styles.container}>
        <div style={{display: visibleLoader ? "flex" : "none"}} className={styles.loaderContainer}>
            <Loader type="Oval" color="#ff4646" height={100} width={100} visible={visibleLoader}/>
        </div>
        <div className={styles.subContainer}>
            <SearchFilterConnected/>
            <SearchListConnected/>
            <div className={styles.paginationBox}>
                <button
                    disabled={props.searchPage === 1}
                    onClick={
                        () => {
                        props.searchPage !== 1 && onQuery(1);
                    }}
                    className={`${styles.paginationLink} ${props.searchPage === 1 && styles.disabled}`}
                >
                    {"←←"}
                </button>
                <button
                    disabled={props.searchPage === 1}
                    onClick={() => {
                        onQuery(props.searchPage - 1);
                    }
                    }
                    className={`${styles.paginationLink} ${props.searchPage === 1 && styles.disabled}`}
                >
                    {"←"}
                </button>
                <div className={`${styles.paginationLink} ${styles.paginationLinkActive}`}>
                    {props.searchPage}
                </div>
                <button
                    disabled={props.searchCount <= 20}
                    onClick={
                        () => {
                            onQuery(props.searchPage + 1);
                        }
                    }
                    className={`${styles.paginationLink} ${props.searchCount <= 20 && styles.disabled}`}
                >
                    {"→"}
                </button>
            </div>
        </div>
    </div>
}
