import {Endpoints} from "./endpoints";
import {BaseApi} from "./baseApi";

export enum UserRole {
    ROLE_ADMIN = "ROLE_ADMIN",
    ROLE_MAIN_ANNOTATOR = "ROLE_MAIN_ANNOTATOR",
    ROLE_ANNOTATOR = "ROLE_ANNOTATOR",
    ROLE_BU_USER = "ROLE_BU_USER",
}

export interface NewUser {
    username: string;
    email: string;
    roles: UserRole[];
    password?: string;
}

export interface User extends NewUser {
    id: number;
}

class UserApi extends BaseApi {
    Endpoint = Endpoints.User;
}

export function getUserApiObj() {
    return new UserApi();
}
