import {Thunk, TypedAction} from "../types";

export const SET_SUPPORT_VISIBLE = "SUPPORT/SET_SUPPORT_VISIBLE";

export type SupportActions = TypedAction<typeof SET_SUPPORT_VISIBLE, boolean>;

export interface SupportState {
    isShow: boolean;
}

export const initialState = {
    isShow: false,
};

export function support(visible: boolean): Thunk {
    return dispatch => {
        dispatch({
            type: SET_SUPPORT_VISIBLE,
            payload: visible,
        });
    };
}

export function supportReducer(state: SupportState = initialState, action: SupportActions): SupportState {
    switch (action.type) {
        case SET_SUPPORT_VISIBLE:
            return {
                ...state,
                isShow: action.payload,
            };
        default:
            return state;
    }
}
