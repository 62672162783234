import {Endpoints} from "./endpoints";
import {BaseApi} from "./baseApi";

class EntryUserApi extends BaseApi {
    Endpoint = Endpoints.EntryUser;
}

export function getEntryUserApiObj() {
    return new EntryUserApi();
}
