import {connect, MapDispatchToProps, MapStateToProps} from "react-redux";
import {State} from "../../../ducks/rootReducer";
import {EntryNotifyPanel, EntryNotifyPanelDispatchProps, EntryNotifyPanelStateProps} from "./EntryNotifyPanel";
import {selectEntryNotify} from "../../../selectors/selectors";

const mapStateToProps: MapStateToProps<EntryNotifyPanelStateProps, {}, State> = state => {
    const notify = selectEntryNotify(state);
    return {
        notify,
    };
};

const dispatchProps: MapDispatchToProps<EntryNotifyPanelDispatchProps, {}> = {

};

export const EntryNotifyPanelConnected = connect(
    mapStateToProps,
    dispatchProps,
)(EntryNotifyPanel);
