import {connect, MapDispatchToProps, MapStateToProps} from "react-redux";
import {State} from "../../ducks/rootReducer";
import {InputFile, InputFileDispatchProps, InputFileStateProps} from "./InputFile";
import {fileSet} from "../../ducks/upload/upload";
import {selectUpload} from "../../selectors/selectors";

const mapStateToProps: MapStateToProps<InputFileStateProps, {}, State> = state => {
    const { file } = selectUpload(state);

    return {
        file,
    };
};

const dispatchProps: MapDispatchToProps<InputFileDispatchProps, {}> = {
    onFileSet: fileSet,
};

export const InputFileConnected = connect(
    mapStateToProps,
    dispatchProps,
)(InputFile);
