import {connect, MapDispatchToProps, MapStateToProps} from "react-redux";
import {State} from "../../../ducks/rootReducer";
import {EntriesListDispatchProps, EntriesListStateProps, EntryListExternal} from "./EntryListExternal";
import {
    selectAuth,
    selectEntries,
    selectEntryFilter,
    selectEntryNotify,
    selectPopup,
    selectUserRole,
    selectUsers
} from "../../../selectors/selectors";
import {
    backlogGetQuery,
    deleteSomeEntries,
    entriesSet,
    entryPushToBacklog,
    entrySaveChanges,
    entryValidate,
    getEntriesByPageExternal,
    getLanguagesForEntryEditor,
    netQuery,
    onSetNets,
    posTagsQuery,
    setEntriesSpinner,
    setEntrySorting,
    setExternalListPage,
} from "../../../ducks/entries/entries";
import {User} from "../../../api/userApi";
import {popupPush} from "../../../ducks/popup/popup";
import {UserApp} from "../../../api/userAppApi";

const mapStateToProps: MapStateToProps<EntriesListStateProps, {}, State> = state => {
    const userRole = selectUserRole(state);
    const { entries, entryLanguages, nets, posTags, pushResult, filteredNets, externalCount, externalPage, sorting, spinners, previousEntries } = selectEntries(state);
    const userId = selectAuth(state).id;
    const notify = selectEntryNotify(state);

    const { spinnerStatus } = selectPopup(state);
    const users: User[] | UserApp[] = selectUsers(state).usersApp;
    const filterProps = selectEntryFilter(state);

    return {
        entries,
        userId,
        userRole,
        users,
        entryLanguages,
        nets,
        posTags,
        pushResult,
        spinnerStatus,
        filteredNets,
        filterProps,
        externalCount,
        notify,
        page: externalPage,
        entrySorting: sorting,
        spinners,
        previousEntries,
    };
};

const dispatchProps: MapDispatchToProps<EntriesListDispatchProps, {}> = {
    onLanguagesQuery: getLanguagesForEntryEditor,
    onPosTagsQuery: posTagsQuery,
    onNetQuery: netQuery,
    onPopupPush: popupPush,
    onEntryValidate: entryValidate,
    onEntryPushToBacklog: entryPushToBacklog,
    onSetEntries: entriesSet,
    onEntryDelete: deleteSomeEntries,
    onSetNets: onSetNets,
    onEntrySaveChanges: entrySaveChanges,
    onBacklogGetQuery: backlogGetQuery,
    getEntriesByPageExternal,
    setExternalListPage,
    setEntrySorting,
    setEntriesSpinner,
};

export const EntryListExternalConnected = connect(
    mapStateToProps,
    dispatchProps,
)(EntryListExternal);
