import * as React from "react";

import styles from "./NewEntriesPage.module.scss";
import {PopupDialogConnected} from "../../components/PopupDialog/PopupDialogConnected";
import {EntryListManualConnected} from "../../components/EntryList/EntryListManual/EntryListManualConnected";

export class NewEntriesPage extends React.PureComponent {
    public render() {
        return (
            <div className={styles.container}>
                <PopupDialogConnected />
                <EntryListManualConnected />
            </div>
        );
    }
}
