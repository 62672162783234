import {Endpoints, getEndpoint} from "./endpoints";
import {BaseApi} from "./baseApi";
import {IEditLabelProps} from "../ducks/entries/entries";
import {IDeleteNet} from "./netApi";

export interface PropsArrLemmaLang {
    lemma: string[];
    lang: string;
}

export interface IBulkSourcesToTargetsNoPOS {
    spellings: string[];
    lemmas: string[];
    synonyms: string[];
    lang: string;
}

export interface PropsLemmaPosLang {
    lemmaLabel: string;
    lang: string;
    posTag: string;
}

export interface QueryResult {
    source: string;
    targets: string[];
}

export interface IDeleteCheckQuery {
    lang: string;
    posTag: string;
    label: string;
}

export interface IEditLabel extends IEditLabelProps{
}

export interface IFetchCluster {
    label: string;
    lang: string;
    posTag: string;
}

class SpellingApi extends BaseApi {
    Endpoint = Endpoints.Spelling;

    newSpellingEndpoint(props: IBulkSourcesToTargetsNoPOS) {
        const res = getEndpoint(this.Endpoint, "bulkSourcesToTargetsNoPOS")
            .post(props)
            .res();

        return res;
    }

    checkDelete(props: IDeleteCheckQuery) {
        const res = getEndpoint(this.Endpoint, "checkDependencies")
            .query(props)
            .get()
            .res();

        return res;
    }

    fetchCluster(props: IFetchCluster) {
        const res = getEndpoint(this.Endpoint, "fetchCluster")
            .query(props)
            .get()
            .res();

        return res;
    }

    deleteNode(props: IDeleteCheckQuery) {
        const res = getEndpoint(this.Endpoint, "deleteNode")
            .query(props)
            .get()
            .res();

        return res;
    }

    spellingByLemma(props: PropsArrLemmaLang) {
        const propsProcessed = `${props.lemma
            .map(lemma => `lemma[]=${encodeURIComponent(lemma)}`)
            .join("&")}&lang=${encodeURIComponent(props.lang)}`;

        const res = getEndpoint(this.Endpoint, "byLemma")
            .query(propsProcessed)
            .get()
            .res();

        return res;
    }

    async editLabel(props: IEditLabel) {
        return await this.endpoint("editLabel")
            .post({...props})
            .res();
    }

    spellingByLemmaPos(props: PropsLemmaPosLang) {
        const propsProcessed = `lemmaLabel=${encodeURIComponent(props.lemmaLabel)}&lang=${encodeURIComponent(
            props.lang,
        )}&posTag=${encodeURIComponent(props.posTag)}`;
        const res = getEndpoint(this.Endpoint, "byLemmaPos")
            .query(propsProcessed)
            .get()
            .res();

        return res;
    }

    async deleteNet(props: IDeleteNet) {
        return await getEndpoint(this.Endpoint,'deleteRelationship')
            .query({strictNET: false})
            .post(props)
            .res()
    }

}

export function getSpellingApiObj() {
    return new SpellingApi();
}
