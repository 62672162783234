import {connect, MapDispatchToProps, MapStateToProps} from "react-redux";
import {State} from "../../ducks/rootReducer";
import {PopupLocation, PopupLocationDispatchProps, PopupLocationStateProps} from "./PopupLocation";
import {setEditableTerm, setLocationPopupHandler, setLocationPopupOpen} from "../../ducks/entries/entries";
import {selectEntries, selectIsLocationPopupOpen} from "../../selectors/selectors";

const mapStateToProps: MapStateToProps<PopupLocationStateProps, {}, State> = state => {
    const {editableTerm, levels, locationPopupHandler} = selectEntries(state);
    return {
        editableTerm: editableTerm,
        isOpen: selectIsLocationPopupOpen(state),
        levels: levels,
        handler: locationPopupHandler,
    };
};

const dispatchProps: MapDispatchToProps<PopupLocationDispatchProps, {}> = {
    setLocationPopupOpen,
    setEditableTerm,
    setLocationPopupHandler,
};

export const PopupLocationConnected = connect(
    mapStateToProps,
    dispatchProps,
)(PopupLocation);
