import {Thunk, TypedAction} from "../types";
import {selectEntryFilter, selectUsers} from "../../selectors/selectors";
import {push} from "connected-react-router";
import {getUserApiObj, NewUser, User} from "../../api/userApi";
import {getUserAppApiObj, UserApp} from "../../api/userAppApi";
import {popupPush, PopupType, spinnerDown, spinnerUp} from "../popup/popup";
import {DEFAULT_FILTER_PARAMS, IFilterParams} from "../../api/baseApi";
import {getEntryUserApiObj} from "../../api/entryUserApi";

const UPDATE_USERS = "USERS/UPDATE_USERS";
const SET_ACTIVE_USER = "USERS/SET_ACTIVE_USER";

const UPDATE_USERS_APP = "USERS/UPDATE_USERS_APP";

export type UsersActions =
    | TypedAction<typeof UPDATE_USERS, User[]>
    | TypedAction<typeof SET_ACTIVE_USER, User>
    | TypedAction<typeof UPDATE_USERS_APP, UserApp[]>;

export function isRegisteredUser(arg: any): arg is User {
    return arg.id !== undefined;
}

export interface UsersState {
    users: User[];
    activeUser?: User;
    count: number;
    limit: number;
    page: number;

    usersApp: UserApp[];
}

export const initialState = {
    users: [],
    count: 0,
    page: 0,
    limit: 10,

    usersApp: [],
};

export function cancelChanges(): Thunk {
    return dispatch => {
        dispatch({
            type: SET_ACTIVE_USER,
            payload: undefined,
        });

        dispatch(push("/users"));
    };
}

export function saveChanges(user: User | NewUser): Thunk {
    return async (dispatch, getState) => {
        if (!isRegisteredUser(user)) {
            return dispatch(createNewUser(user));
        }

        if(!user.password || user.password && user.password.trim() === "") delete user.password;

        await getUserApiObj()
            .update({...user})
            .then(async () => {
                const {count, page, users} = selectUsers(getState());

                await dispatch({
                    type: UPDATE_USERS,
                    payload: {users, count, page},
                });

                await dispatch(push("/users"));
            })
            .catch(error => {
                const errorMessage = JSON.parse(error.message).message;
                dispatch(popupPush({
                    id: new Date().getTime().toString(),
                    type: PopupType.POPUP_MESSAGE,
                    data: [errorMessage],
                    actionTitle: "Ok",
                    actionVisible: true,
                    cancelVisible: false,
                }))
            });
    };
}

export function createNewUser(user: NewUser): Thunk {
    return async dispatch => {
        await getUserApiObj()
            .create(user)
            .then(() => {
                dispatch(push("/users"));
            })
            .catch(error => {
                const errorMessage = JSON.parse(error.message).message;
                dispatch(popupPush({
                    id: new Date().getTime().toString(),
                    type: PopupType.POPUP_MESSAGE,
                    data: [errorMessage],
                    actionTitle: "Ok",
                    actionVisible: true,
                    cancelVisible: false,
                }))
            });
    };
}

export function deleteUserAction(id: number): Thunk {
    return async (dispatch, getState) => {
        await getUserApiObj().delete(id);
        const {count, page, users} = selectUsers(getState());

        dispatch({
            type: UPDATE_USERS,
            payload: {users: users.filter(user => user.id !== id), count, page},
        });
    };
}

export function queryAllUsers(): Thunk {
    return async dispatch => {
        const response = await getUserApiObj().getAll();
        const resJson = await response.json();

        if (resJson) {
            dispatch({
                type: UPDATE_USERS,
                payload: {users: resJson, count: 0, page: 0, limit: 0},
            });
        } else console.error("Не получили res queryAllUsers");
    };
}

export function queryAllUniqueUsers(unique?: boolean): Thunk {
    return async (dispatch, getState) => {
        const entryFilter = selectEntryFilter(getState());
        const params: IFilterParams = {...DEFAULT_FILTER_PARAMS};

        params.lang = entryFilter.lang;

        const response = !unique ? await getUserApiObj().getAllUniq() : await getEntryUserApiObj().getAllUniq(params);
        const resJson = await response.json();

        if (resJson) {
            dispatch({
                type: UPDATE_USERS,
                payload: {users: resJson, count: 0, page: 0, limit: 0},
            });
        } else console.error("Не получили res queryAllUsers");
    };
}

export function queryAllUsersApp(unique?: boolean): Thunk {
    return async (dispatch, getState) => {

        const params: IFilterParams = {...DEFAULT_FILTER_PARAMS};
        const entryFilter = selectEntryFilter(getState())

        params.objectIdApp = entryFilter.objectIdApp;
        params.sourceApp = entryFilter.sourceApp;
        params.lang = entryFilter.lang;

        const response = unique ? await getUserAppApiObj().getAllUniq(params) : await getUserAppApiObj().getAll();
        const resJson = await response.json();

        if (resJson) {
            dispatch({
                type: UPDATE_USERS_APP,
                payload: resJson,
            });
        } else console.error("Не получили res queryAllUsersApp");
    };
}

export function queryUsers(limit: number, page: number): Thunk {
    return async dispatch => {
        dispatch(spinnerUp())
        await getUserApiObj()
            .getAll()
            .then(response => response.json())
            .then(res => {
                if (res) {
                    const {data, count} = res;
                    dispatch({
                        type: UPDATE_USERS,
                        payload: {users: res, count, page, limit},
                    });
                } else console.error("Не получили res queryUsers");
            })
            .catch()
            .finally(() => dispatch(spinnerDown()))
        ;
    };
}

export function setActiveUser(user?: User): Thunk {
    return async dispatch => {
        dispatch(push("/users/edit"));

        dispatch({
            type: SET_ACTIVE_USER,
            payload: user,
        });
    };
}

export function usersReducer(state: UsersState = initialState, action: UsersActions): UsersState {
    switch (action.type) {
        case UPDATE_USERS: {
            const {users, count, page} = action.payload;
            return {
                ...state,
                users: users,
                count,
                page,
            };
        }
        case SET_ACTIVE_USER: {
            return {...state, activeUser: action.payload};
        }
        case UPDATE_USERS_APP: {
            return {...state, usersApp: action.payload};
        }
        default:
            return state;
    }
}
