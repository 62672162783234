import {Endpoints, getEndpoint} from "./endpoints";
import {BaseApi} from "./baseApi";
import {
    ESearchRadio,
    ESortBy,
    ESortDirection,
    EWordPartOrSearchParam
} from "../components/Search/SearchFilter/SearchFilter";

export interface ILemmaSearchQueryProps {
    lang: string;
    lower: string;
    type?: ESearchRadio;
    field?: EWordPartOrSearchParam;
    direction?: ESortDirection;
    caseSensitive?: boolean;
    posTags?: string;
    after?: string;
    before?: string;
    page?: number;
    sort?: ESortBy;
    limit?: number;
}

export interface ILemmaLastInsertionsQueryProps {
    language: string;
    page?: number;
    limit?: number;
}

export interface Lemma {
    label: string;
    posTag: string;
}

export interface PropsLemmaLang {
    lemma: string;
    lang: string;
    limit: number;
}

export interface PropsSynonymLang {
    synonym: string;
    lang: string;
    posTag?: string;
    limit: number;
}

export interface PropsSynonymLanPos {
    synonym: string;
    lang: string;
    posTag: string;
}

export interface PropsArrSynonymLang {
    synonym: string[];
    lang: string;
}

export interface PropsArrSpellingLang {
    spelling: string[];
    lang: string;
}

class LemmaApi extends BaseApi {
    Endpoint = Endpoints.Lemma;

    getSearch(props: ILemmaSearchQueryProps) {
        const res = getEndpoint(this.Endpoint, "searchLexicon")
            .query(props)
            .get()
            .res()
            .then(async r => {
                return {
                    data: await r.json(),
                    count: r.headers.get("x-total-count"),
                };
            });

        return res;
    }

    getLastInsertions(props: ILemmaLastInsertionsQueryProps) {
        const res = getEndpoint(this.Endpoint, "latestInsertions")
            .query(props)
            .get()
            .res()
            .then(async r => {
                return {
                    data: await r.json(),
                    count: r.headers.get("x-total-count"),
                };
            });

        return res;
    }

    getAutocomplete(props: PropsLemmaLang) {
        const res = getEndpoint(this.Endpoint, "autocomplete")
            .query(props)
            .get()
            .res();

        return res;
    }

    lemmaBySynonymPos(props: PropsSynonymLanPos) {
        const res = getEndpoint(this.Endpoint, "bySynonymPos")
            .query(props)
            .get()
            .res();

        return res;
    }

    lemmaBySpelling(props: PropsArrSpellingLang) {
        const propsProcessed = `${props.spelling
            .map(spelling => `spelling[]=${encodeURIComponent(spelling)}`)
            .join("&")}&lang=${encodeURIComponent(props.lang)}`;

        return getEndpoint(this.Endpoint, "bySpelling")
            .query(propsProcessed)
            .get()
            .res();
    }
}

export function getLemmaApiObj() {
    return new LemmaApi();
}
