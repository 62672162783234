import {connect, MapDispatchToProps, MapStateToProps} from "react-redux";
import {State} from "../../../ducks/rootReducer";
import SearchFilter, {ISearchFilterDispatchProps, ISearchFilterStateProps} from "./SearchFilter";
import {selectEntries} from "../../../selectors/selectors";
import {getLemmaLastInsertions, getLemmaSearch, setLemmaSearchPage} from "../../../ducks/entries/entries";

const mapStateToProps: MapStateToProps<ISearchFilterStateProps, {}, State> = state => {
    const {entryLanguages, posTags, isInLemmaSearch} = selectEntries(state);
    const path = window.location.href;
    return {
        languages: entryLanguages.filter(elem => elem.name),
        posTags: posTags.filter(elem => elem.name),
        path: path.toString(),
        isInLemmaSearch,
    };
};

const dispatchProps: MapDispatchToProps<ISearchFilterDispatchProps, {}> = {
    onSearch: getLemmaSearch,
    onSetSearchPage: setLemmaSearchPage,
    getLemmaLastInsertions,
};

export const SearchFilterConnected = connect(
    mapStateToProps,
    dispatchProps,
)(SearchFilter);
