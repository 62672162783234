import {Thunk, TypedAction} from "../types";
import {UserRole} from "../../api/userApi";
import {getAuthApiObj} from "../../api/authApi";
import {push} from "connected-react-router";
import {ActionVariant, SET_ACTION_VARIANT} from "../action/action";
import {selectAuth} from "../../selectors/selectors";

export const UPDATE_AUTH_STATE = "AUTH/UPDATE_AUTH_STATE";
export const UPDATE_AUTH_LOADER = "AUTH/UPDATE_AUTH_LOADER";
export type AuthActions =
    | TypedAction<typeof UPDATE_AUTH_STATE, AuthState>
    | TypedAction<typeof UPDATE_AUTH_LOADER, boolean>;

export interface Token {
    access: string;
    refresh: string;
    exp: number;
}

interface AuthModel {
    id: number;
    username: string;
    email: string;
    roles: UserRole[];
    token: Token;
    loginError: string | undefined;
    authLoader: boolean;
}

export type AuthState = AuthModel | (Partial<AuthModel> & { id: 0 });

const initialState: AuthState = {
    id: 0,
    loginError: undefined,
    authLoader: false,
};

export function authentication(login: string, password: string): Thunk {
    return async dispatch => {
        dispatch(updateAuthLoader(true));
        await getAuthApiObj()
            .auth({ username: login, password: password })
            .then(res => {
                dispatch(updateAuthLoader(false));
                dispatch(updateAuthState(res));
                dispatch(push("/"));
            })
            .catch(err => {
                if (err.response.status === 404) {
                    dispatch(updateAuthState({ loginError: "User not found" }));
                } else if (err.response.status === 500) {
                    dispatch(updateAuthState({ loginError: "Password is incorrect" }));
                } else {
                    dispatch(updateAuthState({ loginError: "Error code: " + err.response.status }));
                }
                dispatch(updateAuthLoader(false));
            });
    };
}

export function refresh(): Thunk {
    return async (dispatch, getState) => {
        const auth = selectAuth(getState());
        dispatch(updateAuthLoader(true));
        await getAuthApiObj()
            .refresh(auth.token!.refresh)
            .then(res => {
                dispatch(updateAuthLoader(false));
                dispatch(updateAuthState(res));
            })
            .catch(err => {
                if (err.response.status === 404) {
                    dispatch(updateAuthState({ loginError: "User not found" }));
                } else if (err.response.status === 500) {
                    dispatch(updateAuthState({ loginError: "Password is incorrect" }));
                } else {
                    dispatch(updateAuthState({ loginError: "Error code: " + err.response.status }));
                }
                dispatch(updateAuthLoader(false));
            });
    };
}

export function updateAuthState(authState: any): Thunk {
    return async dispatch => {
        window.localStorage.setItem("authState", JSON.stringify(authState));

        await dispatch({
            type: UPDATE_AUTH_STATE,
            payload: authState,
        });
    };
}

export function updateAuthLoader(status: boolean): Thunk {
    return async dispatch => {
        dispatch({
            type: UPDATE_AUTH_LOADER,
            payload: status,
        });
    };
}

export function logout(): Thunk {
    return dispatch => {
        window.localStorage.clear();

        dispatch({ type: SET_ACTION_VARIANT, payload: ActionVariant.VARIANT_UNDEFINED });
        dispatch({ type: UPDATE_AUTH_STATE, payload: initialState });
    };
}

export function authReducer(state: AuthState = initialState, action: AuthActions): AuthState {
    switch (action.type) {
        case UPDATE_AUTH_STATE:
            return { ...action.payload };
        case UPDATE_AUTH_LOADER:
            return { ...state, authLoader: action.payload };
        default:
            return state;
    }
}
