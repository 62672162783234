import {State} from "../ducks/rootReducer";

export function selectPathName(state: State) {
    return state.router.location.pathname.replace("/", "");
}

export function selectAuth(state: State) {
    return state.auth;
}

export function selectEntryFilter(state: State) {
    return state.entries.entryFilter;
}

export function selectEntrySpinners(state: State) {
    return state.entries.spinners;
}

export function selectEntrySorting(state: State) {
    return state.entries.sorting;
}

export function selectAdditionalInformationStage(state: State) {
    return state.entries.additionalInfoStage;
}

export function selectAction(state: State) {
    return state.action;
}

export function selectUpload(state: State) {
    return state.upload;
}

export function selectEntries(state: State) {
    return state.entries;
}

export function selectUsers(state: State) {
    return state.users;
}

export function selectUsersApp(state: State) {
    return state.users.usersApp;
}

export function selectEntryNotify(state: State) {
    return state.entries.notify;
}

export function selectSupportVisible(state: State) {
    return state.support.isShow;
}

export function selectIsAuthenticated(state: State) {
    return !!state.auth.id;
}

export function selectUserRole(state: State) {
    return state.auth.roles && state.auth.roles[0];
}

export function selectActiveUser(state: State) {
    return state.users.activeUser;
}

export function selectPopup(state: State) {
    return state.popup;
}

export function selectEditableTerm(state: State) {
    return state.entries.editableTerm;
}
export function selectIsLocationPopupOpen(state: State) {
    return state.entries.isLocationPopupOpen;
}
