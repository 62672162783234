import {connect, MapDispatchToProps, MapStateToProps} from "react-redux";
import {State} from "../../../../ducks/rootReducer";
import {EntryFilterBacklog, EntryFilterDispatchProps, EntryFilterStateProps} from "./EntryFilterBacklog";
import {selectAuth, selectEntries, selectUsers} from "../../../../selectors/selectors";
import {queryAllUniqueUsers, queryAllUsersApp} from "../../../../ducks/users/users";
import {
    backlogGetQuery,
    entriesSet,
    languagesQuery,
    queryObjectIdsApp,
    querySourcesApp,
    setBacklogListPage,
    setEntryFilter,
    setEntrySorting
} from "../../../../ducks/entries/entries";
import {User} from "../../../../api/userApi";

const mapStateToProps: MapStateToProps<EntryFilterStateProps, {}, State> = state => {
    const {languages, sources, objectIds, entries, entryFilter, entryLanguages, nets, posTags} = selectEntries(state);

    let users: User[] = selectUsers(state).users;
    const userId = selectAuth(state).id;

    return {
        users,
        languages,
        sources,
        objectIds,
        entries,
        entryFilter,
        entryLanguages,
        nets,
        posTags,
        userId,
    };
};

const dispatchProps: MapDispatchToProps<EntryFilterDispatchProps, {}> = {
    onQueryUsers: queryAllUniqueUsers,
    onQueryUsersApp: queryAllUsersApp,
    onQuerySourcesApp: querySourcesApp,
    onQueryObjectIdsApp: queryObjectIdsApp,
    onBacklogGetQuery: backlogGetQuery,
    onLanguagesQuery: languagesQuery,
    setEntryFilter,
    setBacklogListPage,
    setEntrySorting,
    onSetEntries: entriesSet,
};

export const EntryFilterBacklogConnected = connect(
    mapStateToProps,
    dispatchProps,
)(EntryFilterBacklog);
