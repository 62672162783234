import {applyMiddleware, createStore} from "redux";
import {history, rootReducer} from "../ducks/rootReducer";
import thunk from "redux-thunk";
import {routerMiddleware} from "connected-react-router";
import logger from "redux-logger";

let middleware = [thunk, routerMiddleware(history)];

if (process.env.NODE_ENV === "development") {
    middleware.push(logger);
}

const authState = JSON.parse(window.localStorage.getItem("authState") || `{"id": 0}`);

export const store = createStore(rootReducer, { auth: authState }, applyMiddleware(...middleware));
