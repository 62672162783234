import wretch, {Wretcher} from "wretch";
import {AuthState, updateAuthState} from "../ducks/auth/auth";
import {getApiUtils} from "./utils";
import {push} from "connected-react-router";

export enum Endpoints {
    Auth = "/api/auth",
    User = "/api/user",
    AppUser = "/api/appUser",
    Entry = "/api/entry",
    Language = "/api/language",
    EntryLanguage = "/api/entryLanguage",
    EntryUser = "/api/entryUser",
    PosTag = "/api/posTag",
    Nets = "/api/nets",
    Lemma = "/api/lemma",
    Spelling = "/api/spelling",
    Synonym = "/api/synonym",
    Source = "/api/source",
    AppObjectId = "/api/appObjectId",
    Term = "/api/getTerm",
}

const getAuthFromStorage = (): AuthState => {
    return JSON.parse(window.localStorage.getItem("authState") || `{"id": 0}`);
};

async function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

const getBase = (): Wretcher => {
    const authState: AuthState = getAuthFromStorage();
    const token = authState.token && authState.token.access;

    return wretch(process.env.REACT_APP_BASE_API_URL)
        .headers({ "X-AUTH-TOKEN": `Bearer ${token}` })
        .catcher(401, async (error, originalRequest) => {
            await sleep(2000);
            const { dispatch } = getApiUtils();
            const authState2: AuthState = getAuthFromStorage();
            const token2 = authState2.token && authState2.token.access;

            if (token2 != token) {
                return originalRequest
                    .headers({
                        "X-AUTH-TOKEN": `Bearer ${token2}`,
                    })
                    .replay()
                    .res();
            } else {
                const refreshToken = authState.token && authState.token.refresh;

                if (refreshToken) {
                    let flag404 = false;
                    const newAuthState: AuthState = await getEndpoint(Endpoints.Auth, "refresh")
                        .headers({ "X-AUTH-REFRESH-TOKEN": `${refreshToken}` })
                        .catcher(404, async (error, originalRequest) => {
                            flag404 = true;
                            await sleep(2000);
                            return getAuthFromStorage();
                        })
                        .post()
                        .json();

                    const newToken: string | undefined =
                        newAuthState && newAuthState.token && newAuthState.token.access;

                    if (newToken) {
                        if (!flag404) await getApiUtils().dispatch(updateAuthState(newAuthState));

                        return originalRequest
                            .headers({
                                "X-AUTH-TOKEN": `Bearer ${newToken}`,
                            })
                            .replay()
                            .res();
                    }

                    dispatch(push("/login"));
                    return wretch(process.env.REACT_APP_BASE_API_URL);
                }
            }
        });
};

type UrlPart = string | number;

export const getEndpoint = (endpoint: Endpoints | string, urlPart?: UrlPart) => {
    return getBase().url(endpoint + (urlPart !== undefined && urlPart !== "" ? `/${urlPart}` : ""));
};
