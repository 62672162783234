import React from "react";
import styles from "./Logout.module.scss";
import HamburgerMenu from "../Menu/Menu";
import {UserRole} from "../../api/userApi";


export interface LogoutStateProps {
    username: string | undefined;
    role: UserRole | undefined;
}

export interface LogoutDispatchProps {
    onLogout: () => void;
}

type LogoutProps = LogoutStateProps & LogoutDispatchProps;

export class Logout extends React.PureComponent<LogoutProps> {
    render() {
        return (
            <React.Fragment>
                <div className={styles.logoutButtonContainer}>
                    <HamburgerMenu
                        username={this.props.username ? this.props.username : ""}
                        role={this.props.role}
                        onLogout={this.props.onLogout}
                    />
                </div>
            </React.Fragment>
        );
    }
}
