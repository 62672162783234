import {getEndpoint} from "./endpoints";

export const DEFAULT_FILTER_PARAMS: IFilterParams = {
    lang: null,
    userApp: null,
    objectIdApp: null,
    sourceApp: null,
}

export interface NewBase {
}

export interface Base extends NewBase {
    id: number;
}

export interface PropsChunk {
    limit: number;
    offset: number;
}

export interface IFilterParams {
    sourceApp?: string | null;
    objectIdApp?: string | null;
    lang?: string | null;
    userApp?: string | null;
    userId?: number | null;
    status?: number | null;
}

export class BaseApi {
    Endpoint = "/api";

    endpoint(urlPart: string | number) {
        return getEndpoint(this.Endpoint, urlPart);
    }

    async getAll() {
        const res = await this.endpoint("")
            .query({limit: 9999999})
            .get()
            .res();

        return res;
    }

    async getAllUniq(params: IFilterParams = DEFAULT_FILTER_PARAMS) {
        const queryParameters = {
            limit: 9999999,
            unique: true,
        }
        if (params.userApp) {
            //@ts-ignore
            queryParameters["userApp"] = params.userApp;
        }
        if (params.lang) {
            //@ts-ignore
            queryParameters["lang"] = params.lang;
        }
        if (params.objectIdApp) {
            //@ts-ignore
            queryParameters["objectIdApp"] = params.objectIdApp;
        }
        if (params.sourceApp) {
            //@ts-ignore
            queryParameters["sourceApp"] = params.sourceApp;
        }
        if (params.status) {
            //@ts-ignore
            queryParameters["status"] = params.status;
        }

        if (params.userId) {
            //@ts-ignore
            queryParameters["userId"] = params.userId;
        }

        const res = await this.endpoint("")
            .query(queryParameters)
            .get()
            .res();

        return res;
    }

    async getChunk(props: PropsChunk) {
        const res = await this.endpoint("")
            .query(props)
            .get()
            .res()
            .then(async r => {
                return {
                    data: await r.json(),
                    count: r.headers.get("x-total-count"),
                    query: r.headers.get("search")
                };
            });

        return res;
    }

    async create(props: Omit<Base, "id">) {
        const res = await this.endpoint("")
            .post(props)
            .res();

        return res;
    }

    async update(props: Base) {
        const res = await this.endpoint(props.id)
            .put(props)
            .res();

        return res;
    }

    async delete(id: number) {
        const res = await this.endpoint(id)
            .delete()
            .res();

        return res;
    }

    async restore(id: number) {
        const res = await this.endpoint(id)
            .put()
            .res();

        return res;
    }
}

export function getBaseApiObj() {
    return new BaseApi();
}
