import {connect, MapDispatchToProps, MapStateToProps} from "react-redux";
import {State} from "../../ducks/rootReducer";
import Search, {ISearchDispatchProps, ISearchStateProps} from "./Search";
import {selectEntries, selectPopup} from "../../selectors/selectors";
import {getLemmaLastInsertions, getLemmaSearch, setLemmaSearchPage} from "../../ducks/entries/entries";

const mapStateToProps: MapStateToProps<ISearchStateProps, {}, State> = state => {
    const {spinnerStatus} = selectPopup(state);
    const {searchPage, entrySearchCount} = selectEntries(state);
    return {
        spinnerStatus,
        searchPage,
        searchCount: entrySearchCount,
    };
};

const dispatchProps: MapDispatchToProps<ISearchDispatchProps, {}> = {
    onSetSearchPage: setLemmaSearchPage,
    onSearch: getLemmaSearch,
    getLemmaLastInsertions,
};

export const SearchConnected = connect(
    mapStateToProps,
    dispatchProps,
)(Search);
