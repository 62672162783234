import React from "react";
import styles from "./Support.module.scss";
import CloseIcon from "@material-ui/icons/Close";

export interface SupportStateProps {
    isShow: boolean;
}

export interface SupportDispatchProps {
    onSupport: (visible: boolean) => void;
}

type SupportProps = SupportStateProps & SupportDispatchProps;

export class Support extends React.PureComponent<SupportProps> {
    render() {
        const { isShow } = this.props;

        return (
            <div className={styles.supportContainer + " " + (isShow ? styles.supportContainerShow : "")}>
                <CloseIcon className={styles.supportCloseButton} onClick={() => this.props.onSupport(false)} />
                <div className={styles.supportSubContainer}>
                    <h2>CSV format</h2>
                    <ul className={styles.supportInfoList}>
                        <li>File must be utf-8 encoded or problems could arise parsing it.</li>
                        <li>Separators can be either the semicolon ";" or the comma “,”.</li>
                        <li>
                            All fields containing semicolons and commas must be enclosed in double quotes. To be 100%
                            safe, enclose all field in quotes!
                        </li>
                        <li>Header (can be omitted): "lemma";"spelling";"POS tag", "SyGr","NET";"language"</li>
                        <li>The only mandatory fields are "lemma", "POS tag" and “language”</li>
                        <li>Valid POS tags are: "noun", "proper noun", "verb", "adj" and "adv"</li>
                        <li>
                            Valid NETs are: "location", "event", "brand_model", "organization", "brand", "creative_work"
                            and "person"
                        </li>
                    </ul>
                    <p>
                        Keep in mind that the choice of the character for separator AND quotes is usually selected when
                        exporting the sheet, you do not need to put them in the cells!
                    </p>
                    <img src="/csv-support.png" alt="" />
                </div>
            </div>
        );
    }
}
