import {connect, MapDispatchToProps, MapStateToProps} from "react-redux";
import {State} from "../../ducks/rootReducer";
import {Action, ActionDispatchProps, ActionStateProps} from "./Action";
import {selectAction, selectUserRole} from "../../selectors/selectors";
import {support} from "../../ducks/support/support";
import {actionChange, actionValidate} from "../../ducks/action/action";

const mapStateToProps: MapStateToProps<ActionStateProps, {}, State> = state => {
    const {actionVariant} = selectAction(state);
    const userRole = selectUserRole(state);

    return {
        actionVariant,
        userRole
    };
};

const dispatchProps: MapDispatchToProps<ActionDispatchProps, {}> = {
    onSupport: support,
    onActionChange: actionChange,
    onActionValidate: actionValidate,
};

export const ActionConnected = connect(
    mapStateToProps,
    dispatchProps,
)(Action);
