import {Endpoints} from "./endpoints";
import {BaseApi} from "./baseApi";

export interface Source {
    name: string;
}

class SourceApi extends BaseApi {
    Endpoint = Endpoints.Source;
}

export function getSourceApiObj() {
    return new SourceApi();
}
