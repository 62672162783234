import {connect, MapDispatchToProps, MapStateToProps} from "react-redux";
import {State} from "../../ducks/rootReducer";
import {selectIsAuthenticated, selectUserRole} from "../../selectors/selectors";
import {TopNavBar, TopNavBarDispatchProps, TopNavBarStateProps} from "./TopNavBar";

const mapStateToProps: MapStateToProps<TopNavBarStateProps, {}, State> = state => {
    return {
        isAuthenticated: selectIsAuthenticated(state),
        role: selectUserRole(state),
    };
};

const dispatchProps: MapDispatchToProps<TopNavBarDispatchProps, {}> = {};

export const TopNavBarConnected = connect(
    mapStateToProps,
    dispatchProps,
)(TopNavBar);
