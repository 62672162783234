import {CSSProperties} from "react";

export interface IManualElementsWidth {
    language: CSSProperties;
    inputs: CSSProperties;
    selectors: CSSProperties;
}

export interface IBacklogElementsWidth extends IManualElementsWidth {
    date: CSSProperties;
    user: CSSProperties;
}

export interface IExternalElementsWidth extends IBacklogElementsWidth {
    source: CSSProperties;
    objectID: CSSProperties;
}

export const ELEMENT_MANUAL_WIDTH: IManualElementsWidth = {
    language: { width: "calc(12.388% - 13px)" },
    inputs: { width: "22.50%" },
    selectors: { width: "9.956%" },
};

export const ELEMENT_BACKLOG_WIDTH: IBacklogElementsWidth = {
    language: { width: "calc(12.674% - 13px)" },
    inputs: { width: "17.34%" },
    selectors: { width: "8.98%" },
    date: { width: "6.42%" },
    user: { width: "10.924%" },
};

export const ELEMENT_EXTERNAL_WIDTH: IExternalElementsWidth = {
    language: { width: "calc(9.97% - 13px)" },
    inputs: { width: "14%" },
    selectors: { width: "8.4%" },
    date: { width: "7.4%" },
    user: { width: "8.97%" },
    source: { width: "8.97%" },
    objectID: { width: "8.97%" },
};
