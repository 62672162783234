import {connect, MapDispatchToProps, MapStateToProps} from "react-redux";
import SearchList, {ISearchListDispatchProps, ISearchListStateProps} from "./SearchList";
import {State} from "../../../ducks/rootReducer";
import {selectAuth, selectEntries, selectUserRole} from "../../../selectors/selectors";
import {
    checkDelete,
    getDetailedTerm,
    getSearchBySort,
    onSetLemmaSearch,
    setAdditionalInformationStage,
    setDetailedRow,
    setDetailedTerm,
    setEditableTerm,
} from "../../../ducks/entries/entries";

const mapStateToProps: MapStateToProps<ISearchListStateProps, {}, State> = state => {
    const role = selectUserRole(state);
    const {entrySearch, entrySearchCount, detailedTerm, detailedRow, additionalInfoStage} = selectEntries(state);
    const {username} = selectAuth(state);
    return {
        stage: additionalInfoStage,
        entrySearch,
        entrySearchCount,
        role,
        detailedTerm,
        detailedRow,
        username,
    };
};

const dispatchProps: MapDispatchToProps<ISearchListDispatchProps, {}> = {
    getDetailedTerm,
    setDetailedRow,
    setDetailedTerm,
    onSetLemmaSearch,
    checkDelete,
    getSearchBySort,
    setAdditionalInformationStage,
    setEditableTerm,
};

export const SearchListConnected = connect(
    mapStateToProps,
    dispatchProps,
)(SearchList);
