import {connect, MapDispatchToProps, MapStateToProps} from "react-redux";
import {State} from "../../ducks/rootReducer";
import {UserList, UserListDispatchProps, UserListStateProps} from "./UserList";
import {selectPopup, selectUsers} from "../../selectors/selectors";
import {deleteUserAction, queryUsers, setActiveUser} from "../../ducks/users/users";
import {popupPush} from "../../ducks/popup/popup";

const mapStateToProps: MapStateToProps<UserListStateProps, {}, State> = state => {
    const usersState = selectUsers(state);
    const { spinnerStatus } = selectPopup(state);
    return {
        ...usersState,
        spinnerStatus,
    };
};

const dispatchProps: MapDispatchToProps<UserListDispatchProps, {}> = {
    onDelete: deleteUserAction,
    onEdit: setActiveUser,
    onQuery: queryUsers,
    redirectToCreateUser: setActiveUser,
    onPopupPush: popupPush,
};

export const UserListConnected = connect(
    mapStateToProps,
    dispatchProps,
)(UserList);
