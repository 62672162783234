import {connect, MapDispatchToProps, MapStateToProps} from "react-redux";
import {ActionPage, IActionPageStateProps} from "./ActionPage";
import {State} from "../../ducks/rootReducer";
import {selectAuth} from "../../selectors/selectors";
import {defineDifferenceBetweenTokenTimeIsNotPositive} from "../../helper/others";

const mapStateToProps: MapStateToProps<IActionPageStateProps, {}, State> = state => {
    let isTokenOutOfTime: boolean = false;
    const authState = selectAuth(state);
    if(authState && authState.token && authState.token.exp){
        isTokenOutOfTime = defineDifferenceBetweenTokenTimeIsNotPositive(authState.token.exp);
    }
    return {
        isTokenOutOfTime,
    };
};

const dispatchProps: MapDispatchToProps<any, {}> = {};

export const ActionPageConnected = connect(
    mapStateToProps,
    dispatchProps
)(ActionPage);
