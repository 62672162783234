import {BacklogProps, Entry} from "../api/entryApi";
import {getApiUtils} from "../api/utils";
import {refresh} from "../ducks/auth/auth";
import {selectAuth} from "../selectors/selectors";
import {ISearchFilterState} from "../components/Search/SearchFilter/SearchFilter";

const jsonToQuery = require('json-to-http-query-string');

export const TOKEN_TIME_TO_UPDATE = 900000;

export const deleteDuplicatedItems = (array: any[]) => {
    return array.filter((item, index) => {
        return array.indexOf(item) === index;
    });
};

export function tableCustomAlphabetSort(a: string, b: string) {
    let x = a ? a.toLowerCase() : "";
    let y = b ? b.toLowerCase() : "";
    // return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    return x.localeCompare(y, undefined, {ignorePunctuation: true});
}

export function defineEntryFilterIsNull (entryFilter: BacklogProps): boolean {
    let isNull = true;
    try {
        if(
            !entryFilter.userApp
            && !entryFilter.sourceApp
            && !entryFilter.objectIdApp
        ){
            return isNull
        } else {
            isNull = false
        }
    }
    catch {

    }

    return isNull;
}

export const defineDifferenceBetweenTokenTimeIsNotPositive = (exp: number) => {
    if(exp){
        const diff = ((exp * 1000) - new Date().getTime())
        return diff <= 0 || diff < TOKEN_TIME_TO_UPDATE;
    } else {
        return false;
    }
}

export const checkForTokenUpdate = async () => {
    const state = getApiUtils().getState();
    const authState = selectAuth(state);
    const token = authState && authState.token;
    const exp = token && token.exp;
//@ts-ignore
    if(exp && ((exp * 1000) - new Date().getTime() <= TOKEN_TIME_TO_UPDATE)){
        getApiUtils().dispatch(refresh());
    }
}

export const convertSearchStateToUrl = (state: any) => {
    return new URLSearchParams(jsonToQuery(state)).toString();
}

export const getSearchStateProcessed = () => {
    const url = window.location.href;
    let stateProcessed: ISearchFilterState | undefined;

    try {
        const urlProcessed = url.split("/search/");
        const urlPart = urlProcessed.length === 2 ? urlProcessed[urlProcessed.length - 1] : undefined;
        if (urlPart) {
            //@ts-ignore
            stateProcessed = Object.fromEntries(new URLSearchParams(urlPart));
            //@ts-ignore
            stateProcessed!.caseSensitive =  stringToBoolean(stateProcessed!.caseSensitive)
            //@ts-ignore
            stateProcessed!.caseSensitiveOpen =  stringToBoolean(stateProcessed!.caseSensitiveOpen)
            //@ts-ignore
            const langProcesses = stateProcessed.language.length > 0 ? stateProcessed.language.split("/") :"UNDEFINED/";
            //@ts-ignore
            stateProcessed.language = {code: langProcesses[0], name: langProcesses[1]};
        }
    } finally {

    }

    return stateProcessed;

}

export function stringToBoolean(string: string){
    switch(string.toLowerCase().trim()){
        case "true":
        case "yes":
        case "1":
            return true;

        case "false":
        case "no":
        case "0":
        case null:
            return false;

        default:
            return Boolean(string);
    }
}

export function getLexiconSplitError(error: string): any[] {
    let splitArray: any[] = [];

    try {
        splitArray = error.split("POS tag").map(elem => {
            const arr = /'(.*)'/.exec(elem);
            return arr && arr.length > 1 ? arr[1] : undefined
        }).filter(elem => elem)
    } catch {


    }

    return splitArray;
}

export function truncate(str: string, maxlength: number, shorter: string = '...'): string {
    return str
        ? (str.length > maxlength) && maxlength !== 0
            ? str.slice(0, maxlength - 1).trim() + shorter
            : str
        : "";
}

export const getIsCheckAllActive = (entries: Entry[]): boolean => {
    const checkedCount = entries.filter(({check}) => check).length;
    return checkedCount === entries.length && checkedCount > 0;
}
