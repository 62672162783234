import {connect, MapDispatchToProps, MapStateToProps} from "react-redux";
import {State} from "../../../../ducks/rootReducer";
import {EntryFilterDispatchProps, EntryFilterExternal, EntryFilterStateProps} from "./EntryFilterExternal";
import {selectAuth, selectEntries, selectUsersApp} from "../../../../selectors/selectors";
import {queryAllUniqueUsers, queryAllUsersApp} from "../../../../ducks/users/users";
import {
    backlogGetQuery,
    entriesSet,
    languagesQuery,
    queryObjectIdsApp,
    querySourcesApp,
    setEntryFilter,
    setEntrySorting,
    setExternalListPage
} from "../../../../ducks/entries/entries";
import {UserApp} from "../../../../api/userAppApi";

const mapStateToProps: MapStateToProps<EntryFilterStateProps, {}, State> = state => {
    const { languages, sources, objectIds, entries, entryLanguages, nets, posTags   } = selectEntries(state);

    let users: UserApp[] = selectUsersApp(state);
    const userId = selectAuth(state).id;

    return {
        users,
        languages,
        sources,
        objectIds,
        entries,
        entryLanguages,
        nets,
        posTags,
        userId,
    };
};

const dispatchProps: MapDispatchToProps<EntryFilterDispatchProps, {}> = {
    onQueryUsers: queryAllUniqueUsers,
    onQueryUsersApp: queryAllUsersApp,
    onQuerySourcesApp: querySourcesApp,
    onQueryObjectIdsApp: queryObjectIdsApp,
    onBacklogGetQuery: backlogGetQuery,
    onLanguagesQuery: languagesQuery,
    setEntryFilter,
    setExternalListPage,
    setEntrySorting,
    onSetEntries: entriesSet,
};

export const EntryFilterExternalConnected = connect(
    mapStateToProps,
    dispatchProps,
)(EntryFilterExternal);
