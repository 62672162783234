import React from "react";
import styles from "./../EntryFilter.module.scss";
import {Button, Icon, MuiThemeProvider, Select} from "@material-ui/core";
import {Language, LANGUAGE_UNDEFINED} from "../../../../api/languageApi";
import {User} from "../../../../api/userApi";
import DateFnsUtils from "@date-io/date-fns";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {customStyles, datePickerTheme, formatDate, theme} from "../../../../helper";
import {BacklogProps, Entry, OrderBy} from "../../../../api/entryApi";
import {BacklogStatuses} from "../../../../ducks/entries/entries";
import MenuItem from "@material-ui/core/MenuItem";
import {UserApp} from "../../../../api/userAppApi";
import {deleteDuplicatedItems, tableCustomAlphabetSort} from "../../../../helper/others";
import {IEntrySorting} from "../../EntryListBacklog/EntryListBacklog";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import {Net, NET_UNDEFINED} from "../../../../api/netApi";
import {POS_TAG_UNDEFINED, PosTag} from "../../../../api/posTagApi";

export interface EntryFilterStateProps {
    entries: Entry[];
    users: User[] | UserApp[];
    languages: Language[];
    sources: { name: string }[];
    objectIds: { name: string }[];
    entryLanguages: Language[];
    nets: Net[];
    posTags: PosTag[];
    userId: number;
}

export interface EntryFilterDispatchProps {
    onQueryUsers: () => void;
    onQueryUsersApp: (unique?: boolean) => void;
    onQuerySourcesApp: (unique?: boolean) => void;
    onQueryObjectIdsApp: (unique?: boolean) => void;
    onBacklogGetQuery: (props: BacklogProps) => void;
    setEntryFilter: (props: BacklogProps) => void;
    onLanguagesQuery: (unique?: boolean) => void;
    setExternalListPage: (page: number) => void;
    setEntrySorting: (sorting: IEntrySorting) => void;
    onSetEntries: (entries: Entry[]) => void;
}

type EntryFilterProps = EntryFilterStateProps & EntryFilterDispatchProps;

export interface EntryFilterState {
    userSelected: string;
    languageCode: string;
    from: string;
    to: string;
    source: string;
    objectId: string;
}

export class EntryFilterExternal extends React.PureComponent<EntryFilterProps, EntryFilterState> {
    constructor(props: EntryFilterProps) {
        super(props);

        this.state = {
            userSelected: undefined,
            languageCode: "",
            from: "1970-01-01",
            to: formatDate(Date.now()),
            source: "",
            objectId: "",
        };
    }

    componentDidMount(): void {
        this.loadBacklog(20);
    }

    render() {
        const {languages, sources, objectIds, users, entryLanguages, posTags, nets} = this.props;
        const {from, to, languageCode, userSelected, source, objectId} = this.state;
        const entries = this.defineDataForFilter();
        const allUsers = deleteDuplicatedItems(users).map(elem => ({name: elem}));
        const visibleAddButton: boolean = entryLanguages.length > 0 && nets.length > 0 && posTags.length > 0;

        let usersJSX =
            users.length > 0
                ? (users as UserApp[])
                    .filter(elem => {
                        if (elem.name) {
                            return elem
                        }
                    })
                    .sort((a, b) => {
                        return tableCustomAlphabetSort(a.name, b.name)
                    }).map((user: UserApp) => ({
                        value: `${user.name}`,
                        label: user.name,
                    }))
                : [];

        const filterLanguages = (languages as Language[])
            .filter(elem => {
                if (elem.name) {
                    return elem
                }
            })
            .sort((a, b) => {
                return tableCustomAlphabetSort(a.name, b.name)
            })
            .map((language: Language) => ({
                value: `${language.code}`,
                label: language.name,
            }));

        const filterObjectIds = (objectIds as { name: string }[])
            .sort((a, b) => {
                return tableCustomAlphabetSort(a.name, b.name)
            })
            .map((objectId: { name: string }) => ({
                value: objectId.name,
                label: objectId.name,
            }));

        const filterSources = (sources as { name: string }[])
            .sort((a, b) => {
                return tableCustomAlphabetSort(a.name, b.name)
            })
            .map((source: { name: string }) => ({
                value: source.name,
                label: source.name,
            }));

        return (<div className={styles.filterContainer}>
                <div className={styles.containerStart}>
                    <div className={styles.subContainerOther}>
                        <Button
                            disabled={!visibleAddButton}
                            variant="outlined"
                            className={styles.addButton}
                            color="primary"
                            onClick={() => this.entryAdd()}
                        >
                            ADD ENTRY <Icon className={styles.plusIcon}/>
                        </Button>
                    </div>
                </div>
                <div className={styles.container}>
                    <div className={styles.subContainerOther}>
                        <MuiThemeProvider theme={theme}>
                            <ThemeProvider theme={theme}>
                                <Select
                                    displayEmpty
                                    className={styles.filterSelect}
                                    value={userSelected}
                                    onChange={this.handleSelectChangeData("userSelected")}
                                    renderValue={!userSelected
                                        ? () => {
                                            return <span>
                                        User
                                    </span>
                                        }
                                        : undefined}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left"
                                        },
                                        getContentAnchorEl: null,
                                        marginThreshold: 110
                                    }}
                                >
                                    <MenuItem key={"undefined"} value={""}/>
                                    {usersJSX.map(user => (
                                        <MenuItem key={user.value} value={user.value}>
                                            {user.label}
                                        </MenuItem>))}
                                </Select>
                            </ThemeProvider>
                        </MuiThemeProvider>
                    </div>
                    <div className={styles.subContainerOther}>
                        <ThemeProvider theme={theme}>
                            <Select
                                renderValue={!languageCode
                                    ? () => {
                                        return <span>
                                        Language
                                    </span>
                                    }
                                    : undefined}
                                displayEmpty
                                className={styles.filterSelect}
                                value={languageCode}
                                onChange={this.handleSelectChangeData("languageCode")}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null,
                                    marginThreshold: 110
                                }}
                            >
                                <MenuItem key={"undefined"} value={""}/>
                                {filterLanguages.map(language => (
                                    <MenuItem key={language.value} value={language.value}>
                                        {language.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </ThemeProvider>
                    </div>
                    <div className={styles.subContainerOther}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <ThemeProvider theme={datePickerTheme}>
                                <KeyboardDatePicker
                                    onFocus={() => {}}
                                    onBlur={() => {}}
                                    className={styles.dateInput}
                                    disableToolbar
                                    variant="inline"
                                    format="yyyy-MM-dd"
                                    margin="normal"
                                    id="date-picker-inline-1"
                                    value={from}
                                    style={{
                                        color: "#FFFFFF",
                                        outline: "none",
                                        borderRadius: "5px",
                                    }}
                                    inputProps={{
                                        style: {
                                            color: "#FFFFFF",
                                            borderRadius: "5px",
                                        },
                                    }}
                                    onChange={(date: any) => {
                                        this.handleChangeData(date, "from");
                                    }}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                        style: {
                                            color: "#FFFFFF",
                                        },
                                    }}
                                    keyboardIcon={<Icon className={styles.dateIcon}/>}
                                />

                            </ThemeProvider>
                        </MuiPickersUtilsProvider>
                    </div>
                    <div className={styles.subContainerOther}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <ThemeProvider theme={datePickerTheme}>
                                <KeyboardDatePicker
                                    onFocus={() => {}}
                                    onBlur={() => {}}
                                    className={styles.dateInput}
                                    disableToolbar
                                    variant="inline"
                                    format="yyyy-MM-dd"
                                    margin="normal"
                                    id="date-picker-inline-2"
                                    value={to}
                                    style={{
                                        color: "#FFFFFF",
                                        outline: "none",
                                        borderRadius: "5px",
                                    }}
                                    inputProps={{
                                        style: {
                                            color: "#FFFFFF",
                                            borderRadius: "5px",
                                        },
                                    }}
                                    onChange={(date: any) => {
                                        this.handleChangeData(date, "to");
                                    }}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                        style: {
                                            color: "#FFFFFF",
                                        },
                                    }}
                                    keyboardIcon={<Icon className={styles.dateIcon}/>}
                                />
                            </ThemeProvider>
                        </MuiPickersUtilsProvider>
                    </div>

                    <div className={styles.subContainerOther}>
                        <ThemeProvider theme={theme}>
                            <Select
                                renderValue={!source
                                    ? () => {
                                        return <span>
                                        Source
                                    </span>
                                    }
                                    : undefined}
                                displayEmpty
                                className={styles.filterSelect}
                                value={source}
                                onChange={this.handleSelectChangeData("source")}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null,
                                    marginThreshold: 110
                                }}
                            >
                                <MenuItem key={"undefined"} value={""}/>
                                {filterSources.map(source => (
                                    <MenuItem key={source.value} value={source.value}>
                                        {source.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </ThemeProvider>
                    </div>
                    <div className={styles.subContainerOther}>
                        <ThemeProvider theme={theme}>
                            <Select
                                renderValue={!objectId
                                    ? () => {
                                        return <span>
                                        Object Id
                                    </span>
                                    }
                                    : undefined}
                                displayEmpty
                                className={styles.filterSelect}
                                value={objectId}
                                onChange={this.handleSelectChangeData("objectId")}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null,
                                    marginThreshold: 110
                                }}
                            >
                                <MenuItem key={"undefined"} value={""}/>
                                {filterObjectIds.map(objectId => (
                                    <MenuItem key={objectId.value} value={objectId.value}>
                                        {objectId.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </ThemeProvider>
                    </div>
                    <div className={styles.subContainerButton}>
                        <Button
                            variant="outlined"
                            className={styles.searchButton}
                            color="primary"
                            onClick={() => this.loadBacklog(20)}
                        >
                            LOAD
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

    entryAdd = () => {
        const newEntries = [...this.props.entries];

        let newEntry: Entry = {
            id: 0,
            check: false,
            created: true,
            language: LANGUAGE_UNDEFINED,
            spelling: [""],
            lemma: "",
            posTag: POS_TAG_UNDEFINED,
            synonym: "",
            itSelf: true,
            nets: NET_UNDEFINED,
            notifications: [],
            date: formatDate(Date.now()),
            userId: this.props.userId,
            source_app: undefined,
            date_app: undefined,
            user_app: undefined,
            account_app: undefined,
            objectid_app: undefined,
        };

        newEntry.id = Date.now();
        newEntries.unshift(newEntry);

        this.props.onSetEntries(newEntries);
    };

    private defineDataForFilter = () => {
        const {entries} = this.props;
        const {languageCode, source, objectId, userSelected} = this.state;
        let newData: Entry[] = [];

        if (entries.length === 0) return [];

        if (userSelected && userSelected !== "") {
            newData = entries.filter(elem => userSelected === elem.user_app);
        }

        if (languageCode !== "") {
            const arrayToFilter = newData.length > 0 ? newData : entries;
            newData = arrayToFilter.filter(elem => elem.language.code === languageCode);
        }

        if (source !== "") {
            const arrayToFilter = newData.length > 0 ? newData : entries;
            newData = arrayToFilter.filter(elem => elem.source_app === source);
        }

        if (objectId !== "") {
            const arrayToFilter = newData.length > 0 ? newData : entries;
            newData = arrayToFilter.filter(elem => elem.objectid_app === objectId);
        }

        return newData.length === 0 ? entries : newData;
    }

    private loadBacklog = (limit: number = 20) => {
        const {
            setEntryFilter,
            onQueryObjectIdsApp,
            onQuerySourcesApp,
            onQueryUsersApp,
            onBacklogGetQuery,
            onLanguagesQuery,
            entries,
            setExternalListPage,
        } = this.props
        const {from, to, userSelected, languageCode, source, objectId} = this.state;
        const props: BacklogProps = {from, to, limit};

        if (userSelected) {
            props.userApp = userSelected;
        }
        if (languageCode !== "" && languageCode !== LANGUAGE_UNDEFINED.code) props.lang = languageCode;
        if (source !== "") props.sourceApp = source;
        if (objectId !== "") props.objectIdApp = objectId;

        if (userSelected && entries.length === 0) {
            props.userApp = undefined
        }

        if (languageCode && entries.length === 0) {
            props.lang = undefined
        }

        if (source && entries.length === 0) {
            props.sourceApp = undefined
        }

        if (objectId && entries.length === 0) {
            props.objectIdApp = undefined
        }

        props.status = BacklogStatuses.BACKLOG_EXTERNAL_APP;

        this.props.setEntrySorting({
            language: undefined,
            lemma: undefined,
            date: undefined,
            net: undefined,
            user: undefined,
            objectId: undefined,
            partOfSpeech: undefined,
            synonym: undefined,
            source: undefined,
            orderBy: OrderBy.Undefined,
            orderByReversed: false,
        });

        setEntryFilter({...props});
        onBacklogGetQuery(props);
        onQueryUsersApp(true);
        onQuerySourcesApp(true);
        onQueryObjectIdsApp(true);
        onLanguagesQuery(true);
        setExternalListPage(0)
    };

    private handleSelectChangeData = (field: string) => {
        return (event: any) => {
            const value = event.target.value;
            this.handleChangeData(value, field);
        };
    };

    private handleSearchSelectChangeData = (field: string) => {
        return (event: any) => {
            const value = event;
            this.handleChangeData(value, field);
        };
    };

    private handleChangeData = (data: any, field: string) => {
        const dataRes = field === "from" || field === "to" ? formatDate(new Date(data)) : data;

        const state = {...this.state};
        // @ts-ignore
        state[`${field}`] = dataRes;

        this.setState(state);
    };
}
