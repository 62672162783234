import {connect, MapDispatchToProps, MapStateToProps} from "react-redux";
import {State} from "../../ducks/rootReducer";
import {UserEditor, UserEditorDispatchProps, UserEditorStateProps} from "./UserEditor";
import {selectActiveUser} from "../../selectors/selectors";
import {cancelChanges, saveChanges} from "../../ducks/users/users";

const mapStateToProps: MapStateToProps<UserEditorStateProps, {}, State> = state => {
    const user = selectActiveUser(state)!;

    return {
        ...user,
    };
};

const dispatchProps: MapDispatchToProps<UserEditorDispatchProps, {}> = {
    onCancel: cancelChanges,
    onSave: saveChanges,
};

export const UserEditorConnected = connect(
    mapStateToProps,
    dispatchProps,
)(UserEditor);
