import {connect, MapDispatchToProps, MapStateToProps} from "react-redux";
import {State} from "../../ducks/rootReducer";
import {Logout, LogoutDispatchProps, LogoutStateProps} from "./Logout";
import {logout} from "../../ducks/auth/auth";
import {selectAuth, selectUserRole} from "../../selectors/selectors";

const mapStateToProps: MapStateToProps<LogoutStateProps, {}, State> = state => {
    const {username} = selectAuth(state);

    return {
        username,
        role: selectUserRole(state),
    };
};

const dispatchProps: MapDispatchToProps<LogoutDispatchProps, {}> = {
    onLogout: logout
};

export const LogoutConnected = connect(
    mapStateToProps,
    dispatchProps,
)(Logout);
