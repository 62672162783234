import * as React from "react";

import styles from "./UserEditorPage.module.scss";
import {UserEditorConnected} from "../../components/UserEditor/UserEditorConnected";
import {PopupDialogConnected} from "../../components/PopupDialog/PopupDialogConnected";

export class UserEditorPage extends React.PureComponent {
    public render() {
        return (
            <div className={styles.container}>
                <PopupDialogConnected />
                <UserEditorConnected />
            </div>
        );
    }
}
