import {connect, MapDispatchToProps, MapStateToProps} from "react-redux";

import {
    AdditionalInformation,
    IAdditionalInformationDispatchProps,
    IAdditionalInformationStateProps
} from "./AdditionalInformation";
import {State} from "../../../ducks/rootReducer";
import {selectEntries, selectUserRole} from "../../../selectors/selectors";
import {checkDelete} from "../../../ducks/entries/entries";

const mapStateToProps: MapStateToProps<IAdditionalInformationStateProps, {}, State> = state => {
    const role = selectUserRole(state);
    const {detailedTerm, detailedRow, additionalInfoStage} = selectEntries(state);

    return {
        stage: additionalInfoStage,
        detailedTerm,
        detailedRow,
        role,
    };
};

const dispatchProps: MapDispatchToProps<IAdditionalInformationDispatchProps, {}> = {
    checkDelete,
};

export const AdditionalInformationConnected = connect(
    mapStateToProps,
    dispatchProps,
)(AdditionalInformation);
