import {Endpoints, getEndpoint} from "./endpoints";
import {BaseApi} from "./baseApi";
import {PropsSynonymLang} from "./lemmaApi";

export interface Synonym {
    label: string;
    posTag: string;
}

export interface PropsLangLemmaPos {
    lemma: string;
    lang: string;
    posTag: string;
}

class SynonymApi extends BaseApi {
    Endpoint = Endpoints.Synonym;

    getAutocomplete(props: PropsSynonymLang) {
        const res = getEndpoint(this.Endpoint, "autocomplete")
            .query(props)
            .get()
            .res();

        return res;
    }

    getByLemmaPos(props: PropsLangLemmaPos) {
        const res = getEndpoint(this.Endpoint, "byLemmaPos")
            .query(props)
            .get()
            .res();

        return res;
    }
}

export function getSynonymApiObj() {
    return new SynonymApi();
}
