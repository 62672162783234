import React from "react";
import moment from "moment";

import {UserRole} from "../../../api/userApi";
import {ITerm} from "../../../api/termApi";
import {AdditionalInformationStage, ILemmaSearchField,} from "../../../ducks/entries/entries";
import {IDeleteCheckQuery} from "../../../api/spellingApi";

import styles from "./AdditionalInformation.module.scss";
import {truncate} from "../../../helper/others";

export interface IAdditionalInformationStateProps {
    detailedTerm: ITerm | undefined;
    detailedRow: ILemmaSearchField | undefined;
    role: UserRole | undefined;
    stage: AdditionalInformationStage;
}

export interface IAdditionalInformationDispatchProps {
    checkDelete: (params: IDeleteCheckQuery) => void;
}

type AdditionalInformationProps = IAdditionalInformationStateProps & IAdditionalInformationDispatchProps;

export function AdditionalInformation(props: AdditionalInformationProps) {
    const {detailedTerm, detailedRow, role} = props;

    return <div className={styles.bottomPart}>
        <div className={styles.topBottomPart}>
            {!detailedTerm && !detailedRow && (
                <div className={styles.detailedNetHeaderEmpty}>{"No term selected"}</div>
            )}
            {detailedTerm && (
                <div className={styles.detailedNet}>
                    <div title={detailedTerm.label} className={styles.detailedNetHeader}>
                        {truncate(detailedTerm.label, 25)}{" "}
                        <span className={styles.subElemSpanGrey}>{detailedTerm.posTag}</span>
                    </div>
                    <div className={styles.info}>
                        {detailedTerm.inserted_at && (
                            <div className={styles.infoRow}>
                                <div className={styles.infoRowLeftItem}>Created by</div>
                                <div className={styles.infoRowRightItem}>{detailedTerm.inserted_by}</div>
                            </div>
                        )}
                        {detailedTerm.updated_by && (
                            <div className={styles.infoRow}>
                                <div className={styles.infoRowLeftItem}>Last modified by</div>
                                <div className={styles.infoRowRightItem}>{detailedTerm.updated_by}</div>
                            </div>
                        )}
                        {detailedTerm.inserted_at && (
                            <div className={styles.infoRow}>
                                <div className={styles.infoRowLeftItem}>Created</div>
                                <div className={styles.infoRowRightItem}>
                                    {moment(detailedTerm.inserted_at).format("YYYY-MM-DD")}
                                </div>
                            </div>
                        )}
                        {detailedTerm.updated_at && (
                            <div className={styles.infoRow}>
                                <div className={styles.infoRowLeftItem}>Last modified</div>
                                <div className={styles.infoRowRightItem}>
                                    {moment(detailedTerm.updated_at).format("YYYY-MM-DD")}
                                </div>
                            </div>
                        )}
                    </div>
                    {(detailedTerm.nets.length > 0 || detailedTerm.location.length > 0) &&
                    <div className={styles.header}>NETs</div>}
                    {detailedTerm.nets.length > 0 && (
                        <React.Fragment>
                            {detailedTerm.nets.map(net => {
                                return <div className={styles.subElemNet}>{net}</div>;
                            })}
                        </React.Fragment>
                    )}
                    {detailedTerm.location.length > 0 && (
                        <React.Fragment>
                            <div className={styles.subElemNet}>location</div>
                            <div className={`${styles.info} ${styles.infoLocationMargin}`}>
                                {detailedTerm.location.map(location => {
                                    return (
                                        ((location.latitude || location.latitude === 0) && (location.longitude || location.longitude === 0) ) ? <React.Fragment>
                                            <div
                                                style={{justifyContent: "flex-start !important"}}
                                                className={styles.infoRow}
                                            >
                                                <div
                                                    className={`${styles.infoRowLeftItemWithoutWidth} ${styles.locationLeftItemWidth}`}>
                                                    <i className={styles.locationIcon}/>
                                                </div>
                                                <div className={styles.infoRowRightItemWithoutWidth}>
                                                    {location.latitude}, {location.longitude}
                                                </div>
                                            </div>
                                            <div
                                                style={{justifyContent: "flex-start !important"}}
                                                className={styles.infoRow}
                                            >
                                                <div className={styles.infoRowLeftItemWithoutWidth}></div>
                                                <div className={styles.infoRowRightItemWithoutWidth}>
                                                    {location.level}
                                                </div>
                                            </div>
                                        </React.Fragment> : <></>
                                    );
                                })}
                            </div>
                        </React.Fragment>
                    )}

                </div>
            )}
        </div>
        {([UserRole.ROLE_ADMIN, UserRole.ROLE_MAIN_ANNOTATOR].includes(props.role as UserRole) && detailedTerm) && (
            <div className={styles.deleteButtonContainer}>
                <div
                    className={styles.button}
                >
                    <div
                        onClick={() => {
                            props.checkDelete({
                                //@ts-ignore
                                lang: detailedTerm.lang,
                                //@ts-ignore
                                posTag: detailedTerm.posTag,
                                label: detailedTerm.label,
                            });
                        }}
                        className={styles.buttonRow}
                    >
                        <i className={styles.deleteIcon}/>
                        <span>Delete</span>
                    </div>
                </div>
            </div>
        )}
    </div>

}
