import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import styles from "./SearchFilter.module.scss";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import {Button, Grow, Icon, Input, MenuItem, Paper, Popper,} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {Language, LANGUAGE_UNDEFINED} from "../../../api/languageApi";
import {PosTag} from "../../../api/posTagApi";
import moment from "moment";
import {ILemmaLastInsertionsQueryProps, ILemmaSearchQueryProps} from "../../../api/lemmaApi";
import Fab from "@material-ui/core/Fab";
import MultiSelect from "react-select";
import {convertSearchStateToUrl, getSearchStateProcessed} from "../../../helper/others";
import InputAdornment from "@material-ui/core/InputAdornment";
import {ThemeProvider} from "@material-ui/styles";
import {customStyles, datePickerTheme, theme} from "../../../helper";

const COMMON_BLUE = "#0E4DB7";
const COMMON_BLACK = "#091124";

export interface ISelectorItem {
    label: string;
    value: any;
}

export enum ESortDirection {
    DESC = "descending",
    ASC = "ascending",
}

export enum ESearchRadio {
    STARTS_WITH = "starts",
    CONTAINS = "contains",
    EXACT_MATCH = "exact",
}

export enum ESortBy {
    DATE = "date",
    SYNONYM = "synonym",
    LEMMA = "lemma",
}

export enum EWordPartOrSearchParam {
    SPELLING = "spelling",
    LEMMA = "lemma",
    SYNONYM = "synonym",
}

export const FILTER_SELECTOR: ISelectorItem[] = [
    {
        label: "Spelling",
        value: EWordPartOrSearchParam.SPELLING,
    },
    {
        label: "Lemma",
        value: EWordPartOrSearchParam.LEMMA,
    },
    {
        label: "Synonym",
        value: EWordPartOrSearchParam.SYNONYM,
    },
];

export const SORT_SELECTOR: ISelectorItem[] = [
    {
        label: "Synonym",
        value: ESortBy.SYNONYM,
    },
    {
        label: "Lemma",
        value: ESortBy.LEMMA,
    },
    {
        label: "Date",
        value: ESortBy.DATE,
    },
];

export interface ISearchFilterStateProps {
    languages: Language[];
    posTags: PosTag[];
    path: string;
    isInLemmaSearch: boolean;
}

export interface ISearchFilterDispatchProps {
    onSearch: (props: ILemmaSearchQueryProps) => void;
    getLemmaLastInsertions: (props: ILemmaLastInsertionsQueryProps) => void;
    onSetSearchPage: (page: number) => void;
}

export interface ISearchFilterState {
    language: Language;
    caseSensitive: boolean;
    searchParam: ESearchRadio | undefined;
    wordPart: EWordPartOrSearchParam;
    posTags: string;
    search: string;
    insDate: string;
    endDate: string;
    direction: ESortDirection;
    sortBy: ESortBy;
    caseSensitiveOpen: boolean;
}

export interface ISearchParam {
    label: string;
    value: ESearchRadio;
}

//@ts-ignore
let stateProcessed: ISearchFilterState | undefined = getSearchStateProcessed();

export const searchFilterState: ISearchFilterState = stateProcessed ? stateProcessed : {
    posTags: "",
    caseSensitive: false,
    language: LANGUAGE_UNDEFINED,
    searchParam: ESearchRadio.STARTS_WITH,
    wordPart: EWordPartOrSearchParam.SPELLING,
    insDate: "1970-01-01",
    endDate: moment().format("YYYY-MM-DD"),
    search: "",
    direction: ESortDirection.DESC,
    sortBy: ESortBy.DATE,
    caseSensitiveOpen: false,
}

const getState = () => {

    return {...searchFilterState};
}

export type ISearchFilterProps = ISearchFilterStateProps & ISearchFilterDispatchProps;

const searchParams: ISearchParam[] = [
    {
        label: "Starts with",
        value: ESearchRadio.STARTS_WITH,
    },
    {
        label: "Contains",
        value: ESearchRadio.CONTAINS,
    },
    {
        label: "Exact match",
        value: ESearchRadio.EXACT_MATCH,
    },
];

export default function SearchFilter(props: ISearchFilterProps) {
    const [state, setState] = useState(() => getState());
    const searchInput = useRef();
    const {
        searchParam,
        wordPart,
        posTags,
        language,
        caseSensitive,
        search,
        sortBy,
        direction,
        caseSensitiveOpen
    } = state;
    const searchButtonDisabled = !language || language && language.code === LANGUAGE_UNDEFINED.code || search.length < 3 && (!["ZHCN", "ZHTW"].includes(language.code))
    const disableFilters = language !== undefined && language.code === LANGUAGE_UNDEFINED.code || search !== undefined && search.length < 2;
    const newState = getSearchStateProcessed();

    useEffect(() => {
        return () => {
            return setState({...searchFilterState})
        }
    }, [])

    useLayoutEffect(() => {
        if (newState) {
            setState({...newState})
            if(!props.isInLemmaSearch){
                //@ts-ignore
                const {
                    search,
                    searchParam,
                    wordPart,
                    posTags,
                    language,
                    caseSensitive,
                    insDate,
                    endDate,
                    sortBy,
                    direction,
                } = newState;
                const requestProps: ILemmaSearchQueryProps = {
                    lang: language.code.toLowerCase(),
                    lower: search,
                    field: wordPart,
                    caseSensitive,
                    type: searchParam,
                    posTags: posTags.length > 0 ? posTags : "",
                    limit: 20,
                    direction: direction,
                    sort: sortBy,
                    after: new Date(insDate).toISOString(),
                    before: new Date(endDate).toISOString(),
                }
                props.onSearch({
                    ...requestProps,
                })
                props.onSetSearchPage(1);
            }
        } else {
            setState({...searchFilterState})
            props.getLemmaLastInsertions({
                language: "",
                limit: 21,
                page: 1,
            })
        }
    }, [props.path])

    const handleChange = (name: keyof ISearchFilterState) => (event: any) => {
        const {
            search,
            searchParam,
            wordPart,
            posTags,
            language,
            caseSensitive,
            insDate,
            endDate,
            sortBy,
            direction,
        } = state;
        let value = event.target;
        let newValue;
        switch (name) {
            case "caseSensitive":
                newValue = value.checked;
                break;
            case "posTags":
                newValue = event && event.length > 0 ? event.map((pos: { label: string }) => pos.label).join(",") : "";
                break;
            case "searchParam":
                newValue = value.value;
                if (state.searchParam === newValue) newValue = "";
                break;
            case "language":
                newValue = {
                    code: value.value,
                    name: "",
                };
                const requestProps: ILemmaSearchQueryProps = {
                    lang: value.value.toLowerCase(),
                    lower: state.search,
                    field: wordPart,
                    caseSensitive,
                    type: searchParam,
                    posTags: posTags.length > 0 ? posTags : "",
                    limit: 20,
                    direction: direction,
                    sort: sortBy,
                    after: new Date(insDate).toISOString(),
                    before: new Date(endDate).toISOString(),
                };
                if ((state.search.length >= 3 && !["ZHCN", "ZHTW"].includes(newValue.code))
                    || (state.search.length >= 2 && ["ZHCN", "ZHTW"].includes(newValue.code))
                ) {
                    props.onSearch({
                        ...requestProps,
                    });
                    window.history.pushState(null, "/search/", `/search/?${convertSearchStateToUrl({
                        ...state, language: `${newValue.code}/${newValue.name}`
                    })}`)
                    props.onSetSearchPage(1);
                } else {
                    props.getLemmaLastInsertions({language: value.value.toLowerCase(), limit: 21});
                }
                break;
            default:
                newValue = value.value;
        }
        const newState = {
            ...state,
            [name]: newValue,
        };
        setState({
            ...newState,
        });
    };
    const onSearch = (isCaseSensitiveOpen: boolean | undefined = undefined) => {
        const {
            search,
            searchParam,
            wordPart,
            posTags,
            language,
            caseSensitive,
            insDate,
            endDate,
        } = state;
        window.history.pushState(null, "/search/", `/search/?${convertSearchStateToUrl({
            ...state, caseSensitiveOpen:isCaseSensitiveOpen === undefined ? caseSensitive : isCaseSensitiveOpen, language: `${state.language.code}/${state.language.name}`
        })}`)

        const requestProps: ILemmaSearchQueryProps = {
            lang: language.code.toLowerCase(),
            lower: search,
            field: wordPart,
            caseSensitive,
            type: searchParam,
            posTags: posTags.length > 0 ? posTags : "",
            limit: 20,
            direction: direction,
            sort: sortBy,
            after: new Date(insDate).toISOString(),
            before: new Date(endDate).toISOString(),
        }
        props.onSearch({
            ...requestProps,
        })
        props.onSetSearchPage(1);
    }
    //@ts-ignore
    return (
        <div className={styles.filterContainer}>
            <div className={styles.filterContainerItem}>
                <ThemeProvider theme={theme}>
                    <Select
                        renderValue={state.language.code === LANGUAGE_UNDEFINED.code
                            ? () => {
                                return <span>
                                        Language
                                    </span>
                            }
                            : undefined}
                        placeholder={"Language"}
                        onChange={handleChange("language")}
                        value={state.language.code}
                        className={styles.filterSelect}
                    >
                        {props.languages.map((elem, index) => (
                            <MenuItem key={`${index}${new Date().getTime()}`} value={elem.code}>{elem.name}</MenuItem>
                        ))}
                    </Select>
                </ThemeProvider>
            </div>
            <div className={`${styles.filterContainerItem}`}>
                <Input
                    ref={searchInput}
                    value={state.search}
                    className={styles.filterInput}
                    startAdornment={<InputAdornment position="start">
                        <i className={styles.searchIcon}/>
                    </InputAdornment>}
                    inputProps={{
                        maxLength: 50,
                    }}
                    onFocus={() => {
                        setState((prevState) => {
                            return {
                                ...prevState,
                                caseSensitiveOpen: true,
                            }
                        })
                    }}
                    onBlur={() => {
                        setState((prevState) => {
                            return {
                                ...prevState,
                                caseSensitiveOpen: false,
                            }
                        })
                    }}
                    onChange={handleChange("search")}
                    onKeyDown={event => {
                        if (event.keyCode === 13 && !searchButtonDisabled) {
                            if (searchInput) {
                                try {
                                    //@ts-ignore
                                    event.currentTarget.blur();
                                } catch {

                                }
                            }
                            setState((prevState) => {
                                return {
                                    ...prevState,
                                    caseSensitiveOpen: false,
                                }
                            });
                            onSearch(false);
                        }
                    }}
                />
                <Popper
                    open={caseSensitiveOpen}
                    transition
                    disablePortal
                    style={{
                        position: "absolute",
                        width: '180px',
                        top: '50px',
                        left: '1px',
                        zIndex: 999998,
                        borderRadius: "8px",
                        backgroundColor: COMMON_BLACK,
                    }}
                >
                    {({TransitionProps, placement}) => (
                        <Grow
                            style={{
                                backgroundColor: COMMON_BLACK,
                            }}
                            {...TransitionProps}
                        >
                            <Paper style={{
                                backgroundColor: COMMON_BLACK,
                            }}>
                                <div onClick={() => {
                                    setState((prevState) => {
                                        return {
                                            ...prevState,
                                            caseSensitive: !prevState.caseSensitive,
                                            caseSensitiveOpen: true,
                                        }
                                    })
                                }} className={styles.filterContainerItemSubRow}>
                                    <div className={styles.filterContainerItemSubRowItem}>
                                        <Checkbox
                                            onChange={(e, checked) => {
                                                handleChange("caseSensitive")(e);
                                                if (searchInput) {
                                                    try {
                                                        //@ts-ignore
                                                        searchInput.current.click();
                                                    } catch {

                                                    }
                                                }
                                            }}
                                            checked={caseSensitive}
                                            className={styles.checkbox}
                                            color={"primary"}
                                        />
                                    </div>
                                    <div className={styles.filterContainerItemSubRowItem}>Case sensitive</div>
                                </div>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
            <div className={styles.filterContainerItem}>
                <ThemeProvider theme={theme}>
                    <Select
                        style={{
                            width: "105px"
                        }}
                        onChange={handleChange("searchParam")}
                        value={searchParam}
                        className={`${styles.filterSelect} ${disableFilters && styles.selectDisabled}`}
                        disabled={disableFilters}
                    >
                        {searchParams.map((elem, index) => {
                            return <MenuItem
                                key={`${index}${new Date().getTime()}`}
                                value={elem.value}
                            >
                                {elem.label}
                            </MenuItem>
                        })}
                    </Select>
                </ThemeProvider>
            </div>
            <div className={styles.filterContainerItem}>
                <ThemeProvider theme={theme}>
                    <Select
                        style={{
                            width: "100px"
                        }}
                        onChange={handleChange("wordPart")}
                        value={wordPart}
                        disabled={disableFilters}
                        className={`${styles.filterSelect} ${disableFilters && styles.selectDisabled}`}
                    >
                        {FILTER_SELECTOR.map((elem, index) => {
                            return <MenuItem
                                key={`${index}${new Date().getTime()}`}
                                value={elem.value}
                            >
                                {elem.label}
                            </MenuItem>;
                        })}
                    </Select>
                </ThemeProvider>
            </div>
            <div className={`${styles.filterContainerItem} ${styles.inputBorderRadius} ${disableFilters && styles.selectOpacity} ${disableFilters && styles.inputDisabled}`}>
                <MultiSelect
                    isDisabled={disableFilters}
                    isClearable
                    closeMenuOnSelect={false}
                    styles={customStyles}
                    isMulti={true}
                    placeholder={"POS tag"}
                    className={styles.filterList}
                    options={props.posTags.map(elem => {
                        return {
                            label: elem.name,
                            value: elem.name,
                        };
                    })}
                    value={
                        posTags && posTags.length > 0
                            ? posTags.split(",").map(elem => {
                                return {value: elem, label: elem};
                            })
                            : []
                    }
                    displayEmpty
                    //@ts-ignore
                    onChange={event => {
                        setState(prevState => {
                            return {
                                ...prevState,
                                posTags:
                                    event && event.length > 0
                                        ? event.map((pos: { label: string }) => pos.label).join(",")
                                        : "",
                            };
                        });
                    }}
                >
                </MultiSelect>
            </div>
            <div className={styles.filterContainerItem}>
                <div className={styles.filterContainerItemSubRow}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <ThemeProvider theme={datePickerTheme}>
                            <KeyboardDatePicker
                                onFocus={() => {}}
                                onBlur={() => {}}
                                disabled={disableFilters}
                                className={`${styles.dateInput} ${disableFilters && styles.dateInputDisabled}`}
                                disableToolbar
                                variant="inline"
                                format="yyyy-MM-dd"
                                id="date-picker-inline-2"
                                value={state.insDate}
                                style={{
                                    color: "#FFFFFF",
                                    outline: "none",
                                    borderRadius: "5px",
                                }}
                                onChange={(date: any) => {
                                    setState({...state, insDate: moment(date).format("YYYY-MM-DD")});
                                }}
                                inputProps={{
                                    style: {
                                        color: "#FFFFFF",
                                        borderRadius: "5px",
                                    },
                                }}
                                KeyboardButtonProps={{
                                    "aria-label": "change date",
                                    style: {
                                        color: "#FFFFFF",
                                    },
                                }}
                                keyboardIcon={<Icon className={styles.dateIcon}/>}
                            />
                        </ThemeProvider>
                    </MuiPickersUtilsProvider>
                </div>
            </div>
            <div className={styles.filterContainerItem}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <ThemeProvider theme={datePickerTheme}>
                        <KeyboardDatePicker
                            onFocus={() => {}}
                            onBlur={() => {}}
                            style={{
                                height: "41px"
                            }}
                            disabled={disableFilters}
                            className={`${styles.dateInput} ${disableFilters && styles.dateInputDisabled}`}
                            disableToolbar
                            variant="inline"
                            format="yyyy-MM-dd"
                            margin="normal"
                            id="date-picker-inline-2"
                            value={state.endDate}
                            onChange={(date: any) => {
                                setState({...state, endDate: moment(date).format("YYYY-MM-DD")});
                            }}
                            inputProps={{
                                style: {
                                    flexDirection: "row",
                                    color: "#FFFFFF",
                                },
                            }}
                            KeyboardButtonProps={{
                                "aria-label": "change date",
                                style: {
                                    color: "#FFFFFF",
                                },
                            }}
                            keyboardIcon={<Icon className={styles.dateIcon}/>}
                        />
                    </ThemeProvider>
                </MuiPickersUtilsProvider>
            </div>
            <div className={styles.filterContainerItem}>
                {direction === ESortDirection.ASC && (
                    <Fab
                        disabled={disableFilters}
                        className={`${styles.sort} ${styles.sortBackground} ${disableFilters && styles.selectDisabled}`}
                        color="primary"
                        aria-label="add"
                        onClick={() => {
                            setState({...state, direction: ESortDirection.DESC});
                        }}
                    >
                        <div className={styles.sortDesIcon}/>
                    </Fab>
                )}
                {direction !== ESortDirection.ASC && (
                    <Fab
                        onClick={() => {
                            setState({...state, direction: ESortDirection.ASC});
                        }}
                        disabled={disableFilters}
                        className={`${styles.sort} ${styles.sortBackground} ${disableFilters && styles.selectDisabled}`}
                        color="primary"
                        aria-label="add"
                    >
                        <div className={styles.sortAskIcon}/>
                    </Fab>
                )}
            </div>
            <div className={styles.filterContainerItem}>
                <ThemeProvider theme={theme}>
                    <Select
                        style={{
                            width: "100px"
                        }}
                        onChange={handleChange("sortBy")}
                        value={sortBy}
                        disabled={disableFilters}
                        className={`${styles.filterSelect} ${disableFilters && styles.selectDisabled}`}
                    >
                        {SORT_SELECTOR.map((elem, index) => {
                            return <MenuItem
                                key={`${index}${new Date().getTime()}`}
                                value={elem.value}
                            >
                                {elem.label}
                            </MenuItem>;
                        })}
                    </Select>
                </ThemeProvider>
            </div>
            <div className={styles.filterContainerItem}>
                <Button
                    className={`${styles.searchButton} ${searchButtonDisabled ? styles.disabled : ""}`}
                    disabled={searchButtonDisabled}
                    onClick={() => onSearch(false)}
                    variant="contained"
                    color="primary"
                >
                    Search
                </Button>
            </div>
        </div>
    );
}
