import * as React from "react";
import styles from "./PopupLocation.module.scss";
import {MenuItem, TextField} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import {locationPopupStyles} from "../../helper";
import {ThemeProvider} from "@material-ui/styles";
import {ITerm} from "../../api/termApi";

export interface PopupLocationStateProps {
    isOpen: boolean;
    editableTerm: ITerm | undefined;
    levels: string[];
    handler: () => void;
}

export interface PopupLocationDispatchProps {
    setLocationPopupOpen: (flag: boolean) => void;
    setLocationPopupHandler: (handler: () => void) => void;
    setEditableTerm: (item: ITerm | undefined) => void;
}

export type PopupDialogProps = PopupLocationStateProps & PopupLocationDispatchProps;

export const LOCALITIES: string[] = [
    'airport',
    'point of interest',
    'locality',
    'natural feature',
    'park',
];

export class PopupLocation extends React.Component<PopupDialogProps> {

    state = {
        level: "default",
        longitude: "",
        latitude: "",
    }

    componentDidUpdate(prevProps: Readonly<PopupDialogProps>, prevState: Readonly<{}>, snapshot?: any) {
        if (this.props.isOpen !== prevProps.isOpen && !this.props.isOpen) {
            this.setState(() => ({
                level: "default",
                longitude: "",
                latitude: "",
            }))
        }
    }

    public render() {
        const {latitude, level, longitude} = this.state;
        const {isOpen} = this.props;
        const addButtonDisabled = level === 'default' || latitude === "" || longitude === "" || isNaN(parseFloat(latitude)) || isNaN(parseFloat(longitude));
        return isOpen ? (
            <div className={styles.popupBack}>
                <div className={styles.popupContainer}>
                    <div className={styles.posTagTitle}>
                        Enter new location's details
                    </div>
                    <div className={styles.editorContainer}>
                        <ThemeProvider theme={locationPopupStyles}>
                            <TextField
                                onChange={(event) => {
                                    if (event && event.target) {
                                        let value = event.target.value
                                        if(value){
                                           value = value.replace(',', ".")
                                        }
                                        if ((value.match(/^\d+[.{1,6}]?(\d{1,6}?)?$/) || value === "" || ((value[0] === "-") && value.substring(1).match(/^\d+[.{1,6}]?(\d{1,6}?)?$/))) || (value.length === 1 && value[0] === "-")) {
                                            if (value === '0' || value === "" || (parseFloat(value) <= 90 && parseFloat(value) >= -90) || (value.length === 1 && value[0] === "-")) {
                                                this.setState((prevState) => ({
                                                    ...prevState,
                                                    latitude: value,
                                                }))
                                            }
                                        }
                                    }
                                }}
                                value={latitude}
                                variant={'outlined'}
                                placeholder={'decimal latitude'}
                            />
                            <TextField
                                onChange={(event) => {
                                    if (event && event.target) {
                                        let value = event.target.value
                                        if(value){
                                            value = value.replace(',', ".")
                                        }
                                        if ((value.match(/^\d+[.{1,6}]?(\d{1,6}?)?$/) || value === "" || ((value[0] === "-") && value.substring(1).match(/^\d+[.{1,6}]?(\d{1,6}?)?$/))) || (value.length === 1 && value[0] === "-")) {
                                            if (value === '0' || value === "" || (parseFloat(value) <= 179.99 && parseFloat(value) >= -179.99) || (value.length === 1 && value[0] === "-")) {
                                                this.setState((prevState) => ({
                                                    ...prevState,
                                                    longitude: value,
                                                }))
                                            }
                                        }
                                    }
                                }}
                                value={longitude}
                                variant={'outlined'}
                                placeholder={'decimal longitude'}
                            />
                            <Select
                                onChange={(event) => {
                                    this.setState((prevState) => ({
                                        ...prevState,
                                        level: event.target.value,
                                    }))
                                }}
                                value={level}
                                variant={'outlined'}
                            >
                                <MenuItem style={{display: 'none'}} key={`index${new Date().getTime()}`}
                                          value={"default"}>level</MenuItem>
                                {this.props.levels.map((elem, index) => (
                                    <MenuItem key={`${index}${new Date().getTime()}`} value={elem}>{elem}</MenuItem>
                                ))}
                            </Select>
                        </ThemeProvider>
                    </div>
                    <div className={styles.buttonContainer}>
                        <button
                            disabled={addButtonDisabled}
                            className={`${styles.buttonAction} ${addButtonDisabled && styles.disabled}`}
                            onClick={() => {
                                if (this.props.editableTerm) {
                                    this.props.setEditableTerm({
                                        ...this.props.editableTerm,
                                        location: [
                                            ...this.props.editableTerm.location,
                                            {
                                                level: this.state.level,
                                                latitude: this.state.latitude ? parseFloat(this.state.latitude) : 0,
                                                longitude: this.state.longitude ? parseFloat(this.state.longitude) : 0,
                                                isNew: true,
                                            }
                                        ]
                                    })
                                    this.props.setLocationPopupOpen(false);
                                }
                                this.props.setLocationPopupHandler(() => {})
                            }}
                        >
                            Ok
                        </button>
                        <button className={styles.buttonCancel} onClick={() => {
                            this.props.handler && this.props.handler();
                            this.props.setLocationPopupOpen(false);
                            this.props.setLocationPopupHandler(() => {});
                        }}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        ) : (
            <></>
        );
    }
}
