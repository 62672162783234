import {connect, MapDispatchToProps, MapStateToProps} from "react-redux";
import {State} from "../../ducks/rootReducer";
import {Upload, UploadDispatchProps, UploadStateProps} from "./Upload";
import {support} from "../../ducks/support/support";
import {fileUpload} from "../../ducks/upload/upload";
import {selectUpload} from "../../selectors/selectors";

const mapStateToProps: MapStateToProps<UploadStateProps, {}, State> = state => {
    const { file, uploadStatus, uploadResult, resultMessage } = selectUpload(state);

    return {
        resultMessage,
        file,
        uploadStatus,
        uploadResult,
    };
};

const dispatchProps: MapDispatchToProps<UploadDispatchProps, {}> = {
    onSupport: support,
    onFileUpload: fileUpload,
};

export const UploadConnected = connect(
    mapStateToProps,
    dispatchProps,
)(Upload);
