import React from "react";
import styles from "./EntryRow.module.scss";
import {EntryRow} from "./EntryRow";
import {ELEMENT_EXTERNAL_WIDTH} from "../../../helper/screenConsts";

export class EntryRowExternal extends EntryRow {
    render() {
        const { entry, entryUserName, entryDate, entryRowWidth } = this.props;
        const didMountFreeze = true;

        return (
            <EntryRow {...this.props} didMountFreeze={didMountFreeze} entryRowWidth={entryRowWidth}>
                <div style={ELEMENT_EXTERNAL_WIDTH.date} className={styles.simpleDataContainer}>
                    {entryDate}
                </div>
                <div style={ELEMENT_EXTERNAL_WIDTH.user} className={styles.simpleDataContainer}>
                    {entryUserName}
                </div>
                <div style={ELEMENT_EXTERNAL_WIDTH.source} className={styles.simpleDataContainer}>
                    {entry.source_app}
                </div>
                <div style={ELEMENT_EXTERNAL_WIDTH.objectID} className={styles.simpleDataContainer}>
                    {entry.objectid_app}
                </div>
            </EntryRow>
        );
    }
}
