import * as React from "react";

import styles from "./UsersPage.module.scss";
import {UserListConnected} from "../../components/UserList/UserListConnected";
import {PopupDialogConnected} from "../../components/PopupDialog/PopupDialogConnected";

export class UsersPage extends React.PureComponent {
    public render() {
        return (
            <div className={styles.container}>
                <PopupDialogConnected />
                <UserListConnected />
            </div>
        );
    }
}
