import {connect, MapDispatchToProps, MapStateToProps} from "react-redux";
import {PopupDialog, PopupDialogDispatchProps, PopupDialogStateProps} from "./PopupDialog";
import {State} from "../../ducks/rootReducer";
import {selectPopup} from "../../selectors/selectors";
import {popupDelete} from "../../ducks/popup/popup";

const mapStateToProps: MapStateToProps<PopupDialogStateProps, {}, State> = state => {
    const { popups } = selectPopup(state);

    return {
        popups,
    };
};

const dispatchProps: MapDispatchToProps<PopupDialogDispatchProps, {}> = {
    onPopupDelete: popupDelete,
};

export const PopupDialogConnected = connect(
    mapStateToProps,
    dispatchProps,
)(PopupDialog);
