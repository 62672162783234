import {Thunk, TypedAction} from "../types";
import {IFetchClusterElement} from "../entries/entries";
import {ILocation} from "../../api/termApi";

export enum PopupType {
    POPUP_MESSAGE = "POPUP_MESSAGE",
    POPUP_POSTAG= "POPUP_POSTAG",
    POPUP_ERROR_LABEL= "POPUP_ERROR_LABEL",
    POPUP_DELETE_NET= "POPUP_DELETE_NET",
    POPUP_DELETE_LOCATION= "POPUP_DELETE_LOCATION",
    POPUP_POSTAG_ERROR= "POPUP_POSTAG_ERROR",
    POPUP_POSTAG_PROPER_NOUN= "POPUP_POSTAG_PROPER_NOUN",
    POPUP_VALIDATE_ERRORS = "POPUP_VALIDATE_ERRORS",
}

const POPUP_PUSH = "POPUP/PUSH",
    POPUP_DELETE = "POPUP/DELETE",
    SPINNER_PUSH = "SPINNER/SPINNER_PUSH";

export type PopupActions =
    | TypedAction<typeof POPUP_PUSH, string>
    | TypedAction<typeof POPUP_DELETE, string>
    | TypedAction<typeof SPINNER_PUSH, number>;

export interface PopupState {
    spinnerStatus: number;
    popups: Array<PopupElement>;
}

export const initialState = {
    spinnerStatus: 0,
    popups: [],
};

export interface IEditTermData {
    label: string;
    errorText?:string;
    data: IFetchClusterElement[];
    replace?: boolean;
}

export interface PopupElement {
    id?: string | undefined;
    type: PopupType;
    data: string[];
    dataPosTag?: IEditTermData;
    actionVisible?: boolean;
    cancelVisible?: boolean;
    actionTitle?: string;
    net?: string;
    label?: string;
    location?: ILocation;
    cancelTitle?: string;
    actionHandler?: Function | null;
    cancelHandler?: Function | null;
}

export function popupPush(popup: PopupElement): Thunk {
    popup.id = Date.now().toString();

    return async dispatch => {
        dispatch({
            type: POPUP_PUSH,
            payload: popup,
        });
    };
}

export function popupDelete(id: string): Thunk {
    return async dispatch => {
        dispatch({
            type: POPUP_DELETE,
            payload: id,
        });
    };
}

export function spinnerUp(): Thunk {
    return async dispatch => {
        dispatch({
            type: SPINNER_PUSH,
            payload: 1,
        });
    };
}

export function spinnerDown(): Thunk {
    return async dispatch => {
        dispatch({
            type: SPINNER_PUSH,
            payload: -1,
        });
    };
}

export function popupReducer(state: PopupState = initialState, action: PopupActions): PopupState {
    switch (action.type) {
        case POPUP_PUSH:
            return { ...state, popups: [...state.popups, action.payload] };
        case POPUP_DELETE:
            return {
                ...state,
                popups: [...state.popups.filter(popupElem => popupElem.id !== action.payload)],
            };
        case SPINNER_PUSH:
            return { ...state, spinnerStatus: state.spinnerStatus + action.payload };
        default:
            return state;
    }
}
