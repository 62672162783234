import React from "react";

import styles from "./UserList.module.scss";
import Button from "@material-ui/core/Button";
import MaterialTable, {Column, QueryResult} from "material-table";
import {tableIcons} from "./tableIcons";
import Edit from "@material-ui/icons/Edit";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import {User} from "../../api/userApi";
import Loader from "react-loader-spinner";
import {MuiThemeProvider} from "@material-ui/core";
import {COMMON_BLACK, theme} from "../../helper";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import {PopupElement, PopupType} from "../../ducks/popup/popup";

export interface UserListStateProps {
    users: User[];
    count: number;
    limit: number;
    page: number;
    spinnerStatus: number;
}

export interface UserListDispatchProps {
    onEdit: (user: User) => void;
    onDelete: (id: number) => void;
    onQuery: (limit: number, page: number) => void;
    redirectToCreateUser: () => void;
    onPopupPush: (popup: PopupElement) => void;
}

type UserListProps = UserListStateProps & UserListDispatchProps;

const columns: Column<User>[] = [
    {
        title: "Username",
        field: "username",
        cellStyle: {
            color: '#FFFFFF',
            width: '45%',
        },
    },
    {
        width: '45%',
        title: "Roles",
        field: "roles",
        cellStyle: {
            color: '#FFFFFF'
        },
    }
];

export class UserList extends React.Component<UserListProps> {
    public componentDidMount(): void {
        const {limit, page} = this.props;
        this.props.onQuery(9999999, page);
    }

    private getUsersData = (): Promise<QueryResult<User>> => {
        let {count, page} = this.props;
        if (!this.props || !this.props.users) count = page = 0;

        return Promise.resolve({
            data: (this.props && this.props.users) || [],
            totalCount: +count,
            page: page,
        });
    };

    private onChangePage = (page: number) => {
        const {limit} = this.props;
        this.props.onQuery(limit, page);
    };

    render() {
        const {onEdit, onDelete, limit, spinnerStatus} = this.props;
        const updateMaterialTableKey = `MaterialTable_UPDATE_KEY_${Math.random()}`;
        const visibleLoader = spinnerStatus > 0;

        return (
            <div className={styles.container}>
                <div style={{display: visibleLoader ? "flex" : "none"}} className={styles.loaderContainer}>
                    <Loader type="Oval" color="#ff4646" height={100} width={100} visible={visibleLoader}/>
                </div>
                <div className={styles.usersContainer}>
                    <Button
                        onClick={() => this.props.redirectToCreateUser()}
                        variant="contained"
                        color="secondary"
                        className={styles.buttonAdd}
                    >
                        Create new user
                    </Button>
                    <MuiThemeProvider theme={theme}>
                        <ThemeProvider theme={theme}>
                            <MaterialTable
                                title="Users"
                                key={updateMaterialTableKey}
                                icons={tableIcons}
                                columns={columns}
                                data={this.props.users}
                                options={{
                                    pageSize: limit,
                                    pageSizeOptions: [limit],
                                    search: true,
                                    headerStyle: {
                                        backgroundColor: COMMON_BLACK,
                                        fontSize: '18px'
                                    },
                                    emptyRowsWhenPaging: true,
                                }}
                                actions={
                                    [
                                        {
                                            tooltip: "Edit",
                                            icon: () => <Edit/>,
                                            onClick: (e, user) => onEdit(user as User),
                                        },
                                        {
                                            tooltip: "Remove",
                                            icon: () => <DeleteOutline/>,
                                            onClick: (e, user) => {
                                                this.props.onPopupPush({
                                                    id: new Date().getTime().toString(),
                                                    type: PopupType.POPUP_MESSAGE,
                                                    data: [`Are you sure you want to delete user ${(user as User).username}?`],
                                                    actionHandler: () => {
                                                        onDelete((user as User).id)
                                                    },
                                                    actionTitle: "Delete",
                                                    cancelTitle: "Cancel",
                                                    actionVisible: true,
                                                    cancelVisible: true,
                                                });
                                            },
                                        },
                                    ]}
                            />
                        </ThemeProvider>
                    </MuiThemeProvider>
                </div>
            </div>
        );
    }
}
