import {connect, MapDispatchToProps, MapStateToProps} from "react-redux";
import {State} from "../../../ducks/rootReducer";
import {EntryListManual, EntryListManualDispatchProps, EntryListManualStateProps} from "./EntryListManual";
import {
    selectAuth,
    selectEntries,
    selectEntryNotify,
    selectPopup,
    selectUserRole,
    selectUsers
} from "../../../selectors/selectors";
import {
    backlogDelete,
    entriesSet,
    entryPushToBacklog,
    entryValidate,
    languagesQuery,
    netQuery,
    onSetNets,
    posTagsQuery,
} from "../../../ducks/entries/entries";
import {User} from "../../../api/userApi";
import {popupPush} from "../../../ducks/popup/popup";

const mapStateToProps: MapStateToProps<EntryListManualStateProps, {}, State> = state => {
    const userRole = selectUserRole(state);
    const { entries, languages, nets, posTags, pushResult, filteredNets, previousEntries } = selectEntries(state);
    const userId = selectAuth(state).id;
    const notify = selectEntryNotify(state);
    const users: User[] = selectUsers(state).users;

    const { spinnerStatus } = selectPopup(state);

    return {
        entries,
        userId,
        userRole,
        users,
        languages,
        nets,
        posTags,
        pushResult,
        spinnerStatus,
        filteredNets,
        notify,
        previousEntries,
    };
};

const dispatchProps: MapDispatchToProps<EntryListManualDispatchProps, {}> = {
    onLanguagesQuery: languagesQuery,
    onPosTagsQuery: posTagsQuery,
    onNetQuery: netQuery,
    onPopupPush: popupPush,
    onEntryValidate: entryValidate,
    onEntryPushToBacklog: entryPushToBacklog,
    onSetEntries: entriesSet,
    onEntryDelete: backlogDelete,
    onSetNets: onSetNets,
};

export const EntryListManualConnected = connect(
    mapStateToProps,
    dispatchProps,
)(EntryListManual);
