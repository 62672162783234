import {Endpoints, getEndpoint} from "./endpoints";
import {BaseApi} from "./baseApi";
import {IEditLabel} from "./spellingApi";

export interface PosTag {
    code: string;
    name: string;
}

export interface IEditPostag extends IEditLabel{

}

export const POS_TAG_UNDEFINED: PosTag = { code: "UNDEFINED", name: "" };
export const POS_TAG_PROPER_NOUN: PosTag = { code: "PROPER_NOUN", name: "proper noun" };

class PosTagApi extends BaseApi {
    Endpoint = Endpoints.PosTag;

    async getPosTagSla() {
        return getEndpoint('/api/posTagSla')
            .get()
    }

    async editPosTag(props: IEditPostag[]) {
        return await getEndpoint(this.Endpoint,'editPosTags')
            .post(props)
            .res()
    }
}

export function getPosTagApiObj() {
    return new PosTagApi();
}
