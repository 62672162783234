import React from "react";
import styles from "./EntryNotifyPanel.module.scss";
import {
    ENTRIES_NOTIFY_SET,
    ENTRY_NOTIFY_INITIAL,
    EntryNotify,
    EntryNotifyStatus,
    EntryNotifyType,
} from "../../../ducks/entries/entries";
import {SpellingBackColors} from "../EntryRow/Spelling/Spelling";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import {getApiUtils} from "../../../api/utils";
import {truncate} from "../../../helper/others";

export interface EntryNotifyPanelStateProps {
    notify: EntryNotify;
}

export interface EntryNotifyPanelDispatchProps {}

type EntryNotifyPanelProps = EntryNotifyPanelStateProps & EntryNotifyPanelDispatchProps;

export interface EntryNotifyPanelState {}

export class EntryNotifyPanel extends React.PureComponent<EntryNotifyPanelProps, EntryNotifyPanelState> {
    render() {
        const { notify } = this.props;
        let text1 = "",
            text2 = "";

        switch (notify.type) {
            case EntryNotifyType.ENTRY_NOTIFY_LEMMA_AUTOCOMPLETE:
                {
                    text1 = "is already a lemma in the lexicon, here are its spelling(s):";
                    text2 = `make sure that your spelling(s) really should be lemmatized to this lemma`;
                }
                break;
            case EntryNotifyType.ENTRY_NOTIFY_SPELLING_EXISTS:
                {
                    text1 = "is already a spelling in the lexicon, lemmatized to";
                }
                break;
            case EntryNotifyType.ENTRY_NOTIFY_SYNONYM_AUTOCOMPLETE:
                {
                    text1 = "already represents a group of synonyms composed of these lemmas:";
                    text2 = `make sure that "${truncate(notify.insertItem,25)}" bears the same meaning before incorporating it to the group`;
                }
                break;
            case EntryNotifyType.ENTRY_NOTIFY_SPELLING_OVERLAP: {
                text1 = "lemmatized to:";
                text2 = "is already in the lexicon";
            }
        }

        const containerClasses = [styles.container];
        if (notify.type !== EntryNotifyType.ENTRY_NOTIFY_EMPTY) containerClasses.push(styles.containerActive);

        const color =
            notify.status === EntryNotifyStatus.NOTIFY_STATUS_WARNING
                ? SpellingBackColors.WARNING
                : SpellingBackColors.ERROR;

        return (<div onClick={(e) => {e.stopPropagation(); e.nativeEvent.stopPropagation()}} className={containerClasses.join(" ")}>
            <div className={styles.containerTop}>
                <div className={styles.notifyObject}>
                    <div title={notify.object ? notify.object : ""} className={styles.notifyObjectLeftPart}>
                        {notify.object ? truncate(notify.object, 25).split(" ").map(elem => (<div className={styles.wordPartItem}>{` ${elem}`}</div>)) : ""}
                    </div>
                    <div className={styles.notifyObjectRightPart}>
                        <ErrorOutlineOutlinedIcon className={styles.errorNotifyIcon}  style={{ color: color }}/>
                        <div onClick={this.notifyClear} className={styles.closeIconContainer}>
                            <div className={styles.closeIcon}/>
                        </div>
                    </div>
                </div>
                <div className={styles.notifyText}>{text1}</div>
                <div className={styles.notifyListContainer}>
                    {notify.list.map((listElem, index) => (
                        <div title={listElem} className={styles.notifyListElem} key={index}>
                            {truncate(listElem, 25)}
                        </div>
                    ))}
                </div>
            </div>
            <div className={styles.notifyText}>{text2}</div>
        </div>);
    }

    notifyClear = () => {
        const currentNotifyType = getApiUtils().getState().entries.notify.type;

        if (currentNotifyType !== EntryNotifyType.ENTRY_NOTIFY_EMPTY) {
            getApiUtils().dispatch({
                type: ENTRIES_NOTIFY_SET,
                payload: ENTRY_NOTIFY_INITIAL,
            });
        }
    };

}
