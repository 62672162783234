import {Thunk, TypedAction} from "../types";
import {push} from "connected-react-router";
import {ENTRIES_NOTIFY_SET, ENTRIES_SET, ENTRY_NOTIFY_INITIAL} from "../entries/entries";
import {
    SET_UPLOAD_FILE,
    SET_UPLOAD_RESULT,
    SET_UPLOAD_RESULT_MESSAGE,
    SET_UPLOAD_STATUS,
    UploadStatus,
} from "../upload/upload";
import {support} from "../support/support";

export enum ActionVariant {
    VARIANT_UNDEFINED = "VARIANT_UNDEFINED",
    VARIANT_UPLOAD = "VARIANT_UPLOAD",
    VARIANT_MANUAL = "ROLE_MANUAL",
    VARIANT_BACKLOG = "ROLE_BACKLOG",
    VARIANT_EXTERNAL_APP = "ROLE_EXTERNAL_APP",
    VARIANT_SEARCH = "SEARCH",
}

export const SET_ACTION_VARIANT = "ACTION/SET_ACTION_VARIANT";

export type ActionActions = TypedAction<typeof SET_ACTION_VARIANT, boolean>;

export interface ActionState {
    actionVariant: ActionVariant;
}

export const initialState = {
    actionVariant: ActionVariant.VARIANT_UNDEFINED,
};

export function actionChange(value: string): Thunk {
    return dispatch => {
        dispatch({
            type: SET_ACTION_VARIANT,
            payload: value,
        });
    };
}

export function actionValidate(action: ActionVariant): Thunk {
    return dispatch => {
        dispatch({ type: ENTRIES_SET, payload: [] });
        dispatch({ type: ENTRIES_NOTIFY_SET, payload: ENTRY_NOTIFY_INITIAL });

        switch (action) {
            case ActionVariant.VARIANT_UPLOAD:
                {
                    dispatch(support(false));
                    dispatch({ type: SET_UPLOAD_RESULT, payload: undefined });
                    dispatch({ type: SET_UPLOAD_RESULT_MESSAGE, payload: "" });
                    dispatch({ type: SET_UPLOAD_STATUS, payload: UploadStatus.BEFORE });
                    dispatch({ type: SET_UPLOAD_FILE, payload: null });
                    dispatch(push("/upload"));
                    window.location.reload();
                }
                break;
            case ActionVariant.VARIANT_MANUAL:
                {
                    dispatch(push("/new-entries"));
                    window.location.reload();
                }
                break;
            case ActionVariant.VARIANT_SEARCH:
                {
                    dispatch(push("/search"));
                }
                break;
            case ActionVariant.VARIANT_BACKLOG:
                {
                    dispatch(push("/backlog"));
                    window.location.reload();
                }
                break;
            case ActionVariant.VARIANT_EXTERNAL_APP:
                {
                    dispatch(push("/external-app"));
                    window.location.reload();
                }
                break;
            default:
                dispatch(push("/"));
        }
    };
}

export function actionReducer(state: ActionState = initialState, action: ActionActions): ActionState {
    switch (action.type) {
        case SET_ACTION_VARIANT:
            return {
                ...state,
                actionVariant: action.payload,
            };
        default:
            return state;
    }
}
